<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Print Documents</h1>

<div mat-dialog-content class="py-2">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0 w-100">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Id Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
      <td mat-cell *matCellDef="let element">
        {{ element.title }}
      </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element">
        {{
          element.isUploaded
            ? 'Uploaded'
            : element.isSubmitted && element.type === 'Meeting Notice'
            ? 'Meeting Held'
            : !element.isSubmitted && element.type === 'Meeting Notice'
            ? 'Meeting Pending'
            : element.isSubmitted
            ? 'Complete'
            : 'Incomplete'
        }}
      </td>
    </ng-container>

    <!-- SubmittedOn Column -->
    <ng-container matColumnDef="submittedOn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Submitted On</th>
      <td mat-cell *matCellDef="let element">
        {{ (element.submittedOn ? element.submittedOn : element.createdOn) | dateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef colspan="7">
        <i>No documents</i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
    <tr
      mat-footer-row
      [hidden]="dataSource.data?.length !== 0"
      *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"
      class="example-second-footer-row"
    ></tr>
  </table>
  <mat-paginator [dataSource]="dataSource"></mat-paginator>
</div>

<div mat-dialog-actions class="display-flex justify-content-end align-items-center">
  <div class="display-flex my-2">
    <button mat-button aria-label="Delete Icon" class="background-color--muted" (click)="onClose()" tabindex="0" [isBusy]="activeCall">
      Close
    </button>
    <button
      mat-raised-button
      aria-label="Print"
      color="primary"
      (click)="onPrint()"
      [disabled]="selection.selected.length === 0"
      tabindex="0"
      [isBusy]="activeCall"
    >
      Print
    </button>
  </div>
</div>
