<!--suppress XmlDuplicatedId -->
<ng-container *ngIf="!readOnly || showReadOnlyControl; else readOnlyTemplate">
  <div class="prefill" *ngIf="useOverwrite; else basic">
    <div class="prefill__input">
      <ng-container *ngTemplateOutlet="basic"></ng-container>
    </div>
    <div class="prefill__text" *ngIf="!disabledState && dataSourceValue !== null">
      QR:
      <span *ngIf="dataSourceValue !== true && dataSourceValue !== false">{{ sourceFieldShownLabel || dataSourceValue }}</span>
      <span *ngIf="dataSourceValue === true || dataSourceValue === false" role="radio">{{ dataSourceValue === true ? 'Yes' : 'No' }}</span>
    </div>
    <div class="prefill__container" (click)="replaceWithSourceValue()" *ngIf="!disabledState && dataSourceValue !== null && !undoValue">
      <div class="prefill__icon">
        <mat-icon aria-label="QR Arrow Symbol">call_merge</mat-icon>
      </div>
    </div>
    <div class="prefill__container prefill__container--undo" *ngIf="undoValue">
      <p class="prefill__icon mb-0" (click)="undoReplaceWithSourceValue()">Undo</p>
    </div>
  </div>
</ng-container>

<ng-template #basic>
  <div class="vertical-control" [ngClass]="{ 'flex-row align-items-center flex-wrap': inline }">
    <div class="display-flex align-items-baseline">
      <label
        class="text-md vertical-label"
        [ngClass]="{
          asterisk_input: control.hasError('required') || showAsterisk,
          'asterisk_input--hide': hideAsterisk,
          'mr-2': inline
        }"
        [innerHTML]="label"
      ></label>

      <button
        *ngIf="useUndo && column"
        mat-icon-button
        aria-label="Clear Icon"
        type="button"
        color="primary"
        class="text-sm vertical-label"
        [ngClass]="{
          'mr-4': dataSourceValue !== null && !undoValue && !inline,
          'mr-2': dataSourceValue !== null && !undoValue && inline
        }"
        (click)="onClear()"
        tabindex="0"
      >
        <i class="fas fa-undo" aria-label="Clear Icon"></i>
      </button>
    </div>
    <mat-radio-group
      [formControl]="control"
      [attr.readOnly]="readOnly ? 'readOnly' : null"
      [attr.disabled]="disabledState ? 'disabled' : null"
      (change)="selectionChanged($event)"
      role="radiogroup"
    >
      <mat-radio-button
        *ngFor="let item of optionsBacking; let i = index; first as isFirst"
        [value]="optionsBacking[i].key"
        [ngClass]="{
          wrap: wrap,
          column: column
        }"
        [disabled]="!optionsBacking[i].enabled"
        attr.aria-label="{{ optionsBacking[i].value }}"
        role="radio"
      >
        <span
          [ngClass]="{
            asterisk_input: isFirst && noLabelAsterisk && control.value === ''
          }"
        >
          {{ optionsBacking[i].value }}
        </span>
      </mat-radio-button>

      <button
        *ngIf="useUndo && !column"
        mat-icon-button
        aria-label="Clear Icon"
        type="button"
        color="primary"
        class="text-sm vertical-label"
        [ngClass]="{
          'mr-4': dataSourceValue !== null && !undoValue
        }"
        (click)="onClear()"
        tabindex="0"
      >
        <i class="fas fa-undo" aria-label="Clear Icon"></i>
      </button>
    </mat-radio-group>
    <mat-error *ngIf="control.touched" [ngClass]="{ 'error--inline': inline }">
      <app-field-error-display [control]="control"></app-field-error-display>
    </mat-error>
  </div>
</ng-template>

<ng-template #readOnlyTemplate>
  <p class="read-only-container" [innerHTML]="'<strong>' + label + '</strong> ' + control.value"></p>
</ng-template>
