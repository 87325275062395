<main class="learner-management">
  <!-- Exited Case Banner -->
  <ng-container *ngFor="let caseSummary of exitedCases">
    <app-case-exit-banner *ngIf="exitedCaseBannerEligible(caseSummary)" [caseSummary]="caseSummary"></app-case-exit-banner>
  </ng-container>

  <!-- Owner header -->
  <app-case-owner-header
    *ngFor="let caseSummary of activeCases; let i = index"
    [class.mt-2]="i > 0"
    [caseSummary]="caseSummary"
    [learnerHasWorkableCase]="hasWorkableCase"
    (caseShared)="loadData()"
  >
  </app-case-owner-header>

  <div
    class="card-spacing-top"
    *ngIf="
      (learner?.movingFromOutOfState || learner?.eligibleInLegacySystem || learner?.eligibleInLegacySystemPartC) &&
      !learner?.isMigrated &&
      !isPortalUser
    "
  >
    <app-move-in-details *ngFor="let caseSummary of activeCases" [caseSummary]="caseSummary"></app-move-in-details>
  </div>

  <!-- Quick Links -->
  <ng-container *ngIf="showQuickLinks">
    <ng-container *ngFor="let caseSummary of quickLinkCases">
      <section class="card-spacing-top">
        <app-quick-links [caseSummary]="caseSummary"></app-quick-links>
      </section>
    </ng-container>
  </ng-container>

  <!-- Shared Users -->
  <ng-container *ngFor="let caseSummary of activeCases">
    <app-shared-users
      [caseId]="caseSummary.caseId"
      [caseUsers]="caseSummary.caseUsers"
      [intakeType]="caseSummary.intakeType"
    ></app-shared-users>
  </ng-container>

  <!-- Regular Early Childhood Programs -->
  <section *ngIf="learner?.isPK && !partBActiveCase?.exitFinalizedOn" class="card-spacing-top">
    <!--(refreshCase)="refreshCase(caseSummary)"-->
    <app-ec-programs
      [learnerId]="learnerId"
      [kindergartenStartDate]="learner.kindergartenStartDate"
      [learnerHasWorkableCase]="hasWorkableCase"
      [caseSummary]="partBActiveCase"
    ></app-ec-programs>
  </section>

  <!-- Activity -->
  <!-- <ng-container
    *appAuthForRoles="{
      permission: [permissions.ViewActivityLog],
      caseId: partBActiveCase?.id
    }"
  > -->
  <section class="card-spacing-top" *ngIf="familyMeetings?.length > 0">
    <h3 class="mb-2 mt-0">{{ isPortalUser ? 'Recent & Upcoming Meetings' : 'Activity' }}</h3>

    <app-help-directional-text>
      <p class="mb-2">Click on event to display detail.</p>
    </app-help-directional-text>

    <mat-card class="p-0 overflow-hidden">
      <mat-accordion #meetingAccordion="matAccordion" class="accordion accordion--close accordion--short" displayMode="flat" multi>
        <ng-container *ngFor="let meeting of familyMeetings | slice : 0 : 3">
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title class="display-flex align-items-center justify-content-between flex-wrap">
                <div class="meeting__title">
                  <h4 class="text-primary text-nrm my-0 mr-1">
                    <span [ngClass]="{ 'text-line-through': isMeetingCancelled(meeting) }">{{ meeting.purposesOfMeeting.join(', ') }}</span>
                    - Meeting {{ isMeetingCancelled(meeting) ? 'CANCELED' : '' }}
                  </h4>
                </div>

                <div class="meeting__meta">
                  <p class="my-0 text-md mr-1" *ngIf="meeting.dateAndTime">{{ meeting?.dateAndTime | dateTimeFormat }} |</p>
                  <p class="my-0 text-md" *ngIf="meeting.location">
                    {{ meeting?.location }}
                  </p>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <app-family-meeting-list
              [familyMeeting]="meeting"
              [intakeType]="meeting.intakeType"
              [caseId]="meeting.caseId"
              [learnerId]="learnerId"
              [learnerHasWorkableCase]="hasWorkableCase"
              [caseFamilyMembers]="familyMembers"
              [inLearnerDashboard]="true"
              (updateRescheduledDate)="updateRescheduledDate($event)"
              (refreshData)="refreshData()"
            ></app-family-meeting-list>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </mat-card>
  </section>
  <!-- </ng-container> -->

  <!-- EARLY Access Referral Submitted date -->
  <mat-card class="card-spacing-top py-2" *ngIf="!!caseWithEarlyAccessReferral && !partCActiveCase?.learner?.eligibleInLegacySystemPartC">
    <mat-card-content>
      <ul class="referral__list">
        <li class="referral__item">
          <div class="referral__body align-items-baseline">
            <h4 class="my-0 mr-1 text-primary text-nrm">Referral Received:</h4>
            <span>
              {{ caseWithEarlyAccessReferral.earlyAccessReferralSubmittedOn | dateFormat }}
            </span>
          </div>
          <ng-container *ngIf="canViewReferral(caseWithEarlyAccessReferral?.caseId)">
            <div class="referral__action">
              <a
                (click)="routeToEarlyAccessReferralReadOnlyForm(caseWithEarlyAccessReferral)"
                mat-raised-button
                color="primary"
                role="link"
              >
                View Referral
                <i class="far fa-eye ml-2" aria-labelledby="View Referral"></i>
              </a></div
          ></ng-container>
        </li>
      </ul>
    </mat-card-content>
  </mat-card>

  <!-- Part C Exiting -->
  <ng-container *ngIf="canExitPartC || isAchieveDataLead">
    <section class="card-spacing-top" *ngIf="partCActiveCase?.exiting && partCActiveCase?.exitFinalizedOn === null">
      <h3 class="mb-2 mt-0">
        Ending / Exiting
        {{ partCActiveCase?.intakeType === intakeTypeEnum.PartB ? 'Special Education' : 'Early ACCESS' }}
      </h3>
      <app-exiting-section
        [case]="partCActiveCase"
        (finalizeExitEvent)="exitFinalized($event)"
        (cancelExitEvent)="exitCancelled($event)"
      ></app-exiting-section>
    </section>
  </ng-container>

  <!-- Part B Exiting -->
  <ng-container *ngIf="canExitPartB || isAchieveDataLead">
    <section class="card-spacing-top" *ngIf="partBActiveCase?.exiting && partBActiveCase?.exitFinalizedOn === null">
      <h3 class="mb-2 mt-0">
        Ending / Exiting
        {{ partBActiveCase?.intakeType === intakeTypeEnum.PartB ? 'Special Education' : 'Early ACCESS' }}
      </h3>
      <app-exiting-section
        [case]="partBActiveCase"
        (finalizeExitEvent)="exitFinalized($event)"
        (cancelExitEvent)="exitCancelled($event)"
      ></app-exiting-section>
    </section>
  </ng-container>

  <!-- Part B Eco Final Score -->
  <ng-container *ngIf="showEcoFinalScorePkPartBSession">
    <section class="card-spacing-top">
      <h3 class="mb-2 mt-0">Final ECO Ratings</h3>
      <app-eco-final-score-pk-part-b [case]="partBActiveCase"> </app-eco-final-score-pk-part-b>
    </section>
  </ng-container>
</main>

<div class="page-bottom-leeway"></div>
