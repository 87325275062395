<form [formGroup]="formGroup">
  <div class="row">
    <div class="col-md-12 text-right">
      <button
        *ngIf="hasOpenAmendment"
        [disabled]="!readonlyEsy"
        class="mb-2"
        mat-raised-button
        aria-label="Amend"
        color="primary"
        (click)="startAmendment()"
        tabindex="0"
      >
        Amend
      </button>
    </div>
  </div>

  <mat-card *ngIf="showField.FactorsSection">
    <div class="row">
      <div class="col-md-12" *ngIf="showField.AccessibleMaterials">
        <label
          class="vertical-label display-block"
          [ngClass]="{
            asterisk_input: formGroup.get('AccessibleMaterials').hasError('required')
          }"
        >
          This student has a special factor of Accessible Education Materials (AEM).<br />
          Please note any considerations for Extended School Year based on the above special factor(s).
        </label>
        <app-textarea
          *ngIf="!readonlyEsy; else AccessibleMaterialsReadOnlyTemplate"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.esyOverview.accessibleMaterials"
          [bankableFieldChildFname]="childFirstName"
          formControlName="AccessibleMaterials"
          [hideAsterisk]="true"
          [rows]="4"
          [maxLength]="stringSizes.extraLarge"
          attr.aria-required="{{ formGroup.get('AccessibleMaterials').hasError('required') ? 'true' : 'false' }}"
        ></app-textarea>
        <ng-template #AccessibleMaterialsReadOnlyTemplate>
          <p class="my-2">
            <app-compare-amendment-output
              [iepAmendments]="amendments"
              [priorVersionId]="esyConsideration?.priorVersionId"
              [isActive]="esyConsideration?.isActive"
              [changedVersionText]="formGroup.get('AccessibleMaterials').value"
              [currentVersionText]="esyConsideration?.priorVersion?.accessibleMaterialsConsiderations"
              [currentPrefixText]="esyConsideration?.priorVersion && esyConsideration.amendingESYPlan ? 'Current: ' : ''"
            ></app-compare-amendment-output>
          </p>
        </ng-template>
        <app-compare-amendment-output
          *ngIf="esyConsideration.amendingESYPlan && hasOpenAmendment"
          [iepAmendments]="amendments"
          [priorVersionId]="esyConsideration?.priorVersionId"
          [isActive]="esyConsideration?.isActive"
          [changedVersionText]="formGroup.get('AccessibleMaterials').value"
          [currentVersionText]="esyConsideration?.priorVersion?.accessibleMaterialsConsiderations"
          [currentPrefixText]="esyConsideration?.priorVersion ? 'Current: ' : ''"
        ></app-compare-amendment-output>
      </div>

      <div class="col-md-12" *ngIf="showField.AssistiveTechnology">
        <label
          class="vertical-label display-block"
          [ngClass]="{
            asterisk_input: formGroup.get('AssistiveTechnology').hasError('required')
          }"
        >
          This student has a special factor of Assistive Technology.<br />
          Please note any considerations for Extended School Year based on the above special factor(s).
        </label>
        <app-textarea
          *ngIf="!readonlyEsy; else AssistiveTechnologyReadOnlyTemplate"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.esyOverview.assistiveTechnology"
          [bankableFieldChildFname]="childFirstName"
          formControlName="AssistiveTechnology"
          [hideAsterisk]="true"
          [rows]="4"
          [maxLength]="stringSizes.extraLarge"
          attr.aria-required="{{ formGroup.get('AssistiveTechnology').hasError('required') ? 'true' : 'false' }}"
        ></app-textarea>
        <ng-template #AssistiveTechnologyReadOnlyTemplate>
          <p class="my-2">
            <app-compare-amendment-output
              [iepAmendments]="amendments"
              [priorVersionId]="esyConsideration?.priorVersionId"
              [isActive]="esyConsideration?.isActive"
              [changedVersionText]="formGroup.get('AssistiveTechnology').value"
              [currentVersionText]="esyConsideration?.priorVersion?.assistiveTechnologyConsiderations"
              [currentPrefixText]="esyConsideration?.priorVersion && esyConsideration.amendingESYPlan ? 'Current: ' : ''"
            ></app-compare-amendment-output>
          </p>
        </ng-template>
        <app-compare-amendment-output
          *ngIf="esyConsideration.amendingESYPlan && hasOpenAmendment"
          [iepAmendments]="amendments"
          [priorVersionId]="esyConsideration?.priorVersionId"
          [isActive]="esyConsideration?.isActive"
          [changedVersionText]="formGroup.get('AssistiveTechnology').value"
          [currentVersionText]="esyConsideration?.priorVersion?.assistiveTechnologyConsiderations"
          [currentPrefixText]="esyConsideration?.priorVersion ? 'Current: ' : ''"
        ></app-compare-amendment-output>
      </div>

      <div class="col-md-12" *ngIf="showField.Braille">
        <label
          class="vertical-label display-block"
          [ngClass]="{
            asterisk_input: formGroup.get('Braille').hasError('required')
          }"
        >
          This student has a special factor of Vision.<br />
          Please note any considerations for Extended School Year based on the above special factor(s).
        </label>
        <app-textarea
          *ngIf="!readonlyEsy; else BrailleReadOnlyTemplate"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.esyOverview.braille"
          [bankableFieldChildFname]="childFirstName"
          formControlName="Braille"
          [hideAsterisk]="true"
          [rows]="4"
          [maxLength]="stringSizes.extraLarge"
          attr.aria-required="{{ formGroup.get('Braille').hasError('required') ? 'true' : 'false' }}"
        ></app-textarea>
        <ng-template #BrailleReadOnlyTemplate>
          <p class="my-2">
            <app-compare-amendment-output
              [iepAmendments]="amendments"
              [priorVersionId]="esyConsideration?.priorVersionId"
              [isActive]="esyConsideration?.isActive"
              [changedVersionText]="formGroup.get('Braille').value"
              [currentVersionText]="esyConsideration?.priorVersion?.brailleConsiderations"
              [currentPrefixText]="esyConsideration?.priorVersion && esyConsideration.amendingESYPlan ? 'Current: ' : ''"
            ></app-compare-amendment-output>
          </p>
        </ng-template>
        <app-compare-amendment-output
          *ngIf="esyConsideration.amendingESYPlan && hasOpenAmendment"
          [iepAmendments]="amendments"
          [priorVersionId]="esyConsideration?.priorVersionId"
          [isActive]="esyConsideration?.isActive"
          [changedVersionText]="formGroup.get('Braille').value"
          [currentVersionText]="esyConsideration?.priorVersion?.brailleConsiderations"
          [currentPrefixText]="esyConsideration?.priorVersion ? 'Current: ' : ''"
        ></app-compare-amendment-output>
      </div>

      <div class="col-md-12" *ngIf="showField.CommunicationLanguage">
        <label
          class="vertical-label display-block"
          [ngClass]="{
            asterisk_input: formGroup.get('CommunicationLanguage').hasError('required')
          }"
        >
          This student has a special factor of Communication.<br />
          Please note any considerations for Extended School Year based on the above special factor(s).
        </label>
        <app-textarea
          *ngIf="!readonlyEsy; else CommunicationLanguageReadOnlyTemplate"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.esyOverview.communicationLanguage"
          [bankableFieldChildFname]="childFirstName"
          formControlName="CommunicationLanguage"
          [hideAsterisk]="true"
          [rows]="4"
          [maxLength]="stringSizes.extraLarge"
          attr.aria-required="{{ formGroup.get('CommunicationLanguage').hasError('required') ? 'true' : 'false' }}"
        ></app-textarea>
        <ng-template #CommunicationLanguageReadOnlyTemplate>
          <p class="my-2">
            <app-compare-amendment-output
              [iepAmendments]="amendments"
              [priorVersionId]="esyConsideration?.priorVersionId"
              [isActive]="esyConsideration?.isActive"
              [changedVersionText]="formGroup.get('CommunicationLanguage').value"
              [currentVersionText]="esyConsideration?.priorVersion?.communicationLanguageConsiderations"
              [currentPrefixText]="esyConsideration?.priorVersion && esyConsideration.amendingESYPlan ? 'Current: ' : ''"
            ></app-compare-amendment-output>
          </p>
        </ng-template>
        <app-compare-amendment-output
          *ngIf="esyConsideration.amendingESYPlan && hasOpenAmendment"
          [iepAmendments]="amendments"
          [priorVersionId]="esyConsideration?.priorVersionId"
          [isActive]="esyConsideration?.isActive"
          [changedVersionText]="formGroup.get('CommunicationLanguage').value"
          [currentVersionText]="esyConsideration?.priorVersion?.communicationLanguageConsiderations"
          [currentPrefixText]="esyConsideration?.priorVersion ? 'Current: ' : ''"
        ></app-compare-amendment-output>
      </div>
      <div class="col-md-12" *ngIf="showField.LimitedEnglish">
        <label
          class="vertical-label display-block"
          [ngClass]="{
            asterisk_input: formGroup.get('LimitedEnglish').hasError('required')
          }"
        >
          This student has a special factor of English Learner.<br />
          Please note any considerations for Extended School Year based on the above special factor(s).
        </label>
        <app-textarea
          *ngIf="!readonlyEsy; else LimitedEnglishReadOnlyTemplate"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.esyOverview.limitedEnglish"
          [bankableFieldChildFname]="childFirstName"
          formControlName="LimitedEnglish"
          [hideAsterisk]="true"
          [rows]="4"
          [maxLength]="stringSizes.extraLarge"
          attr.aria-required="{{ formGroup.get('LimitedEnglish').hasError('required') ? 'true' : 'false' }}"
        ></app-textarea>
        <ng-template #LimitedEnglishReadOnlyTemplate>
          <p class="my-2">
            <app-compare-amendment-output
              [iepAmendments]="amendments"
              [priorVersionId]="esyConsideration?.priorVersionId"
              [isActive]="esyConsideration?.isActive"
              [changedVersionText]="formGroup.get('LimitedEnglish').value"
              [currentVersionText]="esyConsideration?.priorVersion?.limitedEnglishConsiderations"
              [currentPrefixText]="esyConsideration?.priorVersion && esyConsideration.amendingESYPlan ? 'Current: ' : ''"
            ></app-compare-amendment-output>
          </p>
        </ng-template>
        <app-compare-amendment-output
          *ngIf="esyConsideration.amendingESYPlan && hasOpenAmendment"
          [iepAmendments]="amendments"
          [priorVersionId]="esyConsideration?.priorVersionId"
          [isActive]="esyConsideration?.isActive"
          [changedVersionText]="formGroup.get('LimitedEnglish').value"
          [currentVersionText]="esyConsideration?.priorVersion?.limitedEnglishConsiderations"
          [currentPrefixText]="esyConsideration?.priorVersion ? 'Current: ' : ''"
        ></app-compare-amendment-output>
      </div>

      <div class="col-md-12" *ngIf="showField.Health">
        <label
          class="vertical-label display-block"
          [ngClass]="{
            asterisk_input: formGroup.get('Health').hasError('required')
          }"
        >
          This student has a special factor of Health.<br />
          Please note any considerations for Extended School Year based on the above special factor(s).
        </label>
        <app-textarea
          *ngIf="!readonlyEsy; else HealthReadOnlyTemplate"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.esyOverview.health"
          [bankableFieldChildFname]="childFirstName"
          formControlName="Health"
          [hideAsterisk]="true"
          [rows]="4"
          [maxLength]="stringSizes.extraLarge"
          attr.aria-required="{{ formGroup.get('Health').hasError('required') ? 'true' : 'false' }}"
        ></app-textarea>
        <ng-template #HealthReadOnlyTemplate>
          <p class="my-2">
            <app-compare-amendment-output
              [iepAmendments]="amendments"
              [priorVersionId]="esyConsideration?.priorVersionId"
              [isActive]="esyConsideration?.isActive"
              [changedVersionText]="formGroup.get('Health').value"
              [currentVersionText]="esyConsideration?.priorVersion?.healthConsiderations"
              [currentPrefixText]="esyConsideration?.priorVersion && esyConsideration.amendingESYPlan ? 'Current: ' : ''"
            ></app-compare-amendment-output>
          </p>
        </ng-template>
        <app-compare-amendment-output
          *ngIf="esyConsideration.amendingESYPlan && hasOpenAmendment"
          [iepAmendments]="amendments"
          [priorVersionId]="esyConsideration?.priorVersionId"
          [isActive]="esyConsideration?.isActive"
          [changedVersionText]="formGroup.get('Health').value"
          [currentVersionText]="esyConsideration?.priorVersion?.healthConsiderations"
          [currentPrefixText]="esyConsideration?.priorVersion ? 'Current: ' : ''"
        ></app-compare-amendment-output>
      </div>

      <div class="col-md-12" *ngIf="showField.Hearing">
        <label
          class="vertical-label display-block"
          [ngClass]="{
            asterisk_input: formGroup.get('Hearing').hasError('required')
          }"
        >
          This student has a special factor of Hearing.<br />
          Please note any considerations for Extended School Year based on the above special factor(s).
        </label>
        <app-textarea
          *ngIf="!readonlyEsy; else HearingReadOnlyTemplate"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.esyOverview.hearing"
          [bankableFieldChildFname]="childFirstName"
          formControlName="Hearing"
          [hideAsterisk]="true"
          [rows]="4"
          [maxLength]="stringSizes.extraLarge"
          attr.aria-required="{{ formGroup.get('Hearing').hasError('required') ? 'true' : 'false' }}"
        ></app-textarea>
        <ng-template #HearingReadOnlyTemplate>
          <p class="my-2">
            <app-compare-amendment-output
              [iepAmendments]="amendments"
              [priorVersionId]="esyConsideration?.priorVersionId"
              [isActive]="esyConsideration?.isActive"
              [changedVersionText]="formGroup.get('Hearing').value"
              [currentVersionText]="esyConsideration?.priorVersion?.hearingConsiderations"
              [currentPrefixText]="esyConsideration?.priorVersion && esyConsideration.amendingESYPlan ? 'Current: ' : ''"
            ></app-compare-amendment-output>
          </p>
        </ng-template>
        <app-compare-amendment-output
          *ngIf="esyConsideration.amendingESYPlan && hasOpenAmendment"
          [iepAmendments]="amendments"
          [priorVersionId]="esyConsideration?.priorVersionId"
          [isActive]="esyConsideration?.isActive"
          [changedVersionText]="formGroup.get('Hearing').value"
          [currentVersionText]="esyConsideration?.priorVersion?.hearingConsiderations"
          [currentPrefixText]="esyConsideration?.priorVersion ? 'Current: ' : ''"
        ></app-compare-amendment-output>
      </div>

      <div class="col-md-12" *ngIf="showField.Behavior">
        <label
          class="vertical-label display-block"
          [ngClass]="{
            asterisk_input: formGroup.get('Behavior').hasError('required')
          }"
        >
          This student has a special factor of Social Emotional Behavior.<br />
          Please note any considerations for Extended School Year based on the above special factor(s).
        </label>
        <app-textarea
          *ngIf="!readonlyEsy; else BehaviorReadOnlyTemplate"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.esyOverview.behavior"
          [bankableFieldChildFname]="childFirstName"
          formControlName="Behavior"
          [hideAsterisk]="true"
          [rows]="4"
          [maxLength]="stringSizes.extraLarge"
          attr.aria-required="{{ formGroup.get('Behavior').hasError('required') ? 'true' : 'false' }}"
        ></app-textarea>
        <ng-template #BehaviorReadOnlyTemplate>
          <p class="my-2">
            <app-compare-amendment-output
              [iepAmendments]="amendments"
              [priorVersionId]="esyConsideration?.priorVersionId"
              [isActive]="esyConsideration?.isActive"
              [changedVersionText]="formGroup.get('Behavior').value"
              [currentVersionText]="esyConsideration?.priorVersion?.behaviorConsiderations"
              [currentPrefixText]="esyConsideration?.priorVersion && esyConsideration.amendingESYPlan ? 'Current: ' : ''"
            ></app-compare-amendment-output>
          </p>
        </ng-template>
        <app-compare-amendment-output
          *ngIf="esyConsideration.amendingESYPlan && hasOpenAmendment"
          [iepAmendments]="amendments"
          [priorVersionId]="esyConsideration?.priorVersionId"
          [isActive]="esyConsideration?.isActive"
          [changedVersionText]="formGroup.get('Behavior').value"
          [currentVersionText]="esyConsideration?.priorVersion?.behaviorConsiderations"
          [currentPrefixText]="esyConsideration?.priorVersion ? 'Current: ' : ''"
        ></app-compare-amendment-output>
      </div>
    </div>
  </mat-card>
</form>

<div class="divider card-spacing-top"></div>

<mat-accordion class="accordion accordion--short">
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="display-flex justify-content-between align-items-center w-100">
          <h3 class="my-0 text-normal">Incomplete Data Report</h3>

          <div class="display-flex align-items-center">
            <span *ngIf="incompleteItems?.length > 1" class="ml-2 text-danger text-sm position-fix-notice">
              {{ incompleteItems?.length }} Notices
            </span>

            <span *ngIf="incompleteItems?.length === 1" class="ml-2 text-danger text-sm position-fix-notice"> 1 Notice </span>
            <button
              mat-icon-button
              color="primary"
              (click)="refreshIncompleteItems($event)"
              class="ml-2"
              matTooltip="Refresh"
              aria-label="Refresh Icon"
              tabindex="0"
            >
              <mat-icon aria-label="Refresh Icon"> refresh </mat-icon>
            </button>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-esy-incomplete-data-report [incompleteItems]="incompleteItems" [iepId]="iepId"></app-esy-incomplete-data-report>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion class="accordion accordion--short">
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title> <h3 class="my-0 text-normal">ESY Reports</h3> </mat-panel-title>
    </mat-expansion-panel-header>

    <div [removeIfFeatureOff]="'outputEsyEnabled'">
      <button
        *ngIf="showDraftESYBtn"
        mat-raised-button
        color="primary"
        class="mr-2"
        aria-labelledby="ESY Plan"
        (click)="onViewDraftESY()"
        target="blank"
        tabindex="0"
        role="link"
        [isBusy]="generatingDraftESY"
        [disableAfterBusy]="false"
      >
        ESY Plan
      </button>
      <app-create-output-button
        *ngIf="caseSummary?.iepFinalized"
        buttonText="ESY Plan"
        [apiId]="iepId"
        [learnerId]="caseSummary?.learnerId"
        [documentId]="caseSummary?.esyDocumentId"
        [output]="pdfOutputs.EsyPlan"
      ></app-create-output-button>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<div class="page-bottom-leeway"></div>
