import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { CaseSummary } from 'src/app/shared/models/case';
import { HelpSection, HelpTerm } from 'src/app/shared/services/help/help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import { IepGoalHelp } from 'src/app/shared/services/help/models/iep.help';
import { BaseComponent } from '../../../../../shared/components/base-component/base-component';
import { IepGoalQuantifiableDatum, QuantifiableDataRating } from '../../../../../shared/components/quantifiable-data/quantifiable-data';
import { DeactivationService } from '../../../../../shared/services/deactivation.service';
import { IepGoal } from '../../../../models/iep';

@Component({
  selector: 'app-iep-data-points',
  templateUrl: './iep-data-points.component.html',
  styleUrls: ['./iep-data-points.component.scss'],
})
export class IepDataPointsComponent extends BaseComponent implements OnInit, OnChanges {
  helpSection = HelpSection;
  iepGoalHelp = IepGoalHelp;

  constructor(
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private helpService: HelpService,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  @Input() formGroup: FormGroup;
  @Input() scaleRatings: QuantifiableDataRating[];
  @Input() caseSummary: CaseSummary;
  @Input() isAdditionalQuant = false;
  @Input() primaryUnitOfMeasurement: string;
  @Input() primaryTargetValue: number;
  @Input() useSameUnitAndTarget = false;
  @Input() rubricMaxCalc;
  @Input() combinedObjective = false;
  @Input() maxTargetValue: number;
  @Input() secondMeasurement = false;
  @Input() amendingGoal: IepGoal;
  @Input() amendmentId: string;
  @Input() amendmentFinalized: boolean;
  @Input() lastFinalizedDate: Date;

  get dataPointsGroup() {
    return this.formGroup.get('dataPoints');
  }

  get disableBaselineAndTarget() {
    return this.combinedObjective && !this.isAdditionalQuant;
  }

  get currentQuantData() {
    return this.formGroup.value;
  }

  get priorVersionQuantData() {
    if (this.secondMeasurement) {
      return this.amendingGoal.priorVersion?.primaryQuantifiableData.secondaryMeasurement;
    } else {
      return this.amendingGoal.priorVersion?.primaryQuantifiableData.primaryMeasurement;
    }
  }

  get priorVersionAdditionalQuantData() {
    const adltQuantData = this.formGroup.value as IepGoalQuantifiableDatum;
    if (this.secondMeasurement) {
      return this.priorVersion?.additionalQuantifiableData.find((x) => x.secondaryMeasurement.id === adltQuantData.priorVersionId)
        ?.secondaryMeasurement;
    } else {
      return this.priorVersion?.additionalQuantifiableData.find((x) => x.primaryMeasurement.id === adltQuantData.priorVersionId)
        ?.primaryMeasurement;
    }
  }

  get priorVersion() {
    return this.amendingGoal?.priorVersion;
  }

  today = dayjs().startOf('day').toDate();

  dataPointLimitExceeded = false;
  isManualBaseline = true;

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formGroup) {
      this.dataPointsGroup.valueChanges.subscribe(() => {
        setTimeout(() => {
          this.setBaseline();
        }, 0);
      });
      this.setBaseline();
    }
  }

  calculateBaseLine() {
    if (this.dataPointsGroup.value?.length === 0) {
      this.addDataPoint();
    } else {
      const dataFormArray = this.dataPointsGroup as FormArray;
      dataFormArray.clear();
    }
    this.cd.detectChanges();
  }

  addDataPoint() {
    const dataPoints = this.dataPointsGroup as FormArray;
    if (dataPoints.length > 14) {
      this.dataPointLimitExceeded = true;
      return;
    }
    dataPoints.push(this.fb.group({ score: [null, [Validators.min(0)]], date: null }));
  }

  removeDataPoint(idx: number) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        subQuestion: 'Clicking Yes will remove this data point.',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const dataPoints = this.formGroup.get('dataPoints') as FormArray;
        dataPoints.removeAt(idx);
        if (dataPoints.length <= 14) {
          this.dataPointLimitExceeded = false;
        }
      }
    });
  }

  onOpenHelp(e: Event, section: HelpSection, item: HelpTerm) {
    e.preventDefault();
    const help = this.helpService.getIepGoalDictionary();
    this.helpService.openHelpModal({
      help,
      section,
      item,
      canBrowse: true,
    } as HelpModalConfig);
  }

  median(pool) {
    const sorted = pool.slice().sort((a, b) => a - b);
    const middle = Math.floor(sorted.length / 2);

    if (sorted.length % 2 === 0) {
      return (sorted[middle - 1] + sorted[middle]) / 2;
    }

    return sorted[middle];
  }

  private setBaseline() {
    const baselineControl = this.formGroup.get('baseline');
    if (this.dataPointsGroup.value.some((t) => t.score) && this.dataPointsGroup.value.every((t) => t.score >= 0)) {
      this.isManualBaseline = false;
      const scores = this.dataPointsGroup.value.filter((t) => t.score > -1).map((t) => t.score);

      baselineControl.setValue(this.median(scores));
    } else {
      this.isManualBaseline = true;
      baselineControl.setValue(baselineControl.value);
    }
    baselineControl.updateValueAndValidity();
  }
}
