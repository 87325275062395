import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ConsentForm, ConsentFormAgency, ConsentFormType } from '../../../shared/models/fiie-consent/consent-form';
import { ConsentStatus, ConsentStatusNotesUpdate } from '../../../shared/models/fiie-consent/consent-status';
import { FamilyConsentStatus } from '../../models/fiie-consent/family-consent-status';
import { FiieConsentReadForm } from '../../models/fiie-consent/fiie-consent-read';
import { FiieConsentUpdateForm } from '../../models/fiie-consent/fiie-consent-update';
import { OperationResultWithValue } from '../../models/operation-result';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class ConsentFormService {
  private consentsUpdated = new Subject<void>();

  consentsUpdated$ = this.consentsUpdated.asObservable();

  constructor(private http: HttpClient, private spinnerService: SpinnerService) {}

  getFiieConsentForm(fiieConsentFormId: string, caseId: string) {
    return this.http.get<FiieConsentReadForm>(`api/cases/${caseId}/fiie-consent/${fiieConsentFormId}`);
  }

  updateFiieConsentForm(fiieConsentForm: FiieConsentUpdateForm, fiieConsentFormId: string, caseId: string) {
    return this.http
      .put<FiieConsentUpdateForm>('api/cases/' + caseId + '/fiie-consent/' + fiieConsentFormId, fiieConsentForm)
      .pipe(tap(() => this.consentsUpdated.next()));
  }

  openedFiieConsentForm(caseId: string, id: string) {
    return this.http.put<void>(`api/cases/${caseId}/fiie-consent/${id}/opened`, {});
  }

  createFiieConsentPdf(caseId: string, fiieConsentId: string): Observable<string> {
    return this.http.post<string>(`api/cases/${caseId}/fiie-consent/${fiieConsentId}/fiie-consent-pdf`, null);
  }

  hasElectronicCommunicationConsent(caseId: string): Observable<boolean> {
    return this.isConsentFormApproved(caseId, ConsentFormType.ReceiveElectronicCommunication);
  }

  isConsentFormApproved(caseId: string, type: ConsentFormType): Observable<boolean> {
    return this.getConsentForms(caseId, [type]).pipe(
      map((forms) =>
        forms.some((f) => f.statuses.some((s) => s.status === FamilyConsentStatus.Approved || s.status === FamilyConsentStatus.Override))
      )
    );
  }

  findConsentsForAgency(consents: ConsentForm[], type: ConsentFormType, agency: Partial<ConsentFormAgency>) {
    return consents.filter((c) => {
      if (c?.type != type) return false;
      if (!agency || !c?.agency) return false;
      if (agency.name !== c.agency.name) return false;
      if (agency.contact !== c.agency.contact) return false;
      return true;
    });
  }

  getConsentForm(caseId: string, consentFormId: string) {
    return this.http.get<ConsentForm>(`api/cases/${caseId}/consent-forms/${consentFormId}`);
  }

  getConsentForms(caseId: string, types?: ConsentFormType[], maxAmount?: number) {
    let params = new HttpParams();
    if (types?.length > 0) {
      params = types.reduce((accum: HttpParams, type: ConsentFormType) => accum.set('types', type), params);
    }
    if (maxAmount) {
      params = params.set('take', maxAmount.toString());
    }

    return this.http.get<ConsentForm[]>('api/cases/' + caseId + '/consent-forms/', { params });
  }

  getConsentFormsForLearner(learnerId: string, types?: ConsentFormType[], maxAmount?: number) {
    let params = new HttpParams();
    if (types?.length > 0) {
      types.map((x) => {
        return (params = params.append('types', x));
      });
    }

    if (maxAmount) {
      params = params.set('take', maxAmount.toString());
    }

    return this.http.get<ConsentForm[]>('api/learners/' + learnerId + '/consent-forms/', { params });
  }

  getAvailableConsentForms(caseId: string) {
    return this.http.get<ConsentFormType[]>('api/cases/' + caseId + '/consent-forms/available');
  }

  async renderEmptyPdf(caseId: string, consentForm: ConsentForm): Promise<Blob> {
    return this.http.post('api/cases/' + caseId + '/consent-forms/empty-pdf', consentForm, { responseType: 'blob' }).toPromise();
  }

  /**
   * Normal case of creating a single consent form, receive-e-communication may create multiple consent forms (1 per parent), see createConsentForms below
   * @returns first consent form created
   */
  createConsentForm(caseId: string, consentForm: ConsentForm) {
    //TODO: Clean this up.  Needed to handle e-communication consent creating multiple consent forms (1 per parent)
    return this.http.post<ConsentForm[]>('api/cases/' + caseId + '/consent-forms', consentForm).pipe(
      map((addedConsentForms) => {
        if (!addedConsentForms) return null;
        if (addedConsentForms.length == 0) return null;
        if (addedConsentForms.length > 1)
          throw `Unexpected # of ConsentForms created (${addedConsentForms.length}).  Use createConsentForms if expecting multiple consent forms`;
        return addedConsentForms[0];
      }),
      tap(() => this.consentsUpdated.next())
    );
  }

  //TODO: JS - Agreement to Excuse temporary document handling
  createAgreementToExcuse(caseId: string, consentForm: ConsentForm, excuseAnyParticipant = false) {
    return this.http.post<OperationResultWithValue<string>>(
      'api/cases/' + caseId + '/consent-forms/agreement-to-excuse?excuseAnyParticipant=' + excuseAnyParticipant,
      consentForm
    );
  }

  /**
   * Create consent forms, possibly multiple.  Only needed for receive-e-communication
   * @returns all consent forms created
   */
  createConsentForms(caseId: string, consentForm: ConsentForm) {
    //TODO: Clean this up.  Needed to handle e-communication consent creating multiple consent forms (1 per parent)
    return this.http
      .post<ConsentForm[]>('api/cases/' + caseId + '/consent-forms', consentForm)
      .pipe(tap(() => this.consentsUpdated.next()));
  }

  createIfspConsentForServices(ifspId: string, consentForm: ConsentForm) {
    return this.http.post<ConsentForm>(`api/ifsp/${ifspId}/consent-for-services`, consentForm).pipe(tap(() => this.consentsUpdated.next()));
  }

  createIepConsentForServices(iepId: string, consentForm: ConsentForm) {
    return this.http.post<ConsentForm>(`api/iep/${iepId}/consent-for-services`, consentForm).pipe(tap(() => this.consentsUpdated.next()));
  }

  addConsentFormStatus(caseId: string, status: ConsentStatus) {
    return this.http
      .post<ConsentStatus>('api/cases/' + caseId + '/consent-forms/status', status)
      .pipe(tap(() => this.consentsUpdated.next()));
  }

  updateStatusNotes(update: ConsentStatusNotesUpdate) {
    return this.http
      .put<ConsentStatus>(`api/cases/${update.caseId}/consent-forms/status/${update.id}`, update)
      .pipe(tap(() => this.consentsUpdated.next()));
  }

  uploadDocumentation(uploadFile: any, caseId: string, statusId: string) {
    this.spinnerService.incrementLoading();
    const turnOffSpinner = () => this.spinnerService.decrementLoading();
    return this.http
      .post<FileList>(`api/cases/${caseId}/consent-forms/status/${statusId}/documents`, uploadFile, this.getMultipartRequestHeaders())
      .pipe(
        tap(turnOffSpinner, turnOffSpinner),
        tap(() => this.consentsUpdated.next())
      );
  }

  getLastStatus(form: ConsentForm): ConsentStatus {
    return form.statuses?.slice().sort((x, y) => (y.dateSigned as any) - (x.dateSigned as any))?.[0];
  }

  getLastConsentForm(consentForms: Array<ConsentForm>, formType: ConsentFormType): ConsentForm {
    return consentForms
      ?.slice()
      .filter((x) => x.type === formType)
      .sort((x, y) => (y.createdOn as any) - (x.createdOn as any))[0];
  }

  checkForUpdatedConsent() {
    this.consentsUpdated.next();
  }

  protected getMultipartRequestHeaders(): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    const headers = new HttpHeaders({
      'Content-Disposition': 'multipart/form-data',
      Accept: 'application/json, text/plain, */*',
    });

    return { headers };
  }
}
