import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ViewMoreModalData } from 'src/app/shared/modals/view-more-modal/view-more-modal.component';
import { DeactivationService } from 'src/app/shared/services/deactivation.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { needsLabelOffset, openViewMore } from 'src/app/shared/tableHelpers';
import { TrialPlacementTarget } from '../../../../../models/iep';
import { IepActivitySupportDto } from '../../../../../models/iep-activity-support';
import { IepAmendment } from '../../../../../models/iep-amendment';
import { IepActivitySupportService } from '../../../../../services/iep-activity-support.service';

@Component({
  selector: 'app-iep-activities-supports-table',
  templateUrl: './iep-activities-supports-table.component.html',
  styleUrls: ['./iep-activities-supports-table.component.scss'],
  providers: [DatePipe],
})
export class IepActivitiesSupportsTableComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() iepId: string;
  @Input() isProfilePage = false;
  @Input() isEditing = false;
  @Input() iepIncludesTrialPlacement: boolean;
  @Input() trialPlacementTarget: TrialPlacementTarget;
  trialPlacementTargetEnum = TrialPlacementTarget;

  @Input() amendmentId: string;
  @Input() amendments: IepAmendment[];

  activitySupportSubscription: Subscription;

  get amendment() {
    return this.amendments?.length > 0 ? this.amendments[0] : null;
  }

  get amendmentIsFinalized() {
    return this.amendment?.finalizeDate !== null;
  }

  get lastFinalizedDate() {
    if (this.amendments && this.amendments.length > 0) {
      const latest = this.amendments.reduce((r, a) => {
        return r.finalizeDate > a.finalizeDate ? r : a;
      });
      return latest?.finalizeDate;
    }
    return null;
  }

  @Output() activitiesSupportsCount = new EventEmitter<number>();
  @Output() editActivitySupport = new EventEmitter<IepActivitySupportDto>();

  activitiesSupports: Array<IepActivitySupportDto> = [];

  //#region summary
  displayedColumns: string[] = ['actions', 'activitysupport', 'description', 'frequencyPeriod', 'providers'];

  dataSource: MatTableDataSource<any>;
  //#endregion

  shortDateFormat = shortDateFormat;

  constructor(
    private iepActivitySupportService: IepActivitySupportService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource([]);
    this.getActivitesSupports();
    this.subscriptions.add(this.iepActivitySupportService.activitySupportUpdated$.subscribe(() => this.getActivitesSupports()));
    this.dataSource.sortingDataAccessor = (item: IepActivitySupportDto, columnId) => {
      switch (columnId) {
        case 'activitysupport':
          return item.activitySupportType.label;
        case 'providers':
          return this.getProviderNames(item?.providers);
        default:
          return item[columnId];
      }
    };
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onView(activitySupport) {
    const modalData: ViewMoreModalData[] = [
      {
        name: 'Activity Support Type',
        value: activitySupport.activitySupportType.label,
      },
      {
        name: 'Description',
        value: activitySupport.description,
      },
      {
        name: 'Frequency',
        value: activitySupport.frequencyPeriod,
      },
    ];

    if (
      this.iepIncludesTrialPlacement &&
      (this.trialPlacementTarget === this.trialPlacementTargetEnum.AllItems || activitySupport.includesTrialPlacement)
    ) {
      modalData.unshift({
        name: 'Included In Trial Placement',
        value: 'Yes',
      });
    }

    if (activitySupport.providers) {
      const data = {
        name: 'Providers',
        value: this.getProviderNames(activitySupport.providers, activitySupport.otherProviderName),
      };
      modalData.push(data);
    }

    if (activitySupport.requireDeviceType) {
      modalData.push({
        name: 'Device Types',
        value: activitySupport.requireDeviceType.join(', '),
      });
    }
    if (activitySupport.describeSetting) {
      modalData.push({
        name: 'Device Setting Description',
        value: activitySupport.describeSetting,
      });
    }
    if (activitySupport.deviceRequireAEM) {
      modalData.push({
        name: 'Device Require AEM',
        value: activitySupport.deviceRequireAEM ? 'Yes' : 'No',
      });
    }
    if (activitySupport.deviceYearOfPurchase) {
      modalData.push({
        name: 'Device Year Of Purchase',
        value: activitySupport.deviceYearOfPurchase,
      });
    }
    if (activitySupport.deviceEquipmentType) {
      modalData.push({
        name: 'Device Equipment Type',
        value: activitySupport.deviceEquipmentType,
      });
    }
    if (activitySupport.isDeviceNew) {
      modalData.push({
        name: 'Is New Device',
        value: activitySupport.isDeviceNew ? 'Yes' : 'No',
      });
    }
    openViewMore(this.dialog, modalData);
  }

  goToServices() {
    const caseId = this.route.parent?.snapshot.paramMap.get('caseId');
    this.router.navigate(['/', 'cases', caseId, 'iep', this.iepId, 'services']);
  }

  // #region Activities Supports
  getActivitesSupports() {
    this.iepActivitySupportService.getActivitiesSupports(this.iepId).subscribe((activitiesSupports) => {
      this.activitiesSupports = activitiesSupports;
      this.activitiesSupports.forEach((activitySupport) => {
        if (activitySupport.requireDeviceType) {
          activitySupport.requireDeviceType = activitySupport.requireDeviceType.split(',');
          activitySupport.requireDeviceType = activitySupport.requireDeviceType.filter((x) => x !== null && x !== '');
        } else {
          activitySupport.requireDeviceType = [];
        }
      });
      this.dataSource.data = this.activitiesSupports;
      this.activitiesSupportsCount.emit(this.activitiesSupports.length);
    });
  }

  onEdit(activitySupport) {
    this.editActivitySupport.emit(activitySupport);
  }

  onRemove(activitySupport) {
    this.notificationService.confirmation('Are you sure you want to delete this activity/support?', () => {
      this.iepActivitySupportService.deleteActivitySupport(this.iepId, activitySupport.id).subscribe(() => {
        this.notificationService.success('Activity/Support deleted');
      });
    });
  }
  // #endregion

  getProviderNames(providers, otherProvider?: string) {
    const providersMapped = providers?.map((x) => `${x.firstName} ${x.lastName || ''}`).join(', ');

    if (otherProvider && providersMapped) {
      return providersMapped + `, ${otherProvider}`;
    } else if (otherProvider) {
      return otherProvider;
    }

    return providersMapped;
  }

  convertToShortDate(date: Date) {
    return this.datePipe.transform(date, shortDateFormat);
  }

  checkLabelOffset(row): boolean {
    return needsLabelOffset(row);
  }
}
