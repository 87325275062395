<div class="row align-items-center">
  <div class="col">
    <div class="actions">
      <button mat-raised-button aria-label="Add Team Member" color="primary" type="button" (click)="openTeamDialog()" tabindex="0">
        Add Team Member
      </button>
      <button
        *ngIf="availableMembers?.length !== 0 || availableProviders?.length !== 0"
        class="ml-1"
        mat-raised-button
        aria-label="Add Family Member or Provider"
        (click)="openProviderDialog()"
        color="primary"
        type="button"
        tabindex="0"
      >
        Add Family Member or Provider
      </button>
      <!--
      hiding this for now
      <button
        *ngIf="caseSummary.learner.isPK"
        class="ml-1"
        mat-raised-button
        aria-label="Add Early Childhood Program Contact"
        (click)="openEcpDialog()"
        color="primary"
        type="button"
        tabindex="0"
      >
        Add Early Childhood Program Contact
      </button>-->
    </div>
  </div>
</div>

<div class="participant__container mt-2">
  <mat-card class="mb-0 participant__table card--plain">
    <app-help-directional-text *ngIf="displayLeaHelperText">
      <p>An LEA Representative must be selected.</p>
    </app-help-directional-text>
    <div class="table-overflow">
      <table mat-table [dataSource]="dataSource">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" class="text-left">
            <div class="display-flex align-items-center">
              <ng-container class="row" *ngIf="!editingModel">
                <button aria-label="Edit Icon" mat-icon-button (click)="edit(row)" tabindex="0">
                  <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button aria-label="Quick Access Menu" [matMenuTriggerFor]="menu" tabindex="0">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" #actionMenu="matMenu">
                  <ng-container *ngIf="row === editingModel">
                    <button
                      mat-menu-item
                      aria-label="Save Button Icon"
                      (click)="save()"
                      [disabled]="modelFormGroup.invalid"
                      title="Save"
                      tabindex="0"
                    >
                      <mat-icon>save</mat-icon> Save
                    </button>
                  </ng-container>
                  <button class="text-danger" mat-menu-item aria-label="Delete Icon" (click)="removeParticipant(row)" tabindex="0">
                    Remove
                  </button>
                </mat-menu>
              </ng-container>

              <ng-container *ngIf="row === editingModel">
                <button mat-icon-button aria-label="Save Icon" (click)="save()" title="Save" tabindex="0">
                  <mat-icon>save</mat-icon>
                </button>
                <button mat-icon-button aria-label="Clear Icon" (click)="cancel()" title="Cancel" tabindex="0">
                  <mat-icon>clear</mat-icon>
                </button>
                <mat-menu #actionMenu="matMenu">
                  <button mat-menu-item aria-label="Save Button" (click)="save()" tabindex="0">Save</button>
                  <button mat-menu-item aria-label="Cancel Button" (click)="cancel()" tabindex="0">Cancel</button>
                </mat-menu>
              </ng-container>
            </div>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let participant">
            <app-inline-edit [editing]="editingModel === participant">
              <ng-container viewMode>
                <ng-template [ngIf]="participant.fullName === null">
                  <em>NA</em>
                </ng-template>
                <ng-template [ngIf]="participant.fullName !== null">
                  {{ participant.fullName }}
                </ng-template>
              </ng-container>
              <ng-container editMode [formGroup]="modelFormGroup">
                <app-text-field formControlName="fullName"> </app-text-field>
              </ng-container>
            </app-inline-edit>
          </td>
        </ng-container>

        <!-- Profession / Role / Discipline Column -->
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef>Role</th>
          <td mat-cell *matCellDef="let participant">
            <app-inline-edit [editing]="editingModel === participant">
              <ng-container viewMode>
                <ng-template [ngIf]="participant.role === null">
                  <em>NA</em>
                </ng-template>
                <ng-template [ngIf]="participant.role !== null">
                  {{ getProperRole(participant) }}
                  <span *ngIf="participant.role === participantRoles.other">({{ participant.roleOther }})</span>
                </ng-template>
              </ng-container>
              <ng-container editMode [formGroup]="modelFormGroup">
                <ng-container
                  *ngIf="!isFamily(participant) && !participant.agencyId && !participant.medicalSpecialistId && !participant.learnerId"
                >
                  <div class="col-md-12">
                    <app-autocomplete class="input--large" [options]="professionOptions" formControlName="role"></app-autocomplete>
                  </div>
                  <div class="col-md-12" *ngIf="isOtherRoleEdit(modelFormGroup.value.role)">
                    <app-text-field
                      formControlName="roleOther"
                      [required]="isOtherRoleEdit(modelFormGroup.value.role)"
                      (keydown)="$event.stopPropagation()"
                    ></app-text-field>
                  </div>
                </ng-container>
                <ng-container *ngIf="!participant.role">
                  <em>NA</em>
                </ng-container>
                <ng-container
                  *ngIf="
                    participant.role &&
                    (isFamily(participant) || participant.agencyId || participant.medicalSpecialistId || participant.learnerId)
                  "
                >
                  {{ getProperRole(participant) }}
                </ng-container>
              </ng-container>
            </app-inline-edit>
          </td>
        </ng-container>

        <!-- Agency Column -->
        <ng-container matColumnDef="agency">
          <th mat-header-cell *matHeaderCellDef>Agency</th>
          <td mat-cell *matCellDef="let participant">
            <app-inline-edit [editing]="editingModel === participant">
              <ng-container viewMode>
                <ng-template [ngIf]="isFamily(participant) || participant.agency === null">
                  <em>NA</em>
                </ng-template>
                <ng-template [ngIf]="!isFamily(participant)">
                  {{ participant.agency }}
                  <span *ngIf="participant.agency === 'Other'">({{ participant.agencyOther }})</span>
                </ng-template>
              </ng-container>
              <ng-container editMode [formGroup]="modelFormGroup">
                <app-autocomplete
                  *ngIf="!isFamily(participant) && !participant.agencyId && !participant.medicalSpecialistId && !participant.learnerId"
                  [options]="agencyOptions"
                  formControlName="agency"
                ></app-autocomplete>
                <div class="col-md-12" *ngIf="isOtherAgencyEdit(modelFormGroup.value.agency)">
                  <app-text-field
                    formControlName="agencyOther"
                    [required]="isOtherAgencyEdit(modelFormGroup.value.agency)"
                    (keydown)="$event.stopPropagation()"
                  ></app-text-field>
                </div>
                <ng-container
                  *ngIf="participant.agency && (participant.agencyId || participant.medicalSpecialistId || participant.learnerId)"
                >
                  {{ participant.agency }}
                </ng-container>
                <ng-container *ngIf="!participant.agency || isFamily(participant)">
                  <em>NA</em>
                </ng-container>
              </ng-container>
            </app-inline-edit>
          </td>
        </ng-container>

        <!-- Excusal Column -->
        <ng-container [formGroup]="modelFormGroup" matColumnDef="excusal">
          <th mat-header-cell *matHeaderCellDef>Request Agreement to Excuse</th>
          <td mat-cell *matCellDef="let participant">
            <mat-checkbox
              (change)="updateParticipantExcusal($event, participant)"
              [checked]="participant.excusalDate"
              aria-labelledby="Check me!"
            ></mat-checkbox>
          </td>
        </ng-container>

        <!-- LEA Representative Column -->
        <!-- <ng-container [formGroup]="modelFormGroup" matColumnDef="leaRepresentative">
          <th
            mat-header-cell
            *matHeaderCellDef
            [ngClass]="{
              asterisk_input: formGroup.get('leaRepresentative').hasError('required')
            }"
          >
            LEA Representative
          </th>
          <td mat-cell *matCellDef="let participant">
            <div *ngIf="participant.userId">
              <mat-checkbox
                (change)="updateLeaRepresentative($event, participant.userId)"
                [checked]="leaRepresentativeChecked(participant.userId)"
                aria-labelledby="Check me!"
              ></mat-checkbox>
            </div>
          </td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <p *ngIf="!dataSource.data">No data yet...</p>
    </div>
  </mat-card>

  <mat-card class="mt-0 participant__form card--plain">
    <form #formDirective="ngForm" [formGroup]="addParticipantFormGroup" (ngSubmit)="addParticipant(formDirective)">
      <div class="row align-items-center">
        <div class="col-md-3">
          <app-text-field label="Name" formControlName="name" (keydown)="$event.stopPropagation()"></app-text-field>
        </div>
        <div class="col-md-4">
          <app-autocomplete class="input--large" [options]="professionOptions" formControlName="role" label="Role"></app-autocomplete>
        </div>
        <div class="col-md-3" *ngIf="isOtherRole(addParticipantFormGroup.value.role)">
          <app-text-field
            label="Other"
            formControlName="roleOther"
            [required]="isOtherRole(addParticipantFormGroup.value.role)"
            (keydown)="$event.stopPropagation()"
          ></app-text-field>
        </div>
        <div class="col-md-3">
          <app-autocomplete [options]="agencyOptions" formControlName="agency" label="Agency"></app-autocomplete>
        </div>
        <div class="col-md-3" *ngIf="isOtherAgency(addParticipantFormGroup.value.agency)">
          <app-text-field
            label="Other"
            formControlName="agencyOther"
            [required]="isOtherAgency(addParticipantFormGroup.value.agency)"
            (keydown)="$event.stopPropagation()"
          ></app-text-field>
        </div>
        <div class="col-md-1">
          <button mat-raised-button aria-label="Add Icon" [disabled]="!addParticipantFormValid" color="accent" tabindex="0">Add New</button>
        </div>
      </div>
    </form>
  </mat-card>
</div>
