<mat-card class="py-2">
  <mat-card-content>
    <div class="display-flex justify-content-between align-items-center">
      <div class="display-flex align-items-baseline" *ngIf="caseSummary?.caseOwner">
        <h3 class="mb-0 mt-0 mr-1">
          {{ caseSummary.intakeType === intakeTypeEnum.PartB ? 'Facilitator:' : 'Service Coordinator:' }}
        </h3>
        <span>
          {{ caseSummary.caseOwner.fullName }}
          <span *ngIf="caseSummary.scheduledTransferredUserInfo">
            {{ ' | ' }}
            {{ !!transferredUser ? transferredUser : '' }} Transferred On
            {{ caseSummary.scheduledTransferredUserInfo.dateAssigned | dateFormat }}
          </span>
        </span>
        <!-- Temporarily hide: Product Backlog Item 22322: Hide Contact Facilitator Button -->
        <!--<a mat-raised-button
           color="accent"
           *ngIf="caseSummary?.caseOwner?.id && caseSummary.caseOwner.id !== authService.user.id"
           target="_blank"
           class="ml-2"
           href="mailto:{{ caseSummary.caseOwner.email }}">
          {{ caseSummary.intakeType === intakeTypeEnum.PartB ? 'Contact Facilitator' : 'Contact Service Coordinator' }}
        </a>-->
      </div>
      <div>
        <button
          mat-raised-button
          aria-label="Share"
          class="mr-2"
          color="primary"
          (click)="onShareCase(caseSummary)"
          *ngIf="canShare"
          tabindex="0"
        >
          Share
          <i class="fas fa-share-alt ml-2" aria-labelledby="Share"></i>
        </button>
        <button
          mat-raised-button
          aria-label="Transfer"
          color="primary"
          (click)="onTransferCase(caseSummary)"
          *ngIf="canTransfer && learnerHasWorkableCase"
          tabindex="0"
        >
          Transfer
          <i class="fas fa-exchange-alt ml-2" aria-labelledby="Transfer"></i>
        </button>
        <ng-container *ngIf="isPortalUser">
          <span>
            {{ caseSummary.caseOwner.email }}
          </span>
        </ng-container>
      </div>
    </div>
  </mat-card-content>
</mat-card>
