import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterParams, TeamMemberSearchEntity } from '../../modals/team-member-search-modal/team-member-search-modal.component';
import { IntakeType } from '../../models/case';
import { PaginatedList } from '../../models/paginated-list';
import { Team, TeamUser } from '../../models/team';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  constructor(private http: HttpClient) {}

  getTeamUsers(userId: string, intakeType: IntakeType, learnerId: string, isMeeting?: boolean) {
    let params = new HttpParams();
    params = params.append('learnerId', learnerId ?? '');
    params = params.append('intakeType', intakeType ?? '');
    params = params.append('isMeeting', isMeeting ?? false);

    return this.http.get<TeamUser[]>(`api/users/${userId}/teams/team-users`, { params: params });
  }

  getPaginatedTeamUsers(
    userId: string,
    userIds: string,
    intakeType: IntakeType,
    currentPage: number,
    pageSize: number,
    orderBy: string,
    orderByDescending: boolean,
    learnerId: string,
    isMeeting?: boolean,
    searchCriteria?: string,
    buildingId?: string,
    professionId?: string
  ) {
    let params = new HttpParams();
    params = params.append('userId', userId);
    params = params.append('learnerId', learnerId ?? '');
    params = params.append('intakeType', intakeType ?? '');
    params = params.append('userIds', userIds ?? '');
    params = params.append('isMeeting', isMeeting ?? false);
    params = params.append('pageNumber', currentPage);
    params = params.append('pageSize', pageSize);
    params = params.append('orderBy', orderBy);
    params = params.append('orderByDescending', orderByDescending);
    params = params.append('searchCriteria', searchCriteria ?? '');
    params = params.append('buildingId', buildingId ?? '');
    params = params.append('professionId', professionId ?? '');

    return this.http.get<FilterParams<PaginatedList<TeamMemberSearchEntity>>>(`api/users/${userId}/teams/paginated-team-users`, { params });
  }

  /**
   * Gets a users teams. Defaults to current user
   * @param userId defaults to "00000000-0000-0000-0000-000000000000"
   */
  getTeams(userId = '00000000-0000-0000-0000-000000000000') {
    return this.http.get<Team[]>(`api/users/${userId}/teams`);
  }

  createTeam(userId: string, team: Team) {
    return this.http.post<Team>(`api/users/${userId}/teams`, team);
  }

  updateTeam(userId: string, team: Team) {
    return this.http.put<Team>(`api/users/${userId}/teams/${team.id}`, team);
  }

  deleteTeam(userId: string, teamId: string) {
    return this.http.put<void>(`api/users/${userId}/teams/delete/${teamId}`, null);
  }

  addTeamUser(userId: string, teamId: string, userIds: string[]) {
    return this.http.post<Team>(`api/users/${userId}/teams/${teamId}/users`, userIds);
  }

  deleteTeamUser(userId: string, teamId: string, teamUser: TeamUser) {
    return this.http.put<Team>(`api/users/${userId}/teams/${teamId}/users/${teamUser.userId}`, null);
  }
}
