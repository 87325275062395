import { HttpClient, HttpErrorResponse, HttpParams, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { OperationResult } from '../../../behavior-discipline/models/bip';
import {
  CaseCreate,
  CaseListItem,
  CaseSummary,
  CaseSummaryByIepId,
  CaseSummaryByIfspId,
  ExitCaseInfo,
  FamilyMeetingCreate,
  FamilyMeetingRead,
  IntakeType,
  QuickLinkDetails,
  ReopenCaseDto,
  ScheduledCaseTransferRead,
  ScheduledCaseTransferUpdate,
  SifCaseCreate,
} from '../../models/case';
import { CaseClosure } from '../../models/case-closure';
import { KeyValuePair } from '../../models/key-value-pair';
import { OperationResultWithValue } from '../../models/operation-result';
import { UserCaseList } from '../../models/paginated-list';
import { PriorWrittenNotice } from '../../models/prior-written-notices/prior-written-notice';

@Injectable({
  providedIn: 'root',
})
export class CaseService {
  private baseUrl = 'api/cases';
  private caseSummaryChange = new ReplaySubject<CaseSummary>(1);
  private _caseSummary: CaseSummary;

  caseSummaryChange$: Observable<CaseSummary> = this.caseSummaryChange.asObservable();

  get caseSummary(): CaseSummary {
    return this._caseSummary;
  }

  set caseSummary(value: CaseSummary) {
    this._caseSummary = value;
    this.caseSummaryChange.next(value);
  }

  constructor(private http: HttpClient, private router: Router) {}

  async refreshCaseSummary(caseId) {
    this.caseSummary = await this.getCaseSummary(caseId)
      .toPromise()
      .catch((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.NotFound) this.router.navigate(['/not-found']);
        return undefined;
      });
  }

  getCaseSummary(id: string) {
    return this.http.get<CaseSummary>(`${this.baseUrl}/${id}/summary`);
  }

  getCaseSummaryNoLocations(id: string) {
    return this.http.get<CaseSummary>(`${this.baseUrl}/${id}/summary-no-locations`);
  }

  getCaseLearnerName(id: string) {
    return this.http.get<OperationResult>(`${this.baseUrl}/${id}/learner-name`);
  }

  getCaseSummaryByIfsp(ifspId: string) {
    return this.http.get<CaseSummaryByIfspId>(`${this.baseUrl}/${ifspId}/summaryByIfsp`);
  }

  getCaseSummaryByIep(iepId: string) {
    return this.http.get<CaseSummaryByIepId>(`${this.baseUrl}/${iepId}/summaryByIep`);
  }

  getUnassigned(
    pageNumber: number,
    pageSize: number,
    orderBy: string,
    orderByDescending: boolean,
    isSuperAdmin: boolean,
    searchCriteriaDistrict?: string,
    name?: string
  ) {
    let params = new HttpParams();
    params = params.append('pageNumber', pageNumber);
    params = params.append('pageSize', pageSize);
    params = params.append('orderBy', orderBy);
    params = params.append('orderByDescending', orderByDescending);
    params = params.append('isSuperAdmin', isSuperAdmin ?? '');
    params = params.append('searchCriteriaDistrict', searchCriteriaDistrict ?? '');
    params = params.append('name', name ?? '');

    return this.http.get<any>(`${this.baseUrl}/unassigned`, { params });
  }

  getCaseList({
    isOwner,
    intakeType,
    adminAeas,
    isScAssignment = false,
  }: {
    isOwner?: boolean;
    intakeType?: IntakeType;
    adminAeas?: string[];
    isScAssignment?: boolean;
  } = {}) {
    let params = new HttpParams();
    if (isOwner !== undefined) {
      params = params.set('isOwner', isOwner.toString());
    }
    if (intakeType !== undefined) {
      params = params.set('intakeType', intakeType);
    }
    if (adminAeas !== undefined) {
      params = params.set('adminAeas', adminAeas.toString());
    }
    params = params.set('isScAssignment', isScAssignment.toString());
    return this.http.get<CaseListItem[]>(`${this.baseUrl}`, { params });
  }

  getPaginatedCaseList(
    intakeType: IntakeType,
    currentPage: number,
    pageSize: number,
    orderByDescending: boolean,
    orderBy: string,
    searchCriteria?: string,
    searchCriteriaDistrict?: string,
    searchCriteriaBuilding?: string
  ) {
    let params = new HttpParams();
    params = params.set('intakeType', intakeType);
    params = params.set('pageNumber', currentPage);
    params = params.set('pageSize', pageSize);
    params = params.set('orderByDescending', orderByDescending);
    params = params.set('orderBy', orderBy);
    params = params.set('searchCriteria', searchCriteria ?? '');
    params = params.set('searchCriteriaDistrict', searchCriteriaDistrict ?? '');
    params = params.set('searchCriteriaBuilding', searchCriteriaBuilding ?? '');

    return this.http.get<UserCaseList>(`${this.baseUrl}/paginated-list`, { params });
  }

  createCase(caseModel: CaseCreate) {
    return this.http.post<CaseSummary>(`api/learners/${caseModel.learnerId}/cases`, caseModel);
  }

  createSifCase(caseModel: SifCaseCreate) {
    return this.http.post<CaseSummary>('api/learners/cases', caseModel);
  }

  closeCase(caseToClose: CaseClosure) {
    return this.http.put(`${this.baseUrl}/${caseToClose.caseId}/closures`, caseToClose);
  }

  updateLetterStatus(caseId: string, letterSent: boolean) {
    return this.http.put(`${this.baseUrl}/${caseId}/referral-thank-you-letter-status`, { letterSent });
  }

  updateResultLetterStatus(caseId: string, letterSent: boolean) {
    return this.http.put(`${this.baseUrl}/${caseId}/result-letter-status`, {
      letterSent,
    });
  }

  updateNewLearnerStatus(caseId: string, learnerStatus: boolean) {
    return this.http.put(`${this.baseUrl}/${caseId}/update-new-learner-status`, { learnerStatus });
  }

  createMeeting(caseId: string, meeting: FamilyMeetingCreate) {
    return this.http.post<FamilyMeetingRead>(`${this.baseUrl}/${caseId}/family-meetings`, meeting);
  }

  addCaseUser(caseId: string, user) {
    return this.http.post<CaseSummary>(`${this.baseUrl}/${caseId}/caseusers`, user);
  }

  removeCaseUser(caseId: string, userId: string) {
    return this.http.put<CaseSummary>(`${this.baseUrl}/${caseId}/caseusers/${userId}`, null);
  }

  scheduleTransfer(caseId: string, scheduledTransfer: ScheduledCaseTransferUpdate) {
    return this.http.put<ScheduledCaseTransferRead>(`${this.baseUrl}/${caseId}/scheduled-transfers`, scheduledTransfer);
  }

  assignServiceCoordinator(caseId: string, serviceCoordinatorId: string) {
    return this.http.post<void>(`${this.baseUrl}/${caseId}/service-coordinator`, { serviceCoordinatorId });
  }

  assignCaseOwner(caseId: string, userId: string) {
    return this.http.post<void>(`${this.baseUrl}/${caseId}/owner`, { userId });
  }

  getEligibility(caseId: string) {
    return this.http.get<boolean>(`${this.baseUrl}/${caseId}/eligibility`);
  }

  saveEligibility(caseId: string, eligibilityDecision: boolean) {
    return this.http.put<void>(`${this.baseUrl}/${caseId}/eligibility`, {
      eligibilityDecision,
    });
  }

  getLearnerId(caseId: string): Observable<OperationResult> {
    return this.http.get<OperationResult>(`${this.baseUrl}/${caseId}/learner-id`);
  }

  getProgress(id: string) {
    return this.http.get<string>(`${this.baseUrl}/${id}/progress`);
  }

  getQuickLinkDetails(id: string) {
    return this.http.get<QuickLinkDetails>(`${this.baseUrl}/${id}/quick-links`);
  }

  getExitReasons(): Observable<OperationResultWithValue<Array<KeyValuePair>>> {
    return this.http.get<OperationResultWithValue<Array<KeyValuePair>>>(`${this.baseUrl}/exitReasons`);
  }

  getCaseExitReasons(caseId: string): Observable<OperationResultWithValue<Array<KeyValuePair>>> {
    return this.http.get<OperationResultWithValue<Array<KeyValuePair>>>(`${this.baseUrl}/${caseId}/exitReasons`);
  }

  getCaseExitReason(caseId: string): Observable<OperationResultWithValue<KeyValuePair>> {
    return this.http.get<OperationResultWithValue<KeyValuePair>>(`${this.baseUrl}/${caseId}/exit/selectedExitReason`);
  }

  updateExitingInfo(caseId: string, exitCaseInfo: ExitCaseInfo): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${caseId}/exit`, exitCaseInfo);
  }

  preExitDraftPwns(caseId: string): Observable<OperationResultWithValue<Array<PriorWrittenNotice>>> {
    return this.http.get<OperationResultWithValue<Array<PriorWrittenNotice>>>(`${this.baseUrl}/${caseId}/exit/preExitDraftPwns`);
  }

  getExitNextSteps(caseId: string): Observable<OperationResultWithValue<Array<any>>> {
    return this.http.get<OperationResultWithValue<Array<any>>>(`${this.baseUrl}/${caseId}/exit/nextSteps`);
  }

  finalizeExit(learnerId: string, caseId: string): Observable<OperationResult> {
    return this.http.post<OperationResult>(`${this.baseUrl}/${learnerId}/${caseId}/exit/finalize`, null);
  }

  cancelExit(learnerId: string, caseId: string): Observable<OperationResult> {
    return this.http.post<OperationResult>(`${this.baseUrl}/${learnerId}/${caseId}/exit/cancel`, null);
  }

  reopenCase(learnerId: string, reopenCaseDto: ReopenCaseDto) {
    return this.http.post<OperationResultWithValue<any>>(`${this.baseUrl}/${learnerId}/reopenCase`, reopenCaseDto);
  }

  getAllCases(learnerId: string) {
    return this.http.get<OperationResultWithValue<CaseSummary[]>>(`${this.baseUrl}/${learnerId}/allCasesSummary`);
  }

  getCaseEcoRatingProgress(caseId: string): Observable<OperationResultWithValue<Array<KeyValuePair>>> {
    return this.http.get<OperationResultWithValue<Array<KeyValuePair>>>(`${this.baseUrl}/${caseId}/eco-ratings/eco-rating-progresses`);
  }

  saveCaseEcoRatingProgress(caseEcoRatingProgress: Array<KeyValuePair>, caseId: string): Observable<OperationResult> {
    return this.http.post<OperationResult>(`${this.baseUrl}/${caseId}/eco-ratings/eco-rating-progresses`, caseEcoRatingProgress);
  }

  getEligibilityDecisionDates(caseId: string) {
    return this.http.get<Array<KeyValuePair>>(`${this.baseUrl}/${caseId}/eligibility-decision-dates`);
  }

  changeReevaluationDueOn(caseId: string, reevaluationDueOn: Date) {
    return this.http.put<OperationResult>(`${this.baseUrl}/${caseId}/reevaluation-due-date`, { value: reevaluationDueOn });
  }
}
