import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { FeatureFlagService } from '../components/feature-flags/feature-flag.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[removeIfFeatureOff]',
})
export class RemoveIfFeatureOffDirective implements OnInit {
  @Input('removeIfFeatureOff') featureName: string;

  constructor(private el: ElementRef, private featureFlagService: FeatureFlagService) {}

  ngOnInit() {
    if (this.featureFlagService.featureOff(this.featureName)) {
      this.el.nativeElement.parentNode.removeChild(this.el.nativeElement);
    }
  }
}
