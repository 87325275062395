export enum FeatureFlags {
  OutOfStatePlacement = 'outOfStatePlacement',
  MyTools = 'myTools',
  ImplementationPlan = 'implementationPlan',
  DataManagement = 'dataManagement',
  DisputeResolution = 'disputeResolution',
  ContinuousImprovement = 'continuousImprovement',
  IntegratedMonitoring = 'integratedMonitoring',
  DataReviewProtocol = 'dataReviewProtocol',
  ELAA = 'elaa',
  ShowIvrsBanner = 'showIvrsBanner',
  Ivrs = 'ivrs',
  FitCsa = 'fitCsaEnabled',
  IlotReportingExport = 'ilotReportingExport',
}
