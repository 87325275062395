import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, Self, SimpleChanges } from '@angular/core';
import { BaseWrapperComponent } from '../base-wrapper.component';
import { LoggerService } from '../../../services/logger/logger.service';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'app-number-field',
  templateUrl: './number-field.component.html',
  styleUrls: ['./number-field.component.scss'],
})
export class NumberFieldComponent extends BaseWrapperComponent implements OnInit, OnChanges {
  @Input() hideAsterisk = false;
  @Input() useInputGroup = false;
  @Input() min: number;
  @Input() max: number;
  // todo this component is using value accessor. We SHOULD be able to use the native blur but I couldn't get it to work
  @Output() blurMe = new EventEmitter<any>();

  constructor(
    logger: LoggerService,
    @Self()
    @Optional()
    ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(logger, ngControl, changeDetectorRef);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  internalBlur(event: any) {
    if (this.blurMe) {
      this.blurMe.emit(true);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }
}
