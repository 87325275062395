<nav class="stepper stepper--fake stepper__nav mt-5" *ngIf="learner">
  <ul class="stepper__list">
    <a
      [routerLink]="routingService.learnerDashboardPath(learner.id)"
      routerLinkActive="stepper__link--active"
      class="stepper__link"
      tabindex="0"
      role="link"
      aria-labelledby="Learner Dashboard"
    >
      <li class="stepper__item">
        <i class="material-icons"> dashboard </i>
        <p>Learner Dashboard</p>
      </li>
    </a>
    <ng-container *ngIf="!isMoveInOrEligibleLegacyLearner || (isMoveInOrEligibleLegacyLearner && hasMoveInDetails)">
      <a
        *ngIf="showFamilyContact"
        [routerLink]="routingService.familyContactPath(learner.id)"
        routerLinkActive="stepper__link--active"
        class="stepper__link"
        tabindex="0"
        role="link"
      >
        <li class="stepper__item">
          <i class="material-icons"> home </i>
          <p>Family Contact</p>
        </li>
      </a>
      <a
        [routerLink]="routingService.documentationPath(learner.id)"
        routerLinkActive="stepper__link--active"
        class="stepper__link"
        *ngIf="learner.showDocuments || (isMoveInOrEligibleLegacyLearner && hasMoveInDetails)"
        tabindex="0"
        role="link"
      >
        <li class="stepper__item">
          <i class="material-icons"> article </i>
          <p>Documentation</p>
        </li>
      </a>

      <a
        *ngIf="showIFSPStepper"
        [routerLink]="routingService.ifspListPath(learner.id)"
        routerLinkActive="stepper__link--active"
        class="stepper__link"
        tabindex="0"
        role="link"
      >
        <li class="stepper__item">
          <div class="icon__container">
            <svg-icon src="assets/img/icons/IFSP.svg" svgClass="icon--svg"></svg-icon>
          </div>
          <p>IFSP</p>
        </li>
      </a>
      <a
        *ngIf="showIEPStepper"
        [routerLink]="routingService.iepListPath(learner.id)"
        routerLinkActive="stepper__link--active"
        class="stepper__link"
        tabindex="0"
        role="link"
        aria-labelledby="IEP"
      >
        <li class="stepper__item">
          <div class="icon__container">
            <svg-icon src="assets/img/icons/IEP.svg" svgClass="icon--svg"></svg-icon>
          </div>
          <p>IEP</p>
        </li>
      </a>

      <a
        [routerLink]="routingService.evaluationListPath(learner.id)"
        routerLinkActive="stepper__link--active"
        class="stepper__link"
        *ngIf="showEvaluation"
        tabindex="0"
        role="link"
        aria-labelledby="Evaluation"
      >
        <li class="stepper__item">
          <i class="material-icons"> assessment </i>
          <p>Evaluation</p>
        </li>
      </a>

      <a
        [routerLink]="routingService.postSecondaryTransitionPath(learner.id)"
        routerLinkActive="stepper__link&#45;&#45;active"
        class="stepper__link"
        *ngIf="showPSSStepper && !authService.isVrUser"
        tabindex="0"
        role="link"
        aria-labelledby="Postsecondary Summary"
      >
        <li class="stepper__item">
          <i class="material-icons"> post_add </i>
          <p>Postsecondary Summary</p>
        </li>
      </a>

      <a
        [routerLink]="routingService.transitionPlanningPath(learner.id)"
        routerLinkActive="stepper__link--active"
        class="stepper__link"
        *ngIf="learner.showTransitionPlanningStepper"
        tabindex="0"
        role="link"
        aria-labelledby="Transition Planning"
      >
        <li class="stepper__item">
          <i class="material-icons"> assessment </i>
          <p>Transition Planning</p>
        </li>
      </a>
    </ng-container>
  </ul>
</nav>
