<button
  mat-flat-button
  aria-label="Amend"
  color="primary"
  *ngIf="hasOpenAmendment"
  class="mr-2"
  (click)="$event.stopPropagation(); onAmend()"
  tabindex="0"
>
  Amend
</button>

<form [formGroup]="formGroup">
  <div class="row">
    <div class="col-md-12">
      <ng-container *ngIf="!isAmendment || (hasAmendment && amendingAssessment)">
        <div class="display-flex align-items-center">
          <label
            class="vertical-label display-block"
            [ngClass]="{
              asterisk_input: formGroup.get('howParticipate').hasError('required')
            }"
          >
            How does {{ learner.firstName }} participate in
            <a
              (click)="onOpenHelp($event, helpSection.IepService, iepServiceHelp.StatewideDistrictAssessment)"
              class="text-underline text-lower"
              tabindex="0"
              role="link"
              attr.aria-label="{{ iepServiceHelp.StatewideDistrictAssessment }}"
              >{{ iepServiceHelp.StatewideDistrictAssessment }}</a
            >, including reasonable
            <a
              (click)="onOpenHelp($event, helpSection.IepService, iepServiceHelp.Accommodations)"
              class="text-underline text-lower"
              tabindex="0"
              role="link"
              attr.aria-label="{{ iepServiceHelp.Accommodations }}"
              >{{ iepServiceHelp.Accommodations }}</a
            >
            if necessary?
          </label>

          <button
            mat-icon-button
            aria-label="Clear Icon"
            type="button"
            color="primary"
            class="text-sm vertical-label mr-4"
            (click)="onClearControl(formGroup.get('howParticipate'))"
            tabindex="0"
          >
            <i class="fas fa-undo" aria-label="Clear Icon"></i>
          </button>
        </div>
        <fieldset>
          <app-radio-group
            formControlName="howParticipate"
            [hideAsterisk]="true"
            [useUndo]="false"
            [options]="howParticipateOptions"
            [column]="true"
          ></app-radio-group>
        </fieldset>
        <div class="my-2" *ngIf="hasAmendment && assessmentModel">
          <app-compare-amendment-output
            [amendmentId]="assessmentModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="assessmentModel?.priorVersionId"
            [isActive]="assessmentModel?.isActive"
            [changedVersionText]="getHowParticipateText(formGroup.get('howParticipate').value)"
            [currentVersionText]="getHowParticipateText(assessmentModel?.priorVersion?.howParticipate)"
            [currentPrefixText]="assessmentModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </div>
      </ng-container>
      <ng-container *ngIf="isAmendment && (!hasAmendment || !amendingAssessment)">
        <p class="text-semibold">How will {{ learner.firstName }} participate in statewide and districtwide assessments?</p>
        <app-compare-amendment-output
          [amendmentId]="assessmentModel?.amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [priorVersionId]="assessmentModel?.priorVersionId"
          [isActive]="assessmentModel?.isActive"
          [changedVersionText]="howParticipateLabel"
          [currentVersionText]="getHowParticipateText(assessmentModel?.priorVersion?.howParticipate)"
          [currentPrefixText]="assessmentModel?.priorVersion ? 'Current: ' : ''"
          [changedPrefixText]="assessmentModel?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </ng-container>
    </div>
  </div>

  <div class="row" *ngIf="showAccommodationQuestion">
    <div class="col-md-12 col-xl-6">
      <ng-container *ngIf="!isAmendment || (hasAmendment && amendingAssessment)">
        <app-autocomplete-chips formControlName="accommodations" [options]="accommodationOptions" label="Accommodations">
        </app-autocomplete-chips>
        <div class="my-2" *ngIf="hasAmendment && assessmentModel">
          <app-compare-amendment-output
            [amendmentId]="assessmentModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="assessmentModel?.priorVersionId"
            [isActive]="assessmentModel?.isActive"
            [changedVersionText]="getFormAccommodationLabels(formGroup.get('accommodations').value)"
            [currentVersionText]="getFormAccommodationLabels(assessmentModel?.priorVersion?.accommodations)"
            [currentPrefixText]="assessmentModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </div>
        <div *ngIf="hasOtherOption">
          <app-text-field label="Other Accommodation" formControlName="otherText"></app-text-field>
          <div class="my-2" *ngIf="hasAmendment && assessmentModel">
            <app-compare-amendment-output
              [amendmentId]="assessmentModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="assessmentModel?.priorVersionId"
              [isActive]="assessmentModel?.isActive"
              [changedVersionText]="formGroup.get('otherText').value"
              [currentVersionText]="assessmentModel?.priorVersion?.otherText"
              [currentPrefixText]="assessmentModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            ></app-compare-amendment-output>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isAmendment && (!hasAmendment || !amendingAssessment)">
        <p class="text-semibold">Accommodations</p>
        <ul>
          <li *ngFor="let accommodation of accommodationLabels">
            {{ accommodation }}
          </li>
        </ul>
        <app-compare-amendment-output
          [amendmentId]="assessmentModel?.amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [priorVersionId]="assessmentModel?.priorVersionId"
          [isActive]="assessmentModel?.isActive"
          [changedVersionText]=""
          [currentVersionText]="priorVersionAccommodationLabels"
          [currentPrefixText]="assessmentModel?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
      </ng-container>
    </div>
  </div>

  <div
    class="row mt-2"
    *ngIf="howParticipate === 'alternateAssessmentNoAccommodations' || howParticipate === 'alternateAssessmentWithAccommodation'"
  >
    <div class="col-md-12">
      <p class="form__description">
        <a [href]="alternateAssessmentParticipationGuidelines" target="_blank" class="text-wrap display-block">
          Alternate Assessment Participation Guidelines
        </a>
      </p>
    </div>
    <div class="col-md-12 col-xl-6">
      <label class="vertical-label asterisk_input position-fix" for="whatBarriers">
        What barriers prevent {{ learner.firstName }} from participating in the standard assessments?
      </label>
      <ng-container *ngIf="!isAmendment || (hasAmendment && amendingAssessment)">
        <app-textarea
          formControlName="whatBarriers"
          id="whatBarriers"
          [hideAsterisk]="true"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.iepSsaaAssessments.whatBarriers"
        ></app-textarea>
        <div class="my-2" *ngIf="hasAmendment && assessmentModel">
          <app-compare-amendment-output
            [amendmentId]="assessmentModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="assessmentModel?.priorVersionId"
            [isActive]="assessmentModel?.isActive"
            [changedVersionText]="formGroup.get('whatBarriers').value"
            [currentVersionText]="assessmentModel?.priorVersion?.whatBarriers"
            [currentPrefixText]="assessmentModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </div>
      </ng-container>
      <ng-container *ngIf="isAmendment && (!hasAmendment || !amendingAssessment)">
        <p class="text-semibold">
          <app-compare-amendment-output
            [amendmentId]="assessmentModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="assessmentModel?.priorVersionId"
            [isActive]="assessmentModel?.isActive"
            [changedVersionText]="formData.whatBarriers"
            [currentVersionText]="assessmentModel?.priorVersion?.whatBarriers"
            [currentPrefixText]="assessmentModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </p>
      </ng-container>
    </div>
    <div class="col-md-12 col-xl-6">
      <label class="vertical-label asterisk_input position-fix" for="assessmentNecessary">
        Why is the alternate assessment necessary for
        {{ learner.firstName }}?
      </label>
      <ng-container *ngIf="!isAmendment || (hasAmendment && amendingAssessment)">
        <app-textarea
          formControlName="assessmentNecessary"
          id="assessmentNecessary"
          [hideAsterisk]="true"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.iepSsaaAssessments.assessmentNecessary"
        ></app-textarea>
        <div class="my-2" *ngIf="hasAmendment && assessmentModel">
          <app-compare-amendment-output
            [amendmentId]="assessmentModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="assessmentModel?.priorVersionId"
            [isActive]="assessmentModel?.isActive"
            [changedVersionText]="formGroup.get('assessmentNecessary').value"
            [currentVersionText]="assessmentModel?.priorVersion?.assessmentNecessary"
            [currentPrefixText]="assessmentModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </div>
      </ng-container>
      <ng-container *ngIf="isAmendment && (!hasAmendment || !amendingAssessment)">
        <p class="text-semibold">
          <app-compare-amendment-output
            [amendmentId]="assessmentModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="assessmentModel?.priorVersionId"
            [isActive]="assessmentModel?.isActive"
            [changedVersionText]="formData.assessmentNecessary"
            [currentVersionText]="assessmentModel?.priorVersion?.assessmentNecessary"
            [currentPrefixText]="assessmentModel.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </p>
      </ng-container>
    </div>
  </div>

  <div
    *ngIf="!hasAmendment"
    class="display-flex align-items-center justify-content-end"
    [ngClass]="{
      'position-fix-btn':
        howParticipate !== 'alternateAssessmentNoAccommodations' && howParticipate !== 'alternateAssessmentWithAccommodation'
    }"
  >
    <button mat-raised-button aria-label="View Summary" color="accent" (click)="openSummary()" *ngIf="!isDetailsView" tabindex="0">
      View Summary
    </button>
  </div>
</form>
