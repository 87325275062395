import { Component } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { KeyValuePair } from '../shared/models/key-value-pair';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  uniqueLearnerDistricts: KeyValuePair[];

  constructor(private authService: AuthService) {}

  get notSuperAdmin() {
    return !!this.authService?.user && !this.authService.isSuperAdmin;
  }

  get title() {
    return 'My Dashboard';
  }
}
