<app-print-head title="Consent for Early ACCESS Post-Referral Screening" [showLogo]="true"></app-print-head>

<section class="print__container">
  <p>
    The purpose of this screening is to provide you and Early ACCESS information so you can make an informed decision on whether your child
    should have a full evaluation.
  </p>

  <p class="mb-3">I understand the purpose of this notice and I hereby give my consent for post-referral screening.</p>

  <div class="row mb-3">
    <div class="col-6"><strong>Child’s Name:</strong> {{ displayName }}</div>
    <div class="col-6"><strong>DOB:</strong> {{ childInfo?.dateOfBirth | dateFormat }}</div>
  </div>

  <p>You have the right to request and consent to an evaluation at any time during the screening process.</p>
  <p class="mb-3">
    This consent is good for 45 days from the signature date of this form. I understand that this consent is voluntary and that I may revoke
    this consent at any time by contacting my service coordinator or the service coordinator’s agency.
  </p>

  <div class="row pb-5 mt-4 border-signature">
    <div class="col-4 text-semibold">Parent/Guardian Signature</div>
    <div class="col-4 text-semibold">Relationship to child</div>
    <div class="col-4 text-semibold">Date</div>
  </div>

  <div class="row mt-5 pb-5 border-signature">
    <div class="col-4 text-semibold">Parent/Guardian Signature</div>
    <div class="col-4 text-semibold">Relationship to child</div>
    <div class="col-4 text-semibold">Date</div>
  </div>

  <p class="mt-4 text-semibold">Parent rights and procedural safeguards were provided and reviewed by:</p>

  <div class="row pb-5 border-signature">
    <div class="col-4 text-semibold">Name</div>
    <div class="col-4 text-semibold">Agency</div>
    <div class="col-4 text-semibold">Date</div>
  </div>

  <p>
    You may ask questions about your parental rights and obtain additional copies of the manual by contacting your Service Coordinator or or
    online:
    <a href="https://educateiowa.gov/pk-12/special-education/parent-information" target="_blank" tabindex="0" role="link"
      >https://educateiowa.gov/pk-12/special-education/parent-information</a
    >
  </p>

  <div class="mt-5" style="font-weight: bold">
    Health Insurance Portability and Accountability Act (HIPAA)/ Family Educational Rights and Privacy Act (FERPA) Notice
  </div>

  <p class="mt-4">
    Any and all personally identifiable information regarding children and families receiving Early ACCESS services funded under the
    Individuals with Disabilities Education Act (20 U.S.C. §1400 et seq.) is protected from unauthorized disclosure under FERPA. Personally
    identifiable information protected by FERPA is specifically <strong>exempted</strong> from HIPAA privacy standards. FERPA prohibits
    disclosure of personally identifiable information without parent consent except in limited circumstances, requires notice to be provided
    to the child’s family regarding their privacy rights, requires providers to keep records of access to a child’s records, and contains
    complaint and appeal procedures which apply to disputes over records in possession of Early ACCESS or its providers, among other
    provisions. All Early ACCESS providers comply with these procedures.
  </p>
</section>
