import { KeyValuePair } from 'src/app/shared/models/key-value-pair';

export class DialogData {
  documentationTypes: KeyValuePair[];
  title: string;
  fileName: string;
  allowedFileTypes: string;
  maxFileSize: number;
  maxFileSizeLabel: string;
}
