import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { countLeapYears, dateDurationInDays } from '../../dateTimeHelpers';
import { CaseSummary, ReopenCaseDto, ReopenStatus } from '../../models/case';
import { KeyValuePair } from '../../models/key-value-pair';
import { CaseService } from '../../services/case/case.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-reopen-case',
  templateUrl: './reopen-case.component.html',
  styleUrls: ['./reopen-case.component.scss'],
})
export class ReopenCaseComponent {
  @Input() caseSummary: CaseSummary;
  @Input() isButton = false;
  @ViewChild('reopenModal') reopenCaseModal: TemplateRef<any>;
  hasConfirmed = false;
  formGroup = new FormGroup({
    reopenStatus: new FormControl(null, Validators.required),
  });
  daysSinceExited: number;
  isMskTimeBasedReopen: boolean;
  contactSuperAdmin: boolean;
  reOpenCaseDto: ReopenCaseDto;
  reopenStatus = ReopenStatus;
  activeCall = false;
  reevaluationDueOn = 1094;
  daysInAYear = 365;

  reopenStatusOptions: KeyValuePair[] = [
    new KeyValuePair(ReopenStatus.ActiveIepInAnotherState, 'The team has access to an active IEP from another state.'),
    new KeyValuePair(
      ReopenStatus.NotEligibleInAnotherState,
      'The team has evidence that this learner was determined not eligible for special education in another state.'
    ),
    new KeyValuePair(
      ReopenStatus.NoInformationFromAnotherState,
      'The team has no information about an IEP or eligibility from another state.'
    ),
    new KeyValuePair(ReopenStatus.IncorrectlyExited, 'The learner was incorrectly exited and continues to be enrolled in an Iowa school.'),
  ];

  get isTimeBasedReopen() {
    const timeBasedExitCodes = ['DRO', 'MUK', 'UNK'];
    return timeBasedExitCodes.some((c) => c === this.caseSummary?.exitReasonCode);
  }

  get isMskBasedReopen() {
    return this.caseSummary?.exitReasonCode === 'MSK';
  }

  get isEligibilityBasedReopen() {
    const eligibilityBasedExitCodes = ['CNS', 'CSD', 'ENE', 'MOS', 'NDS', 'NMH', 'PDE', 'PRC', 'RRG', 'NSC'];
    return eligibilityBasedExitCodes.some((c) => c === this.caseSummary?.exitReasonCode);
  }

  constructor(private dialog: MatDialog, private caseService: CaseService, private notificationService: NotificationService) {
    this.formGroup.controls.reopenStatus.valueChanges.subscribe((selectedStatus) => {
      this.isMskTimeBasedReopen = false;
      this.contactSuperAdmin = false;
      if (selectedStatus === ReopenStatus.NoInformationFromAnotherState) {
        this.isMskTimeBasedReopen = true;
      } else if (selectedStatus === ReopenStatus.IncorrectlyExited) {
        this.contactSuperAdmin = true;
      }
    });
  }

  onReopenCase() {
    const caseSummaryExitDate = new Date(this.caseSummary?.exitDate);
    const currentDate = new Date();

    this.daysSinceExited = dateDurationInDays(caseSummaryExitDate, currentDate);
    this.reevaluationDueOn += countLeapYears(caseSummaryExitDate, currentDate);
    this.daysInAYear += dayjs(this.daysSinceExited).isLeapYear() ? 1 : 0;

    this.formGroup.reset();

    if (this.isMskBasedReopen) {
      this.formGroup.controls.reopenStatus.addValidators(Validators.required);
    } else {
      this.formGroup.controls.reopenStatus.clearValidators();
    }
    this.formGroup.controls.reopenStatus.updateValueAndValidity();

    this.dialog.open(this.reopenCaseModal, {
      width: '728px',
    });
  }

  reopen() {
    this.activeCall = true;
    this.reOpenCaseDto = {
      learnerId: this.caseSummary.learnerId,
      intakeType: this.caseSummary.intakeType,
      startNewCase: false,
      caseId: this.caseSummary.id,
      activateIep: false,
      retainEligibility: true,
      reopenStatus: this.formGroup.controls.reopenStatus.value,
    } as ReopenCaseDto;

    if (this.isEligibilityBasedReopen) {
      this.reOpenCaseDto.startNewCase = true;
      this.reOpenCaseDto.retainEligibility = false;
      this.reOpenCaseDto.caseId = null;
    } else if (this.isTimeBasedReopen || this.isMskTimeBasedReopen) {
      if (this.daysSinceExited < this.daysInAYear) {
        this.reOpenCaseDto.activateIep = true;
      } else if (this.daysSinceExited > this.reevaluationDueOn) {
        this.reOpenCaseDto.retainEligibility = false;
      }
    }

    if (this.isMskBasedReopen && this.formGroup.controls.reopenStatus.value === this.reopenStatus.NotEligibleInAnotherState) {
      this.reOpenCaseDto.startNewCase = true;
      this.reOpenCaseDto.retainEligibility = false;
      this.reOpenCaseDto.caseId = null;
    }

    this.caseService.reopenCase(this.caseSummary.learnerId, this.reOpenCaseDto).subscribe((reopenResponse) => {
      if (reopenResponse.succeeded) {
        window.location.reload();
      } else {
        this.activeCall = false;
        this.notificationService.error('Cannot reopen case');
      }
    });
  }
}
