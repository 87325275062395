<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Request Excusal</h3>

<div mat-dialog-content class="py-3">
  <mat-card>
    <div class="form__description py-1">
      <p class="my-0">
        Please select meeting participants that need to be excused
        <button mat-icon-button aria-label="Info" color="primary" class="ml-2" tabindex="0">
          <mat-icon>info</mat-icon>
        </button>
      </p>
    </div>
    <div class="table-overflow">
      <table mat-table [dataSource]="dataSource" class="w-100" matSort>
        <ng-container matColumnDef="fullName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Full Name</th>
          <td mat-cell *matCellDef="let element">
            {{ element.fullName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="requestExcusal">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Request Excusal</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              (change)="requestExcusal(element.id)"
              [checked]="element.requestExcusal"
              aria-labelledby="Check me!"
            ></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="noResults">
          <td mat-footer-cell *matFooterCellDef colspan="7">
            <i>No results...</i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr
          [hidden]="dataSource?.data?.length > 0"
          mat-footer-row
          *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"
        ></tr>
      </table>
      <mat-paginator [dataSource]="dataSource"></mat-paginator>
    </div>
  </mat-card>
</div>

<div mat-dialog-actions class="display-flex justify-content-end align-items-center">
  <div class="display-flex my-2">
    <button mat-button aria-label="Delete Icon" class="background-color--muted" (click)="onClose()" cdkFocusInitial tabindex="0">
      Close
    </button>
    <button
      mat-button
      aria-label="Send to Portal"
      class="background-color--muted"
      (click)="onSendToPortal()"
      [disabled]="!enableButtons()"
      tabindex="0"
    >
      Send to Portal
    </button>
    <button
      [disabled]="!enableButtons()"
      [isBusy]="isBusy"
      mat-flat-button
      aria-label="Print"
      color="primary"
      (click)="onPrint()"
      tabindex="0"
    >
      Print
    </button>
  </div>
</div>
