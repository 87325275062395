export class BankableFieldIds {
  public readonly otherFactors = new OtherFactorsFieldIds();
  public readonly providerInfoForm = new ProviderInfoFormFieldIds();
  public readonly learnerPerformance = new LearnerPerformanceFieldIds();
  public readonly evaluationInterview = new EvaluationInterviewFieldIds();
  public readonly reevaluationAdditionalFactors = new ReevaluationAdditionalFactorsFieldIds();
  public readonly medicalHistoryInfo = new MedicalHistoryInfoFieldIds();
  public readonly nextStepsForm = new NextStepsFormComponentFieldIds();
  public readonly esyGoals = new ESYGoalsFieldIds();
  public readonly esyAdjust = new ESYAdjustFieldIds();
  public readonly esyOverview = new ESYOverviewFieldIds();

  public readonly iepPlaafp = new IepPlaafpFieldIds();
  public readonly secondaryTransition = new SecondaryTransitionFieldIds();
  public readonly iepGoals = new IepGoalsFieldIds();
  public readonly iepSsaaAccommodations = new IepSsaaAccommodationsFieldIds();
  public readonly iepSsaaActivities = new IepSsaaActivitiesFieldIds();
  public readonly iepSsaaAssessments = new IepSsaaAssessmentFieldIds();
  public readonly iepSsaaLRE = new IepSsaaLREFieldIds();
  public readonly iepSsaaPhysicalEducation = new IepSsaaPhysicalEducationFieldIds();
  public readonly iepSsaaServices = new IepSsaaServicesFieldIds();

  public readonly outcomes = new IFSPOutcomeFieldIds();
  public readonly ifpsServiceLogs = new IFSPServiceLogsFieldIds();
}

//#region Eval,Intake
export class OtherFactorsFieldIds {
  public readonly testsAndOtherAssessmentsMethods = 'f0dadf13-e00a-4e20-a443-28079b397a19';
}

export class ProviderInfoFormFieldIds {
  public readonly phcpClinicAgencyName = '117D6313-4148-4D6D-97F5-C98F19D3112D';
  public readonly phcpName = 'B78E5DC4-86C3-4730-9551-BD0F50CCBC3C';
  public readonly phcpEmail = 'F1004F6E-8B8A-4E45-BCD1-662189E08AD2';
  public readonly agencyName = '85b8be14-bc2e-4edd-beee-672d6c8eb93e';
}

export class LearnerPerformanceFieldIds {
  public readonly toolOtherDescription = '2c880efc-4833-4612-8c5c-a774ca41c77a';
}

export class EvaluationInterviewFieldIds {
  public readonly toolOtherDescription = 'bfc3938f-b4fb-4832-83c9-6abd9774d66a';
}

export class ReevaluationAdditionalFactorsFieldIds {
  // This is the same as OtherFactorsFieldIds.testsAndOtherAssessmentsMethods
  public readonly testsAndOtherAssessmentsMethods = 'f0dadf13-e00a-4e20-a443-28079b397a19';
}

export class MedicalHistoryInfoFieldIds {
  public readonly hospitalClinicName = '2b66d32c-522c-497b-9bbe-9994a8c4e073';
}

export class NextStepsFormComponentFieldIds {
  public readonly postReferralScreeningToolOther = 'afd9dec6-dcea-4950-bcc6-33e55685bd88';
  public readonly postReferralScreeningRecommendationFollowUp = 'bbcab406-c5ab-4956-ae65-0cb2d6131ffa';
}
//#endregion

//#region IEP
//#region Goals
export class IepGoalsFieldIds {
  public readonly currentLevelDescription = '3fdcadc3-93b7-4e17-9392-8e5b8aef1d6a';
  public readonly standardOfComparisonOther = 'c201a98e-be38-4bf3-9564-5ae511db3850';
  public readonly conditionIndividualWillPerform = 'F760A767-A7B3-4756-8ACE-B2CEC390144D';
  public readonly goalNickName = 'b7f8d64c-ba60-482a-8084-ada2f50582c1';
  public readonly collaborators = '253e63e5-8af6-40d3-a130-82ff61f0a67e';
  public readonly monitoringProceduresDescription = 'cc4f2e82-c46b-468c-a3c5-5ae3cc2dd135';
  public readonly otherMethodToReport = '04957ec7-7d72-4725-b040-5d55f0b1aa1b';
  public readonly quantData = new IepGoalQuantDataFieldIds();
}

export class IepGoalQuantDataFieldIds {
  public readonly description = '15eb8a53-f482-4288-84ed-92a87d2b742d';
  public readonly scaleLevelDescription = '0DE5FE31-483A-47D7-9EC4-90E99CB4D7DF';
  public readonly dataPointUnitOfMeasurement = '3bc805d6-e86a-4846-9006-69ec40c5f1cd';
}
//#endregion

//#region Accommodations
export class IepSsaaAccommodationsFieldIds {
  public readonly description = '64b5b48c-77b2-4a15-bf66-31d7e87fda19';
}
//#endregion
//#region Activities
export class IepSsaaActivitiesFieldIds {
  public readonly description = 'f2c2f24c-63ad-4501-9f33-3a4dfd94f415';
}
//#endregion

//#region Assessment
export class IepSsaaAssessmentFieldIds {
  public readonly whatBarriers = '248dfe28-cbc6-455b-a6e2-0f29b4212651';
  public readonly assessmentNecessary = 'd40ce9fa-2401-4499-b304-cc1b8209af0e';
}
//#endregion

//#region LRE
export class IepSsaaLREFieldIds {
  public readonly minutesRecievingInstructionRationale = 'f5d71049-4af2-46b3-888f-3deafccb0d29';
  public readonly reasonsCantProvide = 'b44f2858-8f1f-4188-ab92-6c18095969e0';
  public readonly specialServicesProvidedExp = '4e4ce44e-3ccd-4617-8190-4d9dafd757c5';
  public readonly attendSchoolIfExp = '79cef867-bae2-4611-966e-c64727004c33';
  public readonly supplementaryAids = '7cee5274-35d4-4431-b1b1-c6e34d2d0c66';
  public readonly notPossibleForAids = '732c7ed3-4541-4838-a444-6239a33968c5';
  public readonly continuumServiceAvailable = '2a560f6a-8737-4c06-9d53-d9a3563096fe';
  public readonly cannotBeProvided = '4cdcbe50-8ed2-4655-b3c1-671c4c02cf3d';
}
//#endregion

//#region Physical Education
export class IepSsaaPhysicalEducationFieldIds {
  public readonly physicalEdDescription = '07192ff9-897a-4302-b7af-edd31e2ee8d3';
}
//#endregion

//#region Services
export class IepSsaaServicesFieldIds {
  public readonly transportationServicesDescription = '6ee7f2ae-b2f6-41a6-91d6-a84305b12b63';
  public readonly description = '6d2cc71f-1b9a-4861-af3b-1f08b5d1d293';
}
//#endregion

//#region PLAAFP/Secondary Transition
export class IepPlaafpFieldIds {
  public readonly description = 'c1d6c3cb-6f82-4fd8-83b7-af2639cd0bce';
  public readonly strengthSkillsComments = '2a85d9d7-5bab-4512-816e-7861a130423d';
  public readonly strengthSkillsDataSource = '1486732e-a047-4cd3-ba63-d2a4d4a7900f';
  public readonly preferenceComments = 'd30f56de-6b04-4716-9574-a3bfb055eeac';
  public readonly preferenceDataSource = 'd2a82911-2bac-4c4d-8bea-4b911dc57297';
  public readonly describeStudentInvolvement = '13adf0de-ddae-4997-891a-ba533e124184';
  public readonly describeHowParticipate = '97901d7d-afe2-47c9-b9ce-21b5d0635df4';
  public readonly concernsComments = 'c18f478d-0b3a-495c-b118-c9dfee190394';
  public readonly concernsDataSource = '0000ffe6-80f4-4f82-a421-6933f957db5e';
  public readonly otherInformation = 'd1157621-1f1d-4442-9478-969e5a11c6b8';
}

//#region Secondary Transition
export class SecondaryTransitionFieldIds {
  public readonly pseActivities = new PSEActivitiesFieldIds();
  public readonly pseActivitiesCourseActivities = new PSEActivitiesCourseActivities();
  public readonly pseSummary = new PSESummaryFieldIds();
  public readonly transferOfRights = new TransferOfRightsFieldIds();
  public readonly transitionAssessment = new TransitionAssessmentFieldIds();
}

export class PSEActivitiesFieldIds {
  public readonly comments = 'f9a13f20-9917-4a1c-ae11-ef43709785dd';
  public readonly changesAdditions = '16ef0890-bdf3-4441-a4d7-c3e0c1121e40';
}

export class PSEActivitiesCourseActivities {
  public readonly name = '5f85759b-09d0-417a-bf73-3991709fe1eb';
  public readonly projectedDate = '9a4cec10-0764-45d5-8bd4-7020b3ed2c5a';
}

export class PSESummaryFieldIds {
  public readonly livingSummaryAssessment = 'fb40b427-2f63-4845-b235-24f6da43cba5';
  public readonly livingExpectations = '99224a0c-b874-4f55-8def-14037b1ac00f';
  public readonly livingOtherDescription = '9e98e5e4-d80e-48c4-8121-01352a591cbb';
  public readonly learningSummaryAssessment = '2e864bfb-85b7-4b2d-be16-6b39d42ad3cd';
  public readonly learningExpectations = 'c6420e4d-42b9-47ac-9808-41d8f0e2477e';
  public readonly learningOtherDescription = 'e03a0227-488c-48d1-bf0f-f5f23e9365a0';
  public readonly workingSummaryAssessment = '56325828-b07d-4698-84fe-8cc5a6277fcf';
  public readonly workingExpectations = 'beb092a2-6c90-4ad2-88e6-9ca00ddd5205';
  public readonly workingOtherDescription = '472d4758-9ad5-4742-910d-fea995532984';
}

export class TransferOfRightsFieldIds {
  public readonly learnerNotificationMethodOther = '825361f4-6cb9-4fed-ab08-2a23de3b0663';
  public readonly willNotTransferExplanation = 'bcb81754-3870-4047-a69c-9285e27a2e45';
}

export class TransitionAssessmentFieldIds {
  public readonly assessment = '00e1fb96-1f68-46ab-89e0-4521d6051603';
  public readonly notes = '85f7a72e-55c6-4545-9e00-50d6d973276e';
}
//#endregion
//#endregion
//#region ESY
export class ESYGoalsFieldIds {
  public readonly transportationServicesDescription = 'c0386407-92d5-4406-a564-4509fc43e65d';
  public readonly serviceDescription = 'e3c972bb-d34c-47f6-8441-9bb4d7cb770e';
  public readonly location = 'dac9378d-7e0b-4145-81ed-9c0ff1b05a6b';
  public readonly activitySupportDescription = 'e5df2789-70b3-4d20-a72d-7b857416cfcb';

  public readonly significantRegressionExplanation = '89588826-daa8-4dff-bcf1-af6541e4670b';
  public readonly rareCircumstancesExplanation = 'df078584-56c9-40a6-965e-5576d05255a0';
  public readonly otherFactorsExplanation = '9b996c4c-7255-4fd9-a28b-812031bb0c2f';
}

export class ESYAdjustFieldIds {
  public readonly otherMethodToReport = '870a50f9-470a-4d8a-a403-c3904cb501e4';
}

export class ESYOverviewFieldIds {
  public readonly accessibleMaterials = '5276c416-6946-4410-834a-b435d1b92718';
  public readonly assistiveTechnology = 'c9c22c49-6bf9-4152-8c9b-7b5eb4a10a65';
  public readonly braille = 'c4b6ecc9-b238-44f9-877b-b65ad935d57c';
  public readonly communicationLanguage = '369a5b97-8b88-451c-b5b3-031d138b2771';
  public readonly limitedEnglish = '7c638ccf-0021-4321-8658-30089b83bef1';
  public readonly health = '9ea93022-bbbc-436a-a216-63926cb3970d';
  public readonly hearing = 'cda6f161-8187-43f7-adef-ff4e9c2dc1d9';
  public readonly behavior = '7afe5fb2-cf15-4d70-9f4d-7dc370b155d3';
}
//#endregion
//#endregion

//#region IFSP
export class IFSPOutcomeFieldIds {
  public readonly strategiesName = '3ac210d1-af67-4856-8044-7b5ef03c82be';
  public readonly criteriaForAccomplishment = 'c46b3a04-77b6-4af8-b5e7-8947e80af14b';
  public readonly quantData = new IFSPOutcomeQuantData();
}

export class IFSPOutcomeQuantData {
  public readonly performanceNarrative = 'ce6a0e73-8630-4c96-8459-d2de69726603';
  public readonly secondMeasurementDescription = '6a756b1a-5eb0-482c-9399-fcd702a3c819';
  public readonly secondExpectedPerformanceNarrative = 'c5fd78a4-614f-4925-a0a9-5bca5b55ef07';
  public readonly secondPerformanceNarrative = 'd10e76e9-9f5b-41ba-983f-febbfc76e606';
  public readonly benchmarkObjectiveMilestone = '9a16f419-f208-4e3e-957f-275a3e47a5a0';
  public readonly scaleLevelDescription = '335dda56-f6fb-461c-a7c7-423cbc7902c0';
}

export class IFSPServiceLogsFieldIds {
  public readonly providerLocation = '750daefe-93bf-49f0-9bba-e65b091ee40b';
  public readonly platform = '00dae213-d928-4ebc-be0d-9ef6b5dfeb4a';
}
//#endregion
