import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { BaseComponent } from '../../shared/components/base-component/base-component';
import { AchieveConfigService } from '../../shared/services/achieve-config-service/achieve-config.service';
import { DeactivationService } from '../../shared/services/deactivation.service';
import { ExpanderService } from '../../shared/services/expander.service';
import { NotificationService } from '../../shared/services/notification.service';
import { PdfOutputs } from '../../shared/services/reporting/reporting.service';
import { conditionalWithContextValidator } from '../../shared/validators';
import { ActionPlan } from '../models/action-plan';
import { Clp } from '../models/clp';
import { clpIepServiceDeliveryFormat } from '../models/clp-iep-service-delivery-format';
import { clpIepServiceFrequency } from '../models/clp-iep-service-frequency';
import { TrialPlacementTarget } from '../models/iep';
import { ClpService } from '../services/clp.service';
import { GoalPlanService } from '../services/goal-plan.service';

@Component({
  selector: 'app-clp',
  templateUrl: './clp.component.html',
  styleUrls: ['./clp.component.scss'],
  providers: [GoalPlanService, ExpanderService],
})
export class ClpComponent extends BaseComponent implements OnInit {
  private caseId: string;
  iepId: string;
  pageTitle: string;
  attendingDistrictId: string;
  learnerId: string;
  iepTrialPlacement: { iepIncludesTrialPlacement: boolean; iepTrialPlacementTarget: TrialPlacementTarget };
  isOutputEnabled: boolean;
  clp = {} as Clp;
  clpForm: FormGroup;
  specialEducationServiceDeliveryFormats: clpIepServiceDeliveryFormat[] = [];
  specialEducationServiceFrequencies: clpIepServiceFrequency[] = [];
  pdfOutputs = PdfOutputs;

  save = new Observable<boolean>((observer) => {
    const done = () => {
      this.isSaving = false;
      observer.next(true);
    };
    const invalidAutoComplete = this.checkAutoCompleteErrors();

    if (!this.clpForm.dirty || this.isSaving || this.clp.isCompleted || invalidAutoComplete) {
      done();
      return;
    }

    this.isSaving = true;

    const clp = this.clpForm.value;
    clp.isReadyForCompletion = this.clpForm.valid;

    this.clpService.updateClp(this.iepId, clp).subscribe(
      (res) => {
        if (!res?.succeeded) {
          this.notificationService.error(res?.errors?.length ? res.errors[0]?.description : 'Failed to save.');
        }
      },
      (err) => {
        this.notificationService.error('Failed to save.');
        observer.error(err);
      },
      () => done()
    );
  });

  get canPushToPortal() {
    return this.achieveConfigService.settings?.featureFlags?.familyPortalEnabled;
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly notificationService: NotificationService,
    private readonly caseService: CaseService,
    private readonly clpService: ClpService,
    private readonly router: Router,
    deactivationService: DeactivationService,
    private achieveConfigService: AchieveConfigService
  ) {
    super(deactivationService);
  }

  async ngOnInit() {
    this.isOutputEnabled = this.achieveConfigService.settings?.featureFlags?.outputClpEnabled;
    this.caseId = this.route.snapshot.paramMap.get('caseId');
    this.iepId = this.route.snapshot.paramMap.get('iepId');
    this.clpForm = this.fb.group({
      goalPlans: this.fb.array([]),
      specialServicePlans: this.fb.array([]),
      activitySupportAccommodationPlans: this.fb.array([]),
      otherSupports: this.fb.group({
        id: null,
        parentSupport: [null, Validators.required],
        technologySupport: [null, Validators.required],
      }),
      otherRelevantFactors: null,
    });

    this.caseService.getCaseSummary(this.caseId).subscribe((caseSummary) => {
      this.attendingDistrictId = caseSummary.learner.attendingDistrictId;
      this.learnerId = caseSummary.learnerId;
      this.pageTitle = 'Contingency Learning Plan - ' + caseSummary.learner.fullName;
    });

    this.getDropdownOptions();
    this.clpService.getClp(this.iepId).subscribe((res) => {
      if (!res?.succeeded) {
        this.notificationService.error(res?.errors?.length ? res.errors[0]?.description : 'Something went wrong'!);
        return;
      }
      this.clp = res.value;
      this.iepTrialPlacement = {
        iepIncludesTrialPlacement: this.clp.iepIncludesTrialPlacement,
        iepTrialPlacementTarget: this.clp.iepTrialPlacementTarget,
      };
      this.setupForm();
    });
  }

  private getDropdownOptions() {
    this.clpService
      .getClpSpecialEducationServicesDeliveryFormats()
      .subscribe((list) => (this.specialEducationServiceDeliveryFormats = list));

    this.clpService.getClpSpecialEducationServicesFrequencies().subscribe((list) => (this.specialEducationServiceFrequencies = list));
  }

  private setupForm() {
    this.populateFormArrays();
    if (!this.clp.isCompleted) {
      this.saveSubscription = this.clpForm.valueChanges;
      this.startAutosaving();
    } else {
      this.save = null;
    }
  }

  private populateFormArrays(): void {
    const goalArray = this.clpForm.controls.goalPlans as FormArray;
    for (const goalPlan of this.clp.goalPlans) {
      goalArray.push(
        this.fb.group({
          id: [goalPlan.id],
          iepGoalId: [goalPlan.iepGoalId],
          goalPlan: this.generateActionPlanForm(goalPlan?.goalPlan),
          progressMonitoring: this.generateActionPlanForm(goalPlan?.progressMonitoring),
        })
      );
    }

    const serviceArray = this.clpForm.controls.specialServicePlans as FormArray;
    for (const servicePlan of this.clp.specialServicePlans) {
      serviceArray.push(
        this.fb.group({
          id: [servicePlan.id],
          iepServiceId: [servicePlan.iepServiceId],
          iepServicePlanDeliveryFormats: [servicePlan?.iepServicePlanDeliveryFormats || []],
          serviceClpFrequencyId: [servicePlan?.serviceClpFrequencyId, Validators.required],
          frequencyDescription: [servicePlan?.frequencyDescription],
        })
      );
    }

    const asaArray = this.clpForm.controls.activitySupportAccommodationPlans as FormArray;
    for (const asaPlan of this.clp.activitySupportAccommodationPlans) {
      asaArray.push(
        this.fb.group({
          id: [asaPlan.id],
          iepActivitySupportId: [asaPlan.iepActivitySupportId],
          iepAccommodationId: [asaPlan.iepAccommodationId],
          serviceClpFrequencyId: [asaPlan?.serviceClpFrequencyId, asaPlan.iepActivitySupportId ? Validators.required : []],
          frequencyDescription: [asaPlan?.frequencyDescription],
        })
      );
    }

    this.clpForm.controls.otherSupports.patchValue(this.clp.otherSupports);
    this.clpForm.controls.otherRelevantFactors.patchValue(this.clp.otherRelevantFactors);
  }

  goToIep() {
    this.router.navigate(['/', 'cases', this.caseId, 'iep', this.iepId, this.clp.isDraftIep ? 'profile' : 'details']);
  }

  private checkAutoCompleteErrors() {
    // why do we need this check?
    // if we really need this, then we need to fix the logic flow. Currently we are not saving if this error exists.
    for (const goal of (this.clpForm.get('goalPlans') as FormArray).controls) {
      if ('typeahead' in (goal.get('goalPlan').get('action').errors ?? {})) {
        return true;
      }
      if ('typeahead' in (goal.get('progressMonitoring').get('action').errors ?? {})) {
        return true;
      }
    }
    return false;
  }

  private generateActionPlanForm(actionPlan: ActionPlan): FormGroup {
    const conditionalValidator = conditionalWithContextValidator(
      (ctrl) => ctrl.parent?.get('action').value && ctrl.parent?.get('action').value !== 'PerformOriginal',
      Validators.required
    );
    return this.fb.group({
      action: [actionPlan?.action, Validators.required],
      description: [actionPlan?.description, conditionalValidator],
    });
  }

  toFormArray(ctrl: AbstractControl): FormArray {
    return ctrl as FormArray;
  }

  saveExit(): void {
    this.clpForm.markAsDirty();
    this.triggerSave();
    this.goToIep();
  }
}
