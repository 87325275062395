import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { DocumentationInformation, IntakeDocuments } from 'src/app/evaluation/models/intake';
import { FeatureFlagService } from 'src/app/shared/components/feature-flags/feature-flag.service';
import {
  DialogData,
  UploadDocumentationModalComponent,
} from 'src/app/shared/modals/upload-documentation-modal/upload-documentation-modal.component';
import { ConsentForm, ConsentFormType } from 'src/app/shared/models/fiie-consent/consent-form';
import { ConsentStatus } from 'src/app/shared/models/fiie-consent/consent-status';
import { FamilyConsentStatus } from 'src/app/shared/models/fiie-consent/family-consent-status';
import { FileDocument } from 'src/app/shared/models/file-document';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { ConsentFormService } from 'src/app/shared/services/consent-form/consent-form.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { PdfOutputs, ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { NewWindowConfig, openNewWindow, openPdfWindow } from 'src/app/shared/windowHelpers';

@Component({
  selector: 'app-documentation-info-form',
  templateUrl: './documentation-info-form.component.html',
  styleUrls: ['./documentation-info-form.component.scss'],
})
export class DocumentationInfoFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() documents: DocumentationInformation;
  @Input() sectionDocuments: IntakeDocuments[];
  @Input() formGroup: FormGroup;
  @Input() title: string;
  @Input() noTitle = false;
  @Input() route: string;
  @Input() documentLink: string;
  @Input() documentTitle: string;
  @Input() documentationTypeOptions: KeyValuePair[];
  @Input() documentsToDisplay: any[];
  @Input() locked: boolean;
  @Input() caseId: string;
  @Input() learnerId: string;
  @Input() consentDocument: boolean;
  @Input() consentFormId: string;
  @Input() section: string;
  @Output() deleteDocument = new EventEmitter();
  @Output() upload = new EventEmitter();
  @Output() formInitialized = new EventEmitter();
  @Input() hideUploadDocButton = false;
  @Input() uploadButtonTitle = 'Upload Documentation';
  @Input() uploadCardTitle: string;
  @Input() documentType: ConsentFormType | string;
  @Input() noCard = false;
  @Input() learnersName: string;

  pdfOutputs = PdfOutputs;

  formInitializationFlags = {
    controlsInitialized: false,
    dataLoaded: false,
    eventEmitted: false,
  };

  @ViewChild('verifyDelete') verifyDeleteWarning: TemplateRef<any>;
  listOfFiles: FileList;
  permissionFormRequired: boolean;

  private subscription: Subscription = new Subscription();

  constructor(
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private consentFormService: ConsentFormService,
    private reportingService: ReportingService,
    private readonly notificationService: NotificationService,
    private readonly featureFlagService: FeatureFlagService
  ) {}

  ngOnInit() {
    this.removeNullTypes();
    this.filterDocuments();
    this.initializeControls();
    this.formInitializationFlags.dataLoaded = true;
    this.evaluateFormInitialization();
  }

  filterDocuments() {
    if (this.section) {
      this.sectionDocuments = this.documents?.intakeDocuments.filter((document) => document.section === this.section);
    } else {
      this.sectionDocuments = this.documents?.intakeDocuments;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.documents?.previousValue !== changes.documents?.currentValue) {
      this.filterDocuments();
    }
    this.removeNullTypes();
  }

  disableUpload() {
    if (this.documents?.intakeDocuments.filter((document) => document.section === this.section).length > 0 && this.consentDocument) {
      return true;
    }
    return false;
  }

  removeNullTypes() {
    if (this.documents) {
      this.documents.intakeDocuments.forEach((documentInfo) => {
        if (documentInfo.document.type === 'null') {
          documentInfo.document.type = 'N/A';
        }
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private initializeControls() {
    this.formGroup.addControl('documentTitle', new FormControl(''));
    this.formGroup.addControl('documentType', new FormControl(null));
    this.formGroup.updateValueAndValidity();
    this.formInitializationFlags.controlsInitialized = true;
    this.evaluateFormInitialization();
  }

  openInput() {
    // TODO: Do not manipulate DOM elements directly.
    document.getElementById('signedPermissionInput').click();
  }

  documentationFileChange($event: Event) {
    this.uploadFiles(($event.target as HTMLInputElement).files, false);
  }

  uploadFiles($event, permissionFile: boolean) {
    this.listOfFiles = $event;
    const formData = new FormData();
    let tooBig = false;
    let empty = false;
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < this.listOfFiles.length; i++) {
      formData.append('documents', this.listOfFiles[i], this.listOfFiles[i].name);
      if (this.listOfFiles[i].size > 209715200) {
        tooBig = true;
      }
      if (this.listOfFiles[i].size === 0) {
        empty = true;
      }
    }
    if (tooBig) {
      this.snackbar.open('File too large. Please limit files to 200MB or less.', '', { duration: 1000 });
    } else if (empty) {
      this.snackbar.open('File is empty.', '', { duration: 1000 });
    } else {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < this.listOfFiles.length; i++) {
        if (permissionFile) {
          formData.append('titles', 'Signed Permission Form');
        } else {
          formData.append('titles', this.formGroup.controls.documentTitle.value);
        }
      }
      this.upload.emit(formData);
      this.formGroup.controls.documentTitle.setValue('');
    }
  }

  confirmDeleteDocument(document: FileDocument) {
    this.dialog
      .open(this.verifyDeleteWarning, {
        width: '400px',
        data: document,
      })
      .afterClosed()
      .subscribe(() => {});
  }

  onDeleteDocument(documentId: string) {
    this.deleteDocument.emit(documentId);
  }

  onOpenDocument() {
    switch (this.route) {
      case PdfOutputs.ConsentInitialEvalPwn:
        if (this.featureFlagService.featureOn('outputConsentPwnEvalAssessmentEnabled')) {
          this.reportingService.createConsentInitialEvalPwnOutput(this.caseId).subscribe({
            next: (documentId) => openPdfWindow(this.learnerId, documentId),
            error: (err) =>
              this.notificationService.errorWithAction("Couldn't open output", 'Why?', () =>
                this.notificationService.alert(err.error, "Couldn't open output")
              ),
          });
        }
        break;
      default:
        const config: NewWindowConfig = {
          path: `evaluation/${this.caseId}/${this.route}`,
          popup: true,
          width: '1300px',
        };
        openNewWindow(config);
        break;
    }
  }

  onOpenExternalDocument(link: string) {
    const config: NewWindowConfig = {
      external: true,
      path: link,
      popup: true,
      width: '1300px',
    };
    openNewWindow(config);
  }

  onOpenUpload() {
    const dialogRef = this.dialog.open(UploadDocumentationModalComponent, {
      data: {
        documentationTypes: this.documentationTypeOptions,
        title: this.uploadCardTitle,
        fileName: this.documentTitle,
        type: this.documentType,
        learnersName: this.learnersName,
      } as DialogData,
      width: '728px',
    });

    dialogRef.afterClosed().subscribe((result: Array<any>) => {
      if (result) {
        const formData = new FormData();
        result.forEach((element) => {
          formData.append('titles', element.title);
          formData.append('documents', element.file, element.file.name);
          formData.append('types', element.type);
          formData.append('sections', this.section);
          formData.append('consents', this.consentDocument ? 'true' : 'false');
          formData.append('isPHCPs', 'false');
        });
        if (this.consentDocument && !this.consentFormId) {
          this.createConsent(formData);
        } else {
          this.upload.emit(formData);
        }
        this.filterDocuments();
      }
    });
  }

  createConsent(formData: FormData) {
    const consentForm = {} as ConsentForm;
    consentForm.caseId = this.caseId;
    if (this.documentType) {
      consentForm.type = this.documentType as ConsentFormType;
    }
    this.consentFormService.createConsentForm(this.caseId, consentForm).subscribe((consentFormAdded) => {
      const consentStatus = {} as ConsentStatus;
      consentStatus.consentFormId = consentFormAdded.id;
      consentStatus.status = FamilyConsentStatus.Approved;
      consentStatus.dateSigned = new Date();
      this.consentFormService.addConsentFormStatus(this.caseId, consentStatus).subscribe();
      this.upload.emit(formData);
    });
  }

  private evaluateFormInitialization() {
    if (this.formInitializationFlags.eventEmitted) {
      return;
    }
    if (this.formInitializationFlags.controlsInitialized && this.formInitializationFlags.dataLoaded) {
      this.formInitializationFlags.eventEmitted = true;
      this.formInitialized.emit();
    }
  }
}
