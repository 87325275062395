import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { District } from '../../models/district';
import { AppPermissions } from 'src/app/permissions';
import { Observable, of } from 'rxjs';
import { OperationResultWithValue } from '../../models/operation-result';

@Injectable({
  providedIn: 'root',
})
export class DistrictService {
  private baseUrl = 'api/districts';

  constructor(private http: HttpClient) {}

  getDistricts(adminAeas?: string[]) {
    let params = new HttpParams();

    if (adminAeas && adminAeas.length > 0) {
      adminAeas.forEach((adminAea) => (params = params.append('adminAeas', adminAea)));
    }
    return this.http.get<District[]>(this.baseUrl, { params });
  }

  getByPermission(includeBuilding: boolean, ...perms: AppPermissions[]): Observable<OperationResultWithValue<any>> {
    if (perms.length === 0) return of({ succeeded: true, value: {} });

    let params = new HttpParams();
    for (const perm of perms) {
      params = params.append('permissions', perm);
    }
    return this.http.get<OperationResultWithValue<any>>(`${this.baseUrl}/by-permission/${includeBuilding}`, { params });
  }
}
