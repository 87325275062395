import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComingSoonComponent } from 'src/app/shared/components/coming-soon/coming-soon.component';
import { CaseSummary } from 'src/app/shared/models/case';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { NewWindowConfig, openNewWindow, openPdfWindow } from '../../shared/windowHelpers';
import { IfspType } from '../models/ifsp';
import { IfspService } from '../services/ifsp.service';
import { ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { Subscription } from 'rxjs';
import { AreYouSureComponent } from '../../shared/components/are-you-sure-modal/are-you-sure.component';
import { FeatureFlagService } from '../../shared/components/feature-flags/feature-flag.service';

@Component({
  selector: 'app-ifsp-header',
  templateUrl: './ifsp-header.component.html',
  styleUrls: ['./ifsp-header.component.scss'],
})
export class IfspHeaderComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  ifspId: string;
  caseId: string;
  caseSummary: CaseSummary;
  isDetailsPage = false;
  headerTitle = 'IFSP Development';

  activeCalls = {
    ifspPdf: false,
    pushToPortal: false,
  };

  get learnerId() {
    return this.caseSummary?.learnerId;
  }

  get canPushToPortal() {
    return this.featureFlagService.featureOn('familyPortalEnabled');
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private caseService: CaseService,
    private ifspService: IfspService,
    private reportingService: ReportingService,
    private dialog: MatDialog,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit(): void {
    // Maybe needs to be a different ID as this gets more baked in
    this.caseService.getCaseSummary(this.route.snapshot.paramMap.get('caseId')).subscribe((caseSummary) => {
      this.caseSummary = caseSummary;
      this.headerTitle = `${this.headerTitle} - ${this.caseSummary.learner.fullName}`;
    });
    this.ifspId = this.route.snapshot.paramMap.get('ifspId');

    this.ifspService.get(this.ifspId).subscribe((ifsp) => {
      if (ifsp.ifspType === IfspType.Interim) {
        this.headerTitle = this.headerTitle.replace(/^/, 'Interim ');
      }
    });

    this.router.url.indexOf('details') > -1 ? (this.isDetailsPage = true) : (this.isDetailsPage = false);
  }

  onOpenMatrix() {
    const config: NewWindowConfig = {
      path: `evaluation/${this.caseSummary.evaluationId}/eco-matrix`,
    };
    openNewWindow(config);
  }

  onViewIfsp() {
    this.activeCalls.ifspPdf = true;

    this.subscriptions.add(
      this.reportingService.createIfspOutput(this.ifspId, false).subscribe({
        next: (documentId) => {
          this.handleOutputCreation(documentId);
          endActiveCall();
        },
        error: (err) => {
          this.reportingService.handleOutputError(err);
          endActiveCall();
        },
      })
    );

    const endActiveCall = () => {
      this.activeCalls.ifspPdf = false;
    };
  }

  onPushToPortal() {
    this.activeCalls.pushToPortal = true;

    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion: 'Clicking Yes will generate a new draft PDF that will be sent to the portal for the family to view.',
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.subscriptions.add(
          this.reportingService.createIfspOutput(this.ifspId, true).subscribe({
            next: (documentId) => {
              this.handleOutputCreation(documentId);
              endActiveCall();
            },
            error: (err) => {
              this.reportingService.handleOutputError(err);
              endActiveCall();
            },
          })
        );
      } else {
        endActiveCall();
      }
    });

    const endActiveCall = () => {
      this.activeCalls.pushToPortal = false;
    };
  }

  private handleOutputCreation(documentId): void {
    openPdfWindow(this.caseSummary.learnerId, documentId);
  }

  onComingSoon() {
    this.dialog.open(DialogComingSoonComponent);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
