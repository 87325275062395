import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import 'chartjs-plugin-labels';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { LookupBase } from 'src/app/shared/models/lookup-base';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SurveyReportingService } from '../../services/survey-reporting.service';
import { SurveyTypes } from '../models/survey-types';
import { ReportExportComponent } from './report-export/report-export.component';

@Component({
  selector: 'app-survey-reporting-dashboard',
  templateUrl: './survey-reporting-dashboard.component.html',
  styleUrls: ['./survey-reporting-dashboard.component.scss'],
})
export class SurveyReportingDashboardComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  surveyTypes = SurveyTypes;
  surveyType: string;
  loadData$ = new Subject();
  learnerSurveyReport: LearnerSurveyReport;
  aeas: LookupBase[] = [];
  leas: LookupBase[] = [];
  buildings: LookupBase[] = [];
  schoolYears: LookupBase[] = [];
  totalCompletionPercentage: number;
  dataSource: MatTableDataSource<LearnerSurveyReportItem> = new MatTableDataSource();
  dataSourceSurveyStatus: MatTableDataSource<LearnerSurveyStatusCount> = new MatTableDataSource();
  dataSourceFamilyInvolvementResult: MatTableDataSource<AbcReportItem> = new MatTableDataSource();
  overallPieChart: PieChart = {
    data: [],
    labels: ['Complete', 'Incomplete'],
    colors: [
      {
        backgroundColor: ['rgba(41, 99, 28, 0.3)', 'rgba(38, 63, 140, 0.2)'],
      },
    ],
    legend: true,
    options: {
      title: {
        display: true,
        text: 'Percentage of Completed Surveys',
        fontSize: 18,
        fontColor: '#263f8c',
        fontStyle: 'normal',
      },
      tooltips: {
        enabled: false,
      },
      plugins: {
        labels: {
          return: 'percentage',
        },
      },
    },
  };

  statusPieChart: PieChart = {
    data: [],
    labels: ['Complete', 'In Progress', 'Not Started', 'Closed'],
    colors: [
      {
        backgroundColor: ['rgba(41, 99, 28, 0.3)', 'rgba(38, 63, 140, 0.2)', 'rgba(245, 214, 142, 1)'],
      },
    ],
    legend: true,
    options: {
      title: {
        display: true,
        text: 'Percentage of All Sent Surveys',
        fontSize: 18,
        fontColor: '#263f8c',
        fontStyle: 'normal',
      },
      tooltips: {
        enabled: false,
      },
      plugins: {
        labels: {
          return: 'percentage',
        },
      },
    },
  };

  formGroup = this.fb.group({
    aeas: [],
    leas: [],
    buildings: [],
    schoolYearId: '',
  });

  displayedColumns = ['label', 'sent', 'responses', 'responseRate', 'percentOfAllResponses', 'comparisonToStatePopulation', 'difference'];

  public aeaFilterControl: FormControl = new FormControl();
  public filteredAeas: ReplaySubject<LookupBase[]> = new ReplaySubject(1);
  public leaFilterControl: FormControl = new FormControl();
  public filteredLeas: ReplaySubject<LookupBase[]> = new ReplaySubject(1);
  public buildingFilterControl: FormControl = new FormControl();
  public filteredBuildings: ReplaySubject<LookupBase[]> = new ReplaySubject(1);

  get isStateUser() {
    return this.authService.isStateUser || this.authService.isSuperAdmin;
  }

  get isAeaUser() {
    return this.authService.isAeaStaff;
  }

  constructor(
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly surveyReportingService: SurveyReportingService,
    private readonly fb: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.loadData$
      .pipe(
        debounceTime(750),
        switchMap((filters) => {
          let apiCall = undefined;
          if (this.surveyType === this.surveyTypes.EarlyAccessFamilyEngagementSurvey) {
            apiCall = this.surveyReportingService.getEAStats(filters);
          }
          if (this.surveyType === this.surveyTypes.SpecialEducationFamilyEngagementSurvey) {
            apiCall = this.surveyReportingService.getSEStats(filters);
          }
          return apiCall.pipe(map((result) => ({ result, filters })));
        })
      )
      .subscribe((result: any) => {
        const res = result.result;
        const filters = result.filters;
        this.learnerSurveyReport = res;
        this.dataSource.data = [
          {
            sent: res.overall.sent,
            responses: res.overall.responses,
            responseRate: res.overall.responseRate,
          } as LearnerSurveyReportItem,
        ];

        this.dataSourceSurveyStatus.data = res.statusCounts;
        this.dataSourceFamilyInvolvementResult.data = res.abcReport;

        this.configureOverallPieChart(res.overall.responseRate);
        this.configureStatusPieChart(res.statusCounts);
        if (!filters) {
          this.aeas = this.learnerSurveyReport.aeaOptions;
          this.leas = this.learnerSurveyReport.leaOptions;
          this.buildings = this.learnerSurveyReport.buildingOptions;
          this.schoolYears = this.learnerSurveyReport.schoolYears;
          this.schoolYears.unshift({ id: '00000000-0000-0000-0000-000000000000', label: 'All' } as LookupBase);
          this.filteredAeas.next(this.learnerSurveyReport.aeaOptions.slice());

          this.filteredLeas.next(this.learnerSurveyReport.leaOptions.slice());
          this.filteredBuildings.next(this.learnerSurveyReport.buildingOptions.slice());
        }
      });
    this.surveyType = this.route.snapshot.data.surveyType;
    if (!!this.surveyType) {
      this.loadData$.next();
      this.setupFormGroupSubscriptions();
    }
  }

  onOpenExport(reportType: string) {
    const filterOptions = this.filterOptionsForExport();
    this.dialog.open(ReportExportComponent, {
      width: '728px',
      data: {
        aeas: filterOptions.aeaOptions,
        leas: filterOptions.leaOptions,
        buildings: filterOptions.buildingOptions,
        isAeaUser: this.isAeaUser,
        sources: this.learnerSurveyReport.sources,
        schoolYears: this.schoolYears,
        races: this.learnerSurveyReport.races,
        genders: this.learnerSurveyReport.genders,
        ages: filterOptions.ageOptions,
        reportType: reportType,
        surveyType: this.surveyType,
      },
    });
  }

  sendNudge(type, value) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion:
          'Please confirm that you wish to send the reminder email to all families in this group that have not yet completed their survey.',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        if (this.surveyType === this.surveyTypes.EarlyAccessFamilyEngagementSurvey) {
          this.surveyReportingService.sendEASurveyReminder(type, value).subscribe(() => {
            this.notificationService.success('Successfully sent nudge');
          });
        }

        if (this.surveyType === this.surveyTypes.SpecialEducationFamilyEngagementSurvey) {
          this.surveyReportingService.sendSESurveyReminder(type, value).subscribe(() => {
            this.notificationService.success('Successfully sent nudge');
          });
        }
      }
    });
  }

  getAverageResponseRate(value: LearnerSurveyReportItem[]) {
    const responseRates = value.map((x) => x.responseRate);
    return Math.round(responseRates.reduce((sum, current) => sum + current, 0) / responseRates.length);
  }

  private filterOptionsForExport(): {
    aeaOptions: LookupBase[];
    leaOptions: LookupBase[];
    buildingOptions: LookupBase[];
    ageOptions: LookupBase[];
  } {
    const aeaOptions: LookupBase[] = [];
    const leaOptions: LookupBase[] = [];
    const buildingOptions: LookupBase[] = [];
    const ageOptions: LookupBase[] = [];
    this.learnerSurveyReport.aeaOptions.forEach((x) => {
      const surveyCount = this.learnerSurveyReport.locationSurveyCount.find((y) => y.key === x.id);
      if (+surveyCount.value >= 5) {
        aeaOptions.push(x);
      }
    });
    this.learnerSurveyReport.leaOptions.forEach((x) => {
      const surveyCount = this.learnerSurveyReport.locationSurveyCount.find((y) => y.key === x.id);
      if (+surveyCount.value >= 5) {
        leaOptions.push(x);
      }
    });
    this.learnerSurveyReport.buildingOptions.forEach((x) => {
      const surveyCount = this.learnerSurveyReport.locationSurveyCount.find((y) => y.key === x.id);
      if (+surveyCount.value >= 5) {
        buildingOptions.push(x);
      }
    });
    this.learnerSurveyReport.ages.forEach((x) => {
      ageOptions.push({ id: x.altId, label: x.label } as LookupBase);
    });

    return { aeaOptions, leaOptions, buildingOptions, ageOptions };
  }

  private configureOverallPieChart(data: number) {
    this.overallPieChart.data = [data, 100 - data];
  }

  private configureStatusPieChart(data: LearnerSurveyStatusCount[]) {
    if (data.length < 1) return;
    this.statusPieChart.data = data.map((x) => x.pct);
  }

  private filterData(value) {
    this.loadData$.next(value);
  }

  private filterAeas() {
    if (!this.aeas) {
      return;
    }
    let search = this.aeaFilterControl.value;
    if (!search) {
      this.filteredAeas.next(this.aeas.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    const aeas = this.aeas.filter((x) => x.label.toLowerCase().indexOf(search) > -1);
    this.filteredAeas.next(aeas);
    const ids = aeas.map((i) => i.id);
    this.filteredLeas.next(this.leas.filter((x) => ids.indexOf(x.parentId) > -1));
  }

  private filterLeas() {
    if (!this.leas) {
      return;
    }
    let search = this.leaFilterControl.value;
    if (!search) {
      this.filteredLeas.next(
        this.leas.filter((x) => !(this.formGroup.value.aeas?.length > 0) || this.formGroup.value.aeas.indexOf(x.parentId) > -1).slice()
      );
    } else {
      search = search.toLowerCase();
      this.filteredLeas.next(
        this.leas.filter((x) => {
          return (
            x.label.toLowerCase().indexOf(search) > -1 &&
            (!(this.formGroup.value.aeas?.length > 0) || this.formGroup.value.aeas.indexOf(x.parentId) > -1)
          );
        })
      );
    }
  }

  private filterBuildings() {
    if (!this.buildings) {
      return;
    }
    let leas = [];
    if (this.formGroup.value.leas && this.formGroup.value.leas.length > 0) {
      leas = this.formGroup.value.leas;
    } else {
      leas = this.leas
        .filter(
          (i) => !this.formGroup.value.aeas || this.formGroup.value.aeas.length === 0 || this.formGroup.value.aeas.indexOf(i.parentId) > -1
        )
        .map((i) => i.id);
    }

    let search = this.buildingFilterControl.value;
    if (!search) {
      this.filteredBuildings.next(this.buildings.filter((x) => !leas || leas.length === 0 || leas.indexOf(x.parentId) > -1));
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredBuildings.next(
      this.buildings.filter(
        (x) => x.label.toLowerCase().indexOf(search) > -1 && (!leas || leas.length === 0 || leas.indexOf(x.parentId) > -1)
      )
    );
  }
  private filterLeasByAeas(aeaList) {
    const leas = this.leas.filter((i) => !aeaList || aeaList.length === 0 || aeaList.indexOf(i.parentId) > -1);
    this.filteredLeas.next(leas);
    return leas;
  }
  private filterBuildingsByDistrict(leaList) {
    const buildings = this.buildings.filter((i) => !leaList || leaList.length === 0 || leaList.indexOf(i.parentId) > -1);
    this.filteredBuildings.next(buildings);
  }
  private setupFormGroupSubscriptions() {
    let prevAeas = [];
    let prevLeas = [];
    this.formGroup.valueChanges.subscribe((value) => {
      const leas = this.filterLeasByAeas(value.aeas || []);
      let searchList = [];
      if (!this.arraysEqual(prevAeas, value.aeas || [])) {
        this.formGroup.get('leas').setValue([], { emitEvent: false });
        this.formGroup.get('buildings').setValue([], { emitEvent: false });
      }
      prevAeas = value.aeas || [];
      if (!this.arraysEqual(prevLeas, value.leas || [])) {
        this.formGroup.get('buildings').setValue([], { emitEvent: false });
      }
      prevLeas = value.leas || [];
      if (this.formGroup.value.leas && this.formGroup.value.leas.length > 0) {
        searchList = this.formGroup.value.leas || [];
      } else {
        searchList = leas.map((i) => i.id);
      }
      this.filterBuildingsByDistrict(searchList);
      this.filterData(this.formGroup.value);
    });

    this.aeaFilterControl.valueChanges.subscribe(() => {
      this.filterAeas();
    });

    this.leaFilterControl.valueChanges.subscribe(() => {
      this.filterLeas();
    });

    this.buildingFilterControl.valueChanges.subscribe(() => {
      this.filterBuildings();
    });
  }
  private arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }
}

export interface LearnerSurveyReport {
  hasData: boolean;
  surveysSent: number;
  surveyResponses: number;
  responseRate: number;
  ageSurveyPercentage: KeyValuePair[];
  genderSurveyPercentage: KeyValuePair[];
  demographicSurveyPercentage: KeyValuePair[];
  locationSurveyCount: KeyValuePair[];
  statusCounts: LearnerSurveyStatusCount[];
  abcReport: AbcReportItem[];
  aeaOptions: LookupBase[];
  leaOptions: LookupBase[];
  buildingOptions: LookupBase[];
  sources: LookupBase[];
  schoolYears: LookupBase[];
  ages: LearnerSurveyReportItem[];
  races: LearnerSurveyReportItem[];
  genders: LearnerSurveyReportItem[];
  overall: LearnerSurveyReportItem;
}

export interface LearnerSurveyStatusCount {
  status: string;
  cnt: number;
  pct: number;
}

export interface LearnerSurveyReportItem {
  id: string;
  altId: string;
  label: string;
  sent: number;
  responses: number;
  responseRate: number;
  percentOfAllResponses: number;
  comparisonToStatePopulation: number;
  difference: number;
}

export interface AbcReportItem {
  rowlabel: string;
  totalSurveys: number;
  yesCnt: number;
  yesPerc: number;
  noCnt: number;
  noPerc: number;
}

export interface SurveyStatusCount {
  status: string;
  count: number;
}

export interface PieChart {
  data: any;
  labels: string[];
  colors: [
    {
      backgroundColor: string[];
    }
  ];
  legend: boolean;
  options: any;
}
