import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component';
import { CaseSummary, IntakeType } from 'src/app/shared/models/case';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { ElpaDto } from './models/elpa.model';
import { IsaspDto } from './models/isasp.model';
import { LearnerService } from '../../shared/services/learner/learner.service';
import { DeactivationService } from '../../shared/services/deactivation.service';

@Component({
  selector: 'app-data-history',
  templateUrl: './data-history.component.html',
  styleUrls: ['./data-history.component.scss'],
})
export class DataHistoryComponent extends BaseComponent implements OnInit {
  learnerId: string;
  stateAssignedId: string;
  intakeTypes = IntakeType;
  cases: CaseSummary[] = [];
  elpas: ElpaDto[];
  isasps: IsaspDto[];

  formGroup = new FormGroup({
    range: new FormControl('all'),
    startDate: new FormControl(''),
    endDate: new FormControl(''),
    domain: new FormControl(''),
  });

  rangeOptions: KeyValuePair[] = [
    new KeyValuePair('lastYear', 'Last Year'),
    new KeyValuePair('lastTwoYears', 'Last Two Years'),
    new KeyValuePair('all', 'All'),
  ];

  domainOptions: KeyValuePair[] = [new KeyValuePair('academic', 'Academic')];

  get hasMultipleActiveCases(): boolean {
    return (
      this.cases?.some((c) => c.intakeType === this.intakeTypes.PartB) && this.cases?.some((c) => c.intakeType === this.intakeTypes.PartC)
    );
  }

  constructor(private learnerService: LearnerService, private route: ActivatedRoute, deactivationService: DeactivationService) {
    super(deactivationService);
  }

  async ngOnInit(): Promise<void> {
    this.learnerId = this.route.snapshot.parent.paramMap.get('learnerId');
    const learnerCases = await this.learnerService.getCases(this.learnerId).toPromise();
    this.populateCases(learnerCases);

    console.log('is two part case', this.hasMultipleActiveCases);
  }

  populateCases(learnerCases) {
    const partBCase = learnerCases?.find((x) => x.intakeType == this.intakeTypes.PartB);
    const partCCase = learnerCases?.find((x) => x.intakeType == this.intakeTypes.PartC);

    if (partBCase) {
      this.cases.push(partBCase);
    }

    if (partCCase) {
      this.cases.push(partCCase);
    }
  }

  rangeChanged() {}

  isPartC() {
    if (!this.cases || this.cases.length === 0) {
      return false;
    }
    return this.cases[0]?.intakeType !== this.intakeTypes.PartB;
  }
}
