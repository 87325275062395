<main class="dashboard">
  <app-page-header [pageTitle]="title" [helpLinks]="false" [backgroundColor]="'blueDark'"></app-page-header>

  <div class="hide-print">
    <!-- <section class="dashboard__stats mt-4 mb-1 mat-horizontal-content-container">
          <app-newly-added-stat
              [icon]="'people'"
              [title]="'Newly Assigned Cases'"
              [amount]="'5'"
          ></app-newly-added-stat>

          <app-newly-added-stat
              [icon]="'share'"
              [title]="'Newly Shared Cases'"
              [amount]="'3'"
          ></app-newly-added-stat>

          <app-newly-added-stat
              [icon]="'format_list_bulleted'"
              [title]="'New To-Do List Items'"
              [amount]="'4'"
          ></app-newly-added-stat>
      </section> -->

    <!--<section class="todo-list mt-2">
      <app-user-todo-list></app-user-todo-list>
    </section>-->

    <section class="case-list mt-3" *ngIf="notSuperAdmin">
      <app-user-case-list (uniqueLearnerDistricts)="uniqueLearnerDistricts = $event"></app-user-case-list>
    </section>

    <section class="calendar mt-3">
      <app-user-calendar [uniqueLearnerDistricts]="uniqueLearnerDistricts"></app-user-calendar>
    </section>
  </div>
</main>

<div class="page-bottom-leeway"></div>
