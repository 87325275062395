<!--suppress HtmlFormInputWithoutLabel -->
<div class="prefill" *ngIf="useOverwrite">
  <div class="prefill__input">
    <ng-container *ngTemplateOutlet="basic"></ng-container>
  </div>
  <div class="prefill__text" *ngIf="dataSourceValue !== null">
    QR:
    <span *ngIf="dataSourceValue !== true && dataSourceValue !== false">{{ sourceFieldShownLabel || dataSourceValue }}</span>
    <span *ngIf="dataSourceValue === true || dataSourceValue === false">{{ dataSourceValue === true ? 'Yes' : 'No' }}</span>
  </div>
  <div class="prefill__container" (click)="replaceWithSourceValue()" *ngIf="dataSourceValue !== null && !undoValue">
    <div class="prefill__icon">
      <mat-icon aria-label="QR Arrow Symbol">call_merge</mat-icon>
    </div>
  </div>
  <div class="prefill__container prefill__container--undo" *ngIf="undoValue">
    <p class="prefill__icon mb-0" (click)="undoReplaceWithSourceValue()">Undo</p>
  </div>
</div>

<ng-container *ngIf="!useOverwrite">
  <ng-container *ngTemplateOutlet="basic"></ng-container>
</ng-container>
<ng-template #basic>
  <div>
    <mat-checkbox
      [formControl]="control"
      [ngClass]="{
        wrap: wrap,
        column: column
      }"
      [attr.disabled]="disabledState ? true : null"
      aria-labelledby="{{ label }}"
      attr.aria-required="{{ (control.hasError('required') || showAsterisk) && hideAsterisk === false ? 'true' : 'false' }}"
    >
      {{ label }}</mat-checkbox
    >
  </div>
  <mat-error *ngIf="control.touched">
    <app-field-error-display [control]="control"></app-field-error-display>
  </mat-error>
</ng-template>
