import { Inject, Injectable } from '@angular/core';
import { JL } from 'jsnlog';
import JSNLogLogger = JL.JSNLogLogger;
import JSNLogAjaxAppender = JL.JSNLogAjaxAppender;
import JSNLogConsoleAppender = JL.JSNLogConsoleAppender;
import { JsnLoggerService } from './jsn-logger.service';
import { Correlation, CorrelationService } from './correlation.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class JsnLoggerFactory {
  private readonly ajaxAppender: JSNLogAjaxAppender;
  private readonly consoleAppender: JSNLogConsoleAppender;

  create(type: any): JsnLoggerService {
    const options = { name: type.constructor.name };
    return new JsnLoggerService(
      this.initializeLogger(options, () => this.correlationService.current),
      options.name,
      this.correlationService
    );
  }

  constructor(@Inject('JSNLOG') private JL: JL.JSNLog, private correlationService: CorrelationService) {
    this.ajaxAppender = JL.createAjaxAppender('ajaxAppenderTss');
    this.consoleAppender = JL.createConsoleAppender('consoleAppenderTss');
  }

  private initializeLogger(options: { name: string }, getCorrelation: () => Correlation): JSNLogLogger {
    const beforeSend = function (xhr) {
      if (getCorrelation) {
        const correlationId = getCorrelation().id;
        xhr.setRequestHeader('X-Correlation-Id', correlationId);
      }
    };
    this.ajaxAppender.setOptions({
      level: JL.getAllLevel(),
      sendWithBufferLevel: JL.getErrorLevel(),
      storeInBufferLevel: -2147483648,
      bufferSize: 0, // buffering switch off by default
      batchSize: 2,
      maxBatchSize: 20,
      batchTimeout: 2147483647,
      sendTimeout: 5000,
      beforeSend: beforeSend,
    });

    const appenders = [];
    if (environment.jsnLogger.logConsole) {
      appenders.push(this.consoleAppender);
    }
    if (environment.jsnLogger.logServer) {
      appenders.push(this.ajaxAppender);
    }
    return JL(options.name).setOptions({
      level: JL.getAllLevel(),
      appenders: [...appenders],
    });
  }
}
