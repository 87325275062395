import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { forkJoin, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ProviderInfoFormService } from 'src/app/evaluation/shared/components/health-info-form/provider-info-form/provider-info-form.service';
import { IepService } from 'src/app/iep/services/iep.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ParticipantSearchModalComponent } from 'src/app/shared/modals/participant-search/participant-search-modal.component';
import { ParticipantType } from 'src/app/shared/modals/participant-search/participant-type';
import { RequestExcusalModalComponent } from 'src/app/shared/modals/request-excusal-modal/request-excusal-modal.component';
import { RescheduleMeetingModalComponent } from 'src/app/shared/modals/reschedule-meeting-modal/reschedule-meeting-modal.component';
import { AddFamilyMeetingParticipant, CaseUserRole, FamilyMeetingRead, IntakeType } from 'src/app/shared/models/case';
import { MeetingStatus } from 'src/app/shared/models/family-meeting-status';
import { ConsentFormType } from 'src/app/shared/models/fiie-consent/consent-form';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { ConsentFormService } from 'src/app/shared/services/consent-form/consent-form.service';

import { finalize } from 'rxjs/operators';
import { JsnLoggerFactory } from 'src/app/shared/services/logger/jsn-logger.factory';
import { JsnLoggerService } from 'src/app/shared/services/logger/jsn-logger.service';
import { ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { TeamService } from 'src/app/shared/services/team/team.service';
import { openPdfWindow } from 'src/app/shared/windowHelpers';
import { AppPermissions } from '../../../permissions';
import { MeetingRollCallComponent } from '../../../shared/components/meeting-roll-call/meeting-roll-call.component';
import { FamilyMember } from '../../../shared/models/learner';
import { getAgency, TeamUser } from '../../../shared/models/team';
import { CaseService } from '../../../shared/services/case/case.service';
import { ConfirmationDialogComponent, NotificationService } from '../../../shared/services/notification.service';
import { FamilyMeetingService } from '../family-meeting.service';
import { MeetingParticipantRead } from '../participants-list/meeting-participants';

@Component({
  selector: 'app-family-meeting-list',
  templateUrl: './family-meeting-list.component.html',
  styleUrls: ['./family-meeting-list.component.scss'],
})
export class FamilyMeetingListComponent implements OnInit, AfterViewChecked, OnChanges, OnDestroy {
  @Input() familyMeeting: FamilyMeetingRead;
  @Input() caseFamilyMembers: FamilyMember[];
  @Input() intakeType: IntakeType;
  @Input() caseId: string;
  @Input() learnerId: string;
  @Input() showMeetingInfo: boolean;
  @Input() inLearnerDashboard: boolean;
  @Input() learnerHasWorkableCase = true;
  @Input() hasAddingPermission = false;
  @Input() hasAddingPermissionPartC = false;
  @Input() hasPermissionToReschedule = false;
  @Input() hasPermissionToReschedulePartC = false;
  @Input() readOnly = false;

  @Output() refreshData = new EventEmitter();
  @Output() updateRescheduledDate = new EventEmitter<FamilyMeetingRead>();

  subscription = new Subscription();
  dayjs = dayjs;
  intakeTypeEnum = IntakeType;
  familyProviders: MeetingParticipantRead[] = [];
  familyMembers: MeetingParticipantRead[] = [];
  users: MeetingParticipantRead[] = [];
  availableProviders: MeetingParticipantRead[] = [];
  availableFamilyMembers: MeetingParticipantRead[] = [];
  availableUsers: MeetingParticipantRead[] = [];
  meetingStatus = MeetingStatus;
  displayedColumns: string[] = ['name', 'agency', 'role'];
  shortDateFormat = shortDateFormat;
  consentedElectronicCommunication = false;
  participantsDataSource: MatTableDataSource<AbstractControl>;
  // viewScheduleRequirements = new Map<string, string[]>([
  //   ['TeamMember', ['ACHIEVEDataLead', 'ACHIEVEDataTechnician', 'AeaEdit', 'LeaProviderEdit', 'ServiceCoordinator']],
  // ]);
  methodOfInputOptions: KeyValuePair[] = [
    new KeyValuePair('Print', 'Print Agreement to Excuse'),
    // TODO this needs to be implemented when the portal comes in
    // Only allowed to use portal or both if they have electronic comm. consent, check consentsElectronicCommunication()
    // new KeyValuePair('Portal', 'Send via portal'),
    // new KeyValuePair('Both', 'Both'),
  ];
  permissions = AppPermissions;
  formGroupArray: FormArray = new FormArray([]);
  private logger: JsnLoggerService;

  constructor(
    private readonly teamService: TeamService,
    readonly authService: AuthService,
    private readonly dialog: MatDialog,
    private readonly familyMeetingService: FamilyMeetingService,
    private readonly providerService: ProviderInfoFormService,
    private readonly consentFormService: ConsentFormService,
    private readonly fb: FormBuilder,
    private readonly iepService: IepService,
    private notificationService: NotificationService,
    private cdRef: ChangeDetectorRef,
    private caseService: CaseService,
    private reportingService: ReportingService,
    private loggerFactory: JsnLoggerFactory
  ) {
    this.logger = this.loggerFactory.create(this);
  }

  getAvailableCount() {
    this.filterOutCurrentParticipants(this.familyMeeting?.participants);
    return this.availableProviders?.length + this.availableFamilyMembers?.length + this.availableUsers?.length;
  }

  ngOnInit(): void {
    // this.getTeamMembersAsParticipants();
    this.initializeData();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openMeetingRollCall() {
    if (dayjs(this.familyMeeting.dateAndTime).isBefore(dayjs().endOf('day'))) {
      this.openRollCallModal(this.familyMeeting, this.caseId);
    }
  }

  openRollCallModal(meeting: FamilyMeetingRead, caseId: string) {
    this.caseService.getCaseSummary(caseId).subscribe((caseSummary) => {
      const dialogRef = this.dialog.open(MeetingRollCallComponent, {
        width: '1100px',
        data: {
          meeting,
          caseSummary,
        },
      });
      dialogRef.afterClosed().subscribe((res) => {
        this.getMeetingAndRefreshTable();
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes.familyMeeting?.previousValue !== changes.familyMeeting?.currentValue) {
    //   this.familyMeeting = changes.familyMeeting.currentValue;
    // }
    // if (changes.intakeType) {
    //   this.intakeType = changes.intakeType ? changes.intakeType.currentValue : this.intakeType;
    //   this.getTeamMembersAsParticipants();
    // }
    if (
      changes.familyMeeting &&
      !changes.familyMeeting.isFirstChange() &&
      changes.familyMeeting.previousValue?.id !== changes.familyMeeting.currentValue?.id
    ) {
      this.initializeData();
    }
    // if (changes.caseFamilyMembers) {
    //   this.caseFamilyMembers = changes.caseFamilyMembers.currentValue;
    //   this.getFamilyMembersAsParticipants();
    // }
    // if (changes.caseId) {
    //   this.caseId = changes.caseId ? changes.caseId.currentValue : this.caseId;
    // }
    // if (changes.learnerId) {
    //   this.learnerId = changes.learnerId ? changes.learnerId.currentValue : this.learnerId;
    //   this.getFamilyProvidersAsParticipants();
    //   this.getTeamMembersAsParticipants();
    // }
  }

  openPdf() {
    this.logger.beginScope();
    this.logger.error({ m: 'we are here 1' });
    this.logger.error({ m: 'we are here 2' });
    this.reportingService
      .createMeetingNoticeOutput(this.familyMeeting.id)
      .pipe(finalize(() => this.logger.endScope()))
      .subscribe({
        next: (documentId: string) => {
          this.logger.error({ m: 'we are here 4' });
          openPdfWindow(this.learnerId, documentId);
          this.logger.error({ m: 'we are here 5' });
        },
        error: (err) =>
          this.notificationService.errorWithAction("Couldn't open output", 'Why?', () =>
            this.notificationService.alert(err.error, "Couldn't open output")
          ),
      });
    this.logger.error({ m: 'we are here 3' });
  }

  onAddParticipants() {
    this.filterOutCurrentParticipants(this.familyMeeting?.participants);
    const dialogRef = this.dialog.open(ParticipantSearchModalComponent, {
      width: '728px',
      data: {
        currentParticipants: this.familyMeeting?.participants,
        learnerId: this.learnerId,
        users: this.availableUsers,
        providers: this.availableProviders,
        familyMembers: this.availableFamilyMembers,
      },
    });

    dialogRef.afterClosed().subscribe((newParticipants: MeetingParticipantRead[]) => {
      if (newParticipants) {
        const addedParticipantIds = this.createAddFamilyParticipantModel(this.familyMeeting, newParticipants);
        this.familyMeetingService.addParticipants(this.caseId, addedParticipantIds).subscribe((updatedMeeting) => {
          this.familyMeeting = updatedMeeting;
          this.setUpFormGroups();
          this.setUpTableData();
        });
      }
    });
  }

  reschedule() {
    const dialogRef = this.dialog.open(RescheduleMeetingModalComponent, {
      width: '728px',
      data: { caseId: this.caseId, familyMeeting: this.familyMeeting },
    });
    dialogRef.afterClosed().subscribe((rescheduledMeeting) => {
      if (this.inLearnerDashboard) {
        this.updateRescheduledDate.emit(rescheduledMeeting);
      } else {
        this.refreshData.emit(false);
      }
      this.getMeetingAndRefreshTable();
    });
  }

  deleteMeeting() {
    const confirmDelete = ({ title, message }) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title,
          message,
        },
        width: '728px',
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.familyMeetingService.deleteMeeting(this.caseId, this.familyMeeting.id).subscribe(() => {
            if (this.inLearnerDashboard) {
              this.getMeetingAndRefreshTable();
            } else {
              this.refreshData.emit(true);
            }
          });
        }
      });
    };
    if (this.familyMeeting.isIep) {
      confirmDelete({
        title: 'Warning: Service Dates Will Reset',
        message:
          'Are you sure you want to cancel this meeting? Rescheduling this meeting will clear all the existing dates for any planned services of this IEP. If you had any custom dates, they will be set to null and you will need to reset those dates.',
      });
    } else {
      confirmDelete({
        title: 'Cancel Meeting',
        message: 'Are you sure you want to cancel this meeting?',
      });
    }
  }

  canDelete() {
    return (
      !this.readOnly &&
      !dayjs(this.familyMeeting?.dateAndTime).isBefore(dayjs()) &&
      !this.rollCallComplete(this.familyMeeting) &&
      this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.CancelMeeting)
    );
  }

  canAdd() {
    return (
      !this.readOnly &&
      (this.hasAddingPermission = this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.AddParticipants))
    );
  }

  canAddPartC() {
    return (
      !this.readOnly &&
      (this.hasAddingPermissionPartC = this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.AddParticipantsPartC))
    );
  }

  canReschedule() {
    return (
      !this.readOnly &&
      (this.hasPermissionToReschedule = this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.RescheduleMeeting))
    );
  }

  canReschedulePartC() {
    return (
      !this.readOnly &&
      (this.hasPermissionToReschedulePartC = this.authService.isAllowedByCaseId(
        this.caseId,
        undefined,
        AppPermissions.RescheduleMeetingPartC
      ))
    );
  }

  rollCallComplete(familyMeeting) {
    return familyMeeting?.participants.every((x) => x.meetingAttendanceId) || familyMeeting?.meetingRollCallDate != null;
  }

  canRollCall(familyMeeting) {
    if (this.readOnly === true) return false;

    const rollCallDone = familyMeeting?.participants.every((x) => x.meetingAttendanceId) || familyMeeting?.meetingRollCallDate != null;
    const canRollCall = this.authService.isAllowedWithAdditionalRequirements(
      new Map<string, string[]>([
        [
          'TeamMember',
          [
            'ServiceCoordinator',
            'StateWideEdit',
            'AeaEdit',
            'ACHIEVEDataLead',
            'ACHIEVEDataTechnician',
            'LeaProviderEdit',
            'StateWideEdit',
          ],
        ],
      ]),
      this.caseId,
      AppPermissions.MeetingRollCall
    );
    return dayjs(familyMeeting?.dateAndTime).isBefore(dayjs().endOf('day')) && !rollCallDone && canRollCall;
  }

  requestExcusal() {
    this.dialog.open(RequestExcusalModalComponent, {
      data: {
        caseId: this.caseId,
        familyMeeting: this.familyMeeting,
      },
    });
  }

  getMeetingAndRefreshTable() {
    this.familyMeetingService.getMeeting(this.caseId, this.familyMeeting.id).subscribe((updatedMeeting) => {
      if (this.refreshData) {
        this.refreshData.emit();
      }
      this.familyMeeting = updatedMeeting;
      this.setUpFormGroups();
      this.setUpTableData();
    });
  }

  getFormattedRole(participant) {
    if (participant?.role === CaseUserRole.ServiceCoordinator) {
      return 'Service Coordinator';
    } else if (participant?.role === 'Family') {
      return !!participant?.learnerId ? 'Learner' : !!participant?.familyMemberId ? 'Parent' : participant?.role;
    } else {
      return participant?.role;
    }
  }

  getFormattedAgency(agency, agencyOther) {
    if (agency === 'Other') {
      return agency + ' (' + agencyOther + ')';
    } else {
      return agency;
    }
  }

  canRequestExcusal() {
    return !this.readOnly && this.authService.isReadOnlyByCaseId(this.caseId, AppPermissions.RequestMeetingExcusal);
    //TODO: Temporary workaround until Excusal from Roll Call Modal is fixed
    //&& !dayjs(this.familyMeeting?.dateAndTime).isBefore(dayjs())
  }

  private initializeData() {
    this.consentsElectronicCommunication();
    this.setUpFormGroups();
    this.setUpTableData();
    this.canAdd();
    this.canAddPartC();
    this.canReschedule();
    this.canReschedulePartC();
  }

  private consentsElectronicCommunication() {
    this.consentFormService.getConsentForms(this.caseId).subscribe((consentForms) => {
      this.consentedElectronicCommunication = consentForms.some((x) => x.type === ConsentFormType.ReceiveElectronicCommunication);
    });
  }

  private addRow(participant: MeetingParticipantRead) {
    const participantRowGroup = this.fb.group({});
    participantRowGroup.addControl('participant', new FormControl({ value: participant, disabled: true }));
    participantRowGroup.addControl('method', new FormControl(null));
    this.formGroupArray.push(participantRowGroup);
  }

  private setUpFormGroups() {
    this.formGroupArray = new FormArray([]);
    this.familyMeeting?.participants.forEach((participant) => {
      this.addRow(participant);
    });
  }

  private setUpTableData() {
    this.participantsDataSource = new MatTableDataSource<AbstractControl>(this.formGroupArray.controls);
  }

  private createAddFamilyParticipantModel(meeting: FamilyMeetingRead, newParticipants: MeetingParticipantRead[]) {
    const addedParticipantIds: AddFamilyMeetingParticipant = {
      meetingId: meeting.id,
      userParticipantIds: newParticipants.filter((x) => x.userId).map((y) => y.userId),
      familyMemberParticipantIds: newParticipants.filter((x) => x.familyMemberId).map((y) => y.familyMemberId),
      otherAgencyProgramIds: newParticipants.filter((x) => x.agencyId).map((y) => y.agencyId),
      medicalSpecialistIds: newParticipants.filter((x) => x.medicalSpecialistId).map((y) => y.medicalSpecialistId),
      phcpLearnerId: newParticipants.find((x) => x.learnerId)?.learnerId,
    };
    return addedParticipantIds;
  }

  private getTeamMembersAsParticipants() {
    this.subscription.add(
      this.teamService.getTeamUsers(this.authService.user.id, this.intakeType, this.learnerId, true).subscribe((teamUsers: TeamUser[]) => {
        this.users = teamUsers.map((x) => {
          return {
            userId: x.userId,
            agencyId: null,
            fullName: x.fullName,
            agency: getAgency(x),
            role: 'AEA',
            roleOther: null,
            email: x.email,
            familyMemberId: null,
            learnerId: null,
            medicalSpecialistId: null,
            type: ParticipantType.User,
            isByResident: x.isByResident,
          } as MeetingParticipantRead;
        });
      })
    );
  }

  private getFamilyMembersAsParticipants() {
    this.familyMembers = this.caseFamilyMembers.map((member) => ({
      id: null,
      familyMemberId: member.id,
      agency: 'Family',
      agencyOther: null,
      role: 'Family',
      roleOther: null,
      fullName: member.fullName,
      email: member.email,
      agencyId: null,
      medicalSpecialistId: null,
      learnerId: null,
      userId: null,
      type: ParticipantType.FamilyMember,
      meetingAttendanceId: null,
      meetingAttendanceOther: null,
      isByResident: false,
    }));
  }

  private getFamilyProvidersAsParticipants() {
    forkJoin([
      this.providerService.getIntakeAgencyOrOtherPrograms(this.learnerId),
      this.providerService.getIntakeMedicalSpecialist(this.learnerId),
      this.providerService.getLearnerPhcp(this.learnerId),
    ]).subscribe(([programs, specialists, phcp]) => {
      this.familyProviders = [];
      this.familyProviders = this.familyProviders.concat(
        programs.map((program) => ({
          id: null,
          agencyId: program.id,
          fullName: program.contact,
          agency: program.name,
          agencyOther: null,
          role: null,
          roleOther: null,
          email: null,
          userId: null,
          familyMemberId: null,
          learnerId: null,
          medicalSpecialistId: null,
          type: ParticipantType.FamilyProvider,
          meetingAttendanceId: null,
          meetingAttendanceOther: null,
          isByResident: false,
        }))
      );
      this.familyProviders = this.familyProviders.concat(
        specialists.map((specialist) => ({
          id: null,
          medicalSpecialistId: specialist.id,
          fullName: specialist.name,
          agency: null,
          agencyOther: null,
          role: specialist.areaOfSpecialty,
          roleOther: null,
          email: null,
          userId: null,
          familyMemberId: null,
          agencyId: null,
          learnerId: null,
          type: ParticipantType.FamilyProvider,
          meetingAttendanceId: null,
          meetingAttendanceOther: null,
          isByResident: false,
        }))
      );
      if (phcp.phcpClinicAgencyName || phcp.phcpName) {
        this.familyProviders.push({
          id: null,
          learnerId: this.learnerId,
          fullName: phcp.phcpTitle && phcp.phcpName ? `${phcp.phcpTitle} ${phcp.phcpName}` : phcp.phcpName ? `${phcp.phcpName}` : null,
          agency: phcp.phcpClinicAgencyName,
          agencyOther: null,
          email: phcp.phcpEmail,
          role: null,
          roleOther: null,
          userId: null,
          familyMemberId: null,
          agencyId: null,
          medicalSpecialistId: null,
          type: ParticipantType.FamilyProvider,
          meetingAttendanceId: null,
          meetingAttendanceOther: null,
          isByResident: false,
        });
      }
    });
  }

  private filterOutCurrentParticipants(currentParticipants: MeetingParticipantRead[]) {
    if (currentParticipants !== null) {
      this.availableProviders = this.familyProviders.filter(
        (x) =>
          !currentParticipants.some(
            (p) => p.agencyId === x.agencyId && p.medicalSpecialistId === x.medicalSpecialistId && p.learnerId === x.learnerId
          )
      );

      this.availableFamilyMembers = this.familyMembers.filter(
        (x) => !currentParticipants?.some((p) => p.familyMemberId === x.familyMemberId)
      );

      const userIds = currentParticipants?.map((u) => u.userId);
      this.availableUsers = this.users.filter((x) => {
        return !userIds?.includes(x.userId);
      });
    }
  }
}
