<ng-container [formGroup]="formGroup">
  <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    {{ cardHeader }}
  </h3>
  <div mat-dialog-content class="py-3">
    <mat-card>
      <h4 *ngIf="fileTitle">{{ fileTitle }}</h4>
      <p>This document is being uploaded for {{ learnersName }}. Please verify this is the correct learner prior to continuing.</p>
      <button mat-raised-button color="accent" (click)="onOpenDocumentationInput()" aria-label="Choose File" tabindex="0">
        Choose File
      </button>

      <input
        id="documentationInput"
        hidden
        aria-labelledby="Choose File"
        type="file"
        [accept]="accepts"
        [multiple]="multiple"
        (change)="documentationFileChange($event)"
      />
      <p class="mb-0 mt-3">
        <i class="text-sm">Max file size allowed: 200MB</i>
      </p>
      <p class="mt-1">
        <i class="text-sm text-link" [matTooltip]="allowedFileTypes">Supported file types</i>
      </p>
    </mat-card>

    <mat-card formArrayName="files" *ngFor="let file of fileFormGroups; let i = index">
      <div class="uploaded-document">
        <div class="uploaded-document__item">
          <p class="uploaded-document__name mt-0 mb-2">
            {{ file.get('file').value.name }}
          </p>
        </div>
        <div class="uploaded-document__remove">
          <p class="my-0 text-error color-maroon cursor-pointer" (click)="onRemoveFile(i)">&times;</p>
        </div>
      </div>
      <div [formGroup]="file" *ngIf="!fileTitle" class="w-100 mt-1">
        <app-text-field label="Document Title" formControlName="title"> </app-text-field>
        <app-text-field *ngIf="data.showComments" label="Comments" formControlName="comment"> </app-text-field>
      </div>
      <div [formGroup]="file">
        <app-autocomplete
          *ngIf="documentationTypeOptions"
          [options]="documentationTypeOptions"
          formControlName="type"
          label="Documentation Type"
        ></app-autocomplete>
      </div>
    </mat-card>
  </div>

  <div mat-dialog-actions class="w-100 display-flex justify-content-end align-items-center">
    <button mat-button aria-label="Cancel Button" class="background-color--muted" (click)="onCancel()" tabindex="0">Cancel</button>
    <button
      mat-raised-button
      aria-label="Done"
      color="primary"
      [disabled]="formGroup.invalid || formGroup.get('files').value.length === 0"
      (click)="onSubmit()"
      tabindex="0"
    >
      Done
    </button>
  </div>
</ng-container>
