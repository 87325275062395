<form [formGroup]="formGroup">
  <mat-card *ngIf="!isQuestionnaire">
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-md-4">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [options]="yesNoOptions"
              formControlName="healthConcerns"
              label="Do you have concerns about your child’s health?"
            ></app-radio-group>
          </fieldset>
        </div>
        <div class="col-md-8" *ngIf="formGroup.get('healthConcerns').value">
          <app-textarea [disabledState]="locked" label="Describe health concerns" formControlName="healthConcernDescription"></app-textarea>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="card-spacing-top">
    <mat-card-title class="mb-3">Birth Information</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row mb-0">
        <!-- TODO: Why we don't show some fields if we are executing this component in questionnaire?
         It seems we may not need to overwrite the fields under the div below
         however, by reading the questions it looks like they may qualify in the
         initial questionnaire; I decided to add the overwrite added them for the demo
        (should be faster remove them later than add them and have them working)
        To remove, search this file for "!isQuestionnaire &&" and remove <app-overwrite-xxxx>
    -->
        <div class="col-md-6">
          <app-autocomplete
            [disabledState]="locked"
            [useOverwrite]="!isQuestionnaire"
            [options]="childBirthLocationOptions"
            formControlName="childBirthLocation"
            label="Where was your child born? - If unknown, leave blank"
            [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
          ></app-autocomplete>
        </div>

        <div
          class="col-md-4 col-xl-4"
          *ngIf="
            (isQuestionnaire && formGroup.controls.childBirthLocation.value === 'Other') ||
            (!isQuestionnaire && formGroup.controls.childBirthLocation.value === 'Other')
          "
        >
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire && formGroup.controls.childBirthLocation.value === 'Other'"
            label="Description of Other"
            formControlName="birthLocationDescription"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire && formGroup.controls.childBirthLocation.value === 'Other'"
            label="Description of Other"
            formControlName="birthLocationDescription"
            [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
          ></app-overwrite-text-field>
        </div>

        <ng-container *ngIf="formGroup.controls.childBirthLocation.value === 'HospitalClinic'">
          <div class="col-xl-4 col-md-6">
            <div *ngIf="isQuestionnaire">
              <app-text-field
                [disabledState]="locked"
                *ngIf="formGroup.controls.childBirthLocation.value === 'HospitalClinic'"
                label="Name of Hospital/Clinic"
                hint="Enter “unknown” if this is not available"
                formControlName="hospitalClinicName"
              ></app-text-field>
            </div>
            <app-overwrite-text-field
              [disabledState]="locked"
              *ngIf="!isQuestionnaire && formGroup.controls.childBirthLocation.value === 'HospitalClinic'"
              label="Name of Hospital/Clinic"
              [isBankableField]="true"
              [bankableFieldId]="bankableFieldIds.medicalHistoryInfo.hospitalClinicName"
              [bankableFieldChildFname]="childFirstName"
              hint="Enter “unknown” if this is not available"
              formControlName="hospitalClinicName"
              [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
            ></app-overwrite-text-field>
          </div>
          <div class="col-xl-4 col-md-6">
            <app-text-field
              [disabledState]="locked"
              *ngIf="isQuestionnaire && formGroup.controls.childBirthLocation.value === 'HospitalClinic'"
              label="City of Hospital/Clinic"
              hint="Enter “unknown” if this is not available"
              formControlName="hospitalClinicCity"
            ></app-text-field>
            <app-overwrite-text-field
              [disabledState]="locked"
              *ngIf="!isQuestionnaire && formGroup.controls.childBirthLocation.value === 'HospitalClinic'"
              label="City of Hospital/Clinic"
              hint="Enter “unknown” if this is not available"
              formControlName="hospitalClinicCity"
              [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
            ></app-overwrite-text-field>
          </div>
          <div class="col-xl-4 col-md-6">
            <app-text-field
              [disabledState]="locked"
              *ngIf="isQuestionnaire && formGroup.controls.childBirthLocation.value === 'HospitalClinic'"
              label="State of Hospital/Clinic"
              hint="Enter “unknown” if this is not available"
              formControlName="hospitalClinicState"
            ></app-text-field>
            <app-overwrite-text-field
              [disabledState]="locked"
              *ngIf="!isQuestionnaire && formGroup.controls.childBirthLocation.value === 'HospitalClinic'"
              label="State of Hospital/Clinic"
              hint="Enter “unknown” if this is not available"
              formControlName="hospitalClinicState"
              [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
            ></app-overwrite-text-field>
          </div>
        </ng-container>
      </div>

      <div class="row">
        <div class="col-md-4 mt-2">
          <mat-label *ngIf="(isQuestionnaire && shownFields.gestationalAgeWeeks) || !isQuestionnaire"
            >What was your child's gestational age at birth?</mat-label
          >
          <div class="row">
            <div class="col-md-6">
              <app-number-field
                [disabledState]="locked"
                *ngIf="isQuestionnaire && shownFields.gestationalAgeWeeks"
                label="Weeks"
                formControlName="gestationalAgeWeeks"
                [min]="0"
                id="gestationalAgeWeeks"
              ></app-number-field>
              <app-overwrite-number-field
                [disabledState]="locked"
                *ngIf="!isQuestionnaire"
                label="Weeks"
                formControlName="gestationalAgeWeeks"
                [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
                [min]="0"
                id="gestationalAgeWeeks"
              ></app-overwrite-number-field>
            </div>
            <div class="col-md-6">
              <app-number-field
                [disabledState]="locked"
                [min]="0"
                [max]="6"
                *ngIf="isQuestionnaire && shownFields.gestationalAgeDays"
                label="Day(s)"
                formControlName="gestationalAgeDays"
                id="gestationalAgeDays"
              ></app-number-field>
              <app-overwrite-number-field
                [disabledState]="locked"
                *ngIf="!isQuestionnaire"
                label="Day(s)"
                [min]="0"
                [max]="6"
                formControlName="gestationalAgeDays"
                [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
                id="gestationalAgeDays"
              ></app-overwrite-number-field>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="autoEligibleByAge && !isQuestionnaire">
        <div class="col-md-12">
          <app-page-alert [alert]="autoEligibleAlert" role="status" aria-live="polite"></app-page-alert>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="card-spacing-top">
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-md-12">
          <mat-label *ngIf="(isQuestionnaire && shownFields.birthWeightUnits) || !isQuestionnaire"
            >What was your child's birth weight?</mat-label
          >
          <div class="row">
            <div class="col-md-4">
              <app-select
                [disabledState]="locked"
                *ngIf="(isQuestionnaire && shownFields.birthWeightUnits) || !isQuestionnaire"
                [options]="birthWeightUnitOptions"
                formControlName="birthWeightUnits"
                (selected)="weightTypeChanged()"
                label="Units"
                [useOverwrite]="!isQuestionnaire"
                [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
              ></app-select>
            </div>
            <ng-container [ngSwitch]="formGroup.get('birthWeightUnits').value">
              <ng-template [ngSwitchCase]="'lbOz'">
                <div class="col-md-2">
                  <app-number-field
                    [disabledState]="locked"
                    *ngIf="isQuestionnaire && shownFields.birthWeightInLb"
                    label="lb"
                    formControlName="birthWeightInLb"
                    [min]="0"
                    id="birthWeightInLb"
                  ></app-number-field>
                  <app-overwrite-number-field
                    [disabledState]="locked"
                    *ngIf="!isQuestionnaire"
                    label="lb"
                    formControlName="birthWeightInLb"
                    [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
                    [min]="0"
                    id="birthWeightInLb"
                  ></app-overwrite-number-field>
                </div>
                <div class="col-md-2">
                  <app-number-field
                    [disabledState]="locked"
                    *ngIf="isQuestionnaire && shownFields.birthWeightInOz"
                    label="oz"
                    formControlName="birthWeightInOz"
                    [min]="0"
                    id="birthWeightInOz"
                  ></app-number-field>
                  <app-overwrite-number-field
                    [disabledState]="locked"
                    *ngIf="!isQuestionnaire"
                    label="oz"
                    formControlName="birthWeightInOz"
                    [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
                    [min]="0"
                    id="birthWeightInOz"
                  ></app-overwrite-number-field>
                </div>
              </ng-template>

              <ng-template [ngSwitchCase]="'g'">
                <div class="col-md-2">
                  <app-number-field
                    [disabledState]="locked"
                    *ngIf="isQuestionnaire && shownFields.birthWeightInGrams"
                    label="g"
                    formControlName="birthWeightInGrams"
                    [min]="0"
                    id="birthWeightInGrams"
                  ></app-number-field>
                  <app-overwrite-number-field
                    [disabledState]="locked"
                    *ngIf="!isQuestionnaire"
                    label="g"
                    formControlName="birthWeightInGrams"
                    [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
                    [min]="0"
                    id="birthWeightInGrams"
                  ></app-overwrite-number-field>
                </div>
              </ng-template>

              <ng-template [ngSwitchCase]="'kg'">
                <div class="col-md-2">
                  <app-number-field
                    [disabledState]="locked"
                    *ngIf="isQuestionnaire && shownFields.birthWeightInKilograms"
                    label="kg"
                    formControlName="birthWeightInKilograms"
                    [min]="0"
                    id="birthWeightInKilograms"
                  ></app-number-field>
                  <app-overwrite-number-field
                    [disabledState]="locked"
                    *ngIf="!isQuestionnaire"
                    label="kg"
                    formControlName="birthWeightInKilograms"
                    [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
                    [min]="0"
                    id="birthWeightInKilograms"
                  ></app-overwrite-number-field>
                </div>
              </ng-template>

              <ng-template *ngSwitchDefault> </ng-template>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="autoEligibleByWeight && !isQuestionnaire">
        <div class="col-md-12">
          <app-page-alert [alert]="autoEligibleAlert" role="status"></app-page-alert>
        </div>
      </div>

      <hr class="input__divider" />

      <div class="row">
        <div class="col-md-12">
          <mat-label>What was your child's birth length?</mat-label>

          <div class="row">
            <div class="col-md-4">
              <app-select
                [disabledState]="locked"
                [useOverwrite]="!isQuestionnaire"
                [options]="birthLengthOptions"
                formControlName="birthLengthUnits"
                (selected)="lengthUnitsChanged()"
                label="Units"
                [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
              ></app-select>
            </div>
            <div class="col-md-4" *ngIf="formGroup.get('birthLengthUnits')?.value">
              <app-number-field
                [disabledState]="locked"
                *ngIf="isQuestionnaire"
                [label]="formGroup.get('birthLengthUnits').value"
                formControlName="birthLength"
                [min]="0"
                id="birthLength"
              ></app-number-field>
              <app-overwrite-number-field
                [disabledState]="locked"
                *ngIf="!isQuestionnaire"
                [label]="formGroup.get('birthLengthUnits').value"
                formControlName="birthLength"
                [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
                [min]="0"
                id="birthLength"
              ></app-overwrite-number-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="card-spacing-top">
    <div class="container container--not-centered container--form">
      <div class="row mb-4">
        <div class="col-md-12 col-xl-6">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              [options]="yesNoUnknownOptions"
              formControlName="prenatalCare"
              label="Did mother receive prenatal care during pregnancy or delivery?"
              [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
            ></app-radio-group>
          </fieldset>
        </div>
        <div class="col-md-12 col-xl-6">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              [options]="yesNoUnknownOptions"
              formControlName="birthComplications"
              label="Were there any complications during pregnancy or delivery?"
              [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
            ></app-radio-group>
          </fieldset>
          <app-textarea
            *ngIf="!isQuestionnaire && formGroup.get('birthComplications').value === unknownYesNo.Yes"
            [disabledState]="locked"
            label="Describe complications during pregnancy or delivery"
            formControlName="birthComplicationsDescription"
          ></app-textarea>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-md-12 col-xl-6">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              [options]="yesNoUnknownOptions"
              formControlName="ivOrOxygen"
              label="Following birth, did your child receive an IV or oxygen prior to discharge?"
              [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
            ></app-radio-group>
          </fieldset>
          <div *ngIf="formGroup.get('ivOrOxygen').value === unknownYesNo.Yes">
            <app-textarea
              [disabledState]="locked"
              *ngIf="isQuestionnaire"
              label="How long did your child receive an IV or oxygen prior to discharge?"
              formControlName="ivOrOxygenLength"
            ></app-textarea>
            <app-overwrite-textarea
              [disabledState]="locked"
              *ngIf="!isQuestionnaire"
              label="How long did your child receive an IV or oxygen prior to discharge?"
              formControlName="ivOrOxygenLength"
              [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
            ></app-overwrite-textarea>
          </div>
        </div>
        <div class="col-md-12 col-xl-6">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              [options]="yesNoNotSureOptions"
              formControlName="geneticTesting"
              label="Has your child had genetic testing?"
              [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
            ></app-radio-group>
          </fieldset>
          <div *ngIf="!isQuestionnaire && formGroup.get('geneticTesting').value === notSureYesNo.Yes">
            <app-textarea
              [ngClass]="{
                'field--highlight': questionnaire.healthInfo.medicalHistoryInformation.geneticTesting
              }"
              [disabledState]="locked"
              label="Do you have further information to provide?"
              formControlName="geneticTestingDescription"
            ></app-textarea>
          </div>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="card-spacing-top">
    <mat-card-title>Medical Diagnosis(es)</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row mb-0">
        <div class="col-md-6 mb-2">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              [options]="yesNoOptions"
              formControlName="medicalDiagnosis"
              label="Does your child have a medical diagnosis?"
              [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
            ></app-radio-group>
          </fieldset>
          <div class="mt-2">
            <a
              *ngIf="!isQuestionnaire"
              target="_blank"
              href="https://docs.google.com/spreadsheets/d/1MhpVfdQc2AlosjprZ2szODV-clp7wI__Q1__VsyYryU/edit#gid=319326613"
              >Diagnosed Conditions Eligibility List</a
            >
          </div>
        </div>
      </div>

      <div class="mt-3" *ngIf="formGroup.get('medicalDiagnosis').value">
        <app-medical-diagnosis-table
          [dataSource]="dataSource"
          [intake]="intake"
          [questionnaire]="questionnaire"
          [isQuestionnaire]="isQuestionnaire"
          (refreshDataSource)="loadData()"
          (medicalConditionUpdated)="medicalConditionUpdatedEmit($event)"
        >
        </app-medical-diagnosis-table>
        <div class="display-flex justify-content-end align-items-center mt-2">
          <button
            [disabled]="locked"
            *ngIf="questConditionsNotImported?.length > 0"
            type="button"
            mat-raised-button
            color="accent"
            (click)="appendQuestionnaireMedicalConditions()"
            tabindex="0"
            aria-label="Import from Questionnaire"
          >
            Import from Questionnaire
          </button>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="card-spacing-top">
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg-6">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              [options]="yesNoOptions"
              formControlName="hospitalized"
              label="Has your child been hospitalized? - If unknown, leave blank"
              [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
            ></app-radio-group>
          </fieldset>
        </div>
      </div>
      <div class="row" *ngIf="!isQuestionnaire && formGroup.get('hospitalized').value">
        <div class="col-lg-12">
          <app-textarea
            [ngClass]="{
              'field--highlight': questionnaire.healthInfo.medicalHistoryInformation.hospitalized
            }"
            [disabledState]="locked"
            label="Briefly describe"
            formControlName="hospitalizedDescription"
          ></app-textarea>
        </div>
      </div>

      <hr class="input__divider" />

      <div class="row">
        <div class="col-lg-6">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              [options]="yesNoOptions"
              label="Has your child had any surgeries? - If unknown, leave blank"
              formControlName="surgery"
              [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
            ></app-radio-group>
          </fieldset>
        </div>
      </div>
      <div class="row" *ngIf="!isQuestionnaire && formGroup.get('surgery').value">
        <div class="col-lg-12">
          <app-textarea
            [ngClass]="{
              'field--highlight': questionnaire.healthInfo.medicalHistoryInformation.surgery
            }"
            [disabledState]="locked"
            label="Briefly describe"
            formControlName="surgeryDescription"
          ></app-textarea>
        </div>
      </div>

      <hr class="input__divider" />

      <div class="row">
        <div class="col-lg-6">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              [options]="yesNoOptions"
              formControlName="medication"
              label="Is your child on any medications? - If unknown, leave blank"
              [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
            ></app-radio-group>
          </fieldset>
        </div>
      </div>
      <div class="row" *ngIf="formGroup.get('medication').value">
        <div class="col-lg-12">
          <app-textarea
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            label="List medication(s) and purpose"
            formControlName="medicationDescription"
          ></app-textarea>
          <app-overwrite-textarea
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="List medication(s) and purpose"
            formControlName="medicationDescription"
            [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
          ></app-overwrite-textarea>
        </div>
      </div>

      <hr class="input__divider" />

      <div class="row">
        <div class="col-lg-6">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              [options]="yesNoUnknownOptions"
              label="Is your child up to date on their immunizations?"
              formControlName="immunization"
              [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
            ></app-radio-group>
          </fieldset>
        </div>
      </div>

      <div class="row" *ngIf="!isQuestionnaire && formGroup.get('immunization').value === unknownYesNo.No">
        <div class="col-lg-12">
          <app-textarea
            [ngClass]="{
              'field--highlight': questionnaire.healthInfo.medicalHistoryInformation.immunization === unknownYesNo.No
            }"
            [disabledState]="locked"
            label="Additional Information"
            formControlName="immunizationDescription"
          ></app-textarea>
        </div>
      </div>

      <hr class="input__divider" />

      <div class="row">
        <div class="col-lg-6">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              [options]="yesNoUnknownOptions"
              label="Does your child have allergies?"
              formControlName="allergy"
              [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
            ></app-radio-group>
          </fieldset>
        </div>
      </div>
      <div class="row" *ngIf="formGroup.get('allergy').value === unknownYesNo.Yes">
        <div class="col-lg-12">
          <app-textarea
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            label="What are they?"
            formControlName="allergyDescription"
          ></app-textarea>
          <app-overwrite-textarea
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="What are they?"
            formControlName="allergyDescription"
            [sourceData]="questionnaire.healthInfo.medicalHistoryInformation"
          ></app-overwrite-textarea>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="card-spacing-top" *ngIf="!isQuestionnaire">
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg-6">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [options]="yesNoOptions"
              label="Are there any precautions/restrictions that the persons working with your child should be aware of?"
              formControlName="precaution"
            ></app-radio-group>
          </fieldset>
        </div>
      </div>
      <div class="row" *ngIf="formGroup.get('precaution').value">
        <div class="col-lg-12">
          <app-textarea [disabledState]="locked" label="Briefly describe" formControlName="precautionDescription"></app-textarea>
        </div>
      </div>

      <hr class="input__divider" />

      <div class="row">
        <div class="col-lg-6">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [options]="yesNoOptions"
              label="Do you have any concerns about your child's behavior?"
              formControlName="behaviorConcerns"
            ></app-radio-group>
          </fieldset>
        </div>
      </div>
      <div class="row" *ngIf="formGroup.get('behaviorConcerns').value">
        <div class="col-lg-12">
          <app-textarea [disabledState]="locked" label="Briefly describe" formControlName="behaviorConcernsDescription"></app-textarea>
        </div>
      </div>

      <hr class="input__divider" />

      <div class="row">
        <div class="col-lg-6">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [options]="yesNoOptions"
              label="Do you have concerns about how your child interacts with you or others?"
              formControlName="interactionConcern"
            ></app-radio-group>
          </fieldset>
        </div>
      </div>
      <div class="row" *ngIf="formGroup.get('interactionConcern').value">
        <div class="col-lg-12">
          <app-textarea [disabledState]="locked" label="Briefly describe" formControlName="interactionConcernDescription"></app-textarea>
        </div>
      </div>

      <hr class="input__divider" />

      <div class="row">
        <div class="col-lg-6">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [options]="yesNoOptions"
              label="Do these interactions differ depending on the people (family, peers, other children), objects, or settings (home or restaurant)?"
              formControlName="interactionDifference"
            ></app-radio-group>
          </fieldset>
        </div>
      </div>
      <div class="row" *ngIf="formGroup.get('interactionDifference').value">
        <div class="col-lg-12">
          <app-textarea [disabledState]="locked" label="Briefly describe" formControlName="interactionDifferenceDescription"></app-textarea>
        </div>
      </div>

      <hr class="input__divider" />

      <div class="row">
        <div class="col-lg-6">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [options]="yesNoOptions"
              label="Does your child have a documented delay in any area of development?"
              formControlName="documentedDelay"
            ></app-radio-group>
          </fieldset>
        </div>
      </div>
      <div class="row" *ngIf="formGroup.get('documentedDelay').value">
        <div class="col-lg-12">
          <app-textarea
            [disabledState]="locked"
            label="In what developmental area(s)"
            formControlName="documentedDelayAreas"
          ></app-textarea>
        </div>
      </div>
      <div class="row" *ngIf="formGroup.get('documentedDelay').value">
        <div class="col-md-12">
          <app-textarea
            [disabledState]="locked"
            label="Description of recorded delay"
            formControlName="recordedDelayDescription"
          ></app-textarea>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <hr class="input__divider mb-2" />

        <app-textarea
          [disabledState]="locked"
          label="Additional Information"
          formControlName="birthAdditionalInformation"
          [maxLength]="10000"
        ></app-textarea>
      </div>
    </div>
  </mat-card>
</form>

<div class="card-spacing-top">
  <app-documentation-info-form
    *ngIf="!isQuestionnaire"
    section="MedicalHistory"
    title="Upload Relevant Documentation"
    [locked]="locked"
    [formGroup]="documentationInfo"
    [documents]="intake?.documentationInfo"
    [caseId]="intake?.caseId"
    [documentationTypeOptions]="documentationTypeOptions"
    [learnersName]="childsName"
    (upload)="onUpload($event)"
    (deleteDocument)="onDeleteDocument($event)"
    documentType="MedicalHistory"
  >
  </app-documentation-info-form>
</div>
