import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { LearnerInvite } from '../models/learner-invite';
import { LearnerRegistration } from '../models/learner-registration';

const passwordsMatchValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password').value;
  const confirmedPassword = control.get('confirmedPassword').value;

  return password !== confirmedPassword ? { passwordMismatch: true } : null;
};

@Component({
  selector: 'app-learner-portal-registration-form',
  templateUrl: './learner-portal-registration-form.component.html',
  styleUrls: ['./learner-portal-registration-form.component.scss'],
})
export class LearnerPortalRegistrationFormComponent implements OnInit {
  @Input() invite: LearnerInvite;
  @Input() registerError: string;
  @Output() submitted = new EventEmitter<LearnerRegistration>();
  formGroup: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initializeFormGroup();
    this.formGroup.patchValue({
      invitationId: this.invite.id,
      username: this.invite.email,
    });
  }

  onLoginSubmit(event) {
    this.formGroup.patchValue({
      username: event.email,
      password: event.password,
    });
    this.onSubmit();
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.valid) {
      this.submitted.emit(this.formGroup.value);
    }
  }

  initializeFormGroup() {
    this.formGroup = this.fb.group(
      {
        invitationId: ['', { validators: [Validators.required] }],
        username: ['', { validators: [Validators.required] }],
        password: ['', { validators: [Validators.required], updateOn: 'change' }],
        confirmedPassword: ['', { validators: [Validators.required], updateOn: 'change' }],
      },
      { updateOn: 'blur', validators: passwordsMatchValidator }
    );
  }
}
