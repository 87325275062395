import { DatePipe } from '@angular/common';
import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { CaseSummaryByIepId } from 'src/app/shared/models/case';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { HelpSection, HelpTerm } from 'src/app/shared/services/help/help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import { IepPlaafpHelp } from 'src/app/shared/services/help/models/iep.help';
import { conditionalValidator } from 'src/app/shared/validators';
import { BooleanYesNoPipe } from '../../../../shared/pipes/boolean-yesno.pipe';
import { DeactivationService } from '../../../../shared/services/deactivation.service';
import { IepAmendment } from '../../../models/iep-amendment';
import { TransferOfRights } from '../models/transfer-of-rights';
import { TransferOfRightsService } from '../services/transfer-of-rights.service';

@Component({
  selector: 'app-transfer-of-rights',
  templateUrl: './transfer-of-rights.component.html',
  styleUrls: ['./transfer-of-rights.component.scss'],
  providers: [BooleanYesNoPipe, DatePipe],
})
export class TransferOfRightsComponent extends BaseComponent implements OnInit, OnChanges, AfterContentChecked, OnDestroy {
  @Input() iepId: string;
  @Input() plaafpId: string;
  @Input() secondaryTransitionId: string;
  @Input() caseSummary: CaseSummaryByIepId;
  @Input() amendments: Array<IepAmendment>;
  eighteenthBirthday: Date;
  shortDateFormat = shortDateFormat;
  today = new Date();
  newDateTransferWillStart: Date;
  iepPlaafpHelp = IepPlaafpHelp;
  helpSections = HelpSection;
  transferOfRights: TransferOfRights;

  notificationMethods: KeyValuePair[] = [
    new KeyValuePair('Phone'),
    new KeyValuePair('inPerson', 'In-Person'),
    new KeyValuePair('Email'),
    new KeyValuePair('portal', 'Family Portal'),
    new KeyValuePair('Other'),
  ];

  willNotTransferOptionOptions: KeyValuePair[] = [
    new KeyValuePair('Rights retained by parent'),
    new KeyValuePair('Rights granted to a third party'),
    new KeyValuePair('Power of Attorney established'),
    new KeyValuePair('Other'),
  ];

  formGroup = this.fb.group({
    id: '',
    familyNotifiedOn: ['', conditionalValidator(() => this.isSixteenOrOlder(this.caseSummary.learner.age), Validators.required)],
    familyNotificationMethod: ['', conditionalValidator(() => this.isSixteenOrOlder(this.caseSummary.learner.age), Validators.required)],
    familyNotificationMethodOther: [
      '',
      conditionalValidator(() => this.formGroup.get('familyNotificationMethod').value === 'Other', Validators.required),
    ],
    learnerNotifiedOn: ['', conditionalValidator(() => this.isSixteenOrOlder(this.caseSummary.learner.age), Validators.required)],
    learnerNotificationMethod: ['', conditionalValidator(() => this.isSixteenOrOlder(this.caseSummary.learner.age), Validators.required)],
    learnerNotificationMethodOther: [
      '',
      conditionalValidator(() => this.formGroup.get('learnerNotificationMethod').value === 'Other', Validators.required),
    ],
    willNotTransfer: false,
    willNotTransferOption: ['', conditionalValidator(() => this.formGroup.get('willNotTransfer').value, Validators.required)],
    willNotTransferExplanation: [
      '',
      conditionalValidator(() => this.formGroup.get('willNotTransferOption').value === 'Other', Validators.required),
    ],
    dateOfTransfer: null,
  });

  get willNotTransferChecked() {
    return this.formGroup.get('willNotTransfer').value as boolean;
  }

  get hasWillNotTransferOptionOther() {
    return this.formGroup.get('willNotTransferOption').value == 'Other';
  }

  save = new Observable<boolean>((observer) => {
    const done = () => {
      this.isSaving = false;
      observer.next(true);
    };

    if (!this.formGroup.dirty || this.isSaving) {
      done();
      return;
    }

    this.isSaving = true;
    this.torService.update(this.iepId, this.plaafpId, this.secondaryTransitionId, this.formGroup.value).subscribe((res) => done());
  });

  constructor(
    private fb: FormBuilder,
    private torService: TransferOfRightsService,
    private helpService: HelpService,
    public boolToYesNoConverter: BooleanYesNoPipe,
    public changeDetector: ChangeDetectorRef,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  async ngOnInit(): Promise<void> {
    this.eighteenthBirthday = dayjs(this.caseSummary.learner.dateOfBirth).add(18, 'year').toDate();
    this.newDateTransferWillStart = dayjs(this.eighteenthBirthday).add(1, 'day').toDate();

    this.transferOfRights = await this.torService.get(this.iepId, this.plaafpId, this.secondaryTransitionId).toPromise();
    this.setupFormGroup(this.transferOfRights);
    this.saveSubscription = this.formGroup.valueChanges;
    this.setViewMode(this.readonly);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('readonly' in changes) {
      const readOnly = changes.readonly.currentValue;
      this.setViewMode(readOnly);
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onOpenHelp(e: Event, section: HelpSection, item: HelpTerm) {
    e.preventDefault();

    const dictionary = this.helpService.getIepPlaafpDictionary();
    this.helpService.openHelpModal({
      help: dictionary,
      item,
      section,
      canBrowse: true,
    } as HelpModalConfig);
  }

  private setupFormGroup(transferOfRights: TransferOfRights) {
    this.formGroup.patchValue(transferOfRights);
    this.formGroup.get('willNotTransfer').valueChanges.subscribe((value) => {
      if (!value) {
        ['willNotTransferOption', 'willNotTransferExplanation', 'dateOfTransfer'].forEach((key) => {
          this.formGroup.get(key).setValue(null);
        });
      }
    });
    this.formGroup.get('familyNotificationMethod').valueChanges.subscribe((value) => {
      if (value !== 'Other') {
        this.formGroup.get('familyNotificationMethodOther').setValue(null);
      }
    });
    this.formGroup.get('learnerNotificationMethod').valueChanges.subscribe((value) => {
      if (value !== 'Other') {
        this.formGroup.get('learnerNotificationMethodOther').setValue(null);
      }
    });
  }

  private isSixteenOrOlder = (age: number) => age >= 16;

  private setViewMode(readOnly: boolean): void {
    if (!this.saveSubscription) {
      return;
    }

    if (readOnly) {
      this.stopAutosaving();
    } else {
      this.startAutosaving();
    }
  }
}
