import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DialogComingSoonComponent } from 'src/app/shared/components/coming-soon/coming-soon.component';
import { CaseSummary } from 'src/app/shared/models/case';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { NewWindowConfig, openNewWindow, popupChecker } from 'src/app/shared/windowHelpers';
import { FeatureFlagService } from '../../shared/components/feature-flags/feature-flag.service';
import { PdfOutputs } from '../../shared/services/reporting/reporting.service';
import { IepType } from '../models/iep';
@Component({
  selector: 'app-iep-header',
  templateUrl: './iep-header.component.html',
  styleUrls: ['./iep-header.component.scss'],
})
export class IepHeaderComponent implements OnInit {
  isPopup = false;
  iepId: string;
  caseSummary: CaseSummary;
  iepHeaderTitle: string;
  pdfOutputs = PdfOutputs;

  get canPushToPortal() {
    return this.featureFlagService.featureOn('familyPortalEnabled');
  }

  constructor(
    public route: ActivatedRoute,
    private caseService: CaseService,
    private dialog: MatDialog,
    private readonly title: Title,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this.isPopup = popupChecker(this.route);
    this.iepId = this.route.snapshot.paramMap.get('iepId');

    this.caseService.getCaseSummary(this.route.snapshot.paramMap.get('caseId')).subscribe((caseSummary) => {
      this.caseSummary = caseSummary;
      this.iepHeaderTitle = 'IEP Development - ' + caseSummary.learner.fullName;
      this.title.setTitle('IEP Development - ACHIEVE');
      if (this.caseSummary.activeIepType === IepType.Interim) {
        this.iepHeaderTitle = 'Interim IEP Development - ' + caseSummary.learner.fullName;
        this.title.setTitle('Interim IEP Development - ACHIEVE');
      }
    });
  }

  onOpenMatrix() {
    const config: NewWindowConfig = {
      path: `evaluation/${this.caseSummary.evaluationId}/eco-matrix`,
    };
    openNewWindow(config);
  }

  onViewBip() {
    const config: NewWindowConfig = {
      path: `cases/${this.caseSummary?.id}/behavior-discipline/bip/${this.caseSummary?.bipId}/overview`,
      width: '1300px',
    };
    openNewWindow(config);
  }

  onComingSoon() {
    this.dialog.open(DialogComingSoonComponent);
  }
}
