<mat-accordion
  displayMode="flat"
  multi
  class="accordion"
  [ngClass]="{
    'learner-info--condensed': condensed,
    'learner-info--print': isPrint
  }"
  *ngIf="learnerSummary"
>
  <mat-expansion-panel class="mat-elevation-z0" aria-live="polite">
    <mat-expansion-panel-header aria-live="polite">
      <div class="display-flex flex-wrap w-100" [ngClass]="{ 'fields--few': fewFields }">
        <app-key-value-label *ngIf="learnerSummary?.fullName" key="Name">
          <a
            class="text-underline-none"
            [routerLink]="getLearnerManagementLink()"
            tabindex="0"
            role="link"
            attr.aria-label="{{ learnerSummary?.fullName }}"
            >{{ learnerSummary?.fullName }}</a
          >
        </app-key-value-label>
        <app-key-value-label key="DOB" [value]="(learnerSummary?.dateOfBirth | date : shortDateFormat) || '-'"> </app-key-value-label>
        <app-key-value-label key="Age">
          <ng-container *ngIf="showAgeInMonths; else ageInYears">
            {{ learnerSummary?.ageInMonths || '-' }}
          </ng-container>
          <ng-template #ageInYears>
            {{ learnerSummary?.age.toString() || '-' }}
          </ng-template>
        </app-key-value-label>
        <ng-container *ngTemplateOutlet="primaryInfoTemplate"></ng-container>
        <ng-container *ngIf="showFamilyMembersInPrimary">
          <ng-container *ngTemplateOutlet="familyMembers"></ng-container>
        </ng-container>
      </div>
    </mat-expansion-panel-header>

    <hr class="input__divider mt-0 mb-3" />

    <div class="display-flex flex-wrap w-100">
      <ng-container *ngIf="!showFamilyMembersInPrimary">
        <ng-container *ngTemplateOutlet="familyMembers"></ng-container>
      </ng-container>
      <ng-container *ngTemplateOutlet="secondaryInfoTemplate"></ng-container>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<ng-template #familyMembers>
  <app-key-value-label *ngIf="learnerSummary?.familyMembers?.length > 0" class="display-flex flex-column text-md" key="Parent Info">
    <ng-container *ngFor="let member of learnerSummary.familyMembers">
      <div>
        <span *ngIf="member.fullName">{{ member.fullName }}</span>
        <span *ngIf="member.homePhone" class="phone-number"> {{ member.homePhone | phone }} (H) </span>
        <span *ngIf="member.workPhone" class="phone-number"> {{ member.workPhone | phone }} (W) </span>
        <span *ngIf="member.cellPhone" class="phone-number"> {{ member.cellPhone | phone }} (C) </span>
      </div>
    </ng-container>
    <ng-container *ngFor="let surrogate of surrogates">
      <div>
        <span *ngIf="surrogate.name">{{ surrogate.name }} (Surrogate)</span>
      </div>
    </ng-container>
  </app-key-value-label>
</ng-template>
