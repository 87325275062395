<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Begin Modification</h3>
<form [formGroup]="formGroup" #formDirective="ngForm">
  <div mat-dialog-content class="py-3">
    <div class="mb-2">
      <app-page-alert
        [alert]="{
          status: 'info',
          message:
            '
      Once you click Complete, an IFSP modification will be created and you will
      be required to complete the full modification process. If you do not
      wish to start the modification process, please click Cancel to exit.'
        }"
        role="status"
        aria-live="polite"
      ></app-page-alert>
    </div>

    <mat-card class="my-0">
      <div class="row">
        <div class="col-md-12">
          <fieldset>
            <app-radio-group
              label="Has the team determined that a meeting is required for this/these modifications(s)"
              formControlName="meetingRequired"
              [options]="yesNoOptions"
            ></app-radio-group>
          </fieldset>
        </div>
        <ng-container *ngIf="meetingRequiredHasValue && !meetingRequired">
          <div class="col-md-12">
            <app-select label="Parent contacted by" formControlName="personContactedParentId" [options]="personContactedParentOptions">
            </app-select>
          </div>
          <div class="col-md-12">
            <app-select label="Method of Contact" formControlName="methodOfContact" [options]="methodOfContactOptions"> </app-select>
          </div>
          <div class="col-md-12">
            <app-date-picker
              label="Date of Agreement to Modify Without a Meeting"
              controlName="dateOfModifyWithoutMeeting"
              [max]="today"
            ></app-date-picker>
          </div>
        </ng-container>
      </div>
    </mat-card>
  </div>

  <div mat-dialog-actions class="display-flex justify-content-end align-items-center">
    <button mat-raised-button aria-label="Cancel Button" (click)="onCancel()" cdkFocusInitial type="button" class="ml-2" tabindex="0">
      Cancel
    </button>
    <button
      mat-raised-button
      aria-label="Complete"
      color="primary"
      class="ml-2"
      [disabled]="disableSubmitBtn"
      [disableAfterBusy]="true"
      [isBusy]="submittingForm"
      (click)="onSubmit()"
      tabindex="0"
    >
      Complete
    </button>
  </div>
</form>
