import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { FeatureFlagService } from '../../../shared/components/feature-flags/feature-flag.service';
import { CaseService } from '../../../shared/services/case/case.service';
import { PdfOutputs } from '../../../shared/services/reporting/reporting.service';
import { IepStatus } from '../../models/iep';
import { IepService } from '../../services/iep.service';

@Component({
  selector: 'app-esy-header',
  templateUrl: './esy-header.component.html',
  styleUrls: ['./esy-header.component.scss'],
})
export class EsyHeaderComponent implements OnInit {
  showNav = true;
  caseId: string;
  iepId: string;
  learnerId: string;
  pageTitle: string;
  goToDetailView = false;
  pdfOutputs = PdfOutputs;

  get readonlyUser() {
    return this.authService.user?.rolePermissions.filter((x) => x.isReadOnly)?.length > 0;
  }

  get canPushToPortal() {
    return this.featureFlagService.featureOn('familyPortalEnabled');
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private caseService: CaseService,
    private iepService: IepService,
    private authService: AuthService,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this.showNav = this.shouldShowNav();
    this.caseId = this.route.snapshot.paramMap.get('caseId');
    this.iepId = this.route.snapshot.paramMap.get('iepId');

    forkJoin([this.caseService.getCaseLearnerName(this.caseId), this.iepService.get(this.iepId)]).subscribe(async ([caseSummary, iep]) => {
      this.pageTitle = 'Extended School Year Plan - ' + caseSummary.value;
      this.goToDetailView = iep.iepStatus === IepStatus.Active;
    });

    this.caseService.getLearnerId(this.caseId).subscribe((res) => {
      this.learnerId = res.value;
    });
  }

  goToIep() {
    this.router.navigate(['/', 'cases', this.caseId, 'iep', this.iepId, this.goToDetailView ? 'details' : 'profile']);
  }

  shouldShowNav(): boolean {
    return this.router.url.indexOf('/view') > -1 ? false : true;
  }
}
