<div class="prefill">
  <div class="prefill__input">
    <mat-form-field class="w-100">
      <mat-label
        [ngClass]="{
          asterisk_input: control.hasError('required')
        }"
        >{{ label }}</mat-label
      >
      <input
        matInput
        type="number"
        [class]="klass"
        id="{{ controlId }}"
        [formControl]="control"
        [placeholder]="placeholder"
        [readonly]="readOnly"
        [attr.disabled]="disabledState ? true : null"
        [attr.min]="min"
        [attr.max]="max"
        (blur)="internalBlur($event)"
        attr.aria-required="{{ control.hasError('required') ? 'true' : 'false' }}"
      />
      <span matPrefix *ngIf="useInputGroup">
        <ng-content select="[prepend]"></ng-content>
      </span>
      <span matSuffix *ngIf="useInputGroup">
        <ng-content select="[append]"></ng-content>
      </span>

      <mat-error>
        <app-field-error-display [control]="control"></app-field-error-display>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="prefill__text" *ngIf="dataSourceValue">QR: {{ dataSourceValue }}</div>

  <div class="prefill__container" (click)="replaceWithSourceValue()" *ngIf="dataSourceValue && !undoValue">
    <div class="prefill__icon">
      <mat-icon aria-label="QR Arrow Symbol">call_merge</mat-icon>
    </div>
  </div>

  <div class="prefill__container prefill__container--undo" *ngIf="undoValue">
    <p class="prefill__icon mb-0" (click)="undoReplaceWithSourceValue()">Undo</p>
  </div>
</div>
