<ng-container *ngIf="isReady">
  <h3 class="mt-0 mb-2">
    Quick Links -
    {{ caseSummary?.intakeType === intakeType.PartB ? 'Special Education' : 'Early ACCESS' }}
  </h3>
  <mat-card class="my-0">
    <div class="row">
      <div class="col-xl-12">
        <div class="display-flex flex-wrap">
          <div class="list-fit" *ngIf="canReopenCase">
            <ul class="list">
              <li class="list__item">
                <app-reopen-case [caseSummary]="caseSummary"></app-reopen-case>
              </li>
            </ul>
          </div>

          <ng-container *ngIf="!caseSummary?.exitFinalized && caseSummary?.isActive">
            <div class="list-fit">
              <ul class="list">
                <ng-container *ngIf="canCompleteDSForm">
                  <li
                    class="list__item"
                    *ngIf="!!quickLinkDetails?.disabilitySuspectFormId && !quickLinkDetails?.disabilitySuspectFormSubmitted"
                  >
                    <a
                      [routerLink]="['/child-find', 'disability-suspect', quickLinkDetails.disabilitySuspectFormId]"
                      tabindex="0"
                      role="link"
                      >Complete Disability Suspected</a
                    >
                  </li>
                </ng-container>
                <ng-container
                  *appAuthForRoles="{
                    permission: [permissions.RequestForFiieConsent],
                    caseId: caseId,
                    additionalRequirement: requestForFiieConsentAdditionalRequirements
                  }"
                >
                  <li
                    class="list__item"
                    *ngIf="
                      quickLinkDetails.intakeType === intakeType.PartB &&
                      quickLinkDetails.fiieConsentFormId !== null &&
                      quickLinkDetails.fiieConsentStatus !== consentStatus.Approved &&
                      quickLinkDetails.fiieConsentStatus !== consentStatus.Requested &&
                      quickLinkDetails.disabilitySuspectFormSubmitted
                    "
                  >
                    <a
                      [routerLink]="routingService.fiieConsentPath(learnerId, caseId)"
                      tabindex="0"
                      role="link"
                      aria-labelledby="Complete Consent for Full and Individual Initial Evaluation"
                    >
                      Complete Consent for Full and Individual Initial Evaluation
                    </a>
                  </li>
                </ng-container>
                <ng-container
                  *appAuthForRoles="{
                    permission: [permissions.PartCIntakeManage],
                    caseId: caseSummary?.caseId
                  }"
                >
                  <li class="list__item" *ngIf="displayIntakeButton() && showUpdateCompleteIntake()">
                    <a
                      [routerLink]="['/cases', caseId, 'evaluation', 'early-access-intake', caseId]"
                      tabindex="0"
                      role="link"
                      attr.aria-label="{{ quickLinkDetails.intakeFinalized ? 'Update Intake' : 'Complete Intake' }}"
                    >
                      {{ quickLinkDetails.intakeFinalized ? 'Update Intake' : 'Complete Intake' }}
                    </a>
                  </li>
                </ng-container>
                <li class="list__item" *ngIf="quickLinkDetails.intakeType === intakeType.PartB && !hideAgeOfMajorityLetter">
                  <a href="javascript:void(0)" (click)="goToAgeOfMajorityLetter()" class="mr-3">
                    Create and {{ familyInPortal ? 'Push' : 'Print' }} the Age of Majority letter
                  </a>
                  <mat-checkbox
                    *ngIf="!familyInPortal"
                    [(ngModel)]="quickLinkDetails.ageOfMajorityLetterSent"
                    (change)="updateAgeOfMajorityLetterSentStatus(quickLinkDetails.ageOfMajorityLetterSent)"
                    aria-labelledby="Age of Majority letter Sent"
                  >
                    Age of Majority letter Sent
                  </mat-checkbox>
                  <mat-spinner *ngIf="generatingAgeOfMajorityLetters" diameter="20"></mat-spinner>
                </li>
                <li
                  class="list__item"
                  *ngIf="
                    quickLinkDetails.intakeType !== intakeType.PartB &&
                    !caseSummary.isMigrated &&
                    quickLinkDetails.earlyAccessReferralId &&
                    !hideThankYouLetter &&
                    !authService.isVrUser
                  "
                >
                  <a href="javascript:void(0)" (click)="goToReferralThankYouLetter()" class="mr-3">Send Thank You Letter</a>
                  <mat-checkbox
                    [(ngModel)]="quickLinkDetails.referralThankYouLetterSent"
                    (change)="updateLetterStatus(quickLinkDetails.referralThankYouLetterSent)"
                    aria-labelledby="Thank You Letter Sent"
                  >
                    Thank You Letter Sent
                  </mat-checkbox>
                </li>
                <li
                  class="list__item"
                  [removeIfFeatureOff]="'outputResultsLetterEnabled'"
                  *ngIf="
                    quickLinkDetails.intakeType !== intakeType.PartB &&
                    !caseSummary.isMigrated &&
                    quickLinkDetails.earlyAccessReferralId &&
                    !hideThankYouLetter &&
                    !authService.isVrUser
                  "
                >
                  <a
                    href="javascript:void(0)"
                    (click)="goToResultLetter()"
                    class="mr-3"
                    tabindex="0"
                    role="link"
                    aria-labelledby="Send Results Letter"
                  >
                    Send Results Letter</a
                  >
                  <mat-checkbox
                    [(ngModel)]="quickLinkDetails.resultLetterSent"
                    (change)="updateResultLetterStatus(quickLinkDetails.resultLetterSent)"
                    aria-labelledby="Results Letter Sent"
                  >
                    Results Letter Sent
                  </mat-checkbox>
                </li>
                <li class="list__item" *ngIf="isAbleForPrintCaseClosureLetter">
                  <a
                    href="javascript:void(0)"
                    (click)="onPrintContactLetter()"
                    class="mr-3"
                    tabindex="0"
                    role="link"
                    aria-labelledby="Print Case Closure Letter"
                  >
                    Print Case Closure Letter</a
                  >
                </li>

                <li class="list__item" *ngIf="showEvaluation()">
                  <a
                    [routerLink]=""
                    (click)="onGoToEvaluation(quickLinkDetails.evaluationId)"
                    tabindex="0"
                    role="link"
                    attr.aria-label="{{ !quickLinkDetails.evaluationId ? 'Begin' : 'Complete' }}
                    Evaluation
                    {{ quickLinkDetails.intakeType === intakeType.PartC ? ' / Assessment' : '' }}"
                  >
                    {{ !quickLinkDetails.evaluationId ? 'Begin' : 'Complete' }}
                    Evaluation
                    {{ quickLinkDetails.intakeType === intakeType.PartC ? ' / Assessment' : '' }}
                  </a>
                </li>
                <li class="list__item" *ngIf="showReevaluation()">
                  <a
                    [routerLink]=""
                    (click)="onGoToReevaluation(quickLinkDetails.reevaluationId)"
                    tabindex="0"
                    role="link"
                    attr.aria-label="{{ !quickLinkDetails.reevaluationId ? 'Begin' : 'Complete' }}
                    Reevaluation"
                  >
                    {{ !quickLinkDetails.reevaluationId ? 'Begin' : 'Complete' }}
                    Reevaluation
                  </a>
                </li>
                <ng-container *appAuthForRoles="{ permission: [permissions.ViewProgressMonitoring], caseId: caseId }">
                  <li class="list__item" *ngIf="quickLinkDetails.hasActiveIepOrIfsp">
                    <a (click)="enterProgress()" class="text-underline" tabindex="0" role="link" aria-labelledby="Enter/View Progress">
                      Enter/View Progress
                    </a>
                  </li>
                </ng-container>
                <ng-container *appAuthForRoles="{ permission: [permissions.CreateEditServiceLog], caseId: caseId }">
                  <li class="list__item" *ngIf="quickLinkDetails.intakeType === intakeType.PartC && quickLinkDetails.hasActiveIfsp">
                    <a
                      (click)="addNewServiceLogEntry()"
                      class="text-underline"
                      tabindex="0"
                      role="link"
                      aria-labelledby="Add New Log Entry"
                    >
                      Add New Log Entry
                    </a>
                  </li>
                </ng-container>
                <ng-container *appAuthForRoles="{ permission: [permissions.BipFullAccess], caseId: caseId }">
                  <li class="list__item" *ngIf="bipId && !authService.isVrUser">
                    <a
                      routerLink="/cases/{{ caseId }}/behavior-discipline/bip/{{ bipId }}/overview"
                      tabindex="0"
                      role="link"
                      aria-labelledby="Open Behavior Intervention Plan"
                    >
                      Open Behavior Intervention Plan
                    </a>
                  </li>
                </ng-container>
                <li class="list__item" *ngIf="caseSummary && !caseSummary.exiting">
                  <ng-container
                    *ngIf="
                      (caseSummary?.intakeType === intakeType.PartC && canExitPartC) ||
                      (caseSummary?.intakeType === intakeType.PartB && canExitPartB)
                    "
                  >
                    <a
                      (click)="exitCase(caseSummary.id)"
                      class="text-underline"
                      tabindex="0"
                      role="link"
                      attr.aria-label="End / Exit {{ caseSummary.learner.firstName }} from
                    {{ caseSummary?.intakeType === intakeType.PartB ? 'Special Education' : 'Early ACCESS' }}"
                    >
                      End / Exit {{ caseSummary.learner.firstName }} from
                      {{ caseSummary?.intakeType === intakeType.PartB ? 'Special Education' : 'Early ACCESS' }}
                    </a>
                  </ng-container>
                </li>
                <li class="list__item" *ngIf="showTransitionToKindergartenFinalECOLink">
                  <a
                    (click)="showTransitionKindergartenFinalEcoModal()"
                    tabindex="0"
                    area-labelledby="Transition to Kindergarten-Final ECO"
                    class="text-underline"
                  >
                    Transition to Kindergarten-Final ECO
                  </a>
                </li>
              </ul>
            </div>

            <div class="list-fit">
              <ul class="list">
                <li class="list__item" *ngIf="canViewIep || canViewIfsp">
                  <a
                    [routerLink]="['/cases', caseId, 'ifsp', quickLinkDetails.ifspId, 'profile']"
                    *ngIf="canViewIfsp()"
                    tabindex="0"
                    role="link"
                    >Complete IFSP</a
                  >
                  <a
                    [routerLink]="['/cases', caseId, 'iep', quickLinkDetails.iepId, 'profile']"
                    *ngIf="canViewIep()"
                    tabindex="0"
                    role="link"
                    >Edit IEP</a
                  >
                </li>
                <li class="list__item" *ngIf="enableInterimIFSPBtn">
                  <a href="javascript:void(0)" (click)="openInterimPlanModal()" tabindex="0" aria-labelledby="Complete Interim IFSP">
                    Complete Interim IFSP</a
                  >
                </li>
                <li class="list__item" *ngIf="canCreateEditPwn(quickLinkDetails)">
                  <a
                    href="javascript:void(0)"
                    (click)="openPwn()"
                    tabindex="0"
                    role="link"
                    attr.aria-label="{{ quickLinkDetails.pwnId ? 'Complete' : 'Create' }} Prior Written Notice"
                  >
                    {{ quickLinkDetails.pwnId ? 'Complete' : 'Create' }} Prior Written Notice
                  </a>
                </li>

                <li class="list__item" *ngIf="canViewReferral(caseId) && quickLinkDetails?.earlyAccessReferralId">
                  <a
                    href="javascript:void(0)"
                    (click)="routeToEarlyAccessReferralReadOnlyForm()"
                    tabindex="0"
                    role="link"
                    aria-labelledby="View Referral"
                  >
                    View Referral
                  </a>
                </li>

                <li *ngIf="IsAbleForScheduleMeeting" class="list__item">
                  <a href="javascript:void(0)" (click)="scheduleMeeting()" tabindex="0" aria-label="Open Scheduler"> Open Scheduler </a>
                </li>

                <li *ngIf="canCancelSecondaryTransition" class="list__item">
                  <a href="javascript:void(0)" (click)="cancelSecondaryTransition()" tabindex="0" aria-label="Cancel Secondary Transition">
                    Cancel Secondary Transition
                  </a>
                </li>

                <li class="list__item" *ngIf="quickLinkDetails.showESY">
                  <a href="javascript:void(0)" (click)="goToESY()" tabindex="0" aria-labelledby="ESY Plan"> ESY Plan </a>
                </li>
                <ng-container *appAuthForRoles="{ permission: [permissions.CreateIFSP], caseId: caseSummary.id }">
                  <li class="list__item" *ngIf="canBeginPeriodicReview">
                    <a
                      href="javascript:void(0)"
                      (click)="beginPeriodicReview()"
                      class="mr-3"
                      tabindex="0"
                      role="link"
                      aria-labelledby="Start Periodic Review"
                      [class.disabled]="activeCall"
                    >
                      Start Periodic Review</a
                    >
                  </li>
                  <li class="list__item" *ngIf="canBeginAnnualReview">
                    <a
                      href="javascript:void(0)"
                      (click)="beginAnnualReview()"
                      class="mr-3"
                      tabindex="0"
                      role="link"
                      aria-labelledby="Start Annual Review"
                      [class.disabled]="activeCall"
                    >
                      Start Annual Review
                    </a>
                  </li>
                </ng-container>
                <li class="list__item" *ngIf="canSendPeriodicReviewPoll">
                  <a *ngIf="activeIfsp" href="javascript:void(0)" (click)="sendPeriodicReviewPoll()" class="mr-3" tabindex="0" role="link"
                    >Send Periodic Review Poll</a
                  >
                </li>
                <li class="list__item" *ngIf="canSendAnnualReviewPoll">
                  <a
                    *ngIf="activeIfsp"
                    href="javascript:void(0)"
                    (click)="sendAnnualReviewPoll()"
                    class="mr-3"
                    tabindex="0"
                    role="link"
                    aria-labelledby="Send Annual Review Poll"
                    [class.disabled]="activeCall"
                  >
                    Send Annual Review Poll</a
                  >
                </li>
                <li class="list__item" *ngIf="isSuperAdmin">
                  <a href="javascript:void(0)" (click)="openChangeStateId()" class="mr-3" tabindex="0" role="link">
                    {{ stateIdQuickLinkTitle }}</a
                  >
                </li>
                <li
                  class="list__item"
                  *ngIf="
                    isSuperAdmin &&
                    (caseSummary?.learner?.isMigrated ||
                      !(
                        caseSummary?.learner?.movingFromOutOfState ||
                        caseSummary?.learner?.eligibleInLegacySystem ||
                        caseSummary?.learner?.eligibleInLegacySystemPartC
                      ))
                  "
                >
                  <a href="javascript:void(0)" (click)="openChangeReevaluationDate()" class="mr-3" tabindex="0" role="link"
                    >Change Reevaluation Date</a
                  >
                </li>
              </ul>
            </div>

            <div class="list-fit">
              <ul class="list">
                <li class="list__item" *ngIf="activeIfsp && !draftIfsp">
                  <a
                    href="javascript:void(0)"
                    (click)="modifyIfsp()"
                    class="mr-3"
                    tabindex="0"
                    aria-labelledby="Modify IFSP"
                    [class.disabled]="activeCall"
                  >
                    Modify IFSP
                  </a>
                </li>
                <li class="list__item" *ngIf="activeIep && !draftIep">
                  <a
                    href="javascript:void(0)"
                    (click)="amendIep()"
                    class="mr-3"
                    tabindex="0"
                    aria-labelledby="Amend IEP"
                    [class.disabled]="activeCall"
                  >
                    Amend IEP</a
                  >
                </li>
              </ul>
            </div>
          </ng-container>
          <div class="list-fit">
            <ul class="list">
              <li class="list__item">
                <a (click)="onOpenNewLearner($event)" class="text-underline" tabindex="0" role="link" aria-labelledby="Learner Information">
                  Learner Information
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</ng-container>
