<ng-container *ngIf="!!case">
  <app-print-head [showLogo]="!isParent"></app-print-head>

  <div class="print__container">
    <p>{{ date | dateFormat }}</p>
    <p class="aea-address">
      {{ aea.name }}<br />
      {{ aea.streetAddress }}<br />
      {{ aea.city }}, {{ aea.state }} {{ aea.zipCode }}<br />
    </p>

    <p>Dear {{ learner.lastName }} Family,</p>

    <p>
      My name is {{ user.fullName }} and I am a service coordinator for Early ACCESS at {{ aea.name }}. I recently received a referral for
      your child {{ learner.firstName }}<span *ngIf="!isParent"> from {{ referralSourceName }}</span
      >. I have been unable to contact you via phone/ or email.
    </p>
    <p>
      Early ACCESS provides early intervention services for children under the age of 3 that may not be developing or learning as expected.
      If you are concerned about your child, Early ACCESS might be right for your family.
    </p>

    <p>
      The focus of Early ACCESS is to support you to help your child learn and grow through everyday routines and activities. This means
      early intervention service providers work with parents and other caregivers to help children learn and grow. Early ACCESS is voluntary
      and is provided at no cost to families. To learn more about Early ACCESS, visit
      <a href="https://www.iafamilysupportnetwork.org" tabindex="0" role="link" aria-labelledby="To learn more about Early ACCESS, visit">
        https://www.iafamilysupportnetwork.org</a
      >
    </p>
    <p>
      If you are interested in Early ACCESS, please contact me at
      {{ (user.phoneNumber | phone) || (aea.primaryPhone | phone) }} to schedule a visit. If I do not hear from you by
      {{ fourteenDaysFromNow | dateFormat : 'long' }}, I will close this referral.
    </p>

    <p>I look forward to hearing from you.</p>
    <p>Sincerely,</p>
    <p>
      {{ user.fullName }}<br />
      <span *ngIf="!isParent">Early ACCESS Service Coordinator<br /></span>
      <span *ngIf="isParent">Service Coordinator<br /></span>
      {{ user.email }}<br />
    </p>
  </div>
</ng-container>
