import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IntakeSections } from 'src/app/evaluation/early-access-intake/intake-sections/intake-sections';
import { EarlyAccessQuestionnaire } from 'src/app/evaluation/models/early-access-questionnaire';
import { Intake } from 'src/app/evaluation/models/intake';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { usStates } from 'src/app/shared/models/us-states';
import { HelpSection, HelpTerm } from 'src/app/shared/services/help/help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import { InsuranceWaiverServices } from 'src/app/shared/services/help/models/insurace';
import { WaiverTypeService } from 'src/app/shared/services/waiver-types/waiver-type.service';
import { InsuranceInformation } from '../../models/insurance-information';
import { WaiverStatus } from '../../models/waiver-status';
import { getInsuranceDictionary } from '../../../../shared/services/help/dictionary/insurace.dictionary';

//#region Custom Validation Functions

//#endregion

@Component({
  selector: 'app-insurance-info-form',
  templateUrl: './insurance-info-form.component.html',
  styleUrls: ['./insurance-info-form.component.scss'],
})
export class InsuranceInfoFormComponent implements OnInit, OnChanges, OnDestroy {
  constructor(private waiverTypeService: WaiverTypeService, private helpService: HelpService) {}
  private subscription: Subscription = new Subscription();
  @Input() formGroup: FormGroup;
  @Input() insuranceInfo: InsuranceInformation;
  @Input() intake: Intake;
  @Input() intakeLocked: boolean;
  @Input() questionnaire: EarlyAccessQuestionnaire;
  @Input() isQuestionnaire: boolean;
  @Input() questionnaireLocked: boolean;
  @Input() documentationInfo: FormGroup;
  @Input() importQuestionnaireSelected: boolean;
  @Output() upload = new EventEmitter();
  @Output() deleteDocument = new EventEmitter();
  @Output() formInitialized = new EventEmitter();
  locked = false;
  formInitializationFlags = {
    controlsInitialized: false,
    dataLoaded: false,
    eventEmitted: false,
  };
  isReadOnly: boolean;
  usStates = usStates;
  intakeSections = IntakeSections;
  helpSection = HelpSection;
  insuranceWaiverServices = InsuranceWaiverServices;

  permissionFormRequired: boolean;
  yesNoOptions: KeyValuePair[] = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];

  waiverStatus = WaiverStatus;
  appliedYesNo: KeyValuePair[] = [
    new KeyValuePair('Yes', 'Yes'),
    new KeyValuePair('No', 'No'),
    new KeyValuePair('AppliedForWaiver', 'Applied For Waiver'),
  ];

  waiverTypes: KeyValuePair[] = [];
  waiverTypesAll: KeyValuePair[] = [];

  ngOnInit() {
    this.waiverTypeService.getWaiverTypes().subscribe((res) => {
      this.waiverTypesAll = res.sort((a, b) => (a.order > b.order ? 1 : -1)).map((waiver) => new KeyValuePair(waiver.id, waiver.label));
      this.waiverTypes = res
        .filter((x) => x.label !== 'Unsure')
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map((waiver) => new KeyValuePair(waiver.id, waiver.label));
    });
    this.initializeControls();
    this.formGroup.patchValue(this.insuranceInfo);
    this.formInitializationFlags.dataLoaded = true;
    this.evaluateFormInitialization();
    this.setLock();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.importQuestionnaireSelected?.currentValue !== changes.importQuestionnaireSelected?.previousValue &&
      this.importQuestionnaireSelected
    ) {
      this.importInsuranceInfoFromQuestionnaire();
      this.formGroup.markAsDirty();
    }
  }

  importInsuranceInfoFromQuestionnaire() {
    const questionnaireInsuranceInfo = this.questionnaire?.insuranceInfo;
    for (const prop in questionnaireInsuranceInfo) {
      if (questionnaireInsuranceInfo[prop] !== null && questionnaireInsuranceInfo[prop] !== '') {
        this.insuranceInfo[prop] = questionnaireInsuranceInfo[prop];
      }
    }
    this.formGroup.patchValue(this.insuranceInfo);
  }

  setLock() {
    if (this.isQuestionnaire) {
      this.locked = this.questionnaireLocked;
    } else {
      this.locked = this.intakeLocked;
    }

    if (this.locked) {
      this.formGroup.controls.hasMedicaid.disable();
      this.formGroup.controls.hasPrivateInsurance.disable();
      this.formGroup.controls?.hasHawki?.disable();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initializeControls() {
    this.formGroup.addControl('hasInsurance', new FormControl(null, { updateOn: 'change' }));
    this.formGroup.addControl('hasMedicaid', new FormControl(null, { updateOn: 'change' }));
    this.formGroup.addControl('medicaidNumber', new FormControl(null));
    this.formGroup.addControl('hasPrivateInsurance', new FormControl(null, { updateOn: 'change' }));
    this.formGroup.addControl('insuranceCompanyName', new FormControl(null));

    this.formGroup.addControl('hasHawki', new FormControl(null, { updateOn: 'change' }));

    this.formGroup.addControl('doesTheChildHaveAWaiver', new FormControl(null, { updateOn: 'change' }));

    this.formGroup.get('doesTheChildHaveAWaiver').valueChanges.subscribe((waiverOption: WaiverStatus) => {
      if (!waiverOption) {
        this.formGroup.addControl('waiverType', new FormControl(null, { updateOn: 'change' }));
      }
      switch (waiverOption) {
        case WaiverStatus.Yes:
          this.formGroup.removeControl('waiverTypes');
          this.formGroup.addControl('waiverType', new FormControl(null, { updateOn: 'change' }));
          break;
        case WaiverStatus.AppliedForWaiver:
          this.formGroup.removeControl('waiverType');
          this.formGroup.addControl('waiverTypes', new FormControl(null, { updateOn: 'change' }));
          break;
        default:
          this.formGroup.removeControl('waiverType');
          this.formGroup.removeControl('waiverTypes');
      }
    });

    if (!this.isQuestionnaire) {
      this.formGroup.addControl('dhsMedicaidNumber', new FormControl(null));
      this.formGroup.controls.hasInsurance.setValidators([Validators.required]);
    }
    this.formGroup.setValidators([]);
    this.formGroup.updateValueAndValidity();
    this.formInitializationFlags.controlsInitialized = true;
    this.evaluateFormInitialization();
  }

  private evaluateFormInitialization() {
    if (this.formInitializationFlags.eventEmitted) {
      return;
    }
    if (this.formInitializationFlags.controlsInitialized && this.formInitializationFlags.dataLoaded) {
      this.formInitializationFlags.eventEmitted = true;
      this.formInitialized.emit();
    }
  }

  requiresInsurance() {
    if (
      this.formGroup.controls.hasInsurance.value &&
      !this.formGroup.controls.hasMedicaid.value &&
      !this.formGroup.controls.hasPrivateInsurance.value &&
      !this.formGroup.controls.hasHawki.value
    ) {
      return true;
    }
    return false;
  }

  onUpload(formData: FormData) {
    this.upload.emit(formData);
  }

  onDeleteDocument(documentId: string) {
    this.deleteDocument.emit(documentId);
  }

  onOpenHelp(e: Event, section: HelpSection, item: HelpTerm) {
    e.preventDefault();

    const dictionary = this.helpService.getInsuranceDictionary();
    this.helpService.openHelpModal({
      help: dictionary,
      section,
      item,
      canBrowse: true,
    } as HelpModalConfig);
  }
}
