<button
  mat-flat-button
  aria-label="Add Icon"
  color="primary"
  *ngIf="hasOpenAmendment"
  class="mr-2"
  (click)="$event.stopPropagation(); addNew()"
  tabindex="0"
>
  Add New
</button>

<button mat-raised-button aria-label="View IEP Service Tags" color="accent" (click)="openTags()" tabindex="0">View IEP Service Tags</button>

<div class="table-overflow app-table">
  <table mat-table [dataSource]="servicesDataSource" class="w-100" matSort>
    <ng-container matColumnDef="actions">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="table-actions"
        [ngClass]="{
          'mat-column-actions--more': !isEditing && hasOpenAmendment
        }"
      >
        Actions
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        [ngClass]="{
          'mat-column-actions--more': !isEditing && atLeastOneOptionAvailable(element)
        }"
      >
        <div class="display-flex align-items-center justify-content-start">
          <button aria-label="View Icon" mat-icon-button color="primary" (click)="onViewMore(element)" tabindex="0">
            <mat-icon>remove_red_eye</mat-icon>
          </button>
          <ng-container *ngIf="!isEditing && atLeastOneOptionAvailable(element)">
            <button mat-icon-button aria-label="Quick Access Menu" color="primary" [matMenuTriggerFor]="actionMenu" tabindex="0">
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #actionMenu="matMenu">
              <ng-container>
                <button mat-menu-item aria-label="Amend" *ngIf="canAmend(element)" (click)="onEdit(element)" tabindex="0">Amend</button>
                <button
                  mat-menu-item
                  aria-label="Include In Trial"
                  *ngIf="canIncludeTrialPlacement(element)"
                  (click)="updateTrialPlacement(element, true)"
                  tabindex="0"
                >
                  Include In Trial
                </button>
                <button
                  mat-menu-item
                  aria-label="Remove From Trial"
                  *ngIf="canRemoveTrialPlacement(element)"
                  (click)="updateTrialPlacement(element, false)"
                  tabindex="0"
                >
                  Remove From Trial
                </button>
                <button *ngIf="canUndo(element)" mat-menu-item aria-label="Undo" (click)="onRemove(element, true)" tabindex="0">
                  Undo
                </button>
                <button *ngIf="canRemove(element)" mat-menu-item aria-label="Delete Icon" (click)="onRemove(element)" tabindex="0">
                  Remove
                </button>
                <button
                  mat-menu-item
                  aria-label="View Audit Log"
                  *ngIf="canViewAuditLog(element)"
                  (click)="viewAuditLog(element)"
                  tabindex="0"
                >
                  View Audit Log
                </button>
                <button *ngIf="canEnd(element)" mat-menu-item aria-label="End" (click)="onEnd(element)" tabindex="0">End</button>
                <button
                  mat-menu-item
                  aria-label="Enter end reason"
                  *ngIf="checkServiceIsEnding(element, true) && canEnterEndReason && !hasOpenAmendment && !anyEndDatesInPast(element)"
                  (click)="enterEndReason(element)"
                  tabindex="0"
                >
                  Enter end reason
                </button>
              </ng-container>
              <button
                mat-menu-item
                aria-label="Change Providers"
                *ngIf="!anyEndDatesEqualOrPast(element)"
                (click)="onChangeProviders(element)"
                tabindex="0"
              >
                Change Providers
              </button>
            </mat-menu>
            <ng-container *ngIf="hasOpenAmendment">
              <div class="fake-icon-button" *ngIf="!element.isComplete">
                <mat-icon class="icon-incomplete" aria-label="Warning">warning</mat-icon>
              </div>
            </ng-container>
            <ng-container *ngIf="anyEndDatesInPast(element)">
              <div class="fake-icon-button">
                <mat-icon aria-labelledby="Check">check</mat-icon>
              </div>
            </ng-container>

            <div *ngIf="element.amendmentEndDate && !element.isActive" class="fake-icon-button">
              <i class="fas fa-calendar-times icon-incomplete"></i>
            </div>
          </ng-container>
          <ng-container *ngIf="checkServiceIsEnding(element)">
            <div class="fake-icon-button">
              <mat-icon
                class="icon-incomplete"
                matTooltip="This service ends in less than 10 days."
                aria-labelledby="This service ends in less than 10 days."
              >
                watch_later
              </mat-icon>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              iepIncludesTrialPlacement &&
              ((element.includesTrialPlacement && trialPlacementTarget === trialPlacementTargetEnum.SomeItems) ||
                trialPlacementTarget === trialPlacementTargetEnum.AllItems)
            "
          >
            <div class="fake-icon-button fake-icon-button--primary">
              <mat-icon aria-labelledby="Hourglass empty">hourglass_empty</mat-icon>
            </div>
          </ng-container>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="service">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Service</th>
      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.amendmentReasonId || anyEndDatesInPast(element)"
          [priorVersionId]="element?.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="element?.serviceType?.label"
          [currentVersionText]="element?.priorVersion?.serviceType?.label"
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </td>
    </ng-container>

    <ng-container matColumnDef="serviceType">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Service Type</th>
      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.amendmentReasonId || anyEndDatesInPast(element)"
          [priorVersionId]="element?.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="getServiceType(element?.customServiceDate)"
          [currentVersionText]="getServiceType(element?.priorVersion?.customServiceDate)"
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        >
        </app-compare-amendment-output>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.amendmentReasonId || anyEndDatesInPast(element)"
          [priorVersionId]="element?.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="element.description"
          [currentVersionText]="element?.priorVersion?.description"
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </td>
    </ng-container>

    <ng-container matColumnDef="otherInformation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Other Information</th>

      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.amendmentReasonId || anyEndDatesInPast(element)"
          [priorVersionId]="element?.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="getOtherInformationText(element)"
          [currentVersionText]="getOtherInformationText(element?.priorVersion)"
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </td>
    </ng-container>

    <ng-container matColumnDef="frequency">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Frequency</th>

      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.amendmentReasonId || anyEndDatesInPast(element)"
          [priorVersionId]="element?.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="getFrequencyText(element)"
          [currentVersionText]="getFrequencyText(element?.priorVersion)"
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </td>
    </ng-container>

    <ng-container matColumnDef="providers">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Provider(s)</th>
      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.amendmentReasonId || anyEndDatesInPast(element)"
          [priorVersionId]="element?.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="getProviderString(element.providers, element.otherProviderName)"
          [currentVersionText]="getProviderString(element?.priorVersion?.providers, element.otherProviderName)"
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="servicesDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: servicesDisplayedColumns"></tr>
  </table>
  <mat-paginator [dataSource]="servicesDataSource"></mat-paginator>
</div>
<p *ngIf="!servicesDataSource">No data yet...</p>

<mat-card class="mt-2" *ngIf="showServicesForm">
  <app-iep-ssaa-services
    #servicesComponent
    [caseSummary]="caseSummary"
    [learner]="caseSummary?.learner"
    [amendments]="amendments"
    [amendingService]="amendingService"
    [amendmentId]="amendment?.id"
    [isPK]="isPK"
    [trialPlacementTarget]="trialPlacementTarget"
    [iepIncludesTrialPlacement]="iepIncludesTrialPlacement"
    [iep]="iepView"
  ></app-iep-ssaa-services>
</mat-card>
