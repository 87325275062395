/* eslint-disable  @typescript-eslint/no-shadow */
import { JL } from 'jsnlog';
import { ILoggerService } from './logger.service';
import JSNLogLogger = JL.JSNLogLogger;
import { Correlation, CorrelationService } from './correlation.service';

export class JsnLoggerService implements ILoggerService {
  constructor(private logger: JSNLogLogger, private loggerName: string, private correlationService: CorrelationService) {}

  beginScope(): Correlation {
    return this.correlationService.begin();
  }
  endScope(): void {
    this.correlationService.end();
  }
  cancelScope(): void {
    this.correlationService.cancel();
  }
  /**
   * Sends message to console.log.
   */
  log(message: any) {
    this.logger.info(this.addLoggerInfo(message));
  }

  /**
   * Sends message to console.info.
   */
  info(
    name: string,
    customProperties?: {
      [key: string]: any;
    }
  ) {
    const message = { name, customProperties };
    this.logger.info(this.addLoggerInfo(message));
  }

  /**
   * Sends message to console.warn.
   */
  warn(message: any) {
    this.logger.warn(this.addLoggerInfo(message));
  }

  /**
   * Sends message to console.error.
   */
  error(message: any) {
    this.logger.error(this.addLoggerInfo(message));
  }
  addLoggerInfo(message: any): any {
    return Object.assign(
      { message: message },
      {
        logger: this.loggerName,
        correlation: this.correlationService.current ? this.correlationService.current?.id : null,
      }
    );
  }
}
