import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import { forkJoin, of, timer } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FeatureFlagService } from 'src/app/shared/components/feature-flags/feature-flag.service';
import { ProceduralSafeguardsRead } from 'src/app/shared/components/procedural-safeguards/procedural-safeguards';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ConsentStatus } from 'src/app/shared/models/fiie-consent/consent-status';
import { FamilyConsentStatus } from 'src/app/shared/models/fiie-consent/family-consent-status';
import { Principal } from 'src/app/shared/models/principal';
import { User } from 'src/app/shared/models/user';
import { HelpArea, HelpSection } from 'src/app/shared/services/help/help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import { FiieConsentHelp } from 'src/app/shared/services/help/models/evaluation.help';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { minimumCount, phoneValidator } from 'src/app/shared/validators';
import { SignedFormService } from 'src/app/signed-forms/signed-form.service';
import { ProceduralSafeguardsListComponent } from '../../shared/components/procedural-safeguards/procedural-safeguards-list.component';
import { CaseSummary } from '../../shared/models/case';
import { EvaluationDomain } from '../../shared/models/evaluation-domain';
import { FiieConsentReadForm } from '../../shared/models/fiie-consent/fiie-consent-read';
import { ContactPeople, FiieConsentUpdateForm } from '../../shared/models/fiie-consent/fiie-consent-update';
import { KeyValuePair } from '../../shared/models/key-value-pair';
import { TeamUser } from '../../shared/models/team';
import { ConsentFormService } from '../../shared/services/consent-form/consent-form.service';
import { DisabilitySuspectService } from '../disability-suspect/services/disability-suspect.service';

@Component({
  selector: 'app-fiie-consent-form',
  templateUrl: './fiie-consent-form.component.html',
  styleUrls: ['./fiie-consent-form.component.scss'],
})
export class FiieConsentFormComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;

  @Input()
  currentCase: CaseSummary;
  willPrint = false;
  readonly = false;
  shortDateFormat = shortDateFormat;
  pageTitle: string;
  helpSection = HelpSection;
  fiieConsentHelp = FiieConsentHelp;
  activeCall = false;

  get isValid() {
    return this.formGroup.valid;
  }

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private consentFormService: ConsentFormService,
    private disabilitySuspectFormService: DisabilitySuspectService,
    private cdRef: ChangeDetectorRef,
    private helpService: HelpService,
    private learnerService: LearnerService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly signedFormService: SignedFormService,
    private readonly notifications: NotificationService
  ) {}

  fiieConsentForm: FiieConsentReadForm;
  ownerOfACase: User;
  caseSummary: CaseSummary;
  participants: { id: string; fullName: string }[] = [];
  submitAttempted: boolean;
  newStatus: ConsentStatus;
  updatedDate: Date = new Date();
  today = new Date();
  minDate = new Date();
  dsFinalizedDate: Date;
  domains: EvaluationDomain[];
  domainOptions: KeyValuePair[];
  participantOptions: KeyValuePair[];
  dsParticipants: TeamUser[];
  displayedColumns = ['contactPerson', 'position', 'email', 'phone'];
  contactPeopleSource = new MatTableDataSource<ContactPeople>();
  mostRecentProceduralSafeguard: ProceduralSafeguardsRead;
  principal: Principal;

  yesNoOptions: KeyValuePair[] = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];
  availableContacts: KeyValuePair[] = [];

  formGroup = new FormGroup({
    evaluationExplanation: new FormControl('', [Validators.required]),
    evaluationFacilitatorId: new FormControl('', [Validators.required]),
    otherOptionsAndReasonsForRejection: new FormControl('', [Validators.required]),
    otherFactorsRelevant: new FormControl(null, [Validators.required]),
    otherFactors: new FormControl(''),
    statuses: new FormControl(''),
    domains: new FormControl([], [minimumCount(1)]),
    contactName: new FormControl(''),
    notificationDate: new FormControl(null, [Validators.required]),
    alternateContact: new FormControl(null),
    reviewedById: new FormControl(''),
    reviewedHow: new FormControl(''),
    reviewedOn: new FormControl(''),
  });

  proceduralSafeguard: ProceduralSafeguardsRead;

  ngOnInit(): void {
    this.today.setDate(this.today.getDate());
    this.minDate.setDate(this.today.getDate() - 365);

    const [currentCase, domains, proceduralSafeguards] = this.route.snapshot.data.data as [
      CaseSummary,
      EvaluationDomain[],
      ProceduralSafeguardsRead[]
    ];
    this.pageTitle = 'Consent for and Notice of Full and Individual Initial Evaluation - ' + currentCase.learner.fullName;
    this.domains = domains.filter((x) => x.isPartB);
    this.domainOptions = this.domains.sort((a, b) => (a.label > b.label ? 1 : -1)).map((x) => new KeyValuePair(x.id, x.label));

    this.currentCase = currentCase;
    proceduralSafeguards.sort((a, b) => {
      return a > b ? -1 : 1;
    });
    this.proceduralSafeguard = proceduralSafeguards.length > 0 ? proceduralSafeguards[0] : undefined;

    this.formGroup.controls.otherFactorsRelevant.valueChanges.subscribe((change) => {
      if (change) {
        this.formGroup.controls.otherFactors.setValidators(Validators.required);
      } else {
        this.formGroup.controls.otherFactors.setValidators(null);
        this.formGroup.controls.otherFactors.setValue(null);
      }
    });

    forkJoin([
      this.consentFormService.getFiieConsentForm(currentCase.fiieConsentFormId, currentCase.id),
      this.disabilitySuspectFormService.getForm(this.currentCase.disabilitySuspectFormId),
      this.learnerService.getPrincipal(this.currentCase.learnerId).pipe(
        catchError(() => {
          return of(undefined);
        })
      ),
    ]).subscribe(([fiieConsent, dsForm, principal]) => {
      this.fiieConsentForm = fiieConsent;
      this.consentFormService.openedFiieConsentForm(this.currentCase.id, this.fiieConsentForm.id).subscribe((res) => {});
      const currentStatus = this.fiieConsentForm.statuses[0].status;
      if (currentStatus === FamilyConsentStatus.Approved || currentStatus === FamilyConsentStatus.Requested) {
        this.readonly = true;
        this.openFiieConsentFormPdf(this.fiieConsentForm.signedFormId, true);
      }

      if (principal) {
        this.principal = principal;
        this.availableContacts.push(new KeyValuePair(principal?.name, principal?.name));
      }

      this.dsFinalizedDate = dsForm.submittedOn;

      this.dsParticipants = dsForm.participants;

      dsForm.participants.forEach((p) => {
        const participant = {
          id: p.userId,
          fullName: p.fullName,
        };
        this.participants.push(participant);
      });
      if (!this.participants.find((p) => p.id === currentCase.caseOwnerId)) {
        this.participants.push({
          id: currentCase.caseOwnerId,
          fullName: currentCase.caseOwner.fullName,
        });
      }

      this.participantOptions = this.participants.map((x) => new KeyValuePair(x.id, x.fullName));

      this.availableContacts = this.availableContacts.concat(dsForm.participants.map((x) => new KeyValuePair(x.fullName, x.fullName)));

      // Autocomplete wrappers don't populate until next event cycle
      // after participantOptions are populated...so setTimeout
      const myTimer = timer(500).subscribe(() => {
        this.populateForm(principal);
        if (proceduralSafeguards.length === 0) {
          this.accordion?.openAll();
        }
        myTimer.unsubscribe();
      });
    });

    this.cdRef.detectChanges(); // For autocomplete patch
  }

  onChanged(e) {
    this.mostRecentProceduralSafeguard = e;
    if (!e) {
      this.accordion.openAll();
    } else {
      if (dayjs(e.providedOn).isBefore(dayjs().subtract(1, 'year'))) {
        this.accordion.openAll();
      } else {
        this.accordion.closeAll();
      }
    }
  }

  populateForm(principal: Principal) {
    this.formGroup.patchValue({
      evaluationExplanation: this.fiieConsentForm.evaluationExplanation,
      evaluationFacilitatorId: this.fiieConsentForm.evaluationFacilitator?.id,
      otherOptionsAndReasonsForRejection: this.fiieConsentForm.otherOptionsAndReasonsForRejection,
      otherFactors: this.fiieConsentForm.otherFactors,
      otherFactorsRelevant: this.fiieConsentForm.otherFactorsRelevant,
      statuses: this.fiieConsentForm.statuses,
      domains: this.fiieConsentForm.consentDomains || [],
      reviewedById: this.proceduralSafeguard?.providedBy?.id,
      reviewedOn: this.proceduralSafeguard?.providedOn,
      reviewedHow: this.proceduralSafeguard?.howProvided.label,
      notificationDate: this.fiieConsentForm.notificationDate,
      contactName: this.fiieConsentForm.contactName,
      alternateContact: this.fiieConsentForm.alternateContact,
      alternateContactPosition: this.fiieConsentForm.alternateContactPosition,
      alternateContactName: this.fiieConsentForm.alternateContactName,
      alternateContactEmail: this.fiieConsentForm.alternateContactEmail,
      alternateContactPhone: this.fiieConsentForm.alternateContactPhone,
    });

    if (this.fiieConsentForm.alternateContact) {
      this.addAlternativeContact();
      this.formGroup.patchValue({
        alternateContactName: this.fiieConsentForm.alternateContactName,
        alternateContactEmail: this.fiieConsentForm.alternateContactEmail,
        alternateContactPhone: this.fiieConsentForm.alternateContactPhone,
        alternateContactPosition: this.fiieConsentForm.alternateContactPosition,
      });
    }
    this.populateTableData(principal);
  }

  domainChanged(domain: EvaluationDomain) {
    const domainsCtrl = this.formGroup.controls.domains;
    domainsCtrl.markAsTouched();
    let currentValues = (domainsCtrl.value as string[]) || [];
    if (currentValues.includes(domain.id)) {
      currentValues = currentValues.filter((x) => x !== domain.id);
    } else {
      currentValues?.push(domain.id);
    }
    domainsCtrl.patchValue(currentValues);
  }

  isChecked(domain: EvaluationDomain) {
    return this.formGroup.controls.domains.value.includes(domain.id);
  }

  getSortedDomains(ids: string[]) {
    ids = ids || [];
    const domains = ids.map((x) => {
      return {
        id: x,
        label: this.domainOptions.find((y) => y.key === x)?.value,
      };
    });
    return domains.sort((a, b) => (a.label > b.label ? 1 : -1));
  }

  updateTouchedAndValidity() {
    this.formGroup.updateValueAndValidity();
    this.formGroup.markAllAsTouched();
  }

  async openFiieConsentFormPdf(signedFormId: string, initial?: boolean) {
    if (signedFormId) {
      const pdf = await this.signedFormService.getSignedFormPdf(signedFormId);
      if (!pdf?.url) {
        this.notifications.alert('Error opening consent form');
        return;
      }

      window.open(pdf.url);
    }

    if (!initial && signedFormId) {
      this.routingService.documentation(this.currentCase.learnerId);
    }
  }

  async onSubmit() {
    /* todo Whenever Sean finishes the saving of the pdf's and electronic signatures
       We must save that pdf each time we submit this fiie consent form to
       each status's document section. Look at after dialog closed line 167
       family-consent.component.ts to see where we save the consent status documents
       at.
    */
    this.submitAttempted = true;
    this.updateTouchedAndValidity();

    if (this.isValid) {
      this.activeCall = true;
      const fiieConsent = this.formGroup.value as FiieConsentUpdateForm;
      if (fiieConsent.contactName) {
        const isPrincipalContact = fiieConsent.contactName === this.principal?.email || fiieConsent.contactName === this.principal?.name;
        const dsContactSelected = this.dsParticipants.find(
          (x) => x.email === fiieConsent.contactName || x.fullName === fiieConsent.contactName
        );
        fiieConsent.contactEmail = isPrincipalContact ? this.principal.email : dsContactSelected?.email;
        fiieConsent.contactPhone = isPrincipalContact ? this.principal.phone : dsContactSelected?.phone;
        fiieConsent.contactPosition = isPrincipalContact ? this.principal.title : dsContactSelected?.jobTitle;
        fiieConsent.contactName = isPrincipalContact ? this.principal.name : dsContactSelected?.fullName;
      }

      fiieConsent.isSubmitted = true;
      fiieConsent.submittedOn = dayjs().startOf('day').toDate();

      this.consentFormService.updateFiieConsentForm(fiieConsent, this.fiieConsentForm.id, this.currentCase.id).subscribe(() => {
        if (this.featureFlagService.featureOn('outputConsentPwnFiieEnabled')) {
          this.consentFormService
            .createFiieConsentPdf(this.currentCase?.id, this.fiieConsentForm?.id)
            .subscribe(async (signedFormId: string) => {
              await this.openFiieConsentFormPdf(signedFormId);
              this.activeCall = false;
              this.readonly = true;
            });
        } else {
          this.activeCall = false;
          this.readonly = true;
        }
      });
    }
  }

  onSaveAndClose() {
    this.activeCall = true;
    const fiieConsent = this.formGroup.value as FiieConsentUpdateForm;
    if (fiieConsent.contactName) {
      const isPrincipalContact = fiieConsent.contactName === this.principal?.email || fiieConsent.contactName === this.principal?.name;
      const dsContactSelected = this.dsParticipants.find(
        (x) => x.email === fiieConsent.contactName || x.fullName === fiieConsent.contactName
      );
      fiieConsent.contactEmail = isPrincipalContact ? this.principal.email : dsContactSelected?.email;
      fiieConsent.contactPhone = isPrincipalContact ? this.principal.phone : dsContactSelected?.phone;
      fiieConsent.contactPosition = isPrincipalContact ? this.principal.title : dsContactSelected?.jobTitle;
      fiieConsent.contactName = isPrincipalContact ? this.principal.name : dsContactSelected?.fullName;
    }

    this.consentFormService.updateFiieConsentForm(fiieConsent, this.fiieConsentForm.id, this.currentCase.id).subscribe(() => {
      this.routingService.documentation(this.currentCase.learnerId);
    });
  }

  onTogglePrint(e: Event) {
    e.preventDefault();
    this.willPrint = !this.willPrint;

    if (this.willPrint) {
      // Wait for DOM changes to take affect
      setTimeout(() => {
        window.print();
      }, 500);

      setTimeout(() => {
        // Return to normal DOM once print screen is captured
        this.willPrint = false;
      }, 600);
    }
  }

  getFacilitatorReviewer(id: string) {
    return this.participants.filter((x) => x.id === id)[0];
  }

  onOpenHelp(e: Event) {
    e.preventDefault();

    this.helpService.openHelpModal({
      nestedTerm: 'domains',
      area: HelpArea.DomainsToBeEvaluated,
      canBrowse: false,
      width: '728px',
    } as HelpModalConfig);
  }

  onPrint() {
    window.print();
  }

  addAlternativeContact() {
    if (this.formGroup.controls.alternateContact.value === true) {
      this.formGroup.controls.contact?.setValidators(null);
      this.formGroup.controls.contact?.updateValueAndValidity();

      this.formGroup.addControl('alternateContactName', new FormControl(null, Validators.required));

      this.formGroup.addControl('alternateContactPosition', new FormControl(null, Validators.required));

      this.formGroup.addControl('alternateContactEmail', new FormControl(null, [Validators.required, Validators.email]));

      this.formGroup.addControl('alternateContactPhone', new FormControl(null, [Validators.required, phoneValidator]));
    } else {
      this.formGroup.controls.contact?.setValidators(Validators.required);
      this.formGroup.controls.contact?.updateValueAndValidity();

      this.formGroup.removeControl('alternateContactName');
      this.formGroup.removeControl('alternateContactPosition');
      this.formGroup.removeControl('alternateContactEmail');
      this.formGroup.removeControl('alternateContactPhone');
    }
  }

  private populateTableData(principal: Principal) {
    if (this.formGroup.value.contact) {
      this.contactPeopleSource.data.push({
        contactPerson: principal?.name,
        phone: null,
        position: null,
        email: principal?.email,
      });
    }
    if (this.formGroup.value.alternateContact) {
      this.contactPeopleSource.data.push({
        contactPerson: this.formGroup.controls.alternateContactName.value,
        position: this.formGroup.controls.alternateContactPosition.value,
        email: this.formGroup.controls.alternateContactPhone.value,
        phone: this.formGroup.controls.alternateContactEmail.value,
      });
    }
  }
}
