<app-page-header
  pageTitle="New Child Entry"
  [backgroundColor]="'blue'"
  [helpLinks]="false"
  [showSubmitBtn]="true"
  [submitDisabled]="!formGroup.valid || activeCall"
  (submitToggled)="onSubmit()"
></app-page-header>
<ng-container [formGroup]="formGroup">
  <mat-card *ngIf="isThreeToFive" class="display-flex align-items-center mt-4">
    <mat-icon aria-hidden="false" aria-labelledby="warning ion" color="warn"> warning </mat-icon>
    <span class="mat-hint ml-4">
      Note that it is likely that children already enrolled in a district's preschool program, including in a Statewide Voluntary Preschool
      Program Community Partner site, will have an existing student record. If the student was not found in the Child Search, contact a
      system administrator to determine the status of a student's record. Students with an existing student record are not to be entered via
      this form.
    </span>
  </mat-card>
  <mat-card class="card-spacing-top">
    <mat-card-title>Child Information</mat-card-title>
    <div class="container-fluid container--not-centered container--form">
      <div class="row">
        <div class="col-md-12 col-lg-3">
          <app-text-field label="First Name" formControlName="firstName"></app-text-field>
        </div>
        <div class="col-md-12 col-lg-2">
          <app-text-field label="Middle Name" formControlName="middleName"></app-text-field>
        </div>
        <div class="col-md-12 col-lg-3">
          <app-text-field label="Last Name" formControlName="lastName"></app-text-field>
        </div>
        <div class="col-md-12 col-lg-4">
          <app-date-picker label="DOB" controlName="dateOfBirth" startView="multi-year" [max]="today"></app-date-picker>
          <mat-error *ngIf="formGroup.controls.dateOfBirth.touched && !validBirthday" class="error--position-fix">
            Child must be
            <strong>under 6 if not in kindergarten and under 5 otherwise</strong>
          </mat-error>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-xl-3 col-lg-4">
          <fieldset>
            <app-radio-group label="Gender" [options]="genderOptions" formControlName="genderId"></app-radio-group>
          </fieldset>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12">
          <app-autocomplete
            label="Primary Language Spoken in Home"
            [options]="languageOptions"
            formControlName="languageSpokenInHomeId"
          ></app-autocomplete>
        </div>
        <div class="col-md-12 col-xl-3 col-lg-4">
          <fieldset>
            <app-radio-group label="Interpreter Needed?" [options]="yesNoOptions" formControlName="interpreterNeeded"></app-radio-group>
          </fieldset>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="card-spacing-top">
    <mat-card-title>Residential Information</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-md-12 col-lg">
          <app-text-field label="Street Address" formControlName="streetAddress"></app-text-field>
        </div>
        <div class="col-md-12 col-lg">
          <app-text-field label="Zip Code" formControlName="zipCode" (blurMe)="populateLocationFromZipCode()"></app-text-field>
        </div>
        <div class="col-md-12 col-lg">
          <app-text-field label="City/Town" formControlName="city"></app-text-field>
        </div>
        <div class="col-md-12 col-lg-4 col-xl-3">
          <app-autocomplete label="State" formControlName="state" [options]="usStateOptions"></app-autocomplete>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg" *ngIf="!isThreeToFive">
          <app-text-field label="Child's County" formControlName="county"></app-text-field>
        </div>
        <div class="col-md-12 col-lg-4">
          <app-autocomplete
            label="Child's Resident District"
            formControlName="residentDistrictId"
            [options]="districtOptions"
          ></app-autocomplete>
        </div>
        <div class="col-md-12 col-lg-4">
          <app-autocomplete
            label="Child's Attending District"
            formControlName="attendingDistrictId"
            [options]="districtOptions"
          ></app-autocomplete>
        </div>
        <div class="col-md-12 col-lg-4" *ngIf="isSixOrOlder">
          <app-autocomplete
            label="Building - Please choose an attending district first"
            formControlName="attendingBuildingId"
            [options]="buildingOptions"
          ></app-autocomplete>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="card-spacing-top" *ngIf="!isThreeToFive">
    <mat-card-title>Child Care</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row mt-3">
        <div class="col-md-12">
          <fieldset>
            <app-radio-group
              label="Does child attend child care? If unknown, leave this section blank."
              formControlName="attendsChildCare"
              [options]="yesNoOptions"
            ></app-radio-group>
          </fieldset>
        </div>
      </div>
      <div class="container-fluid" *ngIf="formGroup.get('attendsChildCare').value">
        <div class="row">
          <div class="col-md-12 col-lg-4">
            <app-text-field label="Name of Child Care Provider" formControlName="childCareProviderName"></app-text-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-4">
            <app-text-field label="Street Address" formControlName="childCareProviderStreetAddress"></app-text-field>
          </div>
          <div class="col-md-12 col-lg-2">
            <app-text-field
              label="Zip Code"
              formControlName="childCareProviderZipCode"
              (blurMe)="populateChildCareLocationFromZip()"
            ></app-text-field>
          </div>
          <div class="col-md-12 col-lg-2">
            <app-text-field label="City" formControlName="childCareProviderCity"></app-text-field>
          </div>
          <div class="col-md-12 col-lg-2">
            <app-autocomplete label="State" formControlName="childCareProviderState" [options]="usStateOptions"></app-autocomplete>
          </div>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="card-spacing-top" *ngIf="isThreeToFive">
    <mat-card-title>Early Childhood Environments</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-md-12 col-lg">
          <div class="vertical-control">
            <label class="text-md vertical-label">Where does the child spend time? Check all that apply:</label>
            <div class="button-toggle-group">
              <mat-button-toggle
                class="mr-2"
                [checked]="formGroup.controls.childsHome.value"
                [ngClass]="{
                  'background-color--secondary text-white': formGroup.controls.childsHome.value === true
                }"
                (change)="buttonToggleChanged($event)"
                value="childsHome"
              >
                Child's Home
              </mat-button-toggle>
              <mat-button-toggle
                class="mr-2"
                [checked]="formGroup.controls.otherFamilyHome.value"
                [ngClass]="{
                  'background-color--secondary text-white': formGroup.controls.otherFamilyHome.value === true
                }"
                (change)="buttonToggleChanged($event)"
                value="otherFamilyHome"
              >
                Other Family Member's Home
              </mat-button-toggle>
              <mat-button-toggle
                class="mr-2"
                [checked]="formGroup.controls.childcareCenter.value"
                [ngClass]="{
                  'background-color--secondary text-white': formGroup.controls.childcareCenter.value === true
                }"
                (change)="buttonToggleChanged($event)"
                value="childcareCenter"
              >
                Child Care Center or Family Child Care Home
              </mat-button-toggle>
              <mat-button-toggle
                class="mr-2"
                [checked]="formGroup.controls.headStart.value"
                [ngClass]="{
                  'background-color--secondary text-white': formGroup.controls.headStart.value === true
                }"
                (change)="buttonToggleChanged($event)"
                value="headStart"
              >
                Head Start
              </mat-button-toggle>
              <mat-button-toggle
                class="mr-2"
                [checked]="formGroup.controls.preschool.value"
                [ngClass]="{
                  'background-color--secondary text-white': formGroup.controls.preschool.value === true
                }"
                (change)="buttonToggleChanged($event)"
                value="preschool"
              >
                Preschool
              </mat-button-toggle>
              <mat-button-toggle
                class="mr-2"
                [checked]="formGroup.controls.playgroup.value"
                [ngClass]="{
                  'background-color--secondary text-white': formGroup.controls.playgroup.value === true
                }"
                (change)="buttonToggleChanged($event)"
                value="playgroup"
              >
                Playgroup
              </mat-button-toggle>
              <mat-button-toggle
                class="mr-2"
                [checked]="formGroup.controls.otherEnvironment.value"
                [ngClass]="{
                  'background-color--secondary text-white': formGroup.controls.otherEnvironment.value === true
                }"
                (change)="buttonToggleChanged($event)"
                value="otherEnvironment"
              >
                Other
              </mat-button-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>

  <section class="card-spacing-top">
    <app-family-member
      [formGroup]="parent1"
      [parentForm]="formGroup"
      [isPk]="isThreeToFive"
      cardTitle="Parent 1"
      [familyMemberType]="familyMemberType.Parent1"
      [isParent]="true"
      [relationshipOptions]="relationshipOptions"
      [referralHowHearAboutUsOptions]="referralHowHearAboutUsOptions"
      [howHearAboutUsOtherId]="howHearAboutUsOtherId"
    ></app-family-member>
  </section>

  <section class="card-spacing-top">
    <app-family-member
      [formGroup]="parent2"
      [parentForm]="formGroup"
      [isPk]="isThreeToFive"
      cardTitle="Parent 2"
      [isParent]="true"
      [familyMemberType]="familyMemberType.Parent2"
      [relationshipOptions]="relationshipOptions"
      [referralHowHearAboutUsOptions]="referralHowHearAboutUsOptions"
      [howHearAboutUsOtherId]="howHearAboutUsOtherId"
    ></app-family-member>
  </section>

  <section class="card-spacing-top" *ngIf="livesWith1?.enabled">
    <app-family-member
      [formGroup]="livesWith1"
      [parentForm]="formGroup"
      [isPk]="isThreeToFive"
      cardTitle="Lives With 1"
      [familyMemberType]="familyMemberType.LivesWith1"
      [relationshipOptions]="relationshipOptions"
      [referralHowHearAboutUsOptions]="referralHowHearAboutUsOptions"
    ></app-family-member>
  </section>

  <section class="card-spacing-top" *ngIf="livesWith2?.enabled">
    <app-family-member
      [formGroup]="livesWith2"
      [parentForm]="formGroup"
      [isPk]="isThreeToFive"
      cardTitle="Lives With 2"
      [familyMemberType]="familyMemberType.LivesWith2"
      [relationshipOptions]="relationshipOptions"
      [referralHowHearAboutUsOptions]="referralHowHearAboutUsOptions"
    ></app-family-member>
  </section>

  <mat-card class="card-spacing-top" *ngIf="!isThreeToFive">
    <mat-card-title>Others in Home</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg">
          <label class="vertical-label" for="othersInHome">
            Please describe the other people in the home. It is helpful to include each person's name, age, and relationship to the child.
          </label>
          <app-textarea formControlName="othersInHome" id="othersInHome"></app-textarea>
        </div>
      </div>
    </div>
  </mat-card>

  <section class="card-spacing-top" *ngIf="referralSourceInfo?.enabled && !isThreeToFive">
    <app-referral-source-info-form
      [formGroup]="referralSourceInfo"
      [parentForm]="formGroup"
      [referralSourceCategoryOptions]="referralSourceCategoryOptions"
      [referralHowHearAboutUsOptions]="referralHowHearAboutUsOptions"
      [howHearAboutUsOtherId]="howHearAboutUsOtherId"
    ></app-referral-source-info-form>
  </section>

  <section class="card-spacing-top">
    <mat-card class="card-spacing-bottom" *ngIf="!isThreeToFive">
      <div class="container container--not-centered container--form">
        <div class="row">
          <div class="col-lg">
            <app-checkbox-single
              formControlName="eligibleInLegacySystemPartC"
              aria-labelledby="Learner was found eligible in the legacy system"
              label="Learner was found eligible in the legacy system"
            ></app-checkbox-single>
          </div>
        </div>
      </div>
    </mat-card>
    <ng-container *ngIf="referralReasonInfo && (isThreeToFive || (!isThreeToFive && !eligibleInLegacySystemPartC))">
      <app-referral-reason-info-form
        [formGroup]="referralReasonInfo"
        [isThreeToFive]="isThreeToFive"
        [screeningToolOptions]="screeningToolOptions"
        [screeningToolOtherId]="screeningToolOtherId"
      >
      </app-referral-reason-info-form>
    </ng-container>
  </section>

  <mat-error *ngIf="submitAttempted && !formGroup.valid" class="mb-4 text-sm"> Some input is <b>invalid</b>, see above </mat-error>

  <mat-card class="card-spacing-top" *ngIf="!!referralId && referral?.documentationInfo?.referralDocuments.length > 0">
    <mat-card-title>Documents</mat-card-title>
    <table class="table table-bordered">
      <tbody>
        <tr *ngFor="let document of referral?.documentationInfo?.referralDocuments">
          <th>{{ document.title }}</th>
          <td>
            <a
              href="/api/early-access-referrals/{{ referralId }}/documents/{{ document.id }}"
              target="_blank"
              tabindex="0"
              role="link"
              attr.aria-label="{{ document.fileName }}"
              >{{ document.fileName }}</a
            >
          </td>
          <td>
            <button
              color="primary"
              type="button"
              (click)="addToConsentList(document)"
              [disabled]="documentInConsent(document)"
              mat-raised-button
              aria-label="Add to Consent List"
              tabindex="0"
            >
              Add to Consent List
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card>

  <mat-card class="card-spacing-top" *ngIf="isFromChildSearch && isThreeToFive">
    <p>If either of the following conditions apply, please check an applicable option.</p>
    <p>
      <app-checkbox-single
        formControlName="movingFromOutOfState"
        [disabledState]="disableMovingFromOutOfState"
        attr.aria-label="Learner is moving in from out-of-state and has an active IEP."
        label="Learner is moving in from out-of-state and has an active IEP."
      ></app-checkbox-single>
      <app-checkbox-single
        formControlName="eligibleInLegacySystem"
        [disabledState]="disableEligibleInLegacySystem"
        attr.aria-label="Learner was found eligible in the legacy system."
        label="Learner was found eligible in the legacy system."
      ></app-checkbox-single>
    </p>
  </mat-card>

  <mat-dialog-actions align="end" class="mt-2">
    <div class="display-flex justify-content-end align-items-center">
      <button mat-raised-button aria-label="Show Required" color="accent" class="mr-2" (click)="formGroup.markAllAsTouched()" tabindex="0">
        Show Required
      </button>
      <button
        [disabled]="!formGroup.valid"
        color="primary"
        (click)="onSubmit()"
        mat-raised-button
        aria-label="Complete"
        [isBusy]="activeCall"
        tabindex="0"
      >
        Complete
      </button>
    </div>
  </mat-dialog-actions>
</ng-container>

<div class="page-bottom-leeway"></div>
