import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AuthService } from '../../../auth/auth.service';
import { ShareCaseComponent } from '../../../child-find/modals/share-case/share-case.component';
import { TransferCaseComponent } from '../../../child-find/modals/transfer-case/transfer-case.component';
import { CaseSummary, IntakeType } from '../../../shared/models/case';
import { LearnerInviteModalComponent } from '../learner-invite-modal/learner-invite-modal.component';

@Component({
  selector: 'app-case-owner-header',
  templateUrl: 'case-owner-header.component.html',
  styleUrls: ['case-owner-header.component.scss'],
})
export class CaseOwnerHeaderComponent {
  intakeTypeEnum = IntakeType;
  shortDateFormat = shortDateFormat;

  @Input() public caseSummary: CaseSummary;
  @Input() learnerHasWorkableCase = true;
  @Output() public caseShared = new EventEmitter();

  isBusy = false;

  get transferredUser() {
    return this.caseSummary.scheduledTransferredUserInfo.transferredUser.fullName;
  }

  public get canShare(): boolean {
    return this.authService.canShareCase(this.caseSummary?.id, this.caseSummary?.intakeType) && !this.caseSummary?.exitFinalizedOn;
  }

  public get canTransfer(): boolean {
    return this.authService.canTransferCase(this.caseSummary?.id, this.caseSummary?.intakeType) && !this.caseSummary?.exitFinalizedOn;
  }

  get isPortalUser() {
    return this.authService.isPortalUser;
  }

  constructor(
    private dialog: MatDialog,
    public authService: AuthService,
    private notificationService: NotificationService,
    private learnerService: LearnerService
  ) {}

  async onShareCase(caseSummary: CaseSummary): Promise<void> {
    const dialogRef = this.dialog.open(ShareCaseComponent, {
      data: caseSummary.id,
    });
    await dialogRef.afterClosed().toPromise();
    this.caseShared.emit();
  }

  onTransferCase(caseSummary: CaseSummary) {
    this.dialog.open(TransferCaseComponent, {
      data: caseSummary.id,
    });
  }
}
