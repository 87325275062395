<!--suppress XmlDuplicatedId -->
<mat-form-field class="w-100">
  <mat-label
    [ngClass]="{
      asterisk_input: control.hasError('required'),
      'asterisk_input--hide': hideAsterisk
    }"
    >{{ label }}</mat-label
  >
  <input
    matInput
    type="number"
    [class]="klass"
    id="{{ controlId }}"
    [formControl]="control"
    [placeholder]="placeholder"
    [readonly]="readOnly"
    [attr.disabled]="disabledState ? true : null"
    [attr.min]="min"
    [attr.max]="max"
    (blur)="internalBlur($event)"
    attr.aria-required="{{ control.hasError('required') ? 'true' : 'false' }}"
  />
  <span matPrefix *ngIf="useInputGroup">
    <ng-content select="[prepend]"></ng-content>
  </span>
  <span matSuffix *ngIf="useInputGroup">
    <ng-content select="[append]"></ng-content>
  </span>

  <mat-error class="word-wrap">
    <app-field-error-display [control]="control"></app-field-error-display>
  </mat-error>
</mat-form-field>
