<ng-container *ngIf="dataSource.data.length === 0">
  <div class="display-flex align-items-center justify-content-between flex-wrap">
    <p class="my-0">No Accommodations added yet</p>
    <button
      mat-raised-button
      aria-labelledby="Add new Accommodation"
      color="primary"
      class="my-0"
      (click)="goToAccommodation()"
      *ngIf="isProfilePage"
      tabindex="0"
    >
      Add new Accommodation
    </button>
  </div>
</ng-container>

<section *ngIf="dataSource.data.length > 0">
  <h4 class="mt-0 mb-2 text-primary">Summary of Accommodations</h4>
  <div class="table-overflow app-table">
    <table mat-table [dataSource]="dataSource" class="w-100" matSort>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="table-actions mat-column-actions--more">Actions</th>
        <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
          <div class="display-flex align-items-center justify-content-start">
            <button mat-icon-button aria-label="View Icon" color="primary" (click)="onView(element)" tabindex="0">
              <mat-icon>remove_red_eye</mat-icon>
            </button>
            <button mat-icon-button aria-label="Edit Icon" color="primary" *ngIf="!isEditing" (click)="onEdit(element)" tabindex="0">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button aria-label="Delete Icon" color="warn" *ngIf="!isEditing" (click)="onRemove(element)" tabindex="0">
              <mat-icon>close</mat-icon>
            </button>
            <div class="fake-icon-button" *ngIf="!element.isComplete">
              <mat-icon class="icon-incomplete" aria-label="Warning">warning</mat-icon>
            </div>
            <div
              *ngIf="
                iepIncludesTrialPlacement &&
                ((element.includesTrialPlacement && trialPlacementTarget === trialPlacementTargetEnum.SomeItems) ||
                  trialPlacementTarget === trialPlacementTargetEnum.AllItems)
              "
            >
              <div class="fake-icon-button fake-icon-button--primary">
                <mat-icon aria-labelledby="Hourglass empty">hourglass_empty</mat-icon>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element">
          <app-compare-amendment-output
            class="mr-1"
            [tableData]="true"
            [amendmentId]="amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [isActive]="element?.isActive"
            [changedVersionText]="element.description"
            [currentVersionText]="element.priorVersion?.description"
            [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          >
          </app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="frequencyPeriod">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Frequency</th>
        <td mat-cell *matCellDef="let element">
          <app-compare-amendment-output
            class="mr-1"
            [tableData]="true"
            [amendmentId]="amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [isActive]="element?.isActive"
            [changedVersionText]="element.frequencyPeriod"
            [currentVersionText]="element.priorVersion?.frequencyPeriod"
            [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          >
          </app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="providers">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Provider(s)</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.providers?.length > 0 || element.otherProvider">
            <app-compare-amendment-output
              class="mr-1"
              [tableData]="true"
              [amendmentId]="amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [isActive]="element?.isActive"
              [changedVersionText]="getProviderNames(element.providers, element.otherProviderName)"
              [currentVersionText]="getProviderNames(element.priorVersion?.providers, element.priorVersion?.otherProviderName)"
              [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </ng-container>
          <ng-container
            *ngIf="(element.providers?.length === 0 && !element.otherProvider) || (element.otherProvider && !element.otherProviderName)"
          >
            -
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [dataSource]="dataSource"></mat-paginator>
  </div>
  <p *ngIf="!dataSource">No data yet...</p>
</section>
