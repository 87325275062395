<div class="prefill w-fit">
  <div class="prefill__input">
    <app-date-picker
      [label]="label"
      [controlName]="controlName"
      [max]="max"
      [min]="min"
      [startView]="startView"
      [startAt]="startAt"
      [disabled]="disabled"
    >
    </app-date-picker>
  </div>

  <div class="prefill__text" *ngIf="dataSourceValue">QR: {{ dataSourceValue | dateFormat }}</div>

  <div
    class="prefill__container prefill__container--offset"
    (click)="replaceWithSourceValue()"
    *ngIf="dataSourceValue && !undoValue && !disabled"
  >
    <div class="prefill__icon">
      <mat-icon aria-label="QR Arrow Symbol">call_merge</mat-icon>
    </div>
  </div>

  <div class="prefill__container prefill__container--undo prefill__container--offset" *ngIf="undoValue">
    <p class="prefill__icon mb-0" (click)="undoReplaceWithSourceValue()">Undo</p>
  </div>
</div>
