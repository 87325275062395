import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import { ProgressMonitorBService } from 'src/app/monitoring-progress/services/progress-monitor-b.service';
import { MultiGraphViewModalComponent } from 'src/app/shared/components/multi-graph-view-modal/multi-graph-view-modal.component';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { NewWindowConfig, openNewWindow } from 'src/app/shared/windowHelpers';
import { DialogComingSoonComponent } from '../../../../../shared/components/coming-soon/coming-soon.component';
import { BooleanYesNoPipe } from '../../../../../shared/pipes/boolean-yesno.pipe';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { IepAmendmentEndItemModalComponent } from '../../../../modals/iep-amendment-end-item-modal/iep-amendment-end-item-modal.component';
import { IepGoal } from '../../../../models/iep';
import { IepAmendment } from '../../../../models/iep-amendment';
import { IepGoalService } from '../../../../services/iep-goal.service';
import { IepService } from '../../../../services/iep.service';
import { IepChangeProviderComponent } from '../../../../shared/iep-change-provider/iep-change-provider.component';
import { IepGoalsViewMoreComponent } from '../../../iep-goals/iep-goals-view-more/iep-goals-view-more.component';
import { IepGoalsComponent } from '../../../iep-goals/iep-goals.component';
import { IepGoalsInfoComponent } from './iep-goals-info/iep-goals-info.component';

@Component({
  selector: 'app-iep-details-goals',
  templateUrl: './iep-details-goals.component.html',
  styleUrls: ['./iep-details-goals.component.scss'],
  providers: [DatePipe, BooleanYesNoPipe],
})
export class IepDetailsGoalsComponent implements OnInit {
  iepId: string;
  @Input() amendments: IepAmendment[];
  @Input() isPK = false;
  @Input() learnerId;
  @ViewChild('goalsComponent') goalsComponent: IepGoalsComponent;

  displayedColumns = [
    'actions',
    'goalDomain',
    'goalSentence',
    'monitorFreq',
    /*'reportProgress',*/
  ];

  dataSource = new MatTableDataSource<IepGoal>([]);
  shortDateFormat = shortDateFormat;
  showGoalsForm = false;
  amendingGoal: IepGoal;
  isEditing = false;
  behaviorAddressed = false;

  get amendment() {
    return this.amendments?.length > 0 ? this.amendments[0] : null;
  }

  get amendmentIsFinalized() {
    return this.amendment?.finalizeDate !== null;
  }

  get lastFinalizedDate() {
    if (this.amendments && this.amendments.length > 0) {
      const latest = this.amendments.reduce((r, a) => {
        return r.finalizeDate > a.finalizeDate ? r : a;
      });
      return latest?.finalizeDate;
    }
    return null;
  }

  get hasOpenAmendment() {
    return !!this.amendment && !this.amendmentIsFinalized;
  }

  get amendmentId() {
    return this.amendment?.id;
  }

  constructor(
    private route: ActivatedRoute,
    private iepGoalService: IepGoalService,
    private dialog: MatDialog,
    private yesNoPipe: BooleanYesNoPipe,
    private datePipe: DatePipe,
    private notificationService: NotificationService,
    private iepService: IepService,
    private progressMonitorBService: ProgressMonitorBService
  ) {}

  ngOnInit(): void {
    this.iepId = this.route.snapshot.paramMap.get('iepId');
    this.dataSource = new MatTableDataSource([]);
    this.getGoals();

    this.iepGoalService.goalsUpdated$.subscribe(() => this.getGoals());
    this.iepGoalService.goalAddNew$.subscribe(() => {
      this.showGoalsForm = true;
      this.isEditing = true;
      this.amendingGoal = null;
    });
    this.iepGoalService.goalClose$.subscribe(() => {
      this.showGoalsForm = false;
      this.isEditing = false;
    });

    this.checkBehaviorSpecialFactor();
    this.iepService.plaafpUpdated$.subscribe(() => {
      this.checkBehaviorSpecialFactor();
    });
  }

  getGoals() {
    this.iepGoalService.getAllForAmendment(this.iepId, this.amendment?.id).subscribe((goals) => {
      this.dataSource.data = goals;
    });
  }

  addNew() {
    this.iepGoalService.goalAddNew.next();
  }

  anyEndDatesInPast(goal) {
    const amendmentEndDate = dayjs(goal.amendmentEndDate).toDate().setHours(23, 59, 59, 9999);
    const today = dayjs().toDate().setHours(0, 0, 0, 9999);
    return amendmentEndDate < today && goal.isActive;
  }

  anyEndDatesEqualOrPast(goal) {
    const amendmentEndDate = dayjs(goal.amendmentEndDate).toDate().setHours(23, 59, 59, 9999);
    const today = dayjs().toDate().setHours(0, 0, 0, 9999);
    return amendmentEndDate <= today;
  }

  canAmend(element) {
    return this.hasOpenAmendment && !this.anyEndDatesEqualOrPast(element) && !this.isEditing;
  }

  canEnd(element) {
    return (
      this.hasOpenAmendment && !this.anyEndDatesInPast(element) && (element?.isActive || (!element?.isActive && !!element?.priorVersionId))
    );
  }

  canRemove(element) {
    return (
      this.hasOpenAmendment && !this.anyEndDatesInPast(element) && !element?.priorVersionId && element.amendmentId === this.amendmentId
    );
  }

  canUndo(element) {
    return this.hasOpenAmendment && !this.anyEndDatesInPast(element) && element?.priorVersionId && element.amendmentId === this.amendmentId;
  }

  canViewAuditLog(element) {
    return (
      this.hasOpenAmendment &&
      !this.anyEndDatesInPast(element) &&
      !this.isEditing &&
      (element?.isActive || element?.priorVersionId) &&
      !element?.amendmentEndDate
    );
  }

  onEdit(goal: IepGoal) {
    const callback = (_goal: IepGoal) => {
      this.amendingGoal = _goal;
      this.isEditing = true;
      this.showGoalsForm = true;
      this.iepGoalService.setAmendingGoal.next(this.amendingGoal);
    };
    if (goal.amendmentId === null || goal.amendmentId !== this.amendmentId) {
      this.iepGoalService.amend(this.iepId, goal.id, this.amendment.id).subscribe((amendResult) => {
        callback(amendResult);
      });
    } else {
      callback(goal);
    }
  }

  viewAuditLog(goal) {
    const dialogRef = this.dialog.open(IepGoalsInfoComponent, {
      width: '758px',
      data: {
        goal,
        id: goal.id,
        iepId: this.iepId,
      },
    });
  }

  onEnd(element) {
    this.dialog.open(IepAmendmentEndItemModalComponent, {
      width: '740px',
      data: {
        iepId: this.iepId,
        amendmentId: this.amendment?.id,
        type: 'Goal',
        model: element,
      },
    });
  }

  onRemove(goal, isUndo = false) {
    this.notificationService.confirmation(
      `Are you sure${isUndo ? '? This will delete this change.' : ' you want to delete this goal?'} `,
      () => {
        this.iepGoalService.delete(this.iepId, goal.id, true).subscribe(() => {
          this.notificationService.success(`Goal ${isUndo ? 'reverted' : 'deleted'}`);
        });
      }
    );
  }

  openComingSoon() {
    this.dialog.open(DialogComingSoonComponent, {
      width: '990px',
    });
  }

  onOpenGraph(goal) {
    this.progressMonitorBService.getSingle(this.learnerId, goal.id).subscribe({
      next: (res) => this.dialog.open(MultiGraphViewModalComponent, { data: { isIep: true, iepGoal: res }, width: '728px' }),
      error: (err) =>
        this.notificationService.errorWithAction("Couldn't open graph", 'Why?', () =>
          this.notificationService.alert(err.error, "Couldn't open graph")
        ),
    });
  }

  getGoalDomainText(goal: IepGoal) {
    let returnText = '';
    if (goal) {
      const goalAreas: string[] = [];
      goal.goalAreas.forEach((area) => {
        goalAreas.push(area.label ? area.label : area.domain);
      });
      returnText += goalAreas?.join(', ');
    }

    return returnText;
  }

  getFrequencyAmountText(element) {
    let returnText = '';
    if (element) {
      if (element.frequencyAmount && element.frequencyPeriod) {
        returnText += element.frequencyAmount + ' times ' + element.frequencyPeriod;
      }
    }

    return returnText;
  }

  onChangeProviders(element) {
    const dialogRef = this.dialog.open(IepChangeProviderComponent, {
      data: {
        id: element.id,
        caseId: this.route.snapshot.paramMap.get('caseId'),
        iepId: this.iepId,
        type: 'IEPGoal',
        dataElement: element,
      },
      width: '364px',
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.getGoals();
      }
    });
  }

  private getMethodsToReportLabel(reportingMethods: string[], otherMethod: string) {
    let modifiedMethods = reportingMethods?.join(', ');
    if (!!modifiedMethods && modifiedMethods.includes('Other')) {
      if (modifiedMethods.includes(', Other')) {
        modifiedMethods = modifiedMethods.replace(', Other', `, ${otherMethod}`);
      } else {
        modifiedMethods = modifiedMethods.replace('Other', `${otherMethod}`);
      }
    }
    return modifiedMethods;
  }

  /// ToDo: The following method is duplicated in these 3 places:
  /// * IepGoalsComponent.getModalData()
  /// * IepDetailsGoalsComponent.getModalData()
  /// * GoalPlanService.getModalData()
  private getModalData(goal): any {
    const isSecondary = (arr): any[] => {
      return arr ? arr.filter((x) => x.secondary) : [];
    };
    const notSecondary = (arr): any[] => {
      return arr ? arr.filter((x) => !x.secondary) : [];
    };
    const sortScale = (a: { rating: number }, b: { rating: number }) => {
      if (a && b) {
        if (a.rating > b.rating) return 1;
        if (a.rating < b.rating) return -1;
      }
      return 0;
    };

    const quantDataIdsNotToIncludeForAdditional = [];
    quantDataIdsNotToIncludeForAdditional.push(goal.primaryQuantifiableData?.id);
    const modalData: any = {
      goalArea: goal.goalAreas.map((x) => x.label)?.join(', '),
      currentLevelDescription: goal.currentLevelDescription,
      standardOfComparison: goal.standardOfComparisonOther ? goal.standardOfComparisonOther : goal.standardOfComparison,
      descriptionOfCondition: goal.conditionIndividualWillPerform,
      nickname: goal.goalNickName,
      primaryMilestone: [
        {
          firstMeasurement: {
            id: goal.primaryQuantifiableData?.primaryMeasurement?.id,
            baseline: goal.primaryQuantifiableData?.primaryMeasurement?.baseline,
            isActive: goal.primaryQuantifiableData?.primaryMeasurement?.isActive,
            amendmentId: goal.primaryQuantifiableData?.primaryMeasurement?.amendmentId,
            priorVersionId: goal.primaryQuantifiableData?.primaryMeasurement?.priorVersionId,
            amendmentEndDate: goal.primaryQuantifiableData?.primaryMeasurement?.amendmentEndDate,
            dataPoints: goal.primaryQuantifiableData?.primaryMeasurement?.dataPoints?.map((dp, index) => ({
              name: `Data Point ${index + 1}:`,
              priorVersionId: dp.priorVersionId,
              isActive: dp.isActive,
              amendmentId: dp.amendmentId,
              amendmentEndDate: dp.amendmentEndDate,
              value: [
                {
                  name: 'Score',
                  value: dp.score,
                  nested: true,
                },
                {
                  name: 'Date',
                  value: this.datePipe.transform(dp.date, shortDateFormat),
                },
              ],
            })),
            unitOfMeasurement: goal.primaryQuantifiableData?.primaryMeasurement?.unitOfMeasurement,
            learnersGoalTarget: goal.primaryQuantifiableData?.primaryMeasurement?.learnersGoalTarget,
            tieredMilestones: notSecondary(goal.primaryQuantifiableData?.tieredMilestones)?.map((tm) => ({
              name: `Tiered Milestone Number: ${tm.number}:`,
              priorVersionId: tm.priorVersionId,
              isActive: tm.isActive,
              amendmentId: tm.amendmentId,
              amendmentEndDate: tm.amendmentEndDate,
              value: [
                {
                  name: 'Target',
                  value: tm.target,
                },
                {
                  name: 'End Date',
                  value: this.datePipe.transform(tm.endDate, shortDateFormat),
                },
              ],
            })),
            scale: notSecondary(goal.primaryQuantifiableData?.scaleRatings)
              ?.sort(sortScale)
              ?.map((s) => ({
                name: 'Scale:',
                priorVersionId: s.priorVersionId,
                isActive: s.isActive,
                amendmentId: s.amendmentId,
                amendmentEndDate: s.amendmentEndDate,
                value: [
                  {
                    name: 'Rating',
                    value: s.rating,
                  },
                  {
                    name: 'Description',
                    value: s.description,
                  },
                ],
              })),
          },
          secondMeasurement: {
            id: goal.primaryQuantifiableData?.secondaryMeasurement?.id,
            baseline: goal.primaryQuantifiableData?.secondaryMeasurement?.baseline,
            isActive: goal.primaryQuantifiableData?.secondaryMeasurement?.isActive,
            amendmentId: goal.primaryQuantifiableData?.secondaryMeasurement?.amendmentId,
            priorVersionId: goal.primaryQuantifiableData?.secondaryMeasurement?.priorVersionId,
            amendmentEndDate: goal.primaryQuantifiableData?.secondaryMeasurement?.amendmentEndDate,
            dataPoints: goal.primaryQuantifiableData?.secondaryMeasurement?.dataPoints?.map((dp, index) => ({
              name: `Data Point ${index + 1}:`,
              priorVersionId: dp.priorVersionId,
              value: [
                {
                  name: 'Score',
                  value: dp.score,
                },
                {
                  name: 'Date',
                  value: this.datePipe.transform(dp.date, shortDateFormat),
                },
              ],
            })),
            unitOfMeasurement: goal.primaryQuantifiableData?.secondaryMeasurement?.unitOfMeasurement,
            learnersGoalsTarget: goal.primaryQuantifiableData?.secondaryMeasurement?.learnersGoalTarget,
            tieredMilestones: isSecondary(goal.primaryQuantifiableData?.tieredMilestones)?.map((tm) => ({
              name: `Tiered Milestone Number: ${tm.number}:`,
              priorVersionId: tm.priorVersionId,
              value: [
                {
                  name: 'Target',
                  value: tm.target,
                },
                {
                  name: 'End Date',
                  value: this.datePipe.transform(tm.endDate, shortDateFormat),
                },
              ],
            })),
            scale: isSecondary(goal.primaryQuantifiableData?.scaleRatings)
              ?.sort(sortScale)
              ?.map((s) => ({
                name: 'Scale:',
                priorVersionId: s.priorVersionId,
                value: [
                  {
                    name: 'Rating',
                    value: s.rating,
                  },
                  {
                    name: 'Description',
                    value: s.description,
                  },
                ],
              })),
          },
        },
      ],
      additionalMilestones: goal.additionalQuantifiableData
        .filter((x) => !quantDataIdsNotToIncludeForAdditional.includes(x.id))
        ?.map((am) => ({
          id: am.id,
          name: `Milestone Number ${am.milestoneNumber}`,
          status: am.status,
          description: am.description,
          isActive: am.isActive,
          amendmentId: am.amendmentId,
          priorVersionId: am.priorVersionId,
          amendmentEndDate: am.amendmentEndDate,
          firstMeasurement: {
            baseline: am.primaryMeasurement?.baseline,
            priorVersionId: am.primaryMeasurement.priorVersionId,
            dataPoints: am?.primaryMeasurement?.dataPoints?.map((dp, index) => ({
              name: `Data Point ${index + 1}:`,
              isActive: dp.isActive,
              amendmentId: dp.amendmentId,
              priorVersionId: dp.priorVersionId,
              amendmentEndDate: dp.amendmentEndDate,
              value: [
                {
                  name: 'Score',
                  value: dp.score,
                },
                {
                  name: 'Date',
                  value: this.datePipe.transform(dp.date, shortDateFormat),
                },
              ],
            })),
            unitOfMeasurement: am.primaryMeasurement?.unitOfMeasurement,
            learnersGoalTarget: am.primaryMeasurement?.learnersGoalTarget,
            tieredMilestones: notSecondary(am.tieredMilestones)?.map((tm) => ({
              name: `Tiered Milestone Number: ${tm.number}:`,
              isActive: tm.isActive,
              amendmentId: tm.amendmentId,
              priorVersionId: tm.priorVersionId,
              amendmentEndDate: tm.amendmentEndDate,
              value: [
                {
                  name: 'Target',
                  value: tm.target,
                },
                {
                  name: 'End Date',
                  value: this.datePipe.transform(tm.endDate, shortDateFormat),
                },
              ],
            })),
            scale: notSecondary(am.scaleRatings)
              ?.sort(sortScale)
              ?.map((s) => ({
                name: 'Scale:',
                isActive: s.isActive,
                amendmentId: s.amendmentId,
                priorVersionId: s.priorVersionId,
                amendmentEndDate: s.amendmentEndDate,
                value: [
                  {
                    name: 'Rating',
                    value: s.rating,
                  },
                  {
                    name: 'Description',
                    value: s.description,
                  },
                ],
              })),
          },
          secondMeasurement: {
            baseline: am.secondaryMeasurement?.baseline,
            priorVersionId: am.secondaryMeasurement.priorVersionId,
            dataPoints: am?.secondaryMeasurement?.dataPoints?.map((dp, index) => ({
              name: `Data Point ${index + 1}:`,
              isActive: dp.isActive,
              amendmentId: dp.amendmentId,
              priorVersionId: dp.priorVersionId,
              amendmentEndDate: dp.amendmentEndDate,
              value: [
                {
                  name: 'Score',
                  value: dp.score,
                },
                {
                  name: 'Date',
                  value: this.datePipe.transform(dp.date, shortDateFormat),
                },
              ],
            })),
            unitOfMeasurement: am.secondaryMeasurement?.unitOfMeasurement,
            learnersGoalTarget: am.secondaryMeasurement?.learnersGoalTarget,
            tieredMilestones: isSecondary(am.tieredMilestones)?.map((tm) => ({
              name: `Tiered Milestone Number: ${tm.number}:`,
              isActive: tm.isActive,
              amendmentId: tm.amendmentId,
              priorVersionId: tm.priorVersionId,
              amendmentEndDate: tm.amendmentEndDate,
              value: [
                {
                  name: 'Target',
                  value: tm.target,
                },
                {
                  name: 'End Date',
                  value: this.datePipe.transform(tm.endDate, shortDateFormat),
                },
              ],
            })),
            scale: isSecondary(am.scaleRatings)
              ?.sort(sortScale)
              ?.map((s) => ({
                name: 'Scale:',
                isActive: s.isActive,
                amendmentId: s.amendmentId,
                priorVersionId: s.priorVersionId,
                amendmentEndDate: s.amendmentEndDate,
                value: [
                  {
                    name: 'Rating',
                    value: s.rating,
                  },
                  {
                    name: 'Description',
                    value: s.description,
                  },
                ],
              })),
          },
        })),
      howOftenProgressMeasured:
        goal.frequencyAmount && goal.frequencyPeriod ? `${goal.frequencyAmount} times per ${goal.frequencyPeriod}` : null,
      responsibleForMonitoring: goal.peopleNamesMonitoringGoal?.join(', '),
      collaborators: goal.collaborators,
      descriptionOfMonitoring: goal.monitoringProceduresDescription,
      howOftenReported: goal.sameRateAsPeersReported
        ? goal.sameRateFrequency && goal.sameRatePeriod
          ? `At the same rate as typical peers ${goal.sameRateFrequency} times per ${goal.sameRatePeriod}`
          : null
        : goal.differentRateFrequency && goal.differentRatePeriod
        ? `At the different rate as typical peers ${goal.differentRateFrequency} times per ${goal.differentRatePeriod}`
        : null,
      methodsToReport: this.getMethodsToReportLabel(goal.methodsToReport, goal.otherMethodToReport),
      isActive: goal.isActive,
      amendmentId: goal.amendmentId,
      priorVersionId: goal.priorVersionId,
      amendmentEndDate: goal.amendmentEndDate,
    };

    return modalData;
  }

  onViewGoal(goal) {
    const modalData = this.getModalData(goal);
    if (goal.priorVersionId !== null) {
      modalData.priorVersion = this.getModalData(goal.priorVersion);
    } else {
      modalData.priorVersion = null;
    }

    this.dialog.open(IepGoalsViewMoreComponent, {
      width: '728px',
      data: {
        goal: modalData,
        amendment: {
          amendmentId: this.amendmentId,
          amendmentIsFinalized: this.amendmentIsFinalized,
          lastFinalizedDate: this.lastFinalizedDate,
        },
      },
    });
  }

  checkBehaviorSpecialFactor() {
    this.iepService.getPlaafp(this.iepId, this.amendmentId).subscribe((x) => {
      this.behaviorAddressed = x.behaviorAddressed;
    });
  }

  openTags() {
    const config: NewWindowConfig = {
      path: `tags/cases/${this.route.snapshot.paramMap.get('caseId')}/goals`,
      popup: true,
    };
    openNewWindow(config);
  }
}
