import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { OutcomeProgressMonitor, ProgressMonitorPlanStatus } from 'src/app/monitoring-progress/models/outcome-progress-monitor';
import { ProgressMonitorCService } from 'src/app/monitoring-progress/services/progress-monitor-c.service';
import { AppPermissions } from 'src/app/permissions';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { CaseSummary } from 'src/app/shared/models/case';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { ProgressMonitorPlanStatusService } from '../../../../monitoring-progress/services/progress-monitor-plan-status.service';
import { RoutingService } from '../../../../shared/services/routing.service';

export function unique(array, propertyName) {
  return array.filter((e, i) => array.findIndex((a) => a[propertyName] === e[propertyName]) === i);
}

@Component({
  selector: 'app-ifsp-data-monitoring-progress',
  templateUrl: './ifsp-data-monitoring-progress.component.html',
  styleUrls: ['./ifsp-data-monitoring-progress.component.scss'],
})
export class IfspDataMonitoringProgressComponent implements OnInit, OnDestroy {
  @Input() caseSummary: CaseSummary;
  @Input() enableForms = true;
  subscriptions = new Subscription();
  outcomes: Array<OutcomeProgressMonitor> = [];
  planStatuses: Array<ProgressMonitorPlanStatus>;
  filteredPlanStatuses: Array<ProgressMonitorPlanStatus>;
  filteredOutcomes: Array<OutcomeProgressMonitor> = [];
  shortDateFormat = shortDateFormat;
  permissions = AppPermissions;

  planWorkingDataSource = new MatTableDataSource<any>();
  planWorkingDisplayedColumns = ['actions', 'date', 'summary'];

  criteriaDataSource = new MatTableDataSource<any>();
  criteriaDisplayedColumns = ['actions', 'date', 'value', 'notes'];
  displayedColumnsMilestones = ['completionDate', 'description', 'value'];
  displayedColumnsScaleRatings = ['rating', 'description'];

  chart = {
    data: [],
    labels: [''],
    colors: [
      {
        backgroundColor: ['rgba(38, 63, 140, 0.2)', 'rgba(245, 214, 142, 1)', 'rgba(41, 99, 28, 0.3)'],
      },
    ],
    legend: false,
  };

  //#region Filter
  ifspOptions: KeyValuePair[] = [];
  serviceOptions: KeyValuePair[] = [];
  statusOptions: KeyValuePair[] = [
    new KeyValuePair('all', 'All Outcomes'),
    new KeyValuePair('complete', 'Completed Outcomes'),
    new KeyValuePair('active', 'Active Outcomes'),
  ];
  providerOptions: KeyValuePair[] = [];
  transitionOptions: KeyValuePair[] = [
    new KeyValuePair('all', 'All '),
    new KeyValuePair('transition', 'Transition Related'),
    new KeyValuePair('non-transition', 'Non-transition Related'),
  ];
  searchString: string;

  formGroupFilter = this.fb.group({
    ifsp: '',
    service: '',
    status: 'all',
    provider: '',
    transition: 'all',
    searchString: '',
    quickDate: null,
  });

  //#endregion

  constructor(
    private fb: FormBuilder,
    private routingService: RoutingService,
    private progressMonitorService: ProgressMonitorCService,
    private planStatusService: ProgressMonitorPlanStatusService
  ) {}

  ngOnInit(): void {
    this.load();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  //#region Private Methods

  private load() {
    this.subscriptions.add(
      this.progressMonitorService.get(this.caseSummary.learnerId).subscribe((outcomes) => {
        this.outcomes = outcomes;
        this.outcomes.forEach((outcome) => {
          this.determineOutcomeCriteriaComplete(outcome.id);
        });
        this.setFilters();
        this.watchFilters();
        this.subscriptions.add(
          this.progressMonitorService.outcomeCriterionChanged.subscribe((outcomeId) => {
            this.determineOutcomeCriteriaComplete(outcomeId);
          })
        );
        this.subscriptions.add(
          this.planStatusService.get(this.caseSummary.learnerId).subscribe((res) => {
            this.planStatuses = res;
            this.filteredPlanStatuses = res;
          })
        );
        this.filteredOutcomes = outcomes;
      })
    );
  }

  private determineOutcomeCriteriaComplete(outcomeId: string) {
    const outcome = this.outcomes.find((x) => x.id === outcomeId);
    if (outcome) {
      outcome.allCriteriaComplete = outcome.criteria.every((criterion) => criterion.progressData.some((item) => item.completed));
    }
  }

  private setFilters() {
    const services = this.outcomes.flatMap((x) => x.services).map((x) => new KeyValuePair(x.serviceId, x.service));
    this.serviceOptions = unique(services, 'key').sort((a, b) => {
      return a.value ? a.value.localeCompare(b.value) : -1;
    });
    const providers = this.outcomes.flatMap((x) => x.services).map((x) => new KeyValuePair(x.providerId, x.provider));
    this.providerOptions = unique(providers, 'key').sort((a, b) => {
      return a.value ? a.value.localeCompare(b.value) : -1;
    });
  }

  private watchFilters() {
    this.formGroupFilter.valueChanges.pipe(debounceTime(100)).subscribe((value) => {
      this.filteredOutcomes = JSON.parse(JSON.stringify(this.outcomes));
      this.filteredPlanStatuses = JSON.parse(JSON.stringify(this.planStatuses));

      if (value.searchString) {
        this.filteredOutcomes = this.filteredOutcomes.filter((x) => x.name.toLowerCase().includes(value.searchString.toLowerCase()));
      }

      if (value.status) {
        switch (value.status) {
          case 'complete':
            this.filteredOutcomes = this.filteredOutcomes.filter((x) => x.allCriteriaComplete);
            break;
          case 'active':
            this.filteredOutcomes = this.filteredOutcomes.filter((x) => !x.allCriteriaComplete && !x.ended);
            break;
          default:
            break;
        }
      }

      if (value.service) {
        this.filteredOutcomes = this.filteredOutcomes.filter((x) => x.services.some((y) => y.serviceId === value.service));
      }

      if (value.provider) {
        this.filteredOutcomes = this.filteredOutcomes.filter((x) => x.services.some((y) => y.providerId === value.provider));
      }

      if (value.transition) {
        switch (value.transition) {
          case 'transition':
            this.filteredOutcomes = this.filteredOutcomes.filter((x) => x.relatedToTransition);
            break;
          case 'non-transition':
            this.filteredOutcomes = this.filteredOutcomes.filter((x) => !x.relatedToTransition);
            break;
          default:
            break;
        }
      }

      if (value.quickDate && value.quickDate.dateShortcut !== 'AllTime') {
        this.filteredOutcomes.forEach((outcome) => {
          outcome.criteria.forEach((criterion) => {
            criterion.progressData.forEach((item) => {
              console.log(item.createdOn);
              if (dayjs(item.date).isBefore(value.quickDate.startDate) || dayjs(item.date).isAfter(value.quickDate.endDate)) {
                criterion.progressData = criterion.progressData.filter((x) => x.id !== item.id);
              }
            });
            if (criterion.progressData.length === 0) {
              outcome.criteria = outcome.criteria.filter((x) => x.id !== criterion.id);
            }
          });
          if (outcome.criteria.length === 0) {
            this.filteredOutcomes = this.filteredOutcomes.filter((x) => x.id !== outcome.id);
          }
        });

        this.filteredPlanStatuses = this.filteredPlanStatuses.filter(
          (x) => dayjs(x.date).isAfter(dayjs(value.quickDate.startDate)) && dayjs(x.date).isBefore(dayjs(value.quickDate.endDate))
        );
      }
    });
  }

  //#endregion
}
