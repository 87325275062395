import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LearnerCalendarComponent } from '../shared/components/learner-calendar/learner-calendar.component';
import { CanDeactivateGuard } from '../shared/guards/can-deactivate.guard';
import { EerComponent } from './evaluation/eer/eer.component';
import { EvaluationComponent } from './evaluation/evaluation.component';
import { ContinuedEligibilityComponent } from './evaluation/steps/continued-eligibility/continued-eligibility.component';
import { EcoWizardComponent } from './evaluation/steps/eco-wizard/eco-wizard.component';
import { EcoMatrixComponent } from './evaluation/steps/eval-details-part-b/eco-matrix/eco-matrix.component';
import { EvalDetailsPartBGuard, EvaluationGuard } from './evaluation/steps/eval-details-part-b/guards/eval-details-part-b.guard';
import { EvalEcoMatrixComponent } from './evaluation/steps/eval-eco-matrix/eval-eco-matrix.component';
import { EvaluationDetailsComponent } from './evaluation/steps/evaluation-details/evaluation-details.component';
import { EvaluationEligibilityWorksheetComponent } from './evaluation/steps/evaluation-eligibility-worksheet/evaluation-eligibility-worksheet.component';
import { EvaluationOverviewComponent } from './evaluation/steps/evaluation-overview/evaluation-overview.component';
import { EvaluationSummaryComponent } from './evaluation/steps/evaluation-summary/evaluation-summary.component';
import { FamilySurveyComponent } from './family-survey/family-survey.component';
import { FamilySurveyResolver } from './family-survey/family-survey.resolver';
import { ConsentReleaseExchangeDocumentComponent } from './shared/components/health-info-form/provider-info-form/consent-release-exchange-document/consent-release-exchange-document.component';
import { ICDPhysicianLetterComponent } from './shared/components/health-info-form/provider-info-form/icd-physician-letter/icd-physician-letter.component';

const routes: Routes = [
  {
    path: ':caseId/icd-physician-letter',
    component: ICDPhysicianLetterComponent,
    children: [
      {
        path: ':section',
        component: ICDPhysicianLetterComponent,
      },
      {
        path: ':section/:id',
        component: ICDPhysicianLetterComponent,
      },
    ],
  },
  {
    path: ':caseId/consent-release-exchange-document',
    component: ConsentReleaseExchangeDocumentComponent,
    children: [
      {
        path: ':section',
        component: ConsentReleaseExchangeDocumentComponent,
      },
      {
        path: ':section/:id',
        component: ConsentReleaseExchangeDocumentComponent,
      },
    ],
  },
  {
    path: ':evaluationId/eer/:stage',
    component: EerComponent,
  },
  {
    path: ':evaluationId',
    component: EvaluationComponent,
    children: [
      {
        path: 'overview',
        component: EvaluationOverviewComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'details-part-c',
        component: EvaluationDetailsComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'summary-part-c',
        component: EvaluationSummaryComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'continued-eligibility',
        component: ContinuedEligibilityComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'summary-part-b',
        component: EvaluationEligibilityWorksheetComponent,
        canActivate: [EvalDetailsPartBGuard],
      },
      {
        path: 'eco-matrix',
        component: EvalEcoMatrixComponent,
      },
      {
        path: 'eco-wizard/:ecoAreaId',
        component: EcoWizardComponent,
      },
      {
        path: 'eer', // todo remove
        component: EerComponent,
      },
      {
        path: 'eco-matrix-part-b',
        component: EcoMatrixComponent,
      },
    ],
    canActivate: [EvaluationGuard],
  },
  {
    path: ':evaluationId/family-survey/:surveyId',
    component: FamilySurveyComponent,
    resolve: {
      survey: FamilySurveyResolver,
    },
  },
  {
    path: ':evaluationId/learner-calendar',
    component: LearnerCalendarComponent,
  },
  {
    path: 'early-access-intake',
    loadChildren: () => import('./early-access-intake/early-access-intake.module').then((mod) => mod.EarlyAccessIntakeModule),
  },
  {
    path: 'early-access-questionnaire',
    loadChildren: () =>
      import('./early-access-questionnaire/early-access-questionnaire.module').then((mod) => mod.EarlyAccessQuestionnaireModule),
  },
  {
    path: ':evaluationId/details-part-b',
    loadChildren: () =>
      import('./evaluation/steps/eval-details-part-b/eval-details-part-b.module').then((mod) => mod.EvalDetailsPartBModule),
    canActivate: [EvaluationGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EvaluationRoutingModule {}
