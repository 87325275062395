<mat-form-field class="w-100 position-relative">
  <mat-label
    [ngClass]="{
      asterisk_input: control.hasError('required') || showAsterisk
    }"
    >{{ label }}</mat-label
  >
  <mat-chip-list #chipList>
    <mat-chip
      *ngFor="let option of chipListCtrl?.value; let indx = index"
      [selectable]="true"
      [removable]="true"
      (removed)="remove(option, indx)"
      aria-label="Cancel"
    >
      {{ option.value }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      #optionInput
      matInput
      attr.aria-required="{{ control.hasError('required') || showAsterisk ? 'true' : 'false' }}"
      [maxLength]="stringSizes.small"
      [formControl]="optionAutocompleteChipsCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedChip($event)">
    <mat-optgroup *ngFor="let group of filteredOptions | async" [label]="group.label">
      <mat-option *ngFor="let option of group.options" [value]="option" role="combobox">
        {{ option.value }}
      </mat-option>
    </mat-optgroup>
  </mat-autocomplete>

  <mat-error>
    <app-field-error-display [control]="control"></app-field-error-display>
  </mat-error>

  <div class="form__carrot" (click)="autoComplete.openPanel()"></div>
</mat-form-field>
