import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { shortDateFormat } from '../../dateTimeHelpers';
import { AreYouSureComponent } from '../are-you-sure-modal/are-you-sure.component';

export interface ResetDate {
  startDate: Date;
  endDate: Date;
  reason: string;
}

@Component({
  selector: 'app-ifsp-iep-reset-date',
  templateUrl: './ifsp-iep-reset-date.component.html',
  styleUrls: ['./ifsp-iep-reset-date.component.scss'],
})
export class IfspIepResetDateComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  shortDateFormat = shortDateFormat;
  startDate: Date;
  endDate: Date;
  isIfsp: boolean;
  isInterim: boolean;
  isIep: boolean;

  formGroup = this.fb.group({
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
    reason: ['', Validators.required],
  });

  constructor(
    private readonly fb: FormBuilder,
    private readonly dialog: MatDialog,
    public dialogRef: MatDialogRef<IfspIepResetDateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { startDate: Date; endDate: Date; isIfsp: boolean; isIep: boolean; isInterim: boolean }
  ) {
    this.isIfsp = data.isIfsp;
    this.isInterim = data.isInterim;
    this.isIep = data.isIep;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
  }

  get startDatesEqual() {
    return dayjs(this.startDate).isSame(dayjs(this.formGroup.get('startDate').value), 'day');
  }

  get endDatesEqual() {
    return dayjs(this.endDate).isSame(dayjs(this.formGroup.get('endDate').value), 'day');
  }

  get minEndDate() {
    return dayjs(this.formGroup.get('startDate').value).toDate();
  }

  get maxEndDate() {
    return dayjs(this.formGroup.get('startDate').value).add(364, 'day').toDate();
  }

  ngOnInit(): void {
    this.patchData();
    this.setFormSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        subQuestion:
          'Changing the dates of a plan will also update other associated dates, such as annual review dates, or custom service dates.  Are you sure that you want to submit this change?',
      },
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.dialogRef.close(this.formGroup.value);
      }
    });
  }

  private patchData() {
    this.formGroup.patchValue({
      startDate: this.startDate,
      endDate: this.endDate,
    });
  }

  private setFormSubscriptions() {
    this.subscriptions.add(
      this.formGroup.get('startDate').valueChanges.subscribe((value) => {
        this.formGroup.clearValidators();
        this.formGroup.updateValueAndValidity({ emitEvent: false });
        if (!this.isInterim) {
          const date = dayjs(value).add(364, 'day').toDate();
          this.formGroup.get('endDate').setValue(date);
        }
        this.setFormValidation();
      })
    );
  }

  // This is to avoid Expression Changed because of the date requirements
  private setFormValidation() {
    this.formGroup.get('startDate').setValidators(Validators.required);
    this.formGroup.get('endDate').setValidators(Validators.required);
    this.formGroup.get('reason').setValidators(Validators.required);
    this.formGroup.get('startDate').updateValueAndValidity({ emitEvent: false });
    this.formGroup.get('endDate').updateValueAndValidity({ emitEvent: false });
    this.formGroup.get('reason').updateValueAndValidity({ emitEvent: false });
  }
}
