import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { DialogData } from './upload-modal.models';

@Component({
  templateUrl: './upload-modal.component.html',
  styleUrls: ['./upload-modal.component.scss'],
})
export class UploadModalComponent implements OnInit {
  @ViewChild('fileInput') fileInput;

  formArray: FormArray;

  documentationTypeOptions: KeyValuePair[];
  cardHeader: string;
  fileTitle: string;
  maxFileSize: number;
  maxFileSizeLabel: string;

  allowedFileTypes = 'TXT, CSV, PDF, DOC, DOCX, ODT, PPTX, PPT, RTF, XLS, XLSX, PNG, JPEG, JPG, GIF, BMP';

  listOfFiles: File[];
  errors: string[];

  get formArrayControls() {
    return this.formArray.controls as FormGroup[];
  }

  constructor(
    private dialogRef: MatDialogRef<UploadModalComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    //this.documentationTypeOptions = data.documentationTypes;
    this.fileTitle = data.fileName;
    this.cardHeader = data.title ?? 'Upload Documentation';
    this.allowedFileTypes = data.allowedFileTypes ?? this.allowedFileTypes;
    this.maxFileSize = data.maxFileSize ?? 209715200;
    this.maxFileSizeLabel = data.maxFileSizeLabel ?? '200 MB';
  }

  ngOnInit(): void {
    this.errors = [];
    this.formArray = this.fb.array([]);
  }

  openNativeFileSelector() {
    this.errors = [];
    this.listOfFiles = [];

    if (this.fileInput) {
      this.fileInput.nativeElement.click();
    }
  }

  onFileSelected() {
    const files: { [key: string]: File } = this.fileInput.nativeElement.files;
    for (const key in files) {
      if (!isNaN(parseInt(key))) {
        const file = files[key];
        if (this.isValidFile(file)) {
          this.listOfFiles.push(file);
        }
      }
    }
    if (this.errors.length === 0) {
      this.addFilesToList();
    }
  }

  private isValidFile(file: File): boolean {
    if (!(file && file.name)) {
      this.errors.push('Invalid file or files');
      return false;
    }

    const ext = file.name.split('.').slice(-1)[0];
    if (!ext) {
      this.errors.push('Cannot determine file type');
      return false;
    }

    if (!this.allowedFileTypes.toLowerCase().includes(ext.toLowerCase())) {
      this.errors.push(`File type '${ext}' is not supported`);
      return false;
    }

    if (file.size === 0) {
      this.errors.push(`File cannot be empty: ${file.name}`);
      return false;
    }

    if (file.size > this.maxFileSize) {
      this.errors.push(`File cannot be larger than ${this.maxFileSizeLabel}: ${file.name}`);
      return false;
    }

    return true;
  }

  addFilesToList() {
    const formArray = this.formArray;
    formArray.clear();

    for (const file of this.listOfFiles) {
      formArray.push(
        this.fb.group({
          title: [this.fileTitle, Validators.required],
          type: [''],
          file: [file],
        })
      );
    }
  }

  onRemoveAt(index: number) {
    this.formArray.removeAt(index);
  }

  cancel() {
    this.dialogRef.close();
  }

  confirm() {
    if (this.formArray.invalid) {
      this.formArray.markAllAsTouched();
      return;
    }

    this.dialogRef.close(this.formArray.value);
  }
}
