import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { DeleteReason } from 'src/app/shared/models/delete-reason';
import { FileDocument } from 'src/app/shared/models/file-document';
import { OperationResult } from '../../models/bip';
import {
  Fba,
  FbaAssessmentComment,
  FbaDataCollection,
  FbaHypothesisStatement,
  FbaNonAchieveReviewMemberUpdate,
  FbaReview,
  FbaReviewUser,
  FbaTargetBehavior,
  FbaUser,
} from '../fba';

@Injectable({
  providedIn: 'root',
})
export class FbaService {
  private fbaChange = new ReplaySubject<Fba>(1);
  private _fba: Fba;

  fbaChange$: Observable<Fba> = this.fbaChange.asObservable();

  get fba(): Fba {
    return this._fba;
  }

  set fba(value: Fba) {
    this._fba = value;
    this.fbaChange.next(value);
  }

  constructor(private http: HttpClient) {}

  getFba(fbaId: string): Observable<Fba> {
    return this.http.get<Fba>(`api/fba/${fbaId}`);
  }

  deleteFba(fbaId: string) {
    return this.http.put<any>(`api/fba/delete/${fbaId}`, null);
  }

  getAllFbas(caseId: string): Observable<Fba[]> {
    return this.http.get<Fba[]>(`api/fba/${caseId}/all`);
  }

  getRecentFba(caseId: string): Observable<OperationResult> {
    return this.http.get<OperationResult>(`api/fba/${caseId}/recentFba`);
  }

  addFba(caseId: string, fba: Fba) {
    return this.http.post<Fba>(`api/fba/${caseId}`, fba);
  }

  updateFba(fba: Fba) {
    return this.http.put<Fba>(`api/fba/${fba.id}`, fba);
  }

  finalizeFba(fba: Fba) {
    return this.http.put<Fba>(`api/fba/${fba.id}/finalize`, fba);
  }

  addFbaComment(comment: FbaAssessmentComment, fbaId: string) {
    return this.http.post<FbaAssessmentComment>(`api/fba/${fbaId}/assessment/${comment.fbaAssessmentId}/comment`, comment);
  }

  updateFbaComment(comment: FbaAssessmentComment, fbaId: string) {
    return this.http.put<FbaAssessmentComment>(`api/fba/${fbaId}/assessment/${comment.fbaAssessmentId}/comment`, comment);
  }

  deleteFbaComment(comment: FbaAssessmentComment, fbaId: string) {
    return this.http.put<FbaAssessmentComment>(`api/fba/${fbaId}/assessment/${comment.fbaAssessmentId}/comment/${comment.id}`, null);
  }

  addFbaTargetBehavior(targetBehavior: FbaTargetBehavior, fbaId: string) {
    return this.http.post<FbaTargetBehavior>(
      `api/fba/${fbaId}/assessment/${targetBehavior.fbaAssessmentId}/targetBehavior`,
      targetBehavior
    );
  }

  updateFbaTargetBehavior(targetBehavior: FbaTargetBehavior, fbaId: string) {
    return this.http.put<FbaTargetBehavior>(`api/fba/${fbaId}/assessment/${targetBehavior.fbaAssessmentId}/targetBehavior`, targetBehavior);
  }

  deleteFbaTargetBehavior(targetBehavior: FbaTargetBehavior, fbaId: string) {
    return this.http.put<FbaTargetBehavior>(
      `api/fba/${fbaId}/assessment/${targetBehavior.fbaAssessmentId}/targetBehavior/${targetBehavior.id}`,
      null
    );
  }

  getTeamMembers(fbaId: string): Observable<FbaUser[]> {
    return this.http.get<FbaUser[]>(`api/fba/${fbaId}/teamMembers`);
  }

  updateTeamMembers(fbaId: string, userIds: string[]) {
    return this.http.put<FbaUser[]>(`api/fba/${fbaId}/teamMembers`, {
      userIds,
    });
  }

  removeTeamMember(fbaId: string, userId: string) {
    return this.http.put(`api/fba/${fbaId}/teamMember/${userId}`, null);
  }

  getHypothesisStatement(fbaId: string) {
    return this.http.get<FbaHypothesisStatement[]>(`api/fba/${fbaId}/hypothesisStatement/null`);
  }

  addHypothesisStatement(hypothesisStatement: FbaHypothesisStatement, fbaId: string) {
    return this.http.post<FbaHypothesisStatement>(`api/fba/${fbaId}/hypothesisStatement/null`, hypothesisStatement);
  }

  updateHypothesisStatement(hypothesisStatement: FbaHypothesisStatement, fbaId: string) {
    return this.http.put<FbaHypothesisStatement>(`api/fba/${fbaId}/hypothesisStatement/${hypothesisStatement.id}`, hypothesisStatement);
  }

  deleteHypothesisStatement(hypothesisStatementId: string, fbaId: string) {
    return this.http.put<FbaTargetBehavior>(`api/fba/${fbaId}/hypothesisStatement/${hypothesisStatementId}/delete`, null);
  }

  getFbaInterviewees() {
    return this.http.get<any>('api/fbaInterviewee');
  }

  getFbaTestSelections() {
    return this.http.get<any>('api/fbaTestSelection');
  }

  getFbaObservationData() {
    return this.http.get<any>('api/fbaObservationData');
  }

  getFbaReviewTopics() {
    return this.http.get<any>('api/fbaReviewTopic');
  }

  getDataCollections(fbaId: string) {
    return this.http.get<any>(`api/fba/${fbaId}/dataCollection/null`);
  }

  addDataCollection(dataCollection: FbaDataCollection, fbaId: string) {
    return this.http.post<any>(`api/fba/${fbaId}/dataCollection/null`, dataCollection);
  }

  updateDataCollection(dataCollection: FbaDataCollection, fbaId: string) {
    return this.http.put<any>(`api/fba/${fbaId}/dataCollection/${dataCollection.id}`, dataCollection);
  }

  addReview(review: FbaReview, fbaId: string) {
    return this.http.post<any>(`api/fba/${fbaId}/review`, review);
  }

  updateReview(review: FbaReview, fbaId: string) {
    return this.http.put<any>(`api/fba/${fbaId}/review`, review);
  }

  deleteReview(reviewId: string, fbaId: string) {
    return this.http.put<any>(`api/fba/${fbaId}/review/${reviewId}`, null);
  }

  addReviewUsers(reviewUsers: FbaReviewUser[], fbaReviewId: string, fbaId: string) {
    return this.http.post<any>(`api/fba/${fbaId}/review/${fbaReviewId}/users`, reviewUsers);
  }

  deleteReviewUser(fbaId: string, fbaReviewId: string, userId: string) {
    return this.http.put<any>(`api/fba/${fbaId}/review/${fbaReviewId}/user/${userId}`, null);
  }

  addNonAchieveReviewMembers(nonAchieveMemberName: string, fbaReviewId: string, fbaId: string) {
    return this.http.post<any>(`api/fba/${fbaId}/review/${fbaReviewId}/nonAchieveMember/${nonAchieveMemberName}`, nonAchieveMemberName);
  }

  updateNonAchieveReviewMembers(names: FbaNonAchieveReviewMemberUpdate, fbaReviewId: string, fbaId: string) {
    return this.http.put<any>(`api/fba/${fbaId}/review/${fbaReviewId}/nonAchieveMember`, names);
  }

  deleteNonAchieveMember(fbaId: string, fbaReviewId: string, nonAchieveMemberName: string) {
    return this.http.put<any>(`api/fba/${fbaId}/review/${fbaReviewId}/nonAchieveMember/${nonAchieveMemberName}`, null);
  }

  deleteDataCollection(dataCollectionId: string, fbaId: string) {
    return this.http.put<FbaTargetBehavior>(`api/fba/${fbaId}/dataCollection/${dataCollectionId}/delete`, null);
  }

  uploadDataCollectionDocumentation(uploadFile: any, fbaId: string, dataCollectionId: string) {
    return this.http.post<FileList>(
      `api/fba/${fbaId}/dataCollection/${dataCollectionId}/documents`,
      uploadFile,
      this.getMultipartRequestHeaders()
    );
  }

  getDocuments(fbaId: string, dataCollectionId: string) {
    return this.http.get<FileDocument[]>(`api/fba/${fbaId}/dataCollection/${dataCollectionId}/documents`);
  }

  deleteDocument(fbaId: string, dataCollectionId: string, documentId: string) {
    return this.http.put<FileDocument[]>(`api/fba/${fbaId}/dataCollection/${dataCollectionId}/document/${documentId}`, null);
  }

  uploadFbaAssessmentDocumentation(uploadFile: any, fbaId: string, fbaAssessmentId: string) {
    return this.http.post<any>(`api/fba/${fbaId}/assessment/${fbaAssessmentId}/documents`, uploadFile, this.getMultipartRequestHeaders());
  }

  deleteFbaAssessmentDocumentation(fbaId: string, dataCollectionId: string, documentId: string) {
    return this.http.put<any>(`api/fba/${fbaId}/assessment/${dataCollectionId}/document/${documentId}`, null);
  }

  getIncompleteDataReport(caseId: string, fbaId: string) {
    return this.http.get<any>(`api/fba/${fbaId}/case/${caseId}/incomplete-data-report`);
  }

  protected getMultipartRequestHeaders(): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    const headers = new HttpHeaders({
      'Content-Disposition': 'multipart/form-data',
      Accept: 'application/json, text/plain, */*',
    });

    return { headers };
  }

  cancelFba(fbaId: string, reason: string) {
    const deleteReason: DeleteReason = { id: fbaId, reason: reason };
    return this.http.put<DeleteReason>('api/fba/', deleteReason);
  }
}
