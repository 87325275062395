<button
  mat-flat-button
  aria-label="Add Icon"
  color="primary"
  *ngIf="hasOpenAmendment"
  class="mr-2"
  (click)="$event.stopPropagation(); addNew()"
  tabindex="0"
>
  Add New
</button>

<ng-container *ngIf="dataSource.data.length === 0">No Accommodations added yet</ng-container>

<div *ngIf="dataSource.data.length > 0" class="table-overflow app-table">
  <table mat-table [dataSource]="dataSource" matSort class="w-100">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>

      <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
        <div class="display-flex align-items-center justify-content-start">
          <button aria-label="View Icon" mat-icon-button color="primary" (click)="onViewMore(element)" tabindex="0">
            <mat-icon>remove_red_eye</mat-icon>
          </button>
          <ng-container *ngIf="!isEditing">
            <button mat-icon-button aria-label="Quick Access Menu" color="primary" [matMenuTriggerFor]="actionMenu" tabindex="0">
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #actionMenu="matMenu">
              <button mat-menu-item aria-label="Add Progress Monitoring" (click)="openComingSoon()" tabindex="0">
                Add Progress Monitoring
              </button>
              <button mat-menu-item aria-label=">View Graphs" (click)="openComingSoon()" tabindex="0">View Graphs</button>
              <button mat-menu-item aria-label="Amend" *ngIf="canAmend(element)" (click)="onEdit(element)" tabindex="0">Amend</button>
              <button
                mat-menu-item
                aria-label="Include In Trial"
                *ngIf="canIncludeTrialPlacement(element)"
                (click)="updateTrialPlacement(element, true)"
                tabindex="0"
              >
                Include In Trial
              </button>
              <button
                mat-menu-item
                aria-label="Remove From Trial"
                *ngIf="canRemoveTrialPlacement(element)"
                (click)="updateTrialPlacement(element, false)"
                tabindex="0"
              >
                Remove From Trial
              </button>
              <button *ngIf="canUndo(element)" mat-menu-item aria-label="Undo" (click)="onRemove(element, true)" tabindex="0">Undo</button>
              <button *ngIf="canRemove(element)" mat-menu-item aria-label="Delete Icon" (click)="onRemove(element)" tabindex="0">
                Remove
              </button>
              <button
                mat-menu-item
                aria-label="View Audit Log"
                *ngIf="canViewAuditLog(element)"
                (click)="viewAuditLog(element)"
                tabindex="0"
              >
                View Audit Log
              </button>
              <button
                mat-menu-item
                aria-label="Change Providers"
                *ngIf="!anyEndDatesEqualOrPast(element)"
                (click)="onChangeProviders(element)"
                tabindex="0"
              >
                Change Providers
              </button>
              <button *ngIf="canEnd(element)" (click)="onEnd(element)" mat-menu-item aria-label="End" tabindex="0">End</button>
            </mat-menu>

            <ng-container *ngIf="hasOpenAmendment">
              <div class="fake-icon-button" *ngIf="!element.isComplete">
                <mat-icon class="icon-incomplete" aria-label="Warning">warning</mat-icon>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="anyEndDatesInPast(element)">
            <div class="fake-icon-button">
              <mat-icon aria-labelledby="Check">check</mat-icon>
            </div>
          </ng-container>

          <ng-container *ngIf="element.amendmentEndDate && !element.isActive">
            <div class="fake-icon-button">
              <i class="fas fa-calendar-times icon-incomplete"></i>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              iepIncludesTrialPlacement &&
              ((element.includesTrialPlacement && trialPlacementTarget === trialPlacementTargetEnum.SomeItems) ||
                trialPlacementTarget === trialPlacementTargetEnum.AllItems)
            "
          >
            <div class="fake-icon-button fake-icon-button--primary">
              <mat-icon aria-labelledby="Hourglass empty">hourglass_empty</mat-icon>
            </div>
          </ng-container>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>

      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.amendmentEndDate || anyEndDatesInPast(element)"
          [priorVersionId]="element.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="element.description"
          [currentVersionText]="element?.priorVersion?.description"
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </td>
    </ng-container>

    <ng-container matColumnDef="frequency">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Frequency</th>

      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.amendmentEndDate || anyEndDatesInPast(element)"
          [priorVersionId]="element.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="element.frequencyPeriod"
          [currentVersionText]="element?.priorVersion?.frequencyPeriod"
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </td>
    </ng-container>

    <ng-container matColumnDef="providers">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Provider(s)</th>

      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.amendmentEndDate || anyEndDatesInPast(element)"
          [priorVersionId]="element.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="getProviderString(element.providers, element.otherProviderName)"
          [currentVersionText]="getProviderString(element?.priorVersion?.providers, element?.priorVersion?.otherProviderName)"
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [dataSource]="dataSource"></mat-paginator>
</div>

<mat-card class="mt-2" *ngIf="showAccommodationsForm">
  <app-iep-ssaa-accommodations
    #accommodationsComponent
    [amendments]="amendments"
    [amendingAccommodation]="amendingAccommodation"
    [amendmentId]="amendment?.id"
    [learnerId]="learnerId"
    [iepIncludesTrialPlacement]="iepIncludesTrialPlacement"
    [trialPlacementTarget]="trialPlacementTarget"
  ></app-iep-ssaa-accommodations>
</mat-card>
