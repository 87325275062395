<ng-container *ngIf="servicesDataSource.data.length === 0">
  <div class="display-flex align-items-center justify-content-between flex-wrap">
    <p class="my-0">No Services added yet</p>
    <button
      mat-raised-button
      aria-labelledby="Add New Service"
      color="primary"
      class="my-0"
      (click)="goToServices()"
      *ngIf="isProfilePage"
      tabindex="0"
    >
      Add New Service
    </button>
  </div>
</ng-container>

<section *ngIf="servicesDataSource.data.length > 0">
  <h4 class="mt-0 mb-2 text-primary">Summary of Services</h4>
  <app-help-directional-text>
    <p>The projected start and end dates for each service can be completed only after a roll call has been initiated for this IEP.</p>
  </app-help-directional-text>
  <div class="table-overflow app-table">
    <table mat-table [dataSource]="servicesDataSource" class="w-100" matSort>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="table-actions mat-column-actions--more">Actions</th>
        <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
          <div class="display-flex align-items-center justify-content-start">
            <button mat-icon-button aria-label="View Icon" color="primary" (click)="onServiceView(element)" tabindex="0">
              <mat-icon>remove_red_eye</mat-icon>
            </button>
            <button mat-icon-button aria-label="Edit Icon" color="primary" *ngIf="!isEditing" (click)="onServiceEdit(element)" tabindex="0">
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-icon-button
              aria-label="Delete Icon"
              color="warn"
              *ngIf="!isEditing"
              (click)="onServiceRemove(element)"
              tabindex="0"
            >
              <mat-icon>close</mat-icon>
            </button>
            <div class="fake-icon-button" *ngIf="!element.isComplete">
              <mat-icon class="icon-incomplete" aria-label="Warning">warning</mat-icon>
            </div>
            <div
              *ngIf="
                iepIncludesTrialPlacement &&
                ((element.includesTrialPlacement && trialPlacementTarget === trialPlacementTargetEnum.SomeItems) ||
                  trialPlacementTarget === trialPlacementTargetEnum.AllItems)
              "
            >
              <div class="fake-icon-button fake-icon-button--primary">
                <mat-icon aria-labelledby="Hourglass empty">hourglass_empty</mat-icon>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="service">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Service</th>
        <td mat-cell *matCellDef="let element">
          <app-compare-amendment-output
            class="mr-1"
            [amendmentId]="amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [isActive]="element.isActive"
            [changedVersionText]="element?.serviceType?.label"
            [currentVersionText]="element?.priorVersion?.serviceType?.label"
            [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
            [tableAlignment]="true"
            changedPrefixText="Proposed: "
          >
          </app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="serviceType">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Service Type</th>
        <td mat-cell *matCellDef="let element">
          <app-compare-amendment-output
            class="mr-1"
            [amendmentId]="amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [isActive]="element.isActive"
            [changedVersionText]="getServiceType(element)"
            [currentVersionText]="getServiceType(element?.priorVersion?.serviceType)"
            [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
            [tableAlignment]="true"
          >
          </app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="projectedStartDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Projected Start Date</th>
        <td mat-cell *matCellDef="let element">
          <app-compare-amendment-output
            class="mr-1"
            [amendmentId]="amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [isActive]="element.isActive"
            [changedVersionText]="getFormattedProjectedDate(element.projectedStartDate)"
            [currentVersionText]="getFormattedProjectedDate(element.priorVersion?.projectedStartDate)"
            [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
            [tableAlignment]="true"
            changedPrefixText="Proposed: "
          >
          </app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="projectedEndDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Projected End Date</th>
        <td mat-cell *matCellDef="let element">
          <app-compare-amendment-output
            class="mr-1"
            [amendmentId]="amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [isActive]="element.isActive"
            [changedVersionText]="getFormattedProjectedDate(element.projectedEndDate)"
            [currentVersionText]="getFormattedProjectedDate(element.priorVersion?.projectedEndDate)"
            [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
            [tableAlignment]="true"
            changedPrefixText="Proposed: "
          >
          </app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="frequency">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Frequency</th>

        <td mat-cell *matCellDef="let element">
          <app-compare-amendment-output
            class="mr-1"
            [amendmentId]="amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [isActive]="element.isActive"
            [changedVersionText]="getFrequencyText(element)"
            [currentVersionText]="getFrequencyText(element.priorVersion)"
            [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
            [tableAlignment]="true"
            changedPrefixText="Proposed: "
          >
          </app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="providers">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Provider(s)</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.providers?.length > 0 || element.otherProviderName">
            <app-compare-amendment-output
              class="mr-1"
              [amendmentId]="amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [isActive]="element.isActive"
              [changedVersionText]="getProviderNames(element.providers, element.otherProviderName)"
              [currentVersionText]="getProviderNames(element.priorVersion?.providers, element.priorVersion?.otherProviderName)"
              [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
              [tableAlignment]="true"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </ng-container>
          <ng-container
            *ngIf="(element.providers?.length === 0 && !element.otherProvider) || (element.otherProvider && !element.otherProviderName)"
          >
            -
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="servicesDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: servicesDisplayedColumns"></tr>
    </table>
    <mat-paginator [dataSource]="servicesDataSource"></mat-paginator>
  </div>
  <p *ngIf="!servicesDataSource">No data yet...</p>
</section>
