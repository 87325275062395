<app-page-header backgroundColor="yellow" [pageTitle]="pageTitle" [condensed]="true">
  <ng-container pageActions>
    <button
      mat-flat-button
      aria-label="Return To IEP"
      *ngIf="!readonlyUser"
      color="primary"
      class="mat-elevation-z0 mr-2"
      (click)="goToIep()"
      tabindex="0"
    >
      Return To IEP
    </button>

    <app-create-output-button
      [output]="pdfOutputs.EsyPlan"
      [apiId]="iepId"
      [learnerId]="learnerId"
      [openNewIcon]="true"
      [isDraft]="true"
      [pushDraftToPortal]="true"
      buttonText="Push to Portal"
      buttonColor="primary"
      class="mr-2"
      buttonType="mat-flat-button"
      *ngIf="canPushToPortal"
    >
    </app-create-output-button>
  </ng-container>
</app-page-header>

<app-dynamic-learner-header></app-dynamic-learner-header>
<app-esy-nav *ngIf="showNav"></app-esy-nav>
