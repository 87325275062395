import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'camelCaseWords',
})
export class CamelCaseWordsPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/((?<!^)[A-Z](?![A-Z]))(?=\S)/g, ' $1').replace(/^./, (s) => s.toUpperCase());
  }
}
