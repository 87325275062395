<mat-form-field class="w-100">
  <input
    #datePickerControl
    matInput
    autocomplete="off"
    attr.aria-required="{{ showAsterisk && !hideAsterisk ? 'true' : 'false' }}"
    [class]="klass"
    [formControl]="_formControl"
    [matDatepicker]="picker"
    [required]="required"
    [placeholder]="label"
    [max]="max"
    [min]="min"
    (blur)="onBlur($event)"
    (dateChange)="onDateChange($event)"
  />

  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker [startView]="startView" [startAt]="startAt"></mat-datepicker>
  <mat-error>
    <app-field-error-display [control]="_formControl"></app-field-error-display>
  </mat-error>
</mat-form-field>
