<div class="prefill">
  <div class="prefill__input">
    <mat-form-field class="w-100">
      <mat-label
        [ngClass]="{
          asterisk_input: control.hasError('required')
        }"
        >{{ label }}</mat-label
      >
      <textarea
        matInput
        [class]="klass"
        id="{{ controlId }}"
        [formControl]="control"
        autocomplete="off"
        [placeholder]="placeholder"
        [attr.maxLength]="maxLength > 0 ? maxLength + 1 : null"
        [readonly]="readOnly"
        [attr.disabled]="disabledState ? true : null"
        (blur)="internalBlur($event)"
        [rows]="rows ? rows : 2"
        attr.aria-required="{{ control.hasError('required') ? 'true' : 'false' }}"
      ></textarea>

      <mat-error>
        <app-field-error-display [control]="control"></app-field-error-display>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="prefill__text" *ngIf="dataSourceValue">QR: {{ dataSourceValue }}</div>

  <div class="prefill__container" (click)="replaceWithSourceValue()" *ngIf="dataSourceValue && !undoValue && !disabledState">
    <div class="prefill__icon">
      <mat-icon aria-label="QR Arrow Symbol">call_merge</mat-icon>
    </div>
  </div>

  <div class="prefill__container prefill__container--undo" *ngIf="undoValue">
    <p class="prefill__icon mb-0" (click)="undoReplaceWithSourceValue()">Undo</p>
  </div>
</div>
