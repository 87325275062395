<section>
  <header>
    <app-page-header [pageTitle]="pageTitle" backgroundColor="yellow"></app-page-header>
  </header>

  <ng-container>
    <app-learner-info-header [learnerId]="learnerId"></app-learner-info-header>
  </ng-container>

  <mat-card class="mt-2 mb-2" [formGroup]="formGroup">
    <div class="row">
      <div class="col-md-12">
        <app-purpose-of-meeting-list [formGroup]="formGroup" [purposes]="allPurposes" [allPurposes]="allPurposes">
        </app-purpose-of-meeting-list>
        <app-textarea *ngIf="otherSelected" [rows]="4" formControlName="otherPurposeDescription" label="Other Purpose"></app-textarea>
      </div>
      <div class="row mb-2 meetingRow">
        <div class="col-lg-4">
          <app-date-picker label="Meeting Date" controlName="date" [min]="minScheduleMeetingDate"></app-date-picker>
        </div>
        <div class="col-lg-4">
          <app-timepicker label="Meeting Time" formControlName="time"></app-timepicker>
        </div>
        <div class="col-lg-4">
          <app-text-field label="Location" formControlName="location"></app-text-field>
        </div>
      </div>
    </div>
    <mat-checkbox
      *ngIf="currentIntake === intakeType.PartB"
      (change)="excusalCheck()"
      [checked]="requestAgreementToExcuse"
      aria-labelledby="Request Agreement to Excuse"
      >Request Agreement to Excuse
    </mat-checkbox>
  </mat-card>

  <div class="row">
    <div class="col">
      <h3 class="mb-0 mt-0 position-fix">
        Requested Participants:
        <mat-error *ngIf="formGroup.get('participants').hasError('participantsRequired')">
          At least one participant is <strong>required</strong>
        </mat-error>
      </h3>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col">
      <app-participants-list
        *ngIf="dataLoaded"
        [caseSummary]="currentCase"
        [learner]="learner"
        [formGroup]="formGroup"
        [requestAgreementToExcuse]="requestAgreementToExcuse"
      >
      </app-participants-list>
    </div>
  </div>
</section>
<h3 class="mb-0 mt-0 position-fix">Contact Person(s)</h3>
<mat-card class="mb-2">
  <div [formGroup]="formGroup">
    <div class="row align-items-center">
      <div class="col-md-12 col-lg-5">
        <app-autocomplete [options]="contactOptions" formControlName="contactPerson" label="Select Participant"></app-autocomplete>
      </div>
      <div class="col">
        <mat-checkbox
          class="display-flex align-items-center"
          [(ngModel)]="alternateContact"
          (change)="setContactValidators()"
          [ngModelOptions]="{ standalone: true }"
          aria-labelledby="Add Alternate Contact"
          >Add Alternate Contact</mat-checkbox
        >
      </div>
    </div>

    <div class="row" *ngIf="alternateContact">
      <div class="col-lg-3">
        <app-text-field label="Name" formControlName="alternateContactName"></app-text-field>
      </div>
      <div class="col-lg-3">
        <app-text-field label="Position" formControlName="alternateContactPosition"></app-text-field>
      </div>
      <div class="col-lg-3">
        <app-text-field label="Email" formControlName="alternateContactEmail"></app-text-field>
      </div>
      <div class="col-lg-3">
        <app-masked-input label="Phone" formControlName="alternateContactPhone" mask="(000) 000-0000"> </app-masked-input>
      </div>
    </div>
    <div class="row mt-3 mb-2" *ngIf="initialHomeVisitSelected && !currentCase.familyHasBeenInvited">
      <div class="col-md-6">
        <app-autocomplete
          [options]="familyMemberOptions"
          formControlName="questionnaireRecipientId"
          label="Indicate Questionnaire Recipient"
        ></app-autocomplete>

        <mat-error *ngIf="errorAddingFamily"
          ><span style="font-size: 14px">{{ errorAddingFamily }}</span></mat-error
        >
      </div>
      <div class="col-md-6">
        <mat-checkbox
          *ngIf="displayIsReferralSource && formGroup.get('questionnaireRecipientId').value"
          [(ngModel)]="inviteIsReferrer"
          [ngModelOptions]="{ standalone: true }"
          aria-labelledby="Is this person the Referral Source?"
          >Is this person the Referral Source?</mat-checkbox
        >
      </div>
    </div>
  </div>
</mat-card>
<div class="display-flex align-items-center justify-content-end mb-2">
  <app-help-directional-text direction="right">
    <p>The meeting notice will be available in the documentation section of the Learner Management pages.</p>
  </app-help-directional-text>
</div>
<div class="display-flex align-items-center justify-content-end">
  <button type="button" mat-raised-button aria-label="Cancel Button" (click)="cancel()" class="mr-2" [disabled]="isSaving" tabindex="0">
    Cancel
  </button>
  <button
    color="primary"
    type="button"
    mat-raised-button
    aria-label="Complete"
    (click)="submit()"
    [disableAfterBusy]="formGroup.invalid"
    [disabled]="formGroup.invalid"
    [isBusy]="isSaving"
    tabindex="0"
  >
    Complete
  </button>
</div>
