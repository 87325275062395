<h3 class="card-spacing-top mb-2">Signatures</h3>
<div class="display-flex align-items-center justify-content-between">
  <!-- <app-page-alert *ngIf="alertConfig" [alert]="alertConfig" role="status" aria-live="polite"></app-page-alert> -->

  <!-- <button
    mat-raised-button
    aria-label="View Icon"
    color="primary"
    (click)="viewDoc(finalizedDocument.id)"
    tabindex="0"
  >
    <mat-icon>wysiwyg</mat-icon>
    View
  </button> -->
</div>
<div class="action__row" *ngIf="isSignedForm">
  <button
    *ngIf="!authService?.isPortalUser"
    color="primary"
    type="button"
    mat-raised-button
    aria-label="View Icon"
    (click)="viewSignedForm()"
    tabindex="0"
  >
    View
  </button>
  <button
    color="primary"
    type="button"
    mat-raised-button
    aria-label="Add Signatures"
    (click)="newSignature()"
    *ngIf="canAddSignatories && hasEditFamilyConsentPermission"
    tabindex="0"
  >
    Add Signatures
  </button>
  <!-- TODO: Form-level revoke handled in future PBI. -->
  <button color="primary" type="button" mat-raised-button aria-label="Revoke" *ngIf="isRevokable" tabindex="0">Revoke</button>
</div>
<div class="table-overflow">
  <table mat-table [dataSource]="signatoriesDataSource" class="w-100">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let sig">
        <button
          mat-icon-button
          aria-label="View Icon"
          color="primary"
          *ngIf="sig.signedFormValues?.length > 0 && authService.isVrUser"
          (click)="viewSignatory(sig)"
        >
          <mat-icon>remove_red_eye</mat-icon>
        </button>
        <button
          mat-icon-button
          aria-label="Quick Access Menu"
          color="primary"
          [matMenuTriggerFor]="menu"
          *ngIf="isFormActive() && (canRevoke(sig) || canSignatorySign(sig) || sig.signedFormValues?.length > 0) && !authService.isVrUser"
          tabindex="0"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item aria-label="Revoke" *ngIf="canRevoke(sig)" (click)="revokeSignatory(sig)" tabindex="0">
            <mat-icon aria-labelledby="Revoke">person_remove</mat-icon>
            Revoke
          </button>
          <button
            mat-menu-item
            aria-label="Edit Icon"
            *ngIf="canSignatorySign(sig)"
            [disabled]="isComplete"
            (click)="inPersonSign(sig)"
            tabindex="0"
          >
            <mat-icon>edit</mat-icon> Sign
          </button>
          <button mat-menu-item aria-label="View Icon" *ngIf="sig.signedFormValues?.length > 0" (click)="viewSignatory(sig)" tabindex="0">
            <mat-icon>visibility</mat-icon> View
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef>First Name</th>
      <td mat-cell *matCellDef="let sig">{{ sig.firstName }}</td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef>Last Name</th>
      <td mat-cell *matCellDef="let sig">{{ sig.lastName }}</td>
    </ng-container>

    <ng-container matColumnDef="sigStatus">
      <th mat-header-cell *matHeaderCellDef>Signature Status</th>
      <td mat-cell *matCellDef="let sig">
        {{ getSignatoryStatus(sig) }}
      </td>
    </ng-container>

    <ng-container *ngIf="consentFormType === 'HomeschoolingSpecialEd'">
      <ng-container matColumnDef="publicAgencyRep">
        <th mat-header-cell *matHeaderCellDef>Public Agency Rep/Designee</th>
        <td mat-cell *matCellDef="let sig">
          {{ sig.signatoryType === 'PublicAgencyRep' ? 'Yes' : '' }}
        </td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef colspan="7">
        <i>No results...</i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr
      mat-footer-row
      *matFooterRowDef="signatoriesDataSource.data.length === 0 ? ['noResults'] : []"
      class="example-second-footer-row"
    ></tr>
  </table>
</div>
<app-select-signatories
  (signatoriesAdded)="onAddSignatories($event)"
  [caseId]="caseId"
  #signFormDialog
  [isHomeschoolConsent]="consentFormType === ConsentFormType.HomeschoolingSpecialEd"
>
</app-select-signatories>

<div *ngIf="allowWetSignature" style="margin-top: 30px">
  <h2>Live Signature</h2>
  <p class="form__description">
    If the signatories are signing offline, upload a document with all of the signatures to mark this form as signed.
    <br /><br />
    <button
      *ngIf="!!viewFinalDocFunc && !isSignedByAllParties()"
      mat-raised-button
      aria-label="Print for Live Signature"
      color="primary"
      (click)="printForLiveSignature()"
      tabindex="0"
    >
      <mat-icon aria-labelledby="Print for Live Signature">wysiwyg</mat-icon>
      Print for Live Signature
    </button>
  </p>
  <!-- <app-document-upload
                [disableDelete]="true"
                [disableUpload]="isSignedByAllParties()"
                text="Upload Live Signature"
                [documents]="finalizedDocument ? [finalizedDocument] : []"
                file-types="DOCX, XLSX, PDF, JPG"
                (uploaded)="onWetSignatureUploaded.emit($event)"
                [requestFiles]="true"
                (fileRequested)="viewDoc($event.id)"
              ></app-document-upload> -->
</div>
