import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { IntakeSections } from 'src/app/evaluation/early-access-intake/intake-sections/intake-sections';
import { EarlyAccessQuestionnaire } from 'src/app/evaluation/models/early-access-questionnaire';
import { Intake } from 'src/app/evaluation/models/intake';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { NAYesNo } from 'src/app/shared/models/yes-no';
import { HelpSection, HelpTerm } from 'src/app/shared/services/help/help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import { IntakeDentalVisionHearingHelp } from 'src/app/shared/services/help/models/intake.help';
import { DentalVisionHearingInformation } from '../../../models/health-information';
import { NotificationService } from './../../../../../shared/services/notification.service';

@Component({
  selector: 'app-dental-vision-hearing-info',
  templateUrl: './dental-vision-hearing-info.component.html',
  styleUrls: ['./dental-vision-hearing-info.component.scss'],
})
export class DentalVisionHearingInfoComponent implements OnInit, OnChanges {
  @Input() formGroup: FormGroup;
  @Input() dentalVisionHearingInfo: DentalVisionHearingInformation;

  yesNoOptions: KeyValuePair[] = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];

  yesNoNAOptions: KeyValuePair[] = [new KeyValuePair('Yes', 'Yes'), new KeyValuePair('No', 'No'), new KeyValuePair('Unknown', 'Unknown')];

  today: Date;
  dateOfBirth: Date;
  intakeSections = IntakeSections;
  @Input() intake: Intake;
  @Input() questionnaire: EarlyAccessQuestionnaire;
  @Input() isQuestionnaire: boolean;
  @Input() documentationInfo: FormGroup;
  @Input() locked: boolean;
  @Input() importQuestionnaireSelected: boolean;
  @Output() upload = new EventEmitter();
  @Output() deleteDocument = new EventEmitter();

  helpSection = HelpSection;
  intakeDentalVisionHearingHelp = IntakeDentalVisionHearingHelp;

  visionCheckListPromptFlag: {
    canDisplay: boolean;
    subscription: Subscription;
    lastVisionReferral?: boolean;
  } = {
    canDisplay: false,
    subscription: null,
    lastVisionReferral: null,
  };

  get childsName() {
    return this.isQuestionnaire
      ? this.questionnaire?.childInfo?.firstName + ' ' + this.questionnaire?.childInfo?.lastName
      : this.intake?.childInfo?.firstName + ' ' + this.intake?.childInfo?.lastName;
  }

  constructor(private notificationService: NotificationService, private helpService: HelpService) {}

  ngOnInit(): void {
    this.today = new Date();
    if (!this.isQuestionnaire) {
      this.dateOfBirth = dayjs(this.intake?.childInfo?.dateOfBirth).toDate();
    } else {
      this.dateOfBirth = dayjs(this.questionnaire?.dateOfBirth).toDate();
    }
    this.initializeControls();
    this.formGroup.patchValue(this.dentalVisionHearingInfo);
    setTimeout(() => this.checkDateValidation(), 500);
    this.visionCheckListPromptFlag.canDisplay = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.importQuestionnaireSelected?.currentValue !== changes.importQuestionnaireSelected?.previousValue &&
      this.importQuestionnaireSelected
    ) {
      this.importDentalVisionHearingInfoFromQuestionnaire();
      this.formGroup.markAsDirty();
    }
  }

  onOpenHelp(e: Event, section: HelpSection, item: HelpTerm) {
    e.preventDefault();

    const dictionary = this.helpService.getIntakeDictionary();
    this.helpService.openHelpModal({
      help: dictionary,
      section,
      item,
      canBrowse: true,
    } as HelpModalConfig);
  }

  importDentalVisionHearingInfoFromQuestionnaire() {
    const questionnaireDentalVisHearingInfo = this.questionnaire?.healthInfo?.dentalVisionHearingInformation;
    for (const prop in questionnaireDentalVisHearingInfo) {
      if (questionnaireDentalVisHearingInfo[prop] !== null && questionnaireDentalVisHearingInfo[prop] !== '') {
        this.dentalVisionHearingInfo[prop] = questionnaireDentalVisHearingInfo[prop];
      }
    }
    this.formGroup.patchValue(this.dentalVisionHearingInfo);
  }

  initializeControls() {
    // Dental
    this.formGroup.addControl(
      'dentistSeen',
      new FormControl(null, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl(
      'dentalConcern',
      new FormControl(null, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl('dentalConcernDescription', new FormControl(null));

    // Vision
    this.formGroup.addControl(
      'visionTested',
      new FormControl(null, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl('visionTestedWhen', new FormControl(null));
    this.formGroup.addControl('visionTestedWhere', new FormControl(null));
    this.formGroup.addControl('visionTestedResults', new FormControl(null));

    this.formGroup.get('visionTested').valueChanges.subscribe((visionTested) => {
      if (!visionTested) {
        this.formGroup.get('visionTestedWhen').setValue(null);
        this.formGroup.get('visionTestedWhere').setValue(null);
        this.formGroup.get('visionTestedResults').setValue(null);
      }
    });

    this.formGroup.addControl(
      'visionConcern',
      new FormControl(null, {
        updateOn: 'change',
      })
    );

    // Hearing
    this.formGroup.addControl(
      'hearingTested',
      new FormControl(null, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl('hearingTestedWhen', new FormControl(null));
    this.formGroup.addControl('hearingTestedWhere', new FormControl(null));
    this.formGroup.addControl('hearingTestedResults', new FormControl(null));

    this.formGroup.get('hearingTested').valueChanges.subscribe((hearingTested) => {
      if (!hearingTested) {
        this.formGroup.get('hearingTestedWhen').setValue(null);
        this.formGroup.get('hearingTestedWhere').setValue(null);
        this.formGroup.get('hearingTestedResults').setValue(null);
      }
    });

    this.formGroup.addControl(
      'hearingConcern',
      new FormControl(null, {
        updateOn: 'change',
      })
    );

    if (!this.isQuestionnaire) {
      // Dental
      this.formGroup.controls.dentistSeen.setValidators(Validators.required);
      this.formGroup.controls.visionTested.setValidators(Validators.required);
      this.formGroup.controls.hearingTested.setValidators(Validators.required);
      this.formGroup.controls.dentalConcern.setValidators(Validators.required);
      this.formGroup.addControl('dentalConcernDescription', new FormControl(null));
      this.formGroup.addControl('dentalAdditionalInformation', new FormControl(null));

      // Vision
      this.formGroup.controls.visionConcern.setValidators(Validators.required);
      this.formGroup.addControl('visionChecklist', new FormControl(null, { updateOn: 'change' }));

      this.formGroup.get('visionChecklist').valueChanges.subscribe((checklistCompleted) => {
        if (checklistCompleted === false) {
          if (this.visionCheckListPromptFlag.subscription) {
            this.visionCheckListPromptFlag.subscription.unsubscribe();
            this.visionCheckListPromptFlag.subscription = null;
          }
          this.formGroup.removeControl('visionReferral');
          this.formGroup.addControl('visionChecklistDescription', new FormControl(null, [Validators.required]));
        }
        if (checklistCompleted) {
          this.formGroup.removeControl('visionChecklistDescription');
          this.formGroup.addControl(
            'visionReferral',
            new FormControl(null, {
              validators: [Validators.required],
              updateOn: 'change',
            })
          );

          this.visionCheckListPromptFlag.subscription = this.formGroup.get('visionReferral').valueChanges.subscribe((v) => {
            this.evaluateVisionCheckListPrompt(v);
          });
        }
      });
      this.formGroup.get('visionConcern').valueChanges.subscribe((hasVisionConcern) => {
        if (hasVisionConcern) {
          this.formGroup.addControl('visionConcernDescription', new FormControl(null, [Validators.required]));
        } else {
          this.formGroup.removeControl('visionConcernDescription');
        }
      });
      this.formGroup.addControl('visionAdditionalInformation', new FormControl(null));

      // Hearing
      this.formGroup.controls.hearingConcern.setValidators(Validators.required);
      this.formGroup.get('hearingConcern').valueChanges.subscribe((hasVisionConcern) => {
        if (hasVisionConcern) {
          this.formGroup.addControl('hearingConcernDescription', new FormControl(null, [Validators.required]));
        } else {
          this.formGroup.removeControl('hearingConcernDescription');
        }
      });
      this.formGroup.addControl('hearingAdditionalInformation', new FormControl(null));
    }
  }

  checkDateValidation() {
    const visionTestedWhen = this.formGroup.get('visionTestedWhen');
    if (
      this.dentalVisionHearingInfo.visionTested === NAYesNo.Yes &&
      !!this.dentalVisionHearingInfo.visionTestedWhen &&
      !!visionTestedWhen.value &&
      visionTestedWhen.invalid
    ) {
      visionTestedWhen.markAsTouched();
    }

    const hearingTestedWhen = this.formGroup.get('hearingTestedWhen');
    if (
      this.dentalVisionHearingInfo.hearingTested === NAYesNo.Yes &&
      !!this.dentalVisionHearingInfo.hearingTestedWhen &&
      !!hearingTestedWhen.value &&
      hearingTestedWhen.invalid
    ) {
      hearingTestedWhen.markAsTouched();
    }
  }

  evaluateVisionCheckListPrompt(visionReferral: boolean) {
    if (
      this.visionCheckListPromptFlag.canDisplay &&
      visionReferral &&
      this.visionCheckListPromptFlag.lastVisionReferral !== visionReferral
    ) {
      this.notificationService.alert('Provide the TVI with the completed vision checklist.');
    }
    this.visionCheckListPromptFlag.lastVisionReferral = visionReferral;
  }

  onUpload(formData: FormData) {
    this.upload.emit(formData);
  }

  onDeleteDocument(documentId: string) {
    this.deleteDocument.emit(documentId);
  }
}
