import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DashboardComponent } from 'src/app/dashboard/dashboard.component';
import { EligibleFamilyDashboardComponent } from 'src/app/eligible-family-dashboard/eligible-family-dashboard.component';
import { FamilyDashboardComponent } from 'src/app/family-dashboard/family-dashboard.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { UniversalDashboardComponent } from 'src/app/universal-dashboard/universal-dashboard.component';
import { NewlyAddedStatComponent } from '../dashboard/dashboard-stats/newly-added-stat/newly-added-stat.component';
import { UserCalendarComponent } from '../dashboard/user-calendar/user-calendar.component';
import { UserCaseListComponent } from '../dashboard/user-case-list/user-case-list.component';
import { AddTodoComponent } from '../dashboard/user-todo-list/add-todo/add-todo.component';
import { EditTodoComponent } from '../dashboard/user-todo-list/edit-todo/edit-todo.component';
import { UserTodoListComponent } from '../dashboard/user-todo-list/user-todo-list.component';
import { EvaluationModule } from '../evaluation/evaluation.module';
import { AppMatModule } from '../shared/app-mat.module';
import { UniversalDashboardRoutingModule } from '../universal-dashboard/universal-dashboard-routing.module';

@NgModule({
  declarations: [
    DashboardComponent,
    FamilyDashboardComponent,
    EligibleFamilyDashboardComponent,
    UniversalDashboardComponent,
    NewlyAddedStatComponent,
    UserTodoListComponent,
    UserCalendarComponent,
    UserCaseListComponent,
    AddTodoComponent,
    EditTodoComponent,
  ],
  imports: [
    CommonModule,
    UniversalDashboardRoutingModule,
    SharedModule,
    FullCalendarModule,
    ReactiveFormsModule,
    AppMatModule,
    EvaluationModule,
  ],
  exports: [
    CommonModule,
    UniversalDashboardRoutingModule,
    SharedModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatMenuModule,
    FullCalendarModule,
    UserCalendarComponent,
  ],
})
export class UniversalDashboardModule {}
