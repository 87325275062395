import { Help, HelpSection } from '../help';
import { I3IepHelp } from '../models/i3.help';

export function getI3IepDictionary(): Help[] {
  return [
    {
      title: HelpSection.I3,
      dictionary: [
        {
          label: I3IepHelp.IepTeams,
          iframeSource: 'https://iowaideainformation.org?p=984',
        },
        {
          label: I3IepHelp.IepIdr,
          iframeSource: 'https://iowaideainformation.org/special-education/individualized-education-programs/components-of-an-iep/',
        },
        {
          label: I3IepHelp.IepReports,
          iframeSource: 'https://iowaideainformation.org/special-education/individualized-education-programs/components-of-an-iep/',
        },
      ],
    },
  ];
}
