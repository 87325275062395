import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private showAllErrorsSubject = new Subject<void>();
  showAllErrors$ = this.showAllErrorsSubject.asObservable();

  constructor() {}

  showAllErrors() {
    this.showAllErrorsSubject.next();
  }

  getAllInvalidControls(formGroup: FormGroup | FormArray): Array<FormControl> {
    const invalidControls = new Array<FormControl>();
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      if (control.valid || 'typeahead' in control.errors) {
        return;
      }

      if (control instanceof FormGroup || control instanceof FormArray) {
        this.getAllInvalidControls(control).forEach((x) => invalidControls.push(x));
      }

      if (control instanceof FormControl) {
        invalidControls.push(control);
      }
    });

    return invalidControls;
  }
}
