import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MaterialTimePickerModule } from '@candidosales/material-time-picker';
import { AppMatModule } from '../../app-mat.module';
import {
  DateFormatNoConversionPipe,
  DateFormatPipe,
  DateTimeFormatPipe,
  DateToUtcPipe,
  DateToUtcWithDaylightPipe,
  TimeFormatPipe,
} from '../../pipes/date-transform.pipe';
import { EnumDescPipe } from '../../pipes/enum-desc.pipe';
import { AscendCheckboxComponent } from './ascend-checkbox/ascend-checkbox.component';
import { AutocompleteChipsGroupsComponent } from './autocomplete-chips-groups/autocomplete-chips-groups.component';
import { AutocompleteChipsComponent } from './autocomplete-chips/autocomplete-chips.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { CheckboxSingleComponent } from './checkbox-single/checkbox-single.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { FieldErrorDisplayComponent } from './field-error-display/field-error-display.component';
import { MaskedInputComponent } from './masked-input/masked-input.component';
import { NoAutocompleteDirective } from './no-autocomplete.directive';
import { NumberFieldComponent } from './number-field/number-field.component';
import { OverwriteDatePickerComponent } from './overwrite-date-picker/overwrite-date-picker.component';
import { OverwriteMatCheckboxComponent } from './overwrite-mat-checkbox/overwrite-mat-checkbox.component';
import { OverwriteNumberFieldComponent } from './overwrite-number-field/overwrite-number-field.component';
import { OverwriteTextFieldComponent } from './overwrite-text-field/overwrite-text-field.component';
import { OverwriteTextareaComponent } from './overwrite-textarea/overwrite-textarea.component';
import { QuickDateComponent } from './quick-date/quick-date.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { SelectComponent } from './select/select.component';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';
import { TextFieldComponent } from './text-field/text-field.component';
import { TextareaComponent } from './textarea/textarea.component';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { ToggleButtonsComponent } from './toggle-buttons/toggle-buttons.component';
import { DatePickerV2Component } from './date-picker-v2/date-picker.component';
import { SchoolYearPipe } from '../../pipes/school-year.pipe';

export let options: Partial<IConfig> | (() => Partial<IConfig>);

const formModules = [
  FieldErrorDisplayComponent,
  TextFieldComponent,
  CheckboxSingleComponent,
  RadioGroupComponent,
  CheckboxComponent,
  SelectComponent,
  DatePickerComponent,
  AutocompleteComponent,
  AutocompleteChipsComponent,
  AutocompleteChipsGroupsComponent,
  SignaturePadComponent,
  OverwriteTextFieldComponent,
  OverwriteDatePickerComponent,
  OverwriteMatCheckboxComponent,
  ToggleButtonsComponent,
  NumberFieldComponent,
  TextareaComponent,
  OverwriteNumberFieldComponent,
  OverwriteTextareaComponent,
  MaskedInputComponent,
  TimepickerComponent,
  QuickDateComponent,
  AscendCheckboxComponent,
  NoAutocompleteDirective,
  DateFormatPipe,
  DateTimeFormatPipe,
  TimeFormatPipe,
  DateFormatNoConversionPipe,
  DateToUtcPipe,
  DateToUtcWithDaylightPipe,
  EnumDescPipe,
  DatePickerV2Component,
  SchoolYearPipe,
];

@NgModule({
  declarations: formModules,
  imports: [
    CommonModule,
    AppMatModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxMaskModule.forRoot(options),
    MaterialTimePickerModule,
  ],
  exports: formModules,
})
export class FormsControlModule {}
