<div class="backdrop">
  <div class="pic"></div>
  <div class="form" *ngIf="invite">
    <app-learner-portal-registration-form
      [registerError]="registerError"
      [invite]="invite"
      (submitted)="onSubmit($event)"
    ></app-learner-portal-registration-form>
  </div>
</div>
