import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { openNewWindow } from '../windowHelpers';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  constructor(private router: Router) {}

  userHome() {
    this.router.navigate(['/']);
  }

  learnerDashboardPath(learnerId: string, caseId?: string) {
    return ['/learner-management', learnerId, 'learner-dashboard', { caseId }];
  }

  learnerDashboard(learnerId: string): Promise<boolean> {
    return this.router.navigate(this.learnerDashboardPath(learnerId));
  }

  dataHistoryPath(learnerId: string) {
    return ['/learner-management', learnerId, 'data-history'];
  }

  dataHistory(learnerId: string) {
    this.router.navigate(this.dataHistoryPath(learnerId));
  }

  documentationPath(learnerId: string) {
    return ['/learner-management', learnerId, 'documentation'];
  }

  documentation(learnerId: string) {
    this.router.navigate(this.documentationPath(learnerId));
  }

  document(learnerId: string, documentId: string) {
    this.router.navigate(['/api/documents', learnerId, documentId]);
  }

  disabilitySuspectFormPath(dsFormId: string) {
    return ['/child-find', 'disability-suspect', dsFormId];
  }

  fiieConsentPath(learner, caseId: string) {
    return ['/learner-management', caseId, 'fiie-consent'];
  }

  reevaluationConsentPath(caseId: string, reevaluationId: string, reevaluationConsentId?: string) {
    return ['/cases', caseId, 'reevaluation', reevaluationId, 'reeval-consent', reevaluationConsentId];
  }

  intakePath(caseId: string) {
    return ['/cases', caseId, 'evaluation', 'early-access-intake', caseId];
  }

  evalOverview(caseId: string, activeEvaluationId: string) {
    return ['/cases', caseId, 'evaluation', activeEvaluationId, 'overview'];
  }

  newLearnerInfoPath(learnerId: string) {
    return ['/learner-management', learnerId, 'new-learner-info'];
  }

  openScheduleMeeting(learnerId: string, beforeunload?: (event) => void) {
    const newWindow = openNewWindow({
      path: `learner-management/${learnerId}/schedule-meeting`,
      popup: true,
      width: '1480px',
    });
    if (beforeunload) {
      newWindow.addEventListener('beforeunload', beforeunload);
    }
    return newWindow;
  }

  openEvaluationScheduleMeeting(learnerId: string, evaluationId: string, beforeunload?: (event) => void) {
    const newWindow = openNewWindow({
      path: `learner-management/${learnerId}/schedule-meeting/evaluations/${evaluationId}`,
      popup: true,
      width: '1480px',
    });
    if (beforeunload) {
      newWindow.addEventListener('beforeunload', beforeunload);
    }
    return newWindow;
  }

  openFamilyContactLetter(learnerId: string, caseId: string, beforeunload?: (event) => void) {
    const newWindow = openNewWindow({
      path: `learner-management/${learnerId}/cases/${caseId}/family-contact-letter`,
      popup: true,
      width: '1480px',
    });
    if (beforeunload) {
      newWindow.addEventListener('beforeunload', beforeunload);
    }
  }

  familyContactPath(learnerId: string) {
    return ['/learner-management', learnerId, 'family-contact'];
  }

  ifspListPath(learnerId: string) {
    return ['/learner-management', learnerId, 'ifsp'];
  }

  ifspList(learnerId: string) {
    this.router.navigate(this.ifspListPath(learnerId));
  }

  ifspDetailsPath(caseId: string, ifspId: string) {
    return ['/cases', caseId, 'ifsp', ifspId, 'details'];
  }

  ifspDetails(caseId: string, ifspId: string) {
    this.router.navigate(this.ifspDetailsPath(caseId, ifspId));
  }

  iepListPath(learnerId: string) {
    return ['/learner-management', learnerId, 'iep'];
  }

  iepList(learnerId: string) {
    return this.router.navigate(this.iepListPath(learnerId));
  }

  addServiceLog(caseId: string) {
    this.router.navigate(this.addServiceLogPath(caseId));
  }

  addServiceLogPath(caseId: string) {
    return ['/learner-management', caseId, 'add-service-log'];
  }

  updateServiceLogPath(caseId: string, serviceLogId: string) {
    return ['/learner-management', caseId, 'update-service-log', serviceLogId];
  }

  evaluationListPath(learnerId: string) {
    return ['/learner-management', learnerId, 'evaluation'];
  }

  evaluationList(learnerId: string) {
    this.router.navigate(this.evaluationListPath(learnerId));
  }

  evalPartBSummary(caseId: string, evalId: string) {
    this.router.navigate(['/cases', caseId, 'evaluation', evalId, 'summary-part-b']);
  }

  evalPartCDetails(caseId: string, evalId: string) {
    this.router.navigate(['/cases', caseId, 'evaluation', evalId, 'details-part-c']);
  }

  secondaryTransitionPath(learnerId: string, caseId: string) {
    return ['/learner-management', learnerId, 'cases', caseId, 'evaluation'];
  }

  secondaryTransition(learnerId: string, caseId: string) {
    this.router.navigate(this.secondaryTransitionPath(learnerId, caseId));
  }

  postSecondaryTransitionPath(learnerId: string) {
    return ['/learner-management', learnerId, 'postsecondary-summary'];
  }

  postSecondarySummary(learnerId: string) {
    this.router.navigate(this.postSecondaryTransitionPath(learnerId));
  }

  transitionPlanningPath(learnerId: string) {
    return ['/learner-management', learnerId, 'transition-planning'];
  }

  transitionPlanning(learnerId: string) {
    this.router.navigate(this.transitionPlanningPath(learnerId));
  }

  enterProgressPath(caseId: string) {
    return ['monitoring-progress', caseId, 'enter-progress'];
  }

  enterProgress(learnerId: string) {
    this.router.navigate(this.ifspListPath(learnerId));
  }

  fbaView(caseId: string, fbaId: string) {
    this.router.navigateByUrl(`/cases/${caseId}/behavior-discipline/fba/${fbaId}/view`);
  }

  bipView(caseId: string, bipId: string) {
    this.router.navigateByUrl(`/cases/${caseId}/behavior-discipline/bip/${bipId}/view`);
  }

  private convertBase64ToBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  openBase64Blob(base64Data: string, contentType: string) {
    const blob = this.convertBase64ToBlob(base64Data, contentType);
    const blobUrl = URL.createObjectURL(blob);
    return window.open(blobUrl);
  }
}
