<header>
  <app-page-header [pageTitle]="pageTitle" backgroundColor="yellow" [condensed]="true"></app-page-header>
</header>
<app-dynamic-learner-header></app-dynamic-learner-header>

<ng-container *ngIf="intakeType === 'PartB'">
  <app-enter-progress-part-b [enableForms]="canCreateEditProgressMonitoring()" [caseSummary]="caseSummary"></app-enter-progress-part-b>
</ng-container>

<ng-container *ngIf="intakeType === 'PartC'">
  <app-enter-progress-part-c [enableForms]="canCreateEditProgressMonitoring()" [caseSummary]="caseSummary"></app-enter-progress-part-c>
</ng-container>
