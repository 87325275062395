<ng-container *ngIf="ecoDataHistory.length > 0">
  <h3 class="card-spacing-top mb-2">Early Childhood Outcomes History</h3>

  <mat-accordion class="accordion accordion--close accordion--short" multi>
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title> <h3 class="my-0 text-normal">Positive Social-Emotional Skills</h3> </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngFor="let ecoRating of getEcoDataHistory(ecoAreaType.PositiveSocialEmotionalSkills)">
        <div class="row pb-4">
          <div class="col-md-2">{{ ecoRating.updatedOn | dateFormat }}</div>
          <div class="col-md-8">{{ ecoRating.ecoRatingDescription }}</div>
          <div class="col-md-2">
            {{ ecoRating.madeProgress === true ? 'Made Progress' : ecoRating.madeProgress === false ? 'Did Not Make Progress' : '' }}
          </div>
        </div>
      </ng-container>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title> <h3 class="my-0 text-normal">Acquisition and Use of Knowledge and Skills</h3> </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngFor="let ecoRating of getEcoDataHistory(ecoAreaType.AcquisitionSkills)">
        <div class="row pb-4">
          <div class="col-md-2">{{ ecoRating.updatedOn | dateFormat }}</div>
          <div class="col-md-8">{{ ecoRating.ecoRatingDescription }}</div>
          <div class="col-md-2">
            {{ ecoRating.madeProgress === true ? 'Made Progress' : ecoRating.madeProgress === false ? 'Did Not Make Progress' : '' }}
          </div>
        </div>
      </ng-container>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title> <h3 class="my-0 text-normal">Use of Appropriate Behaviors to Meet Needs</h3> </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngFor="let ecoRating of getEcoDataHistory(ecoAreaType.AppropriateBehaviors)">
        <div class="row pb-4">
          <div class="col-md-2">{{ ecoRating.updatedOn | dateFormat }}</div>
          <div class="col-md-8">{{ ecoRating.ecoRatingDescription }}</div>
          <div class="col-md-2">
            {{ ecoRating.madeProgress === true ? 'Made Progress' : ecoRating.madeProgress === false ? 'Did Not Make Progress' : '' }}
          </div>
        </div>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
