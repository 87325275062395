import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipList } from '@angular/material/chips';
import { MatFormField } from '@angular/material/form-field';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { KeyValuePair } from '../../../models/key-value-pair';
import { LoggerService } from '../../../services/logger/logger.service';
import { BaseWrapperComponent } from '../base-wrapper.component';

@Component({
  selector: 'app-autocomplete-chips',
  templateUrl: './autocomplete-chips.component.html',
  styleUrls: ['./autocomplete-chips.component.scss'],
})
export class AutocompleteChipsComponent extends BaseWrapperComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('optionInput') optionInput: ElementRef<HTMLInputElement>;
  @ViewChild('matFormField') matFormField: MatFormField;
  @ViewChild(MatAutocompleteTrigger) autoComplete: MatAutocompleteTrigger;
  @ViewChild('chipList') chipList: MatChipList;
  @Output() selected = new EventEmitter<any>();
  controlChangesSubscription = new Subscription();
  @Input()
  set options(value: KeyValuePair[]) {
    value = value || [];
    this.allOptions = [...value];
    this.originalOptions = [...value];
    this.currentOptions = [...value];
  }
  @Input() max: number;
  @Input() showAsterisk: boolean;
  @Input() hideAsterisk: boolean;

  allOptions: KeyValuePair[] = [];
  originalOptions: KeyValuePair[];
  currentOptions: KeyValuePair[];
  optionAutocompleteChipsCtrl = new FormControl('');
  chipListCtrl = new FormControl([]);
  filteredOptions: Observable<any[]>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  componentInitialized = false;

  get selectedOptions(): string {
    return (this.chipListCtrl?.value as Array<any>).map((x) => x.value).toString();
  }

  constructor(
    logger: LoggerService,
    @Self()
    @Optional()
    ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(logger, ngControl, changeDetectorRef);
  }

  ngOnInit() {
    super.ngOnInit();

    const selected = this.control.value;

    this.subscribeToValueChanges();

    if (selected && this.allOptions) {
      this.chipListCtrl.setValue(this.allOptions.filter((option) => selected.includes(option.key)));
      this.currentOptions = this.currentOptions.map((x) => (!selected.includes(x.key) ? x : null));
      this.allOptions = this.currentOptions.filter((x) => x);
    }

    this.componentInitialized = true;

    this.setFilteredOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options && changes.options.currentValue !== changes.options.previousValue) {
      if (this.control.value) {
        this.chipListCtrl.setValue(this.allOptions.filter((option) => this.control.value.includes(option.key)));

        if (this.componentInitialized) {
          this.currentOptions = this.currentOptions.map((x) => (!this.control.value.includes(x.key) ? x : null));
          this.allOptions = this.currentOptions.filter((x) => x);
        }
      }
      this.setFilteredOptions();
    }
  }

  ngOnDestroy() {
    this.controlChangesSubscription.unsubscribe();
  }

  setFilteredOptions() {
    this.filteredOptions = this.optionAutocompleteChipsCtrl.valueChanges.pipe(
      startWith(null as string | null),
      map((selectedOption: string | null) => {
        return selectedOption ? this.filter(selectedOption) : this.allOptions?.slice();
      })
    );
  }

  private filter(value: any): any[] {
    // for typed in values support case-insensitive search
    if (value && typeof value === 'string') {
      value = value.toLowerCase();
    }

    return this.allOptions.filter((option) => option.value.toLowerCase().indexOf(value) > -1);
  }

  private subscribeToValueChanges() {
    this.controlChangesSubscription = this.control.valueChanges.subscribe((val) => {
      if (val && Array.isArray(val) && val.length > 0) {
        this.chipListCtrl.setValue(this.allOptions.filter((option) => val.includes(option.key)));
        val.forEach((v) => {
          const index = this.currentOptions.findIndex((x) => x && x.key === v);
          if (index > -1) {
            this.currentOptions[index] = null;
          }
        });
        this.allOptions = this.currentOptions.filter((x) => x !== null);
        this.setFilteredOptions();
      } else {
        this.reset();
      }
      this.chipListCtrl.updateValueAndValidity();
    });
  }

  private unsubscribeFromValueChanges() {
    this.controlChangesSubscription.unsubscribe();
  }

  remove(option): void {
    const options = this.control.value;
    this.unsubscribeFromValueChanges();
    this.control.setValue(options.filter((x) => x !== option.key));
    this.subscribeToValueChanges();

    const chipOptions = this.chipListCtrl.value;
    this.chipListCtrl.setValue(chipOptions.filter((x) => x.key !== option.key));

    const idx = this.originalOptions.indexOf(option);
    this.currentOptions[idx] = this.originalOptions[idx];
    this.allOptions = this.currentOptions.filter((x) => x);

    this.setFilteredOptions();
    this.autoComplete.closePanel();
    this.setFormDirty();
  }

  selectedChip(event: MatAutocompleteSelectedEvent): void {
    if (this.max && (this.chipListCtrl.value as Array<KeyValuePair>).length >= this.max) {
      return;
    }
    const option = event.option.value;
    const options = this.control.value || [];
    this.unsubscribeFromValueChanges();
    this.control.setValue([...options, option.key]);
    this.subscribeToValueChanges();

    const index = this.currentOptions.indexOf(option);
    if (index > -1) {
      // Maintain an array with the same positions as the original
      // incoming list so we can restore de-selected options to their
      // original place
      this.currentOptions[index] = null;
      this.allOptions = this.currentOptions.filter((x) => x);
    }

    const chipOptions = this.chipListCtrl.value || [];
    this.chipListCtrl.setValue([...chipOptions, option]);

    this.optionInput.nativeElement.blur();
    this.optionInput.nativeElement.value = '';
    this.optionAutocompleteChipsCtrl.setValue(null);
    this.setFormDirty();
  }

  onBlur(event: any) {
    // chip doesn't set the value of the text box so this value SHOULD be w/e someone typed
    const typedValue = event.target.value.toString().toLowerCase();
    // no need to do validation if there's no value
    // or we're allowing custom inputs
    if (!typedValue) {
      this.clearTypeaheadError();
      return;
    }
    const option = this.originalOptions.find(
      (d) => d.key.toString().toLowerCase() === typedValue || d.value.toString().toLowerCase() === typedValue
    );
    if (typedValue && !option) {
      // in case of partial input to narrow down the available options, check if the partial input does exist in the original options.
      const optionsAvailable = this.originalOptions.filter(
        (d) => d.value.toLowerCase().indexOf(typedValue) > -1 || d.value.toLowerCase().indexOf(typedValue) > -1
      );
      if (optionsAvailable.length > 0) {
        this.clearTypeaheadError();
        return;
      }
      this.setTypeaheadError();
      return;
    } else {
      this.clearTypeaheadError();
    }
    if (typedValue !== option.key?.toLowerCase() && typedValue !== option.value?.toLowerCase()) {
      this.selectedChip({
        option: {
          value: {
            key: option.key,
            value: option.value,
          },
        },
      } as MatAutocompleteSelectedEvent);
    }
    this.setFormDirty();
  }

  private setTypeaheadError() {
    this.control.setErrors({ typeahead: true });
    this.chipList.errorState = true;

    this.setFormDirty();
  }

  private clearTypeaheadError() {
    const error = 'typeahead';
    if (this.control?.errors?.[error]) {
      delete this.control.errors[error];
    }
    this.chipList.errorState = false;
    this.setFormDirty();
  }

  private setFormDirty() {
    this.control.markAsDirty();
    this.control.markAsTouched();
    if (this.formGroup && !this.formGroup.dirty) {
      this.formGroup.markAsDirty();
    }
    if (this.control.invalid) {
      this.matFormField._elementRef.nativeElement.classList.add('mat-form-field-invalid');
    } else {
      this.matFormField._elementRef.nativeElement.classList.remove('mat-form-field-invalid');
    }
  }

  reset() {
    this.chipListCtrl.setValue(null);
    this.allOptions = [...this.originalOptions];
    this.currentOptions = [...this.originalOptions];
    this.setFilteredOptions();
  }
}
