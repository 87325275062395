import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import { DialogComingSoonComponent } from '../../../../../shared/components/coming-soon/coming-soon.component';
import { shortDateFormat } from '../../../../../shared/dateTimeHelpers';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { IepAccommodationsViewMoreComponent } from '../../../../modals/iep-accommodations-view-more/iep-accommodations-view-more.component';
import { IepAmendmentEndItemModalComponent } from '../../../../modals/iep-amendment-end-item-modal/iep-amendment-end-item-modal.component';
import { TrialPlacementTarget } from '../../../../models/iep';
import { IepAccommodationDto } from '../../../../models/iep-accommodation';
import { IepAmendment } from '../../../../models/iep-amendment';
import { IepAccommodationService } from '../../../../services/iep-accommodation.service';
import { IepChangeProviderComponent } from '../../../../shared/iep-change-provider/iep-change-provider.component';
import { IepSsaaAccommodationsComponent } from '../../../iep-services-supports-activities/components/iep-ssaa-accommodations/iep-ssaa-accommodations.component';
import { IepAccommodationInfoComponent } from './iep-accommodation-info/iep-accommodation-info.component';

@Component({
  selector: 'app-iep-details-accommodations',
  templateUrl: './iep-details-accommodations.component.html',
  styleUrls: ['./iep-details-accommodations.component.scss'],
})
export class IepDetailsAccommodationsComponent implements OnInit {
  iepId: string;
  @Input() amendments: IepAmendment[] = [];
  @Input() iepIncludesTrialPlacement: boolean;
  @Input() learnerId: string;
  @Input() trialPlacementTarget: TrialPlacementTarget;
  @Output() trialPlacementChange = new EventEmitter();
  @ViewChild('accommodationsComponent')
  accommodationsComponent: IepSsaaAccommodationsComponent;

  displayedColumns = ['actions', 'description', 'frequency', 'providers'];
  dataSource = new MatTableDataSource([]);

  shortDateFormat = shortDateFormat;
  showAccommodationsForm = false;
  amendingAccommodation: IepAccommodationDto;
  isEditing = false;
  trialPlacementTargetEnum = TrialPlacementTarget;

  get amendment() {
    return this.amendments?.length > 0 ? this.amendments[0] : null;
  }

  get amendmentIsFinalized() {
    return this.amendment?.finalizeDate !== null;
  }

  get lastFinalizedDate() {
    if (this.amendments && this.amendments.length > 0) {
      const latest = this.amendments.reduce((r, a) => {
        return r.finalizeDate > a.finalizeDate ? r : a;
      });
      return latest?.finalizeDate;
    }
    return null;
  }

  get hasOpenAmendment() {
    return !!this.amendment && !this.amendmentIsFinalized;
  }

  get amendmentId() {
    return this.amendment?.id;
  }

  get showTrialPlacementCheckBox(): boolean {
    return this.iepIncludesTrialPlacement && this.amendment.trialPlacementTarget === TrialPlacementTarget.SomeItems;
  }

  constructor(
    private route: ActivatedRoute,
    private iepAccommodationService: IepAccommodationService,
    private dialog: MatDialog,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.iepId = this.route.snapshot.paramMap.get('iepId');
    this.dataSource = new MatTableDataSource([]);
    this.getAccommodations();

    this.iepAccommodationService.accommodationsUpdated$.subscribe(() => this.getAccommodations());
    this.iepAccommodationService.accommodationAddNew$.subscribe(() => {
      this.showAccommodationsForm = true;
      this.isEditing = true;
      this.amendingAccommodation = null;
    });
    this.iepAccommodationService.accommodationClose$.subscribe(() => {
      this.showAccommodationsForm = false;
      this.isEditing = false;
    });
    this.dataSource.sortingDataAccessor = (item: IepAccommodationDto, columnId) => {
      switch (columnId) {
        case 'frequency':
          return item.frequencyPeriod;
        case 'providers':
          return this.getProviderString(item?.providers);
        default:
          return item[columnId];
      }
    };
  }

  getAccommodations() {
    this.iepAccommodationService.getAllForAmendment(this.iepId, this.amendment?.id).subscribe((accommodations) => {
      this.dataSource.data = accommodations;
    });
  }

  addNew() {
    this.iepAccommodationService.accommodationAddNew.next();
  }

  anyEndDatesInPast(accommodations) {
    const amendmentEndDate = dayjs(accommodations.amendmentEndDate).toDate().setHours(23, 59, 59, 9999);
    const today = dayjs().toDate().setHours(0, 0, 0, 9999);
    return amendmentEndDate < today && accommodations.isActive;
  }

  anyEndDatesEqualOrPast(accommodations) {
    const amendmentEndDate = dayjs(accommodations.amendmentEndDate).toDate().setHours(23, 59, 59, 9999);
    const today = dayjs().toDate().setHours(0, 0, 0, 9999);
    return amendmentEndDate <= today;
  }

  canAmend(element) {
    return this.hasOpenAmendment && !this.anyEndDatesEqualOrPast(element) && !this.isEditing;
  }

  canEnd(element) {
    return (
      this.hasOpenAmendment && !this.anyEndDatesInPast(element) && (element?.isActive || (!element?.isActive && !!element?.priorVersionId))
    );
  }

  canRemove(element) {
    return (
      this.hasOpenAmendment && !this.anyEndDatesInPast(element) && !element?.priorVersionId && element.amendmentId === this.amendmentId
    );
  }

  canUndo(element) {
    return this.hasOpenAmendment && !this.anyEndDatesInPast(element) && element?.priorVersionId && element.amendmentId === this.amendmentId;
  }

  canIncludeTrialPlacement(element) {
    return (
      this.hasOpenAmendment &&
      !this.anyEndDatesEqualOrPast(element) &&
      !this.isEditing &&
      !element.includesTrialPlacement &&
      this.iepIncludesTrialPlacement
    );
  }

  canRemoveTrialPlacement(element) {
    return (
      this.hasOpenAmendment &&
      !this.anyEndDatesInPast(element) &&
      !this.isEditing &&
      element.includesTrialPlacement &&
      this.iepIncludesTrialPlacement
    );
  }

  canViewAuditLog(element) {
    return (
      this.hasOpenAmendment &&
      !this.anyEndDatesInPast(element) &&
      !this.isEditing &&
      (element?.isActive || element?.priorVersionId) &&
      !element?.amendmentEndDate
    );
  }

  onEdit(accommodation: IepAccommodationDto) {
    const callback = (_accommodation: IepAccommodationDto) => {
      this.showAccommodationsForm = true;
      this.amendingAccommodation = _accommodation;
      this.iepAccommodationService.setAmendingAccommodation.next(_accommodation);
      this.isEditing = true;
    };

    if (accommodation.amendmentId === null || accommodation.amendmentId !== this.amendmentId) {
      this.iepAccommodationService.amend(this.iepId, accommodation.id, this.amendment.id).subscribe((amendResult) => {
        callback(amendResult);
      });
    } else {
      callback(accommodation);
    }
  }

  viewAuditLog(accommodation) {
    const dialogRef = this.dialog.open(IepAccommodationInfoComponent, {
      width: '758px',
      data: {
        accommodation,
        id: accommodation.id,
        iepId: this.iepId,
      },
    });
  }

  onChangeProviders(element) {
    const dialogRef = this.dialog.open(IepChangeProviderComponent, {
      data: {
        id: element.id,
        caseId: this.route.snapshot.paramMap.get('caseId'),
        iepId: this.iepId,
        type: 'IEPAccommodation',
        dataElement: element,
      },
      width: '364px',
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.getAccommodations();
      }
    });
  }

  updateTrialPlacement(element: IepAccommodationDto, includedInTrialPlacement: boolean) {
    this.iepAccommodationService.updateTrialPlacement(element.iepId, element.id, includedInTrialPlacement).subscribe(() => {});
    this.dataSource.data.find((x) => x.id === element.id).includesTrialPlacement = includedInTrialPlacement;
    this.trialPlacementChange.emit();
  }

  onEnd(element) {
    this.dialog.open(IepAmendmentEndItemModalComponent, {
      width: '740px',
      data: {
        iepId: this.iepId,
        amendmentId: this.amendment?.id,
        type: 'Accommodation',
        model: element,
      },
    });
  }

  onRemove(accommodation, isUndo = false) {
    this.notificationService.confirmation(
      `Are you sure${isUndo ? '? This will delete this change.' : ' you want to delete this accommodation?'} `,
      () => {
        this.iepAccommodationService.deleteAccommodation(this.iepId, accommodation.id, true).subscribe(() => {
          this.notificationService.success(`Accommodation ${isUndo ? 'reverted' : 'deleted'}`);
        });
      }
    );
  }

  openComingSoon() {
    this.dialog.open(DialogComingSoonComponent, {
      width: '990px',
    });
  }

  getProviderString(providers, otherProvider?: string) {
    let returnString = '';
    if (providers) {
      let providerCount = 0;
      providers.forEach((provider) => {
        providerCount++;
        let name = provider.firstName;
        if (provider.lastName) {
          name = name + ' ' + provider.lastName;
        }
        returnString += name;
        if (providers.length !== providerCount) {
          returnString += ', ';
        }
      });
    }

    if (otherProvider) {
      return returnString ? returnString + `, ${otherProvider}` : `${otherProvider}`;
    }
    return returnString;
  }

  onViewMore(el) {
    el.includesTrialPlacement = this.trialPlacementTarget === this.trialPlacementTargetEnum.AllItems || el.includesTrialPlacement;
    this.dialog.open(IepAccommodationsViewMoreComponent, {
      width: '728px',
      data: {
        accommodation: el,
        amendmentId: this.amendmentId,
        amendmentIsFinalized: this.amendmentIsFinalized,
        lastFinalizedDate: this.lastFinalizedDate,
      },
    });
  }
}
