<mat-accordion class="accordion accordion--close accordion--short">
  <mat-expansion-panel class="mat-elevation-z0 no-body-padding">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="display-flex flex-1 justify-content-between align-items-center flex-wrap">
          <h3 class="my-0 text-normal">Documents</h3>
          <div *ngIf="learnerHasWorkableCase && hasUploadPermission">
            <button
              class="mr-3"
              mat-raised-button
              aria-label="Upload Documentation"
              [isBusy]="activeCall"
              color="primary"
              (click)="onUploadDocuments($event)"
              tabindex="0"
            >
              Upload Documentation
            </button>
            <button mat-raised-button aria-label="Print Documents" color="primary" (click)="onOpenPrintDocuments($event)" tabindex="0">
              Print Documents
            </button>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="row">
      <div class="col-md-12">
        <app-help-directional-text>
          <p class="mb-2">Click on the document title to view/print.</p>
        </app-help-directional-text>

        <form [formGroup]="formGroup">
          <div class="row">
            <div class="col-xl-9 col-md-12">
              <app-quick-date label="Label" formControlName="quickDate"></app-quick-date>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">
              <app-select label="Status:" formControlName="status" [options]="statusOptions"> </app-select>
            </div>
          </div>
        </form>
        <hr class="input__divider" />
        <div class="table-overflow">
          <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0 w-100">
            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <div class="display-flex align-items-center justify-content-start">
                  <button
                    aria-label="View Icon"
                    (click)="openRollCallModal(getMeeting(element.familyMeetingId), element.caseId, true)"
                    *ngIf="element.isSubmitted && element.type === 'Meeting Notice'"
                    mat-icon-button
                    color="primary"
                    tabindex="0"
                  >
                    <mat-icon>remove_red_eye</mat-icon>
                  </button>

                  <button
                    *ngIf="element.isUploaded && hasDocumentDeletePermission"
                    mat-icon-button
                    aria-label="Delete Icon"
                    color="warn"
                    (click)="deleteUserDocument(element.id)"
                    tabindex="0"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>

            <!-- Id Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
              <td mat-cell *matCellDef="let element">
                <a
                  class="text-underline"
                  (click)="editItem(element)"
                  *ngIf="element.canView || element.canViewCompleted || element.canEdit; else cantOpen"
                  tabindex="0"
                >
                  {{
                    element.isUploaded
                      ? element.title
                      : (element.title | removeObsoleteTerms | spellAbbreviationOut | associatedStateFrom : element.related)
                  }}
                </a>
                <ng-template #cantOpen>
                  {{
                    element.isUploaded
                      ? element.title
                      : (element.title | removeObsoleteTerms | spellAbbreviationOut | associatedStateFrom : element.related)
                  }}
                </ng-template>
              </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
              <td mat-cell *matCellDef="let element">
                {{
                  element.isSubmitted && (element.type === 'Disability Suspected' || element.type === 'Manifestation Determination')
                    ? 'Complete'
                    : !element.isSubmitted && (element.type === 'Disability Suspected' || element.type === 'Manifestation Determination')
                    ? 'Incomplete'
                    : element.isUploaded
                    ? 'Uploaded'
                    : element.isSubmitted && element.type === 'Meeting Notice'
                    ? 'Meeting Held'
                    : !element.isSubmitted && element.type === 'Meeting Notice'
                    ? 'Meeting Pending'
                    : element.isSubmitted
                    ? 'Complete'
                    : 'Incomplete'
                }}
              </td>
            </ng-container>

            <!-- SubmittedOn Column -->
            <ng-container matColumnDef="submittedOn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Submitted On</th>
              <td mat-cell *matCellDef="let element">
                {{ (element.submittedOn ? element.submittedOn : element.createdOn) | dateFormat }}
              </td>
            </ng-container>

            <ng-container matColumnDef="noResults">
              <td mat-footer-cell *matFooterCellDef colspan="7">
                <i>No results</i>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr
              mat-footer-row
              [hidden]="dataSource.data?.length !== 0"
              *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"
              class="example-second-footer-row"
            ></tr>
          </table>
          <mat-paginator [dataSource]="dataSource"></mat-paginator>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
