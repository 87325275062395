<div [hidden]="reportItems.length < 1 && !showForm">
  <mat-accordion class="accordion accordion--short" multi>
    <mat-expansion-panel class="mat-elevation-z0" #panel>
      <mat-expansion-panel-header>
        <mat-panel-title><h3 class="my-0 text-normal">Reporting Progress to Family</h3></mat-panel-title>
      </mat-expansion-panel-header>
      <app-help-directional-text>
        <p class="mb-2">Progress entered here should be summative and inclusive of all the learner's goals.</p>
      </app-help-directional-text>

      <!-- Entry form -->
      <div *ngIf="showFormGroup" [formGroup]="formGroup">
        <div class="row">
          <div class="col-3">
            <app-date-picker label="Date" controlName="date"></app-date-picker>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-textarea
              label="Overall Status Summary"
              formControlName="summary"
              [maxLength]="stringSizes.extraLarge"
              [rows]="2"
            ></app-textarea>
          </div>
        </div>
        <div class="display-flex justify-content-end align-items-center">
          <button mat-raised-button aria-label="Cancel Button" type="button" class="mr-2" (click)="onCancel()" tabindex="0">Cancel</button>
          <button
            mat-raised-button
            aria-label="Save Button"
            type="button"
            color="primary"
            class="mr-2"
            [disabled]="formGroup.invalid"
            [disableAfterBusy]="formGroup.invalid"
            [isBusy]="isLoading"
            (click)="onSave()"
            tabindex="0"
          >
            Save
          </button>
          <button
            mat-raised-button
            aria-label="Finalize and Create Report Button"
            type="button"
            color="primary"
            class="mr-2"
            [disabled]="formGroup.invalid"
            [disableAfterBusy]="formGroup.invalid"
            [isBusy]="isLoading"
            (click)="onSubmit()"
            tabindex="0"
          >
            Finalize and Create Report
          </button>
        </div>
      </div>

      <!-- Table -->
      <ng-container>
        <div class="table-overflow mb-2">
          <table mat-table class="w-100" matSort [dataSource]="dataSource">
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="mat-column-actions--more"></th>
              <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
                <ng-container *ngIf="!isEditing && enableForms && (element.createdOn > latestDocumentDate || !latestDocumentDate)">
                  <button mat-icon-button aria-label="Edit Icon" color="primary" (click)="onEdit(element)" tabindex="0">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button mat-icon-button aria-label="Delete Icon" color="warn" (click)="onDelete(element)" tabindex="0">
                    <mat-icon>close</mat-icon>
                  </button>
                </ng-container>
                <ng-container *ngIf="element.isEditing">
                  <button color="primary" mat-icon-button aria-label="Save Icon" (click)="onUpdate(element)" title="Save" tabindex="0">
                    <mat-icon>save</mat-icon>
                  </button>
                  <button color="warn" mat-icon-button aria-label="Clear Icon" (click)="onCancelEdit(element)" title="Cancel" tabindex="0">
                    <mat-icon>clear</mat-icon>
                  </button>
                </ng-container>
                <button mat-icon-button aria-label="View Icon" color="primary" (click)="onViewMore(element)" tabindex="0">
                  <mat-icon>remove_red_eye</mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
              <td mat-cell *matCellDef="let element">
                <app-inline-edit [editing]="element.isEditing">
                  <ng-container viewMode>
                    <!-- Do not use dateFormat pipe here as it produces a result a day earlier than expected -->
                    {{ element.date | date : shortDateFormat : 'utc' }}
                  </ng-container>
                  <ng-container editMode [formGroup]="tableFormGroup">
                    <app-date-picker label="Date" controlName="date"></app-date-picker>
                  </ng-container>
                </app-inline-edit>
              </td>
            </ng-container>

            <ng-container matColumnDef="summary">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Overall Status Summary</th>
              <td mat-cell *matCellDef="let element">
                <app-inline-edit [editing]="element.isEditing">
                  <ng-container viewMode>
                    {{ element.summary | truncate : 150 }}
                  </ng-container>
                  <ng-container editMode [formGroup]="tableFormGroup">
                    <app-textarea
                      label="Overall Status Summary"
                      formControlName="summary"
                      [maxLength]="stringSizes.extraLarge"
                      [rows]="2"
                    ></app-textarea>
                  </ng-container>
                </app-inline-edit>
              </td>
            </ng-container>

            <ng-container matColumnDef="noResults">
              <td mat-footer-cell *matFooterCellDef colspan="7">
                <i>No results...</i>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['actions', 'date', 'summary']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['actions', 'date', 'summary']"></tr>
            <tr
              [hidden]="dataSource.data.length > 0"
              mat-footer-row
              *matFooterRowDef="dataSource.data.length === 0 ? ['noResults'] : []"
            ></tr>
          </table>

          <mat-paginator [dataSource]="dataSource"></mat-paginator>
        </div>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div #bottom></div>
