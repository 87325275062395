import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { IepPrintModalComponent } from 'src/app/iep/modals/iep-print-modal/iep-print-modal.component';
import { IepSectionDetails } from 'src/app/iep/models/iep-section-details';
import { IepService } from 'src/app/iep/services/iep.service';
import { AppPermissions } from 'src/app/permissions';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { DialogComingSoonComponent } from 'src/app/shared/components/coming-soon/coming-soon.component';
import { FeatureFlagService } from 'src/app/shared/components/feature-flags/feature-flag.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { PdfOutputs, ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { NewWindowConfig, openNewWindow, openPdfWindow } from 'src/app/shared/windowHelpers';
import { RoutingService } from '../../../../shared/services/routing.service';

@Component({
  selector: 'app-iep-reports',
  templateUrl: './iep-reports.component.html',
  styleUrls: ['./iep-reports.component.scss'],
})
export class IepReportsComponent implements OnInit {
  iepId: string;
  caseId: string;
  documentId: string;
  @Input() canFinalize: boolean;
  @Input() finalized: boolean;
  @Input() pwnId: string;
  @Input() learnerId: string;
  @Input() showLockWarning: boolean;
  activeCall = false;
  pdfOutputs = PdfOutputs;
  loading = {
    finalization: false,
  };
  canFinalizeIep: boolean;
  generatingDraftIep = false;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private iepService: IepService,
    private notificationService: NotificationService,
    private readonly reportingService: ReportingService,
    private readonly routingService: RoutingService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.iepId = this.route.parent.snapshot.paramMap.get('iepId');
    this.caseId = this.route.parent.snapshot.paramMap.get('caseId');
    this.canFinalizeIep =
      this.authService.isSuperAdmin || this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.FinalizeIEP);
  }

  openPWNPopUp() {
    const config: NewWindowConfig = {
      path: `cases/${this.caseId}/pwns${this.pwnId ? '/' + this.pwnId : ''}`,
      popup: true,
    };
    openNewWindow(config);
  }

  finalizeIep() {
    let question = 'Are you sure you want to finalize? This action cannot be undone.';
    if (this.showLockWarning) {
      question = question.concat(
        '\n\nFinalizing this IEP will remove the ability to update any data for the learner in the legacy (WebIEP/IFSP) system. '
      );
    }
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        subQuestion: question,
      },
    });
    dialogRef.afterClosed().subscribe((yesImSure) => {
      if (yesImSure) {
        this.activeCall = true;
        this.iepService.finalize(this.iepId, null, this.showLockWarning).subscribe(
          () => {
            this.notificationService.success('IEP Finalized');
            this.createOutput(null);
            this.routingService.iepList(this.learnerId).then();
          },
          (error) => console.log(error)
        );
      }
    });
  }

  onViewDraftIep(): void {
    this.generatingDraftIep = true;

    const dialogRef = this.dialog.open(IepPrintModalComponent, {
      width: '450px',
      data: {
        iepId: this.iepId,
        learnerId: this.learnerId,
      },
    });

    dialogRef.afterClosed().subscribe((formData) => {
      if (formData) {
        this.createOutput(formData);
      } else {
        this.generatingDraftIep = false;
      }
    });
  }

  private createOutput(formData: IepSectionDetails) {
    if (this.featureFlagService.featureOn('outputIepEnabled')) {
      this.loading.finalization = true;
      this.reportingService.createIepOutput(this.iepId, formData, false).subscribe({
        next: (documentId: string) => {
          this.generatingDraftIep = false;
          this.handleCreateOutput(documentId);
        },
        error: (err) => {
          this.generatingDraftIep = false;
          this.handleError(err);
        },
      });
    }
  }

  private handleCreateOutput(documentId: string) {
    this.documentId = documentId;
    this.viewOutput();
  }

  private viewOutput() {
    openPdfWindow(this.learnerId, this.documentId);
  }

  private handleError(err) {
    this.loading.finalization = false;

    this.notificationService.errorWithAction("Couldn't open output", 'Why?', () =>
      this.notificationService.alert(err.error, "Couldn't open output")
    );
  }

  onComingSoon(): void {
    this.dialog.open(DialogComingSoonComponent);
  }
}
