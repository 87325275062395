import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FamilyMemberRegistrationComponent } from './family-member-registration/family-member-registration.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { FamilyMemberPortalRegistrationComponent } from './family-member-registration/family-member-portal-registration/family-member-portal-registration.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './forgot-password/reset-password/reset-password.component';
import { LearnerRegistrationComponent } from './learner-registration/learner-registration.component';
import { LearnerPortalRegistrationComponent } from './learner-registration/learner-portal-registration/learner-portal-registration.component';
import { IvrsLoginComponent } from './ivrs/ivrs-login/ivrs-login.component';
import { IvrsForgotPasswordComponent } from './ivrs/ivrs-forgot-password/ivrs-forgot-password.component';
import { IvrsMemberRegistrationComponent } from './ivrs/ivrs-member-registration/ivrs-member-registration.component';
import { IvrsResetPasswordComponent } from './ivrs/ivrs-reset-password/ivrs-reset-password.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'register', component: RegisterComponent, pathMatch: 'full' },
  {
    path: 'family-invite/:inviteId',
    component: FamilyMemberRegistrationComponent,
    pathMatch: 'full',
  },
  {
    path: 'family-members/confirm-invitation/:invitationId',
    component: FamilyMemberPortalRegistrationComponent,
    pathMatch: 'full',
  },
  {
    path: 'learner-invite/:inviteId',
    component: LearnerRegistrationComponent,
    pathMatch: 'full',
  },
  {
    path: 'learner/confirm-invitation/:invitationId',
    component: LearnerPortalRegistrationComponent,
    pathMatch: 'full',
  },
  { path: 'forgot-password', component: ForgotPasswordComponent, pathMatch: 'full' },
  { path: 'reset-password', component: ResetPasswordComponent, pathMatch: 'full' },
  {
    path: 'ivrs',
    children: [
      {
        path: 'confirm-invitation/:token',
        component: IvrsMemberRegistrationComponent,
        pathMatch: 'full',
      },
      {
        path: 'login',
        component: IvrsLoginComponent,
        pathMatch: 'full',
      },
      {
        path: 'forgot-password',
        component: IvrsForgotPasswordComponent,
        pathMatch: 'full',
      },
      {
        path: 'reset-password/:userId/:token',
        component: IvrsResetPasswordComponent,
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
