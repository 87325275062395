import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { ProgressMonitorReportItem } from 'src/app/monitoring-progress/models/progress-monitor-report-item';
import { ProgressMonitorReportService } from 'src/app/monitoring-progress/services/progress-monitor-report.service';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { StringSizes } from 'src/app/shared/components/form/constants/constants';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ViewMoreModalData } from 'src/app/shared/modals/view-more-modal/view-more-modal.component';
import { ConfirmationDialogComponent, NotificationService } from 'src/app/shared/services/notification.service';
import { ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { openViewMore } from 'src/app/shared/tableHelpers';
import { openPdfWindow } from 'src/app/shared/windowHelpers';
import { DateFormatPipe, DateToUtcPipe } from '../../../../../../shared/pipes/date-transform.pipe';

@Component({
  selector: 'app-reporting-progress-to-family',
  templateUrl: './reporting-progress-to-family.component.html',
  styleUrls: ['./reporting-progress-to-family.component.scss'],
})
export class ReportingProgressToFamilyComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('bottom') bottom: ElementRef;
  @ViewChild('panel') panel: MatExpansionPanel;

  @Input() learnerId: string;
  @Input() enableForms: boolean;
  reportItems: Array<ProgressMonitorReportItem> = [];
  dataSource = new MatTableDataSource<ProgressMonitorReportItem>();

  stringSizes = StringSizes;
  shortDateFormat = shortDateFormat;
  today = dayjs().startOf('day').toISOString();

  forceExpand: boolean;
  showForm: boolean;
  showFormGroup: boolean;

  isLoading = false;
  isEditing: boolean;

  formGroup = this.fb.group({
    date: [this.today, [Validators.required]],
    summary: ['', [Validators.required]],
  });

  tableFormGroup = this.fb.group({
    date: [null, [Validators.required]],
    summary: ['', [Validators.required]],
  });

  latestDocumentDate: Date;

  constructor(
    private readonly progressMonitorReportService: ProgressMonitorReportService,
    private readonly datePipe: DatePipe,
    private readonly dialog: MatDialog,
    private readonly fb: FormBuilder,
    private readonly notificationService: NotificationService,
    private readonly reportingService: ReportingService
  ) {}

  ngOnInit(): void {
    this.load();
    this.progressMonitorReportService.showForm.subscribe({
      next: () => this.handleShowForm(),
    });
  }

  onEdit(progressMonitorReportItem: ProgressMonitorReportItem) {
    this.isEditing = true;
    progressMonitorReportItem.isEditing = true;
    this.tableFormGroup.patchValue(progressMonitorReportItem);
  }

  onCancel() {
    this.formGroup.reset({ date: this.today });
    this.showFormGroup = false;
    if (this.reportItems.length < 1) {
      this.showForm = false;
    }
  }

  onSave() {
    if (this.formGroup.invalid) {
      return;
    }

    const dto = this.formGroup.value as ProgressMonitorReportItem;
    dto.date = new DateToUtcPipe().transform(new Date(dto.date));
    this.isLoading = true;
    this.progressMonitorReportService.create(this.learnerId, dto).subscribe({
      next: (res) => this.handleCreateReportItem(res),
      error: (err: HttpErrorResponse) => (this.isLoading = false),
    });
  }

  onDelete(progressMonitorReportItem: ProgressMonitorReportItem) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '728px',
      data: {
        title: 'Remove',
        message: 'Are you sure you want to remove this progress item report?',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.progressMonitorReportService.delete(progressMonitorReportItem.id, this.learnerId).subscribe(
          (result) => {
            if (result) {
              const index = this.reportItems.findIndex((x) => x.id === progressMonitorReportItem.id);
              this.reportItems.splice(index, 1);
              this.initializeDataSource();
              this.notificationService.success('Progress monitor report item deleted');
            }
          },
          (error) => {
            this.notificationService.error('Could not delete progress monitor report item');
          }
        );
      }
    });
  }

  onCancelEdit(progressMonitorReportItem: ProgressMonitorReportItem) {
    progressMonitorReportItem.isEditing = false;
    this.isEditing = false;
    this.tableFormGroup.reset();
  }

  onUpdate(progressMonitorReportItem: ProgressMonitorReportItem) {
    if (this.tableFormGroup.invalid) {
      return;
    }
    const dto = this.tableFormGroup.value as ProgressMonitorReportItem;
    dto.date = new DateToUtcPipe().transform(new Date(dto.date));
    dto.id = progressMonitorReportItem.id;
    this.progressMonitorReportService.update(this.learnerId, dto).subscribe(
      (res) => {
        const index = this.reportItems.findIndex((x) => x.id === res.id);
        if (index > -1) {
          this.reportItems[index] = res;
          this.reportItems[index].date = this.tableFormGroup.get('date').value;
          this.initializeDataSource();
          this.notificationService.success('Progress monitor report item updated');
          this.onCancelEdit(progressMonitorReportItem);
        }
      },
      (err) => {
        this.notificationService.error('Could not update progress monitor report item');
      }
    );
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }

    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        subQuestion: 'Clicking Yes will finalize and generate the Progress Report.',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.createReportItem();
      }
    });
  }

  onViewMore(reportItem: ProgressMonitorReportItem) {
    const modalData: ViewMoreModalData[] = [
      {
        name: 'Date',
        value: new DateFormatPipe().transform(reportItem.date),
      },
      {
        name: 'Overall Summary Status',
        value: reportItem.summary,
      },
    ];

    openViewMore(this.dialog, modalData);
  }

  private load() {
    this.progressMonitorReportService.get(this.learnerId).subscribe((res) => {
      this.reportItems = res;
      if (this.reportItems.some((x) => x.outputDocumentId != null))
        this.latestDocumentDate = this.reportItems
          .filter((x) => x.outputDocumentId != null)
          .reduce((a, b) => (a.createdOn > b.createdOn ? a : b)).createdOn;
      this.initializeDataSource();
    });
  }

  private createReportItem() {
    const dto = this.formGroup.value as ProgressMonitorReportItem;
    dto.date = new DateToUtcPipe().transform(new Date(dto.date));
    this.isLoading = true;
    this.progressMonitorReportService.create(this.learnerId, dto, true).subscribe({
      next: (res) => this.handleCreateReportItem(res),
      error: (err: HttpErrorResponse) => {
        this.isLoading = false;
        this.notificationService.errorWithAction("Couldn't open output", 'Why?', () =>
          this.notificationService.alert(err?.error[0]?.description, "Couldn't open output")
        );
      },
    });
  }

  private handleCreateReportItem(reportItem: ProgressMonitorReportItem) {
    this.formGroup.reset({ date: this.today });
    this.reportItems.push(reportItem);
    this.reportItems = this.reportItems.sort((a, b) => (a.date > b.date ? -1 : 1));
    this.initializeDataSource();
    this.onCancel();
    this.isLoading = false;
    if (!!reportItem.outputDocumentId) {
      this.latestDocumentDate = reportItem.createdOn;
      openPdfWindow(this.learnerId, reportItem.outputDocumentId);
    }
  }

  private initializeDataSource() {
    this.dataSource = new MatTableDataSource(this.reportItems);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private handleShowForm() {
    this.showForm = true;
    this.showFormGroup = true;
    if (!this.panel.expanded) {
      this.panel.toggle();
    }
    // Need to wait for the panel to be expanded before attempting to scroll
    setTimeout(() => this.bottom.nativeElement.scrollIntoView({ behavior: 'smooth' }), 200);
  }
}
