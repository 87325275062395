import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IntakeSections } from 'src/app/evaluation/early-access-intake/intake-sections/intake-sections';
import { EarlyAccessQuestionnaire } from 'src/app/evaluation/models/early-access-questionnaire';
import { Intake } from 'src/app/evaluation/models/intake';
import { IntakeService } from 'src/app/evaluation/services/intake.service';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { LengthUnits } from 'src/app/shared/models/length-units';
import { WeightUnits } from 'src/app/shared/models/weight-units';
import { AchieveConfigService } from 'src/app/shared/services/achieve-config-service/achieve-config.service';
import { HelpSection, HelpTerm } from 'src/app/shared/services/help/help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import {
  IntakeDentalVisionHearingHelp,
  IntakeHavingEnoughHelp,
  IntakeNutritionGrowthHelp,
} from 'src/app/shared/services/help/models/intake.help';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NutritionGrowthInformation } from '../../../models/health-information';

@Component({
  selector: 'app-nutrition-growth-info',
  templateUrl: './nutrition-growth-info.component.html',
  styleUrls: ['./nutrition-growth-info.component.scss'],
})
export class NutritionGrowthInfoComponent implements OnInit, OnChanges {
  @Input() formGroup: FormGroup;
  @Input() documentationInfo: FormGroup;
  @Input() intake: Intake;
  @Input() nutritionGrowthInfo: NutritionGrowthInformation;
  @Input() questionnaire: EarlyAccessQuestionnaire;
  @Input() isQuestionnaire: boolean;
  @Input() locked: boolean;
  @Input() importQuestionnaireSelected: boolean;
  @Output() upload = new EventEmitter();
  @Output() deleteDocument = new EventEmitter();

  intakeSections = IntakeSections;
  helpSection = HelpSection;
  intakeDentalVisionHearingHelp = IntakeDentalVisionHearingHelp;
  intakeNutritionGrowthHelp = IntakeNutritionGrowthHelp;
  intakeHavingEnoughHelp = IntakeHavingEnoughHelp;

  readonly PEACH_RECOMMEND_CHSC_THRESHOLD = 4;
  peachRecommendationFlag = {
    lastValue: null,
  };
  birthLengthOptions: KeyValuePair[] = [new KeyValuePair(LengthUnits.In, 'Inches'), new KeyValuePair(LengthUnits.Cm, 'Centimeters')];

  yesNoOptions: KeyValuePair[] = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];

  lastVisitWeightUnitsOptions: KeyValuePair[] = [
    new KeyValuePair(WeightUnits.lbOz, 'Pounds and Ounces'),
    new KeyValuePair(WeightUnits.g, 'Grams'),
    new KeyValuePair(WeightUnits.kg, 'Kilograms'),
  ];

  documentsToDisplay = [
    {
      title: 'PEACH Questionnaire',
      link: this.achieveConfigService.settings.peachEnglishUrl,
    },
    {
      title: 'PEACH Questionnaire (Spanish)',
      link: this.achieveConfigService.settings.peachSpanishUrl,
    },
  ];

  constructor(
    private notificationService: NotificationService,
    private intakeService: IntakeService,
    private helpService: HelpService,
    private achieveConfigService: AchieveConfigService
  ) {}

  get peachScore() {
    return +this.formGroup.controls.peachScore.value;
  }

  get childsName() {
    return this.isQuestionnaire
      ? this.questionnaire?.childInfo?.firstName + ' ' + this.questionnaire?.childInfo?.lastName
      : this.intake?.childInfo?.firstName + ' ' + this.intake?.childInfo?.lastName;
  }

  ngOnInit(): void {
    this.initializeControls();

    if (!this.isQuestionnaire) {
      this.formGroup.controls.nutritionConcern.valueChanges.subscribe((change) => {
        if (change) {
          this.setRequiredValidator('nutritionConcernDescription');
        } else {
          this.setNullToControl('nutritionConcernDescription');
        }
      });

      this.formGroup.controls.peachCompleted.valueChanges.subscribe((change) => {
        if (change) {
          this.formGroup.controls.peachScore.enable();
          this.setNullToControl('peachNotCompletedReason');
          this.setRequiredValidator('peachSummary');
        } else {
          this.formGroup.controls.peachScore.disable();
          this.setNullToControl('peachSummary');
          this.setRequiredValidator('peachNotCompletedReason');
        }
      });
    }

    this.formGroup.patchValue(this.nutritionGrowthInfo);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.importQuestionnaireSelected?.currentValue !== changes.importQuestionnaireSelected?.previousValue &&
      this.importQuestionnaireSelected
    ) {
      this.importNutritionInfoFromQuestionnaire();
      this.formGroup.markAsDirty();
    }
  }

  onOpenHelp(e: Event, section: HelpSection, item: HelpTerm) {
    e.preventDefault();

    const dictionary = this.helpService.getIntakeDictionary();
    this.helpService.openHelpModal({
      help: dictionary,
      section,
      item,
      canBrowse: true,
    } as HelpModalConfig);
  }

  importNutritionInfoFromQuestionnaire() {
    const questionnaireNutritionInfo = this.questionnaire?.healthInfo?.nutritionGrowthInformation;
    for (const prop in questionnaireNutritionInfo) {
      if (questionnaireNutritionInfo[prop] !== null && questionnaireNutritionInfo[prop] !== '') {
        this.nutritionGrowthInfo[prop] = questionnaireNutritionInfo[prop];
      }
    }
    this.formGroup.patchValue(this.nutritionGrowthInfo);
  }

  setNullToControl(controlName: string) {
    this.formGroup.controls[controlName].setValue(null);
    this.formGroup.controls[controlName].setValidators(null);
    this.formGroup.controls[controlName].updateValueAndValidity();
  }

  setMinAndMaxValidators(controlName: string) {
    this.formGroup.controls[controlName].setValidators([Validators.min(0), Validators.max(33)]);
    this.formGroup.controls[controlName].updateValueAndValidity();
  }

  setRequiredValidator(controlName: string) {
    this.formGroup.controls[controlName].setValidators(Validators.required);
    this.formGroup.controls[controlName].updateValueAndValidity();
  }

  private initializeControls() {
    this.formGroup.addControl('lastVisitWeightUnits', new FormControl(null));
    this.formGroup.addControl('lastVisitWeightInLb', new FormControl(null, { validators: [Validators.min(0)] }));
    this.formGroup.addControl('lastVisitWeightInOz', new FormControl(null, { validators: [Validators.min(0)] }));
    this.formGroup.addControl('lastVisitWeightInGrams', new FormControl(null, { validators: [Validators.min(0)] }));
    this.formGroup.addControl('lastVisitWeightInKilograms', new FormControl(null, { validators: [Validators.min(0)] }));
    this.formGroup.addControl('lastVisitLengthUnits', new FormControl(null));
    this.formGroup.addControl('lastVisitLength', new FormControl(null, { validators: [Validators.min(0)] }));

    if (!this.isQuestionnaire) {
      this.formGroup.addControl(
        'nutritionConcern',
        new FormControl(null, {
          updateOn: 'change',
          validators: Validators.required,
        })
      );
      this.formGroup.addControl('nutritionConcernDescription', new FormControl(null));
      this.formGroup.addControl(
        'peachCompleted',
        new FormControl(null, {
          updateOn: 'change',
          validators: Validators.required,
        })
      );
      this.formGroup.addControl(
        'peachScore',
        new FormControl(null, {
          updateOn: 'change',
          validators: [Validators.required, Validators.min(0), Validators.max(33)],
        })
      );
      this.formGroup.addControl(
        'agreeToRefer',
        new FormControl(null, {
          updateOn: 'change',
        })
      );
      this.formGroup.addControl('peachSummary', new FormControl(null, Validators.required));
      this.formGroup.addControl('peachNotCompletedReason', new FormControl(null, Validators.required));
      this.formGroup.addControl('nutritionAdditionalInformation', new FormControl(null));
    }
  }

  weightTypeChanged() {
    this.formGroup.patchValue({
      lastVisitWeightInLb: null,
      lastVisitWeightInOz: null,
      lastVisitWeightInGrams: null,
      lastVisitWeightInKilograms: null,
    });
  }

  lengthUnitsChanged() {
    this.formGroup.patchValue({ lastVisitLength: null });
  }

  onUpload(formData: FormData) {
    this.upload.emit(formData);
  }

  onDeleteDocument(documentId: string) {
    this.deleteDocument.emit(documentId);
  }

  evaluatePeachScore() {
    const score = this.peachScore;
    if (score >= this.PEACH_RECOMMEND_CHSC_THRESHOLD && this.peachRecommendationFlag.lastValue !== score) {
      this.peachRecommendationFlag.lastValue = score;
      this.notificationService.alert(
        'The PEACH score results indicate the need for additional information. Please refer the family to CHSC.'
      );
    } else if (score < this.PEACH_RECOMMEND_CHSC_THRESHOLD) {
      this.formGroup.get('agreeToRefer').setValue(null);
    }
    this.peachRecommendationFlag.lastValue = score;
  }
}
