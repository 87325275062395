import { Component, Input, OnInit } from '@angular/core';
import { IfspModification } from '../../../../../../../ifsp/models/ifsp-modification';
import { CaseSummaryByIfspId } from '../../../../../../../shared/models/case';
import { DataHistoryService } from '../../../../../services/data-history.service';
import { IfspModificationService } from '../../../../../../../ifsp/services/ifsp-modification.service';
import { CaseService } from '../../../../../../../shared/services/case/case.service';
import { IfspService } from '../../../../../../../ifsp/services/ifsp.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-other-services-ifsp-view',
  templateUrl: './other-services-ifsp-view.component.html',
  styleUrls: ['./other-services-ifsp-view.component.scss'],
})
export class OtherServicesIfspViewComponent implements OnInit {
  ifspIdVal: string;
  @Input()
  set ifspId(val) {
    this.ifspIdVal = val;
    this.updateIfspData();
  }
  get ifspId() {
    return this.ifspIdVal;
  }
  ifspModifications: IfspModification[];
  caseSummary: CaseSummaryByIfspId;
  constructor(
    private dataHistoryService: DataHistoryService,
    private ifspModificationService: IfspModificationService,
    private caseService: CaseService
  ) {}

  ngOnInit(): void {}
  updateIfspData() {
    if (this.ifspIdVal) {
      console.log('my id', this.ifspIdVal);
      forkJoin([
        this.ifspModificationService.getModificationsByIfspId(this.ifspIdVal),
        this.caseService.getCaseSummaryByIfsp(this.ifspIdVal),
      ]).subscribe(async ([ifspModifications, caseSummary]) => {
        this.ifspModifications = ifspModifications;
        this.caseSummary = caseSummary;
      });
    }
  }
}
