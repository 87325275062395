import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, Optional, Self, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseWrapperComponent } from '../base-wrapper.component';
import { LoggerService } from '../../../services/logger/logger.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-checkbox-single',
  templateUrl: './checkbox-single.component.html',
  styleUrls: ['./checkbox-single.component.scss'],
})
export class CheckboxSingleComponent extends BaseWrapperComponent implements OnInit, OnChanges, OnDestroy {
  value: any;
  @Input() wrap = false;
  @Input() column = false;
  @Input() showAsterisk = false;
  @Input() hideAsterisk = false;

  constructor(
    logger: LoggerService,
    @Self()
    @Optional()
    ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(logger, ngControl, changeDetectorRef);
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  private init(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {}
}
