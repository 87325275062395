<form [formGroup]="formGroup">
  <div class="flex-container">
    <div *ngIf="aeaOptions" class="flex-item">
      <app-autocomplete label="Aea" [options]="aeaOptions" formControlName="aeaId"></app-autocomplete>
    </div>
    <div *ngIf="districtOptions" class="flex-item">
      <app-autocomplete label="District" [options]="districtOptions" formControlName="districtId"></app-autocomplete>
    </div>
    <div *ngIf="buildingOptions" class="flex-item">
      <app-autocomplete label="Building" [options]="buildingOptions" formControlName="buildingId"></app-autocomplete>
    </div>
  </div>
</form>
