<app-learner-info
  [learnerSummary]="learnerSummary"
  [showAgeInMonths]="isPartC"
  [showFamilyMembersInPrimary]="isPartC"
  [condensed]="condensed"
  [fewFields]="fewFields"
  [isPrint]="isPrint"
>
  <ng-template #primaryInfo>
    <ng-container *ngIf="isPartC; else partBPrimaryInfo">
      <app-key-value-label *ngIf="learnerSummary?.referralDate" key="Referral Date" [value]="learnerSummary.referralDate | dateFormat">
      </app-key-value-label>
      <app-key-value-label key="Service Coordinator" [value]="serviceCoordinator"> </app-key-value-label>
      <app-key-value-label
        *ngIf="learnerSummary?.initialDueDate"
        key="Initial Due by Date"
        [value]="learnerSummary.initialDueDate | dateFormat"
      >
      </app-key-value-label>
    </ng-container>
    <ng-template #partBPrimaryInfo>
      <app-key-value-label key="Grade" [value]="learnerSummary?.gradeDescription || '-'"> </app-key-value-label>
      <app-key-value-label class="text-md" key="State ID" [value]="learnerSummary?.stateAssignedId || '-'"> </app-key-value-label>
      <app-key-value-label class="text-md" key="Resident District" [value]="learnerSummary?.residentDistrict || '-'"> </app-key-value-label>
      <app-key-value-label class="text-md" key="Attending District" [value]="learnerSummary?.attendingDistrict || '-'">
      </app-key-value-label>
      <app-key-value-label class="text-md" key="AEA" [value]="learnerSummary?.attendingAea || '-'"> </app-key-value-label>
      <app-key-value-label class="text-md" key="Consent Date" [value]="(learnerSummary.fiieFormCompletition | dateFormat) || '-'">
      </app-key-value-label>
    </ng-template>
  </ng-template>
  <ng-template #secondaryInfo>
    <ng-container *ngIf="isPartC; else partBSecondaryInfo">
      <app-key-value-label *ngIf="learnerSummary?.stateAssignedId" class="text-md" key="State ID" [value]="learnerSummary?.stateAssignedId">
      </app-key-value-label>
      <app-key-value-label *ngIf="learnerSummary?.gender" class="text-md" key="Gender" [value]="learnerSummary?.gender">
      </app-key-value-label>
      <app-key-value-label *ngIf="learnerSummary?.attendingAea" class="text-md" key="AEA" [value]="learnerSummary?.attendingAea">
      </app-key-value-label>
      <app-key-value-label *ngIf="learnerSummary?.county" class="text-md" key="County" [value]="learnerSummary?.county">
      </app-key-value-label>
      <app-key-value-label
        *ngIf="learnerSummary?.residentDistrict"
        class="text-md"
        key="Resident District"
        [value]="learnerSummary?.residentDistrict"
      >
      </app-key-value-label>
      <app-key-value-label
        *ngIf="learnerSummary?.attendingDistrict"
        class="text-md"
        key="Attending District"
        [value]="learnerSummary?.attendingDistrict"
      >
      </app-key-value-label>
    </ng-container>
    <ng-template #partBSecondaryInfo>
      <app-key-value-label *ngIf="learnerSummary?.gender" class="text-md" key="Gender" [value]="learnerSummary?.gender">
      </app-key-value-label>

      <app-key-value-label
        *ngIf="learnerSummary?.eligibilityFacilitatorName"
        class="text-md"
        key="Eligibility Facilitator Name"
        [value]="learnerSummary.eligibilityFacilitatorName"
      >
      </app-key-value-label>

      <app-key-value-label
        *ngIf="learnerSummary?.eligibilityDeterminationDueDate"
        class="text-md"
        key="Eligibility Determination Due by Date"
        [value]="learnerSummary.eligibilityDeterminationDueDate | dateFormat"
      >
      </app-key-value-label>

      <app-key-value-label
        *ngIf="learnerSummary?.eligibilityMeetingDate"
        class="text-md"
        key="Date of Eligibility Meeting"
        [value]="learnerSummary.eligibilityMeetingDate | dateFormat"
      >
      </app-key-value-label>
    </ng-template>
  </ng-template>
</app-learner-info>
