import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { LoginModel } from '../auth-models';
import { AuthService } from '../auth.service';
import { LearnerInvite } from './models/learner-invite';
import { LearnerPortalRegistrationDto } from './models/learner-portal-registration-dto';
import { LearnerRegistration } from './models/learner-registration';

@Component({
  selector: 'app-learner-registration',
  templateUrl: './learner-registration.component.html',
  styleUrls: ['./learner-registration.component.scss'],
})
export class LearnerRegistrationComponent implements OnInit {
  invite: LearnerInvite;
  registerError: string;
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private learnerService: LearnerService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    const inviteId = this.route.snapshot.paramMap.get('inviteId');
    console.log(inviteId);
    this.learnerService.getLearnerInvite(inviteId).subscribe(
      (invite) => {
        if (!invite) {
          this.router.navigate(['/']);
          return;
        }
        this.invite = invite;
        console.log(this.invite);
        if (invite.verified) {
          this.router.navigate(['/auth/login']);
        }
      },
      (error) => console.log(error)
    );
  }

  onSubmit(event) {
    if (this.invite.learnerUserId && this.invite.verified) {
      this.loginVerifiedUser(event);
    } else {
      this.registerLearner(event);
    }
  }

  registerLearner(event) {
    this.registerError = undefined;
    const learnerRegistration = event as LearnerPortalRegistrationDto;
    this.learnerService.confirmInvitation(learnerRegistration).subscribe(
      (response) => {
        if (response.succeeded) {
          this.notificationService.success('Confirmation succeeded');
          this.loginVerifiedUser({
            username: response.value.email,
            password: learnerRegistration.password,
          });
        } else {
          this.notificationService.error(response.errors?.map((x) => x.description).join(','));
        }
      },
      (error: HttpErrorResponse) => {
        this.registerError = error.error.complexity ? error.error.complexity : '';
      }
    );
  }

  loginVerifiedUser(event) {
    const loginModel = {
      email: event.username,
      password: event.password,
    } as LoginModel;

    this.authService.login(loginModel).subscribe((res) => {
      if (this.authService.isAuthenticated) {
        this.authService.navigateToPortal();
      }
    });
  }
}
function catchError(arg0: () => any): any {
  throw new Error('Function not implemented.');
}
