import { ReferralSourceCategory } from 'src/app/child-find/early-access-referral/referral-source-category';
import { MeetingParticipantRead } from 'src/app/learner-management/family-meeting/participants-list/meeting-participants';
import { IepStatus, TrialPlacementTarget } from '../../iep/models/iep';
import { Building } from './building';
import { FamilyMemberType, LearnerSummary } from './learner';
import { UserNameOnly } from './user-name-only';

export enum IEPStatus {
  NonActive = 'No Active Case',
  ChildFind = 'Child Find',
  Exited = 'Exited',
  ActiveIEP = 'Active IEP',
  ActiveIFSP = 'Active IFSP',
}

export interface CaseHistoryItem {
  dateTime: Date;
  eventType: string;
  eventDescription: string;
}

/*export interface CaseLearner {
  firstName: string;
  lastName: string;
  fullName: string;
  dateOfBirth: string;
  age: number;
  email: string;
  gender: string;
  stateAssignedId: string;
  attendingDistrictId: string;
  attendingDistrict: string;
  residentDistrictId: string;
  residentDistrict: string;
  residentAeaId: string;
  attendingAeaId: string;
  gradeDescription: string;
  familyMembers: CaseLearnerFamilyMember[];
  eligibilitySubmittedOnIntake?: boolean;
  isPK?: boolean;
  isSecondaryTransition: boolean;
  kindergartenStartDate?: any;
  languageSpokenInHome: string;
}

export interface CaseLearnerFamilyMember {
  id: string;
  fullName: string;
  email: string;
  homePhone: string;
  workPhone: string;
  cellPhone: string;
  relationship: string;
  familyUserId: string;
}*/

// A smaller viewmodel for working with several cases at once
export interface CaseListItem {
  id: string;
  learnerId: string;
  learnerStateId: string;
  learnerBuildingId: string;
  learnerFullName: string;
  learnerFirstName: string;
  learnerLastName: string;
  learnerDateOfBirth: string;
  learnerThirdBirthday: string;
  learnerGrade: string;
  learnerCounty: string;
  familyMemberEmails: string[];
  initialDueDate: string;
  periodicDueDate: string;
  annualDueDate: string;
  reevaluationDueDate: string;
  beginTransitionDate: string;
  transitionMtgDate: string;
  iepStatus: IEPStatus;

  newLearner: boolean;
  caseOwnerId: string;
  residentAea: string;
  attendingDistrict: string;
  attendingDistrictId: string;
  residentDistrict: string;
  residentDistrictId: string;

  referralDate: string;
  disabilitySuspectFormId: string;
  fiieConsentFormId: string;
  fiieConsentIsApproved: boolean;
  disabilitySuspectFormSubmitted: boolean;
  consentIsApproved: boolean;
  intakeType: IntakeType;
  activeEvaluationId: string;
  activeReevaluationId: string;
  intakeLocked?: boolean;
  activePwn?: string;

  caseUsers: CaseUserSummary[];

  canBeginPeriodicReview?: boolean;
  canBeginAnnualReview?: boolean;
  currentIFSPId?: string;
  canSendPeriodicReviewPoll?: boolean;
  canSendAnnualReviewPoll?: boolean;

  showESY?: boolean;
  activeIep: boolean;
  draftIEPId?: string;
  hasCompleteEvaluation: boolean;
  activeIfsp: boolean;
  exitFinalized: boolean;
  attendingAeaName: string;
}

export interface CaseSummary {
  id: string;
  /***
   * This is here for legacy sake. Once all caseSummary calls are converted
   * we can then get rid of caseId
   */
  caseId: string;
  learnerId: string;
  learner: LearnerSummary;
  caseOwnerId: string;
  caseOwner: CaseOwner;
  /***
   * This is an array of full names
   */
  sharedUsers: string[];
  isFirstPartBCase?: boolean;
  intakeLockedOn?: Date;
  isCaseUser: boolean;
  isActive: boolean;
  newLearner: boolean;
  intakeType: IntakeType;
  earlyAccessReferralId: string;
  earlyAccessReferralSubmittedOn: string;
  disabilitySuspectFormId: string;
  disabilitySuspectFormSubmitted: boolean;
  disabilitySuspectFormOpenedOn: Date;
  disabilitySuspectFormCreatedOn: Date;
  disabilitySuspectFormUpdatedOn: Date;
  fiieConsentFormId: string;
  consideringSld?: boolean;
  hasEvaluationConsent: boolean;
  evaluationId: string;
  evaluationFinalized?: boolean;
  eligibilityDecision?: boolean;
  exchangeElectronicCommunication: boolean;
  caseUserIds: string[];
  caseUsers: CaseUserSummary[];
  scheduledTransfers: ScheduledCaseTransferRead[];
  scheduledTransferredUserInfo: ScheduledCaseTransferredUser;
  newChildReferralSourceEmail: string;
  familyHasBeenInvited: boolean;
  activeIepId: string;
  activeIepStatus: IepStatus;
  activeIepIsPKToK: boolean;
  activeIepType: string;
  activeIepStartDate?: Date;
  activeIepCreatedOn?: Date;
  activeIfspStartDate?: Date;
  activeIepIncludesTrialPlacement: boolean;
  activeIepTrialPlacementTarget?: TrialPlacementTarget;
  previousIepId: string;
  hasIfsp: boolean;
  activeIfspId: string;
  mostRecentFbaId: string;
  bipId: string;
  finalizedIfsp: boolean;
  finalizedIep: boolean;
  socialBehaviorIdentified: boolean;
  draftReevaluationId: string;
  postSecondarySummaryId: string;
  caseClosureLetterDocumentId?: string;
  resultsLetterDocumentId?: string;
  exiting: boolean;
  isSystemExit: boolean;
  exitStartedOn?: Date;
  exitDate?: Date;
  exitReasonId: string;
  exitReasonCode: string;
  childFindExitReasonId: string;
  childFindExitReasonCode: string;
  childFindExitDate?: Date;
  exitFinalizedOn?: Date;
  exitFinalized: boolean;
  pwnConsentEaDocumentId?: string;
  isBusy?: boolean;
  activeIfspAnnualReview: boolean;
  activeIfspPeriodicReview: boolean;
  pwnId?: string;
  createdOn: Date;
  hasMoveInDetails?: boolean;
  movingFromOutOfState?: boolean;
  //TODO: These properties don't exist on C# CaseSummaryDto, but on LearnerSummaryDto.  Find any references to these properties and fix/redirect to using the learner properties
  eligibleInLegacySystem?: boolean;
  eligibleInLegacySystemPartC?: boolean;
  reevaluationDueOn?: Date;
  isMigrated: boolean;
}

export interface CaseSummaryByIfspId {
  ifspId: string;
  caseId: string;
  pwnId: string;
  ifspStartDate: Date;
  learnerId: string;
  learner: LearnerSummary;
  caseOwnerId: string;
  caseOwner: CaseOwner;
  evaluationId: string;
  /***
   * This is an array of full names
   */
  sharedUsers: string[];
  isCaseUser: boolean;
  intakeType: IntakeType;
  eligibilityDecision?: boolean;
  caseUserIds: string[];
  caseUsers: CaseUserSummary[];
  bipId: string;
  hasDraftIFSPs: boolean;
}

export interface CaseSummaryByIepId {
  id: string;
  caseId: string;
  pwnId: string;
  learnerId: string;
  learner: LearnerSummary;
  caseOwnerId: string;
  caseOwner: CaseOwner;
  evaluationId: string;
  /***
   * This is an array of full names
   */
  sharedUsers: string[];
  isCaseUser: boolean;
  intakeType: IntakeType;
  eligibilityDecision?: boolean;
  caseUserIds: string[];
  caseUsers: CaseUserSummary[];
  isSecondaryTransition: boolean;
  isFirstSecondaryTransition: boolean;
  bipId: string;
  fbaId: string;
  bipNeeded: boolean;
  hasDraftIEPs: string;
  activeIepIsPKToK: boolean;
  iepFinalized: boolean;
  esyDocumentId?: string;
  iepDocumentId?: string;
}

export interface CaseOwner {
  id: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  jobTitle: string;
  buildings: Building[];
  aeaId: string;
}

export interface CaseUserSummary {
  id: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  isOwner: boolean;
  role: string;
  dateAssigned: Date;
  aea: string;
  profession: string;
}

export interface ReferralSourceInfo {
  referralSource: FamilyMemberType;
  ReferralSourceEmail: string;
  ReferralSourceName: string;
  ReferralSourceAddress: string;
  ReferralSourceCity: string;
  ReferralSourceZipCode: string;
  ReferralSourceState: string;
  ReferralSourceAgency: string;
  ReferralSourceCategory: ReferralSourceCategory;
  ReferralSourceCategoryId: string;
  ReferralSourceCellPhone: string;
  ReferralSourceHomePhone: string;
  ReferralSourceWorkPhone: string;
  ReferralSourceHowHearAboutUs: string;
  ReferralSourceHowHearAboutUsId: string;
}

export enum IntakeType {
  PartB = 'PartB',
  PartCLate = 'PartCLate',
  PartC = 'PartC',
  Both = 'Both',
}

export interface CaseCreate {
  learnerId: string;
  earlyAccessReferralId?: string;
  movingFromOutOfState?: boolean;
  eligibleInLegacySystem?: boolean;
  referralId?: string;
}

export interface SifCaseCreate {
  movingFromOutOfState?: boolean;
  eligibleInLegacySystem?: boolean;
  referralId?: string;
  studentPersonal?: any;
  currentEnrollment?: any;
}

export interface ScheduledCaseTransferRead {
  userId: string;
  caseId: string;
  date: Date;
  role: CaseUserRole;
  user: UserNameOnly;
}

export interface ScheduledCaseTransferredUser {
  transferredUserId: string;
  transferredUser: UserNameOnly;
  dateAssigned: Date;
}

export interface ScheduledCaseTransferUpdate {
  userId: string;
  date: Date;
  role: CaseUserRole;
}

export enum CaseUserRole {
  Aea = 'AEA',
  ServiceCoordinator = 'ServiceCoordinator',
  Shared = 'Shared',
}

export interface FamilyMeetingRead {
  dateAndTime: Date;
  location: string;
  status: string;
  id: string;
  participants: MeetingParticipantRead[];
  participantsDataSource: any;
  purposesOfMeeting?: string[];
  inviteIsReferrer?: boolean;
  isInitialIfsp: boolean;
  isIep: boolean;
  alternateContactName: string;
  alternateContactEmail: string;
  alternateContactPhone: string;
  alternateContactPosition: string;
  contactPerson?: UserNameOnly;
  meetingRollCallDate?: Date;
  intakeType: IntakeType;
  caseId: string;
  meetingNotices: FamilyMeetingNotice[];
}

export interface FamilyMeetingNotice {
  id: string;
  documentId: string;
  createdOn: Date;
  familyMeetingId: string;
}

export interface FamilyMeetingCreate {
  dateAndTime: Date;
  purposesOfMeeting: string[];
  otherPurposeDescription: string;
  location: string;
  contactPersonId: string;
  alternateContactName: string;
  alternateContactPosition: string;
  alternateContactEmail: string;
  alternateContactPhone: string;
  participants: MeetingParticipantRead[];
  inviteIsReferrer?: boolean;
  questionnaireRecipientId?: string;
  forceSecondaryTransition?: boolean;
  leaRepresentative?: string;
}

export interface FamilyMeetingUpdate {
  id: string;
  dateAndTime: Date;
  location: string;
  rescheduledReasonId: string;
}

export interface AddFamilyMeetingParticipant {
  meetingId: string;
  userParticipantIds: string[];
  familyMemberParticipantIds: string[];
  otherAgencyProgramIds: string[];
  medicalSpecialistIds: string[];
  phcpLearnerId: string;
}

export interface EvaluationForLeanerManagement {
  id: string;
  isActive: boolean;
  isFinalized: boolean;
  date: Date;
  type: EvaluationType;
  intakeType: IntakeType;
  learnerId: string;
  eerDocumentId?: string;
  evaluationSummaryDocumentId?: string;
  caseId: string;
}

export enum EvaluationType {
  Evaluation = 'Evaluation',
  Reevaluation = 'Reevaluation',
  ContinuedEvaluation = 'ContinuedEvaluation',
}

export interface QuickLinkDetails {
  intakeType: string;
  intakeLocked: boolean;
  intakeFinalized: boolean;
  referralThankYouLetterSent: boolean;
  earlyAccessReferralId: string;
  resultLetterSent: boolean;
  thankYouLetterDocumentId?: string;
  screeningResultsDocumentId?: string;
  disabilitySuspectFormId: string;
  disabilitySuspectFormSubmitted: boolean;
  fiieConsentFormId: boolean;
  fiieConsentSubmitted: boolean;
  fiieConsentStatus: string;
  hasIepIfsp: boolean;
  pwnId: string;
  ifspId: string;
  iepId: string;
  hasActiveIepOrIfsp: boolean;
  hasActiveIfsp: boolean;
  evaluationId: string;
  evaluationFinalized: boolean;
  reevaluationId: string;
  showESY: boolean;
  showNewLearnerInformation: boolean;
  canBeginPeriodicReview: boolean;
  canBeginAnnualReview: boolean;
  activeMdId: string;
  ageOfMajorityLetterSent: boolean;
  ageOfMajorityDocumentIds: string[];
}

export interface RescheduleReason {
  id: string;
  label: string;
}

export interface ExitCaseInfo {
  exiting: boolean;
  exitDate?: Date;
  exitReasonId?: string;
  childFindExitReasonId?: string;
  childFindExitDate?: Date;
  isSystemExit: boolean;
}

export enum ExitCaseCodes {
  NDS = 'NDS',
  CNS = 'CNS',
  CSD = 'CSD',
  NMH = 'NMH',
  ENE = 'ENE',
  ERI = 'ERI',
  PDE = 'PDE',
  MOS = 'MOS',
  MUK = 'MUK',
  NSC = 'NSC',
}

export interface ExitStep {
  name: string;
  description: string;
  data: any;
}

export enum ExitStepType {
  familyContact = 'familyContact',
  consentPostReferral = 'consentPostReferral',
  consentInitialEvaluation = 'consentInitialEvaluation',
  postReferral = 'postReferral',
  evaluationAndAssessment = 'evaluationAndAssessment',
  pwn = 'pwn',
  postsecondary = 'postsecondary',
  eco = 'eco',
  transitionPlanning = 'transitionPlanning',
  pkEco = 'pkEco',
  reevaluation = 'reevaluation',
  reevaluationMeeting = 'reevaluationMeeting',
}

export interface ReopenCaseDto {
  learnerId: string;
  intakeType: IntakeType;
  startNewCase: boolean;
  caseId?: string;
  activateIep: boolean;
  retainEligibility?: boolean;
  reopenStatus?: ReopenStatus;
}

export enum ReopenStatus {
  ActiveIepInAnotherState = 'ActiveIepInAnotherState',
  NotEligibleInAnotherState = 'NotEligibleInAnotherState',
  NoInformationFromAnotherState = 'NoInformationFromAnotherState',
  IncorrectlyExited = 'IncorrectlyExited',
}
