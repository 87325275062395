<div class="display-flex justify-content-between align-items-center mb-2">
  <h3 class="my-0">IEP Management</h3>

  <div *ngIf="isCaseWorkable">
    <ng-container *ngIf="canEnterProgressData">
      <button mat-raised-button aria-label="Enter/View Progress" color="primary" class="mr-2" (click)="enterProgress()" tabindex="0">
        Enter/View Progress
      </button>
    </ng-container>
    <ng-container *ngIf="canViewProgressData">
      <button
        mat-raised-button
        aria-label="Print Goals Progress Report"
        color="primary"
        class="mr-2"
        [isBusy]="loading.iepGoalProgressOutput"
        (click)="onPrintGoalsProgressReport()"
        tabindex="0"
      >
        Print Goals Progress Report
      </button>
    </ng-container>
    <ng-container *ngIf="canStartIep">
      <button
        mat-raised-button
        aria-label="Start New IEP"
        color="primary"
        class="mr-2"
        [disabled]="!enableIEPBtn"
        [disableAfterBusy]="!enableIEPBtn"
        [isBusy]="creatingIep"
        (click)="createIep()"
        tabindex="0"
      >
        Start New IEP
      </button>
      <button
        mat-raised-button
        aria-label="Start New Interim IEP"
        color="primary"
        [disabled]="!enableIEPBtn"
        [disableAfterBusy]="!enableIEPBtn"
        [isBusy]="creatingIep"
        (click)="onStartInterim()"
        tabindex="0"
      >
        Start New Interim IEP
      </button>
    </ng-container>
  </div>
</div>

<mat-card [ngClass]="{ 'pt-1': ieps.length }">
  <p *ngIf="!ieps.length" class="my-0">No IEPs...</p>
  <ng-container *ngIf="ieps.length">
    <div class="table-overflow app-table">
      <table matSort mat-table [dataSource]="dataSource" class="w-100">
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <mat-menu #actionMenu="matMenu">
              <button
                *ngIf="element.showESY && !isPortalUser && !isVrUser"
                (click)="goToESY(element)"
                mat-menu-item
                aria-label="ESY Plan"
                tabindex="0"
              >
                ESY Plan
              </button>
              <button mat-menu-item aria-label="BIP" (click)="goToBip()" *ngIf="canViewBip" tabindex="0">BIP</button>
              <button *ngIf="canResetDates(element)" mat-menu-item aria-label="Reset Dates" (click)="onResetDates(element)" tabindex="0">
                Reset Dates
              </button>

              <button
                *ngIf="achieveSettings.printAssociatedDocuments && element.documents?.length > 0"
                mat-menu-item
                aria-label="Print Documents"
                (click)="onPrintDocuments(element)"
                tabindex="0"
              >
                Print Documents
              </button>
              <button
                *ngIf="achieveSettings.printAssociatedDocuments"
                mat-menu-item
                aria-label="Print IEP Sections"
                (click)="onPrintIEP(element.id)"
                tabindex="0"
              >
                Print IEP Sections
              </button>
              <button
                *ngIf="element.isPK && element.iepStatus === 'Draft' && isSuperAdmin && learner.gradeDescription === 'K'"
                mat-menu-item
                aria-label="Change to K IEP"
                (click)="onChangeToKIEP(element.id)"
                tabindex="0"
              >
                Change to K IEP
              </button>
              <button *ngIf="canDeleteIep(element)" mat-menu-item aria-label="Delete" (click)="onDeleteIep(element)" tabindex="0">
                Delete IEP
              </button>
            </mat-menu>

            <button
              mat-icon-button
              aria-label="More option"
              color="primary"
              [matMenuTriggerFor]="actionMenu"
              *ngIf="showActions(element) && isCaseWorkable"
              tabindex="0"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="createdOn">
          <th mat-sort-header mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdOn | dateFormat }}<br />
            <span *ngIf="element.iepStatus === iepStatuses.Active && element.isModifying" [class.amending]="element.isModifying">
              Active - Amending
            </span>
            <span *ngIf="element.iepStatus === iepStatuses.Active && !element.isModifying">
              <ng-container *ngIf="!element.amendments || element.amendments.length === 0"> Active </ng-container>
              <ng-container *ngIf="!!element.amendments && element.amendments.length > 0">
                Active - Amended on {{ getAmendmentDates(element) }}
              </ng-container>
            </span>
            <span *ngIf="element.iepStatus === iepStatuses.Complete"> Archived </span>
            <span *ngIf="element.iepStatus === iepStatuses.Draft"> New (Draft) </span>
            <span *ngIf="element.iepStatus === iepStatuses.Legacy"> Legacy </span>
            <span *ngIf="element.iepType === iepTypes.Interim"> (Interim) </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="iepStart">
          <th mat-sort-header mat-header-cell *matHeaderCellDef>Start Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.startDate | dateFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="iepEnd">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.endDate | dateFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="latestWeightedLevel">
          <th mat-sort-header mat-header-cell *matHeaderCellDef>Weighted Level</th>
          <td mat-cell *matCellDef="let element">
            {{ element.iepStatus === 'Active' || element.iepStatus === 'Complete' ? element.latestWeightedLevel : '' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="familyInfo">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="text-right">
            <button
              mat-raised-button
              aria-label="IEP Document"
              color="primary"
              *ngIf="element.iepStatus === 'Active'"
              (click)="onFamilyIepView(element)"
              tabindex="0"
            >
              IEP Document
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="info">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="text-right">
            <button
              mat-raised-button
              aria-label="Amend"
              color="primary"
              class="mr-2"
              *ngIf="!element.isModifying && element.iepStatus === 'Active' && canCreateIEPAmendment && isCaseWorkable"
              (click)="amendIep(element)"
              tabindex="0"
            >
              Amend
            </button>

            <a class="mr-2" tabindex="0" role="link" aria-labelledby="IEP Document">
              <app-create-output-button
                *ngIf="(element.iepStatus === iepStatuses.Active || element.iepStatus === iepStatuses.Complete) && canViewIEP"
                [apiId]="element.id"
                [learnerId]="learnerId"
                [documentId]="!iepsInAmendment ? getLastIepDocumentId(element) : null"
                [hasOpenModification]="iepsInAmendment"
                [output]="pdfOutputs.IepOutput"
                buttonColor="primary"
                buttonText="IEP Document"
              ></app-create-output-button>
            </a>

            <button
              mat-raised-button
              attr.aria-label="{{ element.iepType === iepTypes.Interim ? 'Complete Interim IEP' : 'Edit IEP' }}"
              color="primary"
              *ngIf="element.iepStatus === 'Draft' && canEditIEP && isCaseWorkable"
              [routerLink]="['/', 'cases', caseId, 'iep', element.id, 'profile']"
              tabindex="0"
            >
              {{ element.iepType === iepTypes.Interim ? 'Complete Interim IEP' : 'Edit IEP' }}
            </button>
            <button
              mat-raised-button
              aria-label="View Draft IEP"
              color="primary"
              class="ml-2 my-1"
              [isBusy]="loading.finalization"
              [ngClass]="{
                'mr-2': element.iepStatus === iepStatuses.Active && canViewCompleteIEP
              }"
              *ngIf="element.iepStatus === iepStatuses.Draft && canViewIEP && isCaseWorkable"
              (click)="onIepViewOnlyView(element.id)"
              tabindex="0"
            >
              View Draft IEP
            </button>
            <button
              mat-raised-button
              aria-label="IEP View"
              color="primary"
              *ngIf="element.iepStatus === 'Active' && (canViewIEP || canViewCompleteIEP)"
              (click)="onIepView(element)"
              tabindex="0"
              [isBusy]="loading.iepOutput"
            >
              IEP View
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator [dataSource]="dataSource"></mat-paginator>
    </div>
    <p *ngIf="!dataSource.data">No data yet...</p>
  </ng-container>
</mat-card>

<ng-container *ngIf="!isPortalUser && weightedMatrixHistory && weightedMatrixHistory.length > 0">
  <h3 class="my-0 mb-2 mt-4">Weighted Enrollment Matrix for data collection purposes</h3>
  <mat-card>
    <app-weight-matrix-score-history
      *ngIf="caseId"
      [caseId]="caseId"
      [isLmIepPage]="true"
      [weightedMatrixLevels]="weightedMatrixLevels"
      [scoreHistory]="weightedMatrixHistory"
      [readOnly]="true"
    ></app-weight-matrix-score-history>
  </mat-card>
</ng-container>

<div class="mt-2">
  <ng-container *ngIf="isPortalUser && caseSummary && caseSummary.activeIepId">
    <mat-accordion class="accordion accordion--close accordion--short">
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="display-flex justify-content-between align-items-center flex-1">
              <h3 class="my-0 text-normal">Monitoring for Progress</h3>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-enter-progress-part-b [enableForms]="false" [caseSummary]="caseSummary"></app-enter-progress-part-b>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
</div>

<div class="page-bottom-leeway"></div>

<ng-template #interimConfirmation>
  <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Start New Interim Plan</h3>
  <mat-dialog-content class="py-3">
    <form [formGroup]="interimIep">
      <mat-card class="mb-0">
        <div class="form__description py-2 mb-2">
          <p class="my-0">This interim plan will expire 50 days after the date the IEP meeting is held.</p>
        </div>

        <hr class="input__divider my-3" />

        <label class="text-md vertical-label display-block"> Has the team determined that a reevaluation is needed? </label>
        <mat-radio-group aria-labelledby="Select an option" formControlName="reevaluationNeeded" role="radiogroup">
          <mat-radio-button [value]="true" class="mr-2" aria-label="Yes">Yes</mat-radio-button>
          <mat-radio-button [value]="false" aria-label="No">No</mat-radio-button>
        </mat-radio-group>

        <app-textarea formControlName="interimNotes" label="Notes" [rows]="4" [maxLength]="10000"></app-textarea>
      </mat-card>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" mat-raised-button aria-label="Cancel Button" [mat-dialog-close]="false" cdkFocusInitial class="mr-2" tabindex="0">
      Cancel
    </button>
    <button
      color="primary"
      type="button"
      mat-raised-button
      aria-label="Complete"
      [mat-dialog-close]="interimIep.value"
      [disabled]="interimIep.invalid"
      tabindex="0"
    >
      Complete
    </button>
  </mat-dialog-actions>
</ng-template>
