import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { forbiddenRedirect } from 'src/app/auth/auth.guard';
import { AuthService } from 'src/app/auth/auth.service';
import { AppPermissions } from 'src/app/permissions';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';

@Injectable({
  providedIn: 'root',
})
export class IvrsPermissionGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly learnerService: LearnerService,
    private readonly router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.isVrUser) {
      // non-VR user, no need to check further
      return of(true);
    }

    const learnerId = route.params.learnerId;
    if (!learnerId) {
      return of(false);
    }

    if (this.authService.isAllowedStatewide(AppPermissions.VRViewLearner)) {
      return of(true);
    }

    return this.learnerService.getAreaOffice(learnerId).pipe(
      map((res) => {
        if (res.succeeded) {
          if (this.authService.isAllowedInLocation(res.value?.id, AppPermissions.VRViewLearner)) {
            return true;
          }
          return forbiddenRedirect(this.router);
        }
        return forbiddenRedirect(this.router);
      }),
      catchError((err) => {
        return of(false);
      })
    );
  }
}
