import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppPermissions } from 'src/app/permissions';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { CaseSummary, CaseUserRole, ExitCaseCodes, FamilyMeetingRead, IntakeType } from 'src/app/shared/models/case';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { FinalExitOutputResult, ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { NewWindowConfig, openNewWindow, openPdfWindow } from 'src/app/shared/windowHelpers';
import { AuthService } from '../../auth/auth.service';
import { MeetingStatus } from '../../shared/models/family-meeting-status';
import { FamilyMember, FinalEcoRatingPkStatus, LearnerSummary } from '../../shared/models/learner';
import { AchieveConfigService } from '../../shared/services/achieve-config-service/achieve-config.service';
import { LearnerService } from '../../shared/services/learner/learner.service';
import { FamilyMeetingService } from '../family-meeting/family-meeting.service';
import { MoveInDetailService } from './move-in-details/move-in-detail.service';

@Component({
  selector: 'app-learner-dashboard',
  templateUrl: './learner-dashboard.component.html',
  styleUrls: ['./learner-dashboard.component.scss'],
})
export class LearnerDashboardComponent implements OnInit, OnDestroy {
  @ViewChild('meetingAccordion') meetingPanels: MatAccordion;

  cases: CaseSummary[];
  today = new Date();
  familyMeetings: FamilyMeetingRead[] = [];
  shortDateFormat = shortDateFormat;
  perms = AppPermissions;
  learnerId = this.route.parent.snapshot.paramMap.get('learnerId');
  subscription = new Subscription();
  intakeTypeEnum = IntakeType;
  hasWorkableCase = false;
  permissions = AppPermissions;
  viewReferralAdditionalRequirements = new Map<string, string[]>([['TeamMember', ['ServiceCoordinator', 'AeaEdit']]]);
  meetingStatus = MeetingStatus;

  get caseUserRole() {
    return CaseUserRole;
  }

  get exitedCases(): Array<CaseSummary> {
    return this.cases?.filter((x) => !x.isActive && x.exitFinalized);
  }

  get caseAvailable(): Array<CaseSummary> {
    return this.cases?.filter((x) => x.isActive && x.exitFinalized);
  }

  get activeCases(): Array<CaseSummary> {
    return this.cases?.filter((x) => x.isActive);
  }

  get quickLinkCases(): Array<CaseSummary> {
    const casesForQuickLink = this.activeCases;
    this.exitedCases?.forEach((e) => {
      if (!casesForQuickLink.find((c) => c.intakeType === e.intakeType)) {
        casesForQuickLink.push(e);
      }
    });
    return casesForQuickLink;
  }

  get partCActiveCase(): CaseSummary {
    return this.activeCases?.find((x) => x.intakeType === IntakeType.PartC || x.intakeType === IntakeType.PartCLate);
  }

  get partBActiveCase(): CaseSummary {
    return this.activeCases?.find((x) => x.intakeType === IntakeType.PartB);
  }

  get caseWithEarlyAccessReferral(): CaseSummary {
    return this.cases?.find((x) => x.intakeType !== IntakeType.PartB && !!x.earlyAccessReferralId);
  }

  get learner(): LearnerSummary {
    return this.cases?.[0]?.learner;
  }

  get familyMembers(): Array<FamilyMember> {
    return this.cases?.[0]?.learner.familyMembers;
  }

  get canExitPartC() {
    return (
      (this.authService.isSuperAdmin &&
        this.authService.isAllowedByCaseId(this.partCActiveCase?.id, null, AppPermissions.ExitPartCLearner)) ||
      this.authService.isAllowedByCaseId(
        this.partCActiveCase?.id,
        new Map<string, string[]>([['CaseOwner', ['ServiceCoordinator']]]),
        AppPermissions.ExitPartCLearner
      )
    );
  }

  get canExitPartB() {
    return (
      this.authService.isSuperAdmin ||
      this.authService.isAllowedByCaseId(
        this.partBActiveCase?.id,
        new Map<string, string[]>([['TeamMember', ['AeaEdit', 'LeaProviderEdit']]]),
        AppPermissions.ExitPartBLearner
      ) ||
      (this.authService.isCaseOwner(this.partBActiveCase?.id) &&
        this.authService.isAllowedByCaseId(
          this.partBActiveCase?.id,
          new Map<string, string[]>([['CaseOwner', ['AeaEdit', 'LeaProviderEdit']]]),
          AppPermissions.ExitPartBLearner
        ))
    );
  }

  get isAchieveDataLead() {
    return this.authService.isAchieveDataLead;
  }

  get isPortalUser() {
    return this.authService.isPortalUser;
  }

  get showQuickLinks() {
    return !this.isPortalUser && (!this.isMoveInOrEligibleLegacyLearner || (this.isMoveInOrEligibleLegacyLearner && this.hasMoveInDetails));
  }

  get isMoveInOrEligibleLegacyLearner() {
    return this.learner?.movingFromOutOfState || this.learner?.eligibleInLegacySystem || this.learner?.eligibleInLegacySystemPartC;
  }

  get hasMoveInDetails() {
    return this.cases?.some((x) => x.learner.hasMoveInDetails) || this.achieveConfigService.settings.canAddAnyAgeLearner;
  }

  get canAddAnyAgeLearner() {
    return this.achieveConfigService.settings.canAddAnyAgeLearner;
  }

  get showEcoFinalScorePkPartBSession() {
    return this.partBActiveCase?.learner?.isPK && this.partBActiveCase?.learner?.finalEcoRatingPkStatus === FinalEcoRatingPkStatus.Draft;
  }

  constructor(
    private route: ActivatedRoute,
    private caseService: CaseService,
    private dialog: MatDialog,
    public authService: AuthService,
    private familyMeetingService: FamilyMeetingService,
    private readonly learnerService: LearnerService,
    private reportingService: ReportingService,
    private moveInDetailsService: MoveInDetailService,
    private achieveConfigService: AchieveConfigService
  ) {}

  async ngOnInit(): Promise<void> {
    this.caseService.caseSummary = null;
    this.moveInDetailsService.moveInDetailsUpdated$.subscribe(async (learnerId) => {
      this.learnerId = learnerId;
      await this.loadData();
    });
    await this.loadData();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async loadData() {
    this.cases = await this.learnerService.getCases(this.learnerId).toPromise();
    this.hasWorkableCase = this.cases.find((x) => x.isActive && x.exitFinalizedOn === null)?.id != null;

    this.subscription.add(
      this.familyMeetingService.getMeetings(this.learnerId).subscribe((familyMeetings) => {
        this.familyMeetings = familyMeetings;
      })
    );

    const caseId = this.route.snapshot.paramMap.get('caseId');
    if (caseId) {
      const activeCase = this.activeCases.find((x) => x.id === caseId && x.isActive && x.newLearner);
      if (activeCase != null) {
        this.subscription.add(this.caseService.updateNewLearnerStatus(activeCase.id, false).subscribe(() => {}));
      }
    } else {
      const activeCaseExist = this.cases.find((x) => x.isActive && x.newLearner);
      if (activeCaseExist != null) {
        this.subscription.add(this.caseService.updateNewLearnerStatus(activeCaseExist.id, false).subscribe(() => {}));
      }
    }
  }

  refreshCase(caseSummary: CaseSummary) {
    this.caseService.getCaseSummary(caseSummary.id).subscribe((caseResult) => {
      this.caseService.caseSummary = caseResult;
    });
  }

  routeToEarlyAccessReferralReadOnlyForm(caseSummary: CaseSummary) {
    const config: NewWindowConfig = {
      path: `child-find/early-access-referral/${caseSummary.earlyAccessReferralId}/read-only`,
      popup: true,
    };
    openNewWindow(config);
  }

  updateRescheduledDate(meeting: FamilyMeetingRead) {
    if (!meeting) {
      return;
    }
    const familyMeeting = this.familyMeetings.find((x) => x.id === meeting.id);
    if (familyMeeting && meeting) {
      familyMeeting.dateAndTime = meeting.dateAndTime;
      familyMeeting.location = meeting.location;
    }
  }

  exitFinalized(caseId: string) {
    // Print Output
    this.reportingService.createFinalExitOutput(caseId).subscribe({
      next: (result: FinalExitOutputResult) => this.handleOutputCreation(result),
      error: (error: any) => this.reportingService.handleOutputError(error),
    });
  }

  handleOutputCreation(finalExitResult: FinalExitOutputResult) {
    if (!!finalExitResult) {
      openPdfWindow(this.learnerId, finalExitResult.exitLetterDocumentId);

      if (finalExitResult.partCTransitionPlanDocumentId) openPdfWindow(this.learnerId, finalExitResult.partCTransitionPlanDocumentId);
    }

    this.pageRefresh();
  }

  exitCancelled(caseId: string) {
    this.activeCases?.forEach((element, index) => {
      if (element.id === caseId) {
        this.activeCases[index].exiting = null;
        this.activeCases[index].exitDate = null;
        this.activeCases[index].exitReasonId = null;
        this.activeCases[index].exitStartedOn = null;
        this.activeCases[index].exitFinalizedOn = null;
        this.activeCases[index].isSystemExit = false;
      }
    });
  }

  pageRefresh(): void {
    window.location.reload();
  }

  canViewReferral(caseId: string) {
    let isAllowed = true;
    if (this.authService.isAeaEdit || this.authService.isServiceCoordinator) {
      isAllowed = this.authService.isCaseOwner(caseId) || this.authService.isCaseTeamMember(caseId);
    }
    return (
      isAllowed &&
      this.authService.isAllowedByCaseId(
        caseId,
        new Map<string, string[]>([['TeamMember', ['AeaEdit', 'ServiceCoordinator']]]),
        AppPermissions.PartCViewReferral
      )
    );
  }

  refreshData() {
    this.familyMeetingService.getMeetings(this.learnerId).subscribe((familyMeetings) => {
      this.familyMeetings = familyMeetings;
    });
  }

  exitedCaseBannerEligible(model: CaseSummary): boolean {
    if (model.exitReasonCode === ExitCaseCodes.NSC) return true;
    if (model.intakeType === this.intakeTypeEnum.PartB && model.finalizedIep) return true;
    if (model.intakeType !== this.intakeTypeEnum.PartB && model.finalizedIfsp) return true;
    return false;
  }

  isMeetingCancelled(meeting: FamilyMeetingRead) {
    return meeting.status === this.meetingStatus.Cancelled ? true : false;
  }
}
