import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { DateFilterFn } from '@angular/material/datepicker';
import { AchieveConfigService } from '../../../services/achieve-config-service/achieve-config.service';
import { LoggerService } from '../../../services/logger/logger.service';
import { BaseFieldComponent } from '../base-field';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DatePickerComponent extends BaseFieldComponent implements OnInit, OnChanges {
  @Input() startView: 'month' | 'year' | 'multi-year' = 'month';
  @Input() startAt = new Date();
  @Input() min = new Date(1900, 0, 1);
  @Input() max: Date | null;
  @Input() hideAsterisk: boolean;
  @Input() dateFilter: DateFilterFn<Date>;
  @Output() controlBlur = new EventEmitter();
  formattedValue: string;

  constructor(parentForm: FormGroupDirective, logger: LoggerService, private readonly achieveConfigService: AchieveConfigService) {
    super(parentForm, logger);
  }

  onBlur(evt) {
    this.controlBlur.emit(evt);
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.klass) {
      this.klass = 'form-control';
    }
    if (this.extraClasses) {
      this.klass = `${this.klass} ${this.extraClasses}`;
    }

    this.formatValue();
    this.control.valueChanges.subscribe(() => {
      this.formatValue();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.min) {
      if (!changes.min.currentValue) {
        this.min = new Date(1900, 0, 1);
      }
    }
  }

  async formatValue() {
    const controlValue = this.control.value;
    if (!controlValue) {
      return;
    }

    if (typeof controlValue === 'string') {
      this.formattedValue = controlValue;
    } else {
      const serverVersion = await this.achieveConfigService.getDateTimeFromTimeString(new Date(controlValue), '').toPromise();

      this.formattedValue = serverVersion.toString();
    }
  }
}
