import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgIdleModule } from '@ng-idle/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthModule } from './auth/auth.module';
import { CustomErrorHandler } from './custom-error-handler';
import { MonitoringProgressModule } from './monitoring-progress/monitoring-progress.module';
import { NavModule } from './nav/nav.module';
import { AppMatModule } from './shared/app-mat.module';
import { RetryInterceptor } from './shared/interceptors/retry.interceptor';
import { AchieveConfigService } from './shared/services/achieve-config-service/achieve-config.service';
import { AppConfigService } from './shared/services/app-config-service/app-config.service';
import { AlertDialogComponent, ConfirmationDialogComponent, VersionDialogComponent } from './shared/services/notification.service';
import { SharedModule } from './shared/shared.module';
import { UniversalDashboardModule } from './universal-dashboard/universal-dashboard.module';
import { SurveyModule } from './surveys/surveys.module';
import { SurveyToolModule } from './survey-tool/survey-tool.module';
import { JL } from 'jsnlog';
import { LoggerInterceptor } from './shared/interceptors/logger.interceptor';

export function initApp(appConfigService: AppConfigService, achieveConfigService: AchieveConfigService) {
  return async () => {
    await achieveConfigService.load();
    appConfigService.init();
  };
}

@NgModule({
  declarations: [AppComponent, AlertDialogComponent, ConfirmationDialogComponent, VersionDialogComponent],
  imports: [
    AppRoutingModule,
    AuthModule,
    BrowserAnimationsModule,
    UniversalDashboardModule,
    NavModule,
    AppMatModule,
    SharedModule,
    MonitoringProgressModule,
    AngularSvgIconModule.forRoot(),
    NgIdleModule.forRoot(),
    SurveyModule,
    SurveyToolModule,
  ],
  providers: [
    AchieveConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [AppConfigService, AchieveConfigService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoggerInterceptor, multi: true },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: Window, useValue: window },
    { provide: 'JSNLOG', useValue: JL },
  ],
  bootstrap: [AppComponent],
  entryComponents: [AlertDialogComponent, ConfirmationDialogComponent, VersionDialogComponent],
})
export class AppModule {
  constructor() {}
}
