import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';
import { AutocompleteGroup } from '../../../models/autocomplete-group';
import { KeyValuePair } from '../../../models/key-value-pair';
import { LoggerService } from '../../../services/logger/logger.service';
import { BaseWrapperComponent } from '../base-wrapper.component';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent extends BaseWrapperComponent implements OnInit, OnDestroy, OnChanges {
  private controlValueSubscription: Subscription;

  @Output() selected = new EventEmitter<any>();
  @Input() hideAsterisk = false;
  @Input() panelWidth = 'auto';

  useGrouping = false;
  private filteredOptions: KeyValuePair[] | AutocompleteGroup[];
  optionsBacking: KeyValuePair[] | AutocompleteGroup[] = [];

  get groupOptions() {
    return this.filteredOptions as AutocompleteGroup[];
  }

  get selectOptions() {
    return this.filteredOptions as KeyValuePair[];
  }

  dataSourceLabel: any;

  @Input()
  set options(value: KeyValuePair[] | AutocompleteGroup[]) {
    this.optionsBacking = value || [];
    // this *should* allow us to check at runtime which type is provided
    // since the KeyValuePair type doesn't have an "options" property
    this.useGrouping = this.optionsBacking[0]?.hasOwnProperty('options');
  }

  /**
   * If true will not set selection error
   * if the user types in a value
   * that is not present in the choice
   * of options
   */
  @Input() allowCustomInput = false;

  @Input() allowInvalidModelValue = true;

  @Input() displayWith: (key: string) => any = (key: string) => {
    if (key) {
      return this.find(key)?.value;
    }
    return '';
  };

  constructor(
    logger: LoggerService,
    @Self()
    @Optional()
    ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(logger, ngControl, changeDetectorRef);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.useOverwrite) {
      this.setDataSourceLabel();
    }

    this.controlValueSubscription = this.control.valueChanges.subscribe((x) => {
      if (!x || this.allowCustomInput) {
        return;
      }

      if (!this.isValidValue(x)) {
        if (this.allowInvalidModelValue) {
          const errors = this.control.errors || {};
          errors.typeahead = true;
          this.control.setErrors(errors);
        } else {
          this.control.setValue(null, { emitModelToViewChange: false });
        }
      }
    });
  }

  ngOnDestroy() {
    this.controlValueSubscription?.unsubscribe();
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    const selectedValue = event.option.value;
    if (this.selected) {
      this.selected.emit(selectedValue);
    }
    this.control.setValue(selectedValue);
    this.resetList();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.options?.currentValue) {
      this.resetList();
      this.control?.updateValueAndValidity({ emitEvent: false });
    }
  }

  onKey(event: any) {
    // Don't filter on arrow key up/down presses
    if (![38, 40].includes(event.keyCode)) {
      this.filteredOptions = this.filter(event.target.value);
    }
  }

  onBlur(event: any) {
    const controlValue = this.control?.value;
    // no need to do validation if there's no value
    // or we're allowing custom inputs
    if (!controlValue || this.allowCustomInput) {
      return;
    }
    const option = this.find(controlValue);
    if (controlValue && !option) {
      this.control.setErrors({ typeahead: true });
      return;
    } else {
      const error = 'typeahead';
      if (this.control?.errors?.[error]) {
        delete this.control.errors[error];
      }
    }
    if (controlValue !== option.key) {
      this.control.setValue(option.key);
    }
  }

  private filter(value): KeyValuePair[] | AutocompleteGroup[] {
    if (!value) {
      return this.optionsBacking;
    }

    const innerFilter = (options) => {
      return options.filter((option) => option.value.toLowerCase().includes(value.toLowerCase()));
    };

    if (this.useGrouping) {
      return (this.optionsBacking as AutocompleteGroup[])
        .map((group) => new AutocompleteGroup(group.label, innerFilter(group.options)))
        .filter((group) => group.options.length > 0);
    } else {
      return innerFilter(this.optionsBacking as KeyValuePair[]);
    }
  }

  private find(key): KeyValuePair {
    let values = this.optionsBacking as KeyValuePair[];
    if (this.useGrouping) {
      values = (this.optionsBacking as AutocompleteGroup[]).flatMap((x) => x.options);
    }
    return values.find(
      (d) =>
        d.key.toString().toLowerCase() === key.toString().toLowerCase() || d.value.toString().toLowerCase() === key.toString().toLowerCase()
    );
  }

  resetList() {
    this.filteredOptions = this.optionsBacking;
  }

  private setDataSourceLabel() {
    if (this.dataSourceValue && this.optionsBacking) {
      let values = this.optionsBacking as KeyValuePair[];
      if (this.useGrouping) {
        values = (this.optionsBacking as AutocompleteGroup[]).flatMap((x) => x.options);
      }
      const match = values.find((d) => d.key === this.dataSourceValue);

      this.dataSourceLabel = match?.value;
    }
  }

  private isValidValue(value: any): boolean {
    return this.useGrouping
      ? this.groupOptions?.some((x) => x.options.some((o) => o.key === value))
      : this.selectOptions?.some((o) => o.key === value);
  }
}
