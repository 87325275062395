import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { IepPrintModalComponent } from 'src/app/iep/modals/iep-print-modal/iep-print-modal.component';
import { DialogComingSoonComponent } from 'src/app/shared/components/coming-soon/coming-soon.component';
import { FeatureFlagService } from 'src/app/shared/components/feature-flags/feature-flag.service';
import { IfspIepResetDateComponent } from 'src/app/shared/components/ifsp-iep-reset-date/ifsp-iep-reset-date.component';
import { CaseSummary, IntakeType } from 'src/app/shared/models/case';
import { AchieveConfigService } from 'src/app/shared/services/achieve-config-service/achieve-config.service';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { ConfirmationDialogComponent, NotificationService } from 'src/app/shared/services/notification.service';
import { PdfOutputs, ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { AuthService } from '../../auth/auth.service';
import { IepAmendmentModalComponent } from '../../iep/modals/iep-amendment-modal/iep-amendment-modal.component';
import { IepAddUpdate, IepGetByCaseId, IepStatus, IepType, IepView } from '../../iep/models/iep';
import { ExtendedSchoolYearOptions } from '../../iep/models/iep-extended-school-year';
import { WeightedMatrixLevelDto, WeightedMatrixScoreHistoryDto } from '../../iep/models/iep-weighted-matrix';
import { IepAmendmentService } from '../../iep/services/iep-amendment.service';
import { IepDetailWeightedMatrixService } from '../../iep/services/iep-detail-weighted-matrix.service';
import { IepService } from '../../iep/services/iep.service';
import { IfspStatus } from '../../ifsp/models/ifsp';
import { AppPermissions } from '../../permissions';
import { LearnerSummary } from '../../shared/models/learner';
import { DateFormatPipe } from '../../shared/pipes/date-transform.pipe';
import { LearnerService } from '../../shared/services/learner/learner.service';
import { RoutingService } from '../../shared/services/routing.service';
import { NewWindowConfig, openDocumentWindow, openNewWindow, openPdfWindow } from '../../shared/windowHelpers';
import { PrintDocumentsModalComponent } from '../documentation/print-documents-modal/print-documents-modal.component';
import { PkTokTransitionModalComponent } from './pk-tok-transition-modal/pk-tok-transition-modal.component';

@Component({
  selector: 'app-iep-data',
  templateUrl: './iep-data.component.html',
  styleUrls: ['./iep-data.component.scss'],
})
export class IepDataComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  public creatingIep = false;

  @ViewChild('interimConfirmation') interimConfirmation: TemplateRef<any>;
  dataSource = new MatTableDataSource<IepGetByCaseId>();
  displayedColumns = ['actions', 'createdOn', 'iepStart', 'iepEnd', 'latestWeightedLevel', 'info'];
  caseId: string;
  ieps: IepGetByCaseId[] = [];
  pdfOutputs = PdfOutputs;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  interimExpirationDate = dayjs().add(50, 'day').startOf('day').toDate();
  interimIep: FormGroup;
  iepStatuses = IepStatus;
  ifspStatuses = IfspStatus;
  iepTypes = IepType;
  learnerId: string;
  learner: LearnerSummary;
  caseSummary: CaseSummary;
  permissions = AppPermissions;
  loading = {
    finalization: false,
    iepOutput: false,
    iepGoalProgressOutput: false,
  };
  weightedMatrixLevels: WeightedMatrixLevelDto[] = [];
  weightedMatrixHistory: WeightedMatrixScoreHistoryDto[] = [];

  get canViewFinalizedIEP() {
    return (
      this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.ViewFinalizedIEP) ||
      (this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.ViewFinalizedIEPIfPartOfCase) &&
        this.authService.isCaseUser(this.caseSummary))
    );
  }

  get canViewFinalizedIFSP() {
    return (
      this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.ViewFinalizedIFSP) ||
      (this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.ViewFinalizedIFSPInTransition) &&
        this.learner.isSecondaryTransition)
    );
  }

  get canCreateIEP() {
    return this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.CreateIEP);
  }

  get canCreateIEPAmendment() {
    return this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.CreateIEPAmendment);
  }

  get canEditIEP() {
    return this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.EditIEP);
  }

  get canViewIEP() {
    return this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.ViewIEP);
  }

  get canViewCompleteIEP() {
    return this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.ViewCompletedIEP);
  }

  get canEditIFSP() {
    return this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.EditIFSP);
  }

  get canViewIFSP() {
    return this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.ViewIFSP);
  }

  get canViewCompleteIFSP() {
    return this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.ViewCompletedIFSP);
  }

  get canEnterProgressData() {
    return this.activeIEP && this.authService.isAllowedByCaseId(this.caseId, null, AppPermissions.ViewProgressMonitoring);
  }

  get canViewProgressData() {
    return this.activeIEP && (this.canEnterProgressData || this.isPortalUser);
  }

  get canViewWeightedMatrix() {
    return this.authService.isWeightedMatrixEditor;
  }

  get draftIEP() {
    return this.ieps.find((x) => x.iepStatus === IepStatus.Draft);
  }

  get activeIEP() {
    return this.ieps.find((x) => x.iepStatus === IepStatus.Active);
  }

  get iepsInAmendment() {
    return this.ieps.filter((x) => x.isModifying).length > 0;
  }

  get enableIEPBtn() {
    return !this.draftIEP && this.canCreateIEP && !this.creatingIep && !this.iepsInAmendment;
  }

  get isCaseWorkable(): boolean {
    return this.caseSummary?.isActive && this.caseSummary?.exitFinalizedOn === null;
  }

  get achieveSettings() {
    return this.achieveConfigService.settings;
  }

  get canStartIep() {
    return (
      (this.learner?.movingFromOutOfState || this.learner?.eligibleInLegacySystem || this.learner.partBEligibilityDecision) &&
      !this.isPortalUser
    );
  }

  get isPortalUser() {
    return this.authService?.isPortalUser;
  }

  get isVrUser() {
    return this.authService.isVrUser;
  }

  get canViewBip() {
    return !this.isPortalUser && !this.isVrUser && this.caseSummary?.bipId;
  }

  get isSuperAdmin() {
    return this.authService?.isSuperAdmin;
  }

  constructor(
    private readonly router: Router,
    private readonly iepService: IepService,
    private readonly iepAmendmentService: IepAmendmentService,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly dialog: MatDialog,
    private readonly fb: FormBuilder,
    private readonly caseService: CaseService,
    private readonly routingService: RoutingService,
    private readonly learnerService: LearnerService,
    private readonly reportingService: ReportingService,
    private readonly featureFlagService: FeatureFlagService,
    private notificationService: NotificationService,
    private achieveConfigService: AchieveConfigService,
    private weightedMatrixService: IepDetailWeightedMatrixService
  ) {}

  async ngOnInit(): Promise<void> {
    this.learnerId = this.route.parent?.snapshot.paramMap.get('learnerId');
    await this.loadData();

    if (this.isPortalUser) {
      this.displayedColumns = ['actions', 'createdOn', 'iepStart', 'iepEnd', 'familyInfo'];
    }
  }

  async loadData() {
    this.learner = await this.learnerService.getLearnerSummary(this.learnerId).toPromise();
    this.caseId = this.learner.activeCases.find((x) => x.intakeType === IntakeType.PartB)?.id;
    if (!!this.caseId) {
      this.caseSummary = await this.caseService.getCaseSummary(this.caseId).toPromise();

      this.weightedMatrixHistory = (await this.weightedMatrixService.getWeightedMatrixScoreHistory(this.caseId).toPromise())?.value;
      this.weightedMatrixLevels = (await this.weightedMatrixService.getWeightedLevels(this.caseId).toPromise())?.value;
    }
    this.iepService.getByLearnerId(this.learnerId).subscribe((res) => {
      this.ieps = res.filter((x) => x.isIep);
      this.dataSource.data = res;
      if (!this.activeIEP) {
        this.displayedColumns = this.displayedColumns.filter((x) => x !== 'latestWeightedLevel');
      }
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'createdOn':
            return item.createdOn;
          case 'iepStart':
            return item.startDate;
          case 'iepEnd':
            return item.endDate;
          default:
            return item[property];
        }
      };
    });
  }

  getAmendmentDates(iep) {
    return iep.amendments.map((x) => new DateFormatPipe().transform(x.finalizeDate))?.join(', ');
  }

  enterProgress() {
    const config: NewWindowConfig = {
      path: this.routingService.enterProgressPath(this.caseId)?.join('/'),
      popup: true,
      width: '1280px',
    };
    openNewWindow(config);
  }

  amendIep(activeIep: any): void {
    this.iepAmendmentService.getAmendmentsByIepId(activeIep.id).subscribe((amendments) => {
      const amendment = amendments.find((x) => !x.finalizeDate);
      if (!amendment) {
        // does not have amendments
        const dialogRef = this.dialog.open(IepAmendmentModalComponent, {
          width: '760px',
          data: {
            iepId: activeIep.id,
            caseId: this.caseId,
            includesTrialPlacement: activeIep.includesTrialPlacement,
            includesTrialPlacementServices: activeIep.includesTrialPlacementServices,
            trialPlacementDate: activeIep.trialPlacementDate,
            trialPlacementTarget: activeIep.trialPlacementTarget,
            iepEndDate: activeIep.endDate,
          },
        });
        dialogRef.afterClosed().subscribe((closeResult) => {
          if (closeResult) {
            this.router.navigate(['cases', this.caseId, 'iep', activeIep.id, 'details']);
          }
        });
      } else {
        this.router.navigate(['cases', this.caseId, 'iep', activeIep.id, 'details']);
      }
    });
  }

  async createIep(): Promise<void> {
    try {
      if (this.creatingIep) {
        return;
      }

      this.creatingIep = true;

      const newIEP = {
        caseId: this.caseId,
        learnerId: this.learnerId,
        iepType: IepType.Initial,
        iepStatus: IepStatus.Draft,
        isPKToK: false,
      } as IepAddUpdate;

      if (this.learner.age >= 4) {
        if (!!this.learner.kindergartenStartDate && this.learner.kindergartenStartDate.valueOf() <= dayjs().add(4, 'month').valueOf()) {
          newIEP.isPKToK = true;
        } else if (this.learner.isPK) {
          const dialogRef = this.dialog.open(PkTokTransitionModalComponent, {
            width: '728px',
            data: {
              learnerId: this.learnerId,
              kindergartenStartDate: this.learner.kindergartenStartDate,
            },
          });

          const confirm = await dialogRef.afterClosed().toPromise();
          if (!confirm) return;

          newIEP.isPKToK = true;
        }
      }

      const res = await this.iepService.createIEP(newIEP).toPromise();
      this.goToIep(res.id);
    } finally {
      this.creatingIep = false;
    }
  }

  async onStartInterim(): Promise<void> {
    this.interimIep = this.fb.group({
      expirationDate: this.interimExpirationDate,
      reevaluationNeeded: [null, Validators.required],
      interimNotes: '',
    });

    const res = await this.dialog.open(this.interimConfirmation, { width: '768px' }).afterClosed().toPromise();

    if (res) {
      await this.createInterimIep(res);
    }
  }

  goToESY(iep: IepGetByCaseId) {
    if (iep.iepStatus === IepStatus.Active && iep.esyType === ExtendedSchoolYearOptions.DevelopNow) {
      this.router.navigate(['/', 'cases', this.caseId, 'iep', iep.id, 'esy', 'view']);
    } else {
      this.router.navigate(['/', 'cases', this.caseId, 'iep', iep.id, 'esy', 'overview']);
    }
  }

  async createInterimIep(interimIepData): Promise<void> {
    try {
      if (this.creatingIep) {
        return;
      }

      this.creatingIep = true;

      // TODO: If reevaluation is needed, kick off reevaluation process.
      const newIEP = {
        caseId: this.caseId,
        iepType: IepType.Interim,
        iepStatus: IepStatus.Draft,
        interimNotes: interimIepData.interimNotes,
        learnerId: this.learnerId,
      } as IepAddUpdate;

      const res = await this.iepService.createIEP(newIEP).toPromise();
      this.goToIep(res.id);
    } finally {
      this.creatingIep = false;
    }
  }

  onFamilyIepView(iep) {
    openPdfWindow(this.learnerId, this.getLastIepDocumentId(iep));
  }

  onIepView(iep: IepGetByCaseId) {
    if (this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.CreateIEPAmendment)) {
      this.router.navigate(['/', 'cases', this.caseId, 'iep', iep.id, 'details']);
    } else if (this.canViewCompleteIEP && iep.isModifying) {
      this.loading.finalization = true;
      this.loading.iepOutput = true;

      if (this.featureFlagService.featureOn('outputIepEnabled')) {
        this.reportingService.createIepOutput(iep.id, null, true).subscribe({
          next: (documentId: string) => this.handleCreateOutput(documentId),
          error: () => this.handleError(),
        });
      } else {
        this.notificationService.error('IEP output is not enabled');
      }
    } else if (this.canViewCompleteIEP && !iep.isModifying) {
      openPdfWindow(this.learnerId, this.getLastIepDocumentId(iep));
    } else {
      this.notificationService.error('Sorry, you do not have permission to access this functionality');
    }
  }

  onIepViewOnlyView(iepId) {
    if (this.canViewIEP) {
      this.loading.finalization = true;

      if (this.featureFlagService.featureOn('outputIepEnabled')) {
        this.reportingService.createIepOutput(iepId, null, false).subscribe({
          next: (documentId: string) => this.handleCreateOutput(documentId),
          error: () => this.handleError(),
        });
      } else {
        this.notificationService.error('IEP output is not enabled');
      }
    } else {
      this.notificationService.error('Sorry, you do not have permission to access this functionality');
    }
  }

  private handleError() {
    this.loading.finalization = false;
    this.loading.iepOutput = false;
    this.notificationService.error("Couldn't open output");
  }

  private handleCreateOutput(documentId: string) {
    this.loading.finalization = false;
    this.loading.iepOutput = false;
    openPdfWindow(this.learnerId, documentId);
  }

  private handleCreateInlineOutput(documentId: string) {
    this.loading.finalization = false;
    openPdfWindow(this.learnerId, documentId);
  }

  goToIep(iepId: string) {
    this.router.navigate(['/', 'cases', this.caseId, 'iep', iepId, 'profile']);
  }

  goToBip() {
    this.router.navigateByUrl(`/cases/${this.caseSummary.id}/behavior-discipline/bip/${this.caseSummary.bipId}/overview`);
  }

  onComingSoon(): void {
    this.dialog.open(DialogComingSoonComponent);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public showActions(iep: IepGetByCaseId): boolean {
    return (
      iep.iepStatus !== IepStatus.Legacy &&
      ((!this.isPortalUser &&
        (iep.showESY ||
          !!this.caseSummary?.bipId ||
          this.canResetDates(iep) ||
          (this.achieveSettings.printAssociatedDocuments && iep?.documents?.length > 0))) ||
        (this.isPortalUser && (iep.iepStatus === IepStatus.Active || iep.iepStatus === IepStatus.Complete)))
    );
  }

  canResetDates(iep: IepGetByCaseId) {
    return this.authService.isSuperAdmin && iep.startDate && !iep.isModifying;
  }

  onResetDates(iep: IepGetByCaseId) {
    const data = {
      isIfsp: false,
      isInterim: iep.iepType === IepType.Interim,
      isIep: true,
      startDate: iep.startDate,
      endDate: iep.endDate,
    };
    const dialogRef = this.dialog.open(IfspIepResetDateComponent, { data, width: '728px' });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.iepService.resetDates(iep.id, res).subscribe(
          (response) => {
            if (response) {
              this.notificationService.success('IEP date reset success!');
              this.loadData();
            }
          },
          (error) => {
            this.notificationService.error('IEP date reset failed.');
            console.log(error);
          }
        );
      }
    });
  }

  onPrintGoalsProgressReport() {
    if (this.achieveSettings.featureFlags.outputIepGoalProgress) {
      this.loading.iepGoalProgressOutput = true;
      this.reportingService.createIepGoalProgressReport(this.activeIEP.id, true).subscribe({
        next: (documentId: string) => openDocumentWindow(this.learnerId, documentId),
        error: (err) => this.reportingService.handleOutputError(err),
        complete: () => (this.loading.iepGoalProgressOutput = false),
      });
    } else {
      this.onComingSoon();
    }
  }

  onPrintDocuments(iep: IepGetByCaseId) {
    this.dialog.open(PrintDocumentsModalComponent, {
      width: '728px',
      data: {
        documents: iep.documents,
        type: 'iep',
      },
    });
  }

  onPrintIEP(iepId: string) {
    const dialogRef = this.dialog.open(IepPrintModalComponent, {
      width: '450px',
      data: {
        iepId: iepId,
        learnerId: this.learnerId,
      },
    });
  }

  onChangeToKIEP(iepId: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Change IEP to Kindergarten',
        message: 'Clicking Ok will change the PK IEP to K.  Click Cancel to exit.',
      },
    });
    dialogRef.afterClosed().subscribe((val) => {
      if (val) {
        this.iepService.changeIepPkToK(iepId).subscribe(async (_) => {
          await this.loadData();
        });
      }
    });
  }

  getLastIepDocumentId(iep: IepGetByCaseId) {
    if (iep) {
      if (!iep.amendments || iep.amendments?.length == 0) return iep.iepDocumentId;

      return (
        iep.amendments?.sort((a, b) => {
          return Date.parse(b.finalizeDate) - Date.parse(a.finalizeDate);
        })[0].outputId || iep.iepDocumentId
      );
    }
  }

  canDeleteIep(iep: IepView) {
    return this.authService.isSuperAdmin && iep.iepStatus === IepStatus.Draft;
  }

  onDeleteIep(iep: IepView) {
    this.notificationService.areYouSure(
      'Pressing Yes below will delete the data entered for this Draft IEP but will leave uploaded documents available in the Documents stepper.',
      () => {
        // delete.
        this.iepService.deleteIep(iep.id).subscribe(async (_) => {
          await this.loadData();
        });
      }
    );
  }
}
