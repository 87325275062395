import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import { AuthUser } from 'src/app/auth/auth-user';
import { AuthService } from 'src/app/auth/auth.service';
import { Aea } from 'src/app/shared/models/aea';
import { CaseSummary } from 'src/app/shared/models/case';
import { FamilyMemberType, LearnerSummary } from 'src/app/shared/models/learner';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { longDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ReferralService } from 'src/app/child-find/early-access-referral/early-access-referral.service';

@Component({
  selector: 'app-family-contact-letter',
  templateUrl: './family-contact-letter.component.html',
  styleUrls: ['./family-contact-letter.component.scss'],
})
export class FamilyContactLetterComponent implements OnInit {
  case: CaseSummary;
  learner: LearnerSummary;
  user: AuthUser;
  aea: Aea;
  referralSourceName: string;
  longDateFormat = longDateFormat;
  isParent: boolean;

  constructor(
    public authService: AuthService,
    private caseService: CaseService,
    private referralService: ReferralService,
    private route: ActivatedRoute
  ) {}

  date = new Date();
  fourteenDaysFromNow = dayjs(this.date).add(14, 'day').toDate();

  ngOnInit(): void {
    this.user = this.authService.user;
    this.aea = this.authService.user.aeas[0];
    this.caseService.getCaseSummary(this.route?.snapshot.paramMap.get('caseId')).subscribe((caseResponse) => {
      this.case = caseResponse;
      this.learner = caseResponse.learner;
      this.referralService.getReferral(caseResponse.earlyAccessReferralId).subscribe((referral) => {
        switch (referral.referralSource) {
          case FamilyMemberType.Parent1: {
            this.referralSourceName = referral.parentInfo.parent1Name;
            this.isParent = true;
            break;
          }
          case FamilyMemberType.Parent2: {
            this.referralSourceName = referral.parentInfo.parent2Name;
            this.isParent = true;
            break;
          }
          default: {
            this.referralSourceName = referral.referralSourceInfo.referralSourceName;
            this.isParent = false;
            break;
          }
        }
      });
    });
  }

  print() {
    window.print();
  }
}
