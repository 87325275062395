<section class="registration-form">
  <mat-card *ngIf="invitationVerifyDto">
    <mat-card-title><h2 class="confirm-title">ACHIEVE Family Portal</h2></mat-card-title>
    <mat-card-subtitle>Confirm Invitation</mat-card-subtitle>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <ng-container class="child-verification-form" [formGroup]="formGroup">
            <div class="mb-0">
              <app-text-field label="Learner's First Name" formControlName="learnerFirstName"></app-text-field>

              <app-text-field label="Learner's Last Name" formControlName="learnerLastName"></app-text-field>

              <app-date-picker label="Learner's DOB" controlName="learnerDateOfBirth" [startView]="'multi-year'"></app-date-picker>
            </div>

            <hr class="input__divider" />

            <ng-container *ngIf="!invitationVerifyDto.hasPassword">
              <div class="mb-0">
                <app-text-field label="Your First Name" formControlName="familyMemberFirstName"></app-text-field>

                <app-text-field label="Your Last Name" formControlName="familyMemberLastName"></app-text-field>

                <app-text-field label="Your Email" formControlName="familyMemberEmail"></app-text-field>
              </div>
            </ng-container>

            <app-text-field label="Your Password" formControlName="password" [inputType]="'password'"></app-text-field>

            <ng-container *ngIf="!invitationVerifyDto.hasPassword">
              <app-text-field label="Confirm Your Password" formControlName="confirmedPassword" [inputType]="'password'"></app-text-field>

              <mat-error *ngIf="formGroup.touched && formGroup.hasError('passwordMismatch')"
                >Password and Confirm Password do not match.</mat-error
              >

              <p>
                <b>Password requirements</b><br />
                <small>
                  A minimum of 8 characters <br />
                  Must have at least one uppercase letter <br />
                  Must have at least one lowercase letter <br />
                  Must have a number <br />
                  Must have any one of these special characters (#?!@$%^&*-)
                </small>
              </p>
            </ng-container>

            <div class="mt-2" *ngIf="!invitationVerifyDto.hasPortalAccount">
              <div class="consent-container">
                <h3>Read and Accept Terms and Conditions</h3>
                <div>
                  The ACHIEVE Family Portal is a tool developed for parents/legal guardians ("Guardians"), students who have reached the age
                  of majority, and other students with parent permission (collectively, "Students") to access timely and secure information
                  online. Access to the portal provides many benefits, some of which include:
                  <ul>
                    <li>
                      Easy access to all information and documentation in one centralized portal, including historical data such as
                      assessment information, evaluations, and IEP / IFSP documents
                    </li>
                    <li>A learner calendar, with information about pending items related to your child</li>
                    <li>Easy methods for contacting your child's IEP Facilitator or IFSP Service Coordinator</li>
                    <li>Direct access to family surveys</li>
                    <li>Access to a wide array of online help and resources</li>
                  </ul>
                </div>

                <div class="mt-2">
                  All of these things make it easier to participate in your child’s education and improve communication between all team
                  members.
                  <br />
                  This access is a free service available to all current and active Guardians and Students. Once a child exits IDEA
                  services, such access will be deactivated after 90 days.
                </div>

                <div class="mt-2">
                  By agreeing to activate this user account, you agree to abide by and support the terms of use and user expectations set
                  forth here:
                  <ul>
                    <li>
                      All Guardians who use the Portal to access their child(ren)'s information consent to electronic monitoring and
                      understand that they are granted access to a private network used as an educational tool by the IDE employees.
                    </li>
                    <li>Account activity is electronically recorded.</li>
                    <li>
                      IDE reserves the right to discontinue the use of the Family Portal at any time, with, or without, notice. In addition,
                      IDE reserves the right to modify or terminate any user’s access to the Portal temporarily or permanently at any time,
                      at its sole discretion, with notice.
                    </li>
                    <li>
                      IDE reserves the right to add, modify or delete functions viewed via the Family Portal at any time without notice.
                    </li>
                    <li>
                      IDE reserves the right, at its discretion, to modify, add, or delete provisions of the Terms and Conditions of Use. It
                      is your responsibility to periodically review the Terms and Conditions of Use for such changes. Your continued use of
                      the Portal after such changes have been made and posted on the website indicates your acceptance of such changes.
                    </li>
                    <li>
                      IDE, in its sole discretion, reserves the right to request that Guardians or Students bring photo identification, or
                      other proof of identity, to the school in order to verify their identity should any question arise concerning the
                      validity of the acceptance of this Agreement.
                    </li>
                    <li>
                      All users must act in a responsible, ethical, and legal manner. If you are identified as a security risk, you will be
                      denied access to the site. You must:
                      <ul>
                        <li>Not attempt to harm or destroy the school, the District's data, or networks.</li>
                        <li>
                          Not attempt to access information concerning any students other than that of your own child(ren) or any account
                          assigned to another person.
                        </li>
                        <li>
                          Not use the Portal or the state's networks for any illegal activity, including, but not limited to violation of
                          Federal and State Data Privacy laws. Anyone found to be in violation of these laws will be subject to civil and/or
                          criminal prosecution.
                        </li>
                        <li>
                          Notify your school immediately if you identify a security problem within the Portal without demonstrating the
                          problem to anyone else.
                        </li>
                        <li>
                          Not share your password or allow anyone other than yourself to use your Portal account, including your own
                          child(ren).
                        </li>
                        <li>Not set any computer to automatically log in to the Portal.</li>
                        <li>Log out of your Portal user account when you are not at your computer.</li>
                        <li>Not intentionally disrupt the use of the network or devices attached to the network.</li>
                        <li>Agree that hardware or software shall not be destroyed, modified, damaged or abused in any way</li>
                        <li>
                          Not use the system to develop malicious programs or computer viruses that harass other users, infiltrate a
                          computer or computer system, damage the software components of a computer or computing system.
                        </li>
                        <li>Not load, transmit, or intentionally receive hate mail, harassing content, and other antisocial content.</li>
                        <li>
                          Not use the system to process or upload pornographic material, inappropriate text files, illegal software, or
                          files dangerous to the integrity of the local area network or any attached device.
                        </li>
                        <li>Not make unauthorized copies of software or media or other copyrighted content found on the ACHIEVE system.</li>
                      </ul>
                    </li>
                    <li>
                      Security Features
                      <ul>
                        <li>
                          Access is made available through a secure Internet site. Account holders are responsible for not sharing their
                          passwords and to properly protect or destroy any printed or electronic documentation generated from or about the
                          Portal site.
                        </li>
                        <li>
                          The users will be automatically logged off if they leave their web browser open and inactive for a period of time.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Limitation of IDE Liability: ACHIEVE will use all reasonable measures to protect student information from unauthorized
                      viewing. IDE WILL NOT BE LIABLE FOR UNAUTHORIZED USE OF THE ACHIEVE SYSTEM OR INTERNET. IDE does not promise any
                      particular level or method of access to the Portal for viewing student information. IDE will not be responsible for
                      actions taken by Guardians or Students that compromise their student information.
                    </li>
                  </ul>
                </div>
              </div>

              <div class="mt-3">
                <app-checkbox-single
                  formControlName="consentAccepted"
                  aria-labelledby="Accept Terms and Conditions"
                  label="Accept Terms & Conditions"
                >
                </app-checkbox-single>
              </div>

              <div class="mt-4">
                As a Guardian or parent, you have the option for full two-way, interactive communication with this portal, or you may choose
                to limit your account by choosing a view-only registration. By opting for the limited, "view only" version of the portal,
                you will lose the ability to interact with the portal in any capacity, including, but not limited to, losing the ability
                for:
                <ul>
                  <li>Electronically signing consent forms</li>
                  <li>Accepting formal meeting requests</li>
                  <li>Completing IEP review surveys</li>
                </ul>

                <div class="mt-2">
                  <app-radio-group
                    label="Access preference"
                    formControlName="accessPreference"
                    [options]="accessPreferences"
                  ></app-radio-group>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div class="row">
        <div class="col">
          <button
            mat-flat-button
            aria-label="Complete Button"
            class="buttons mt-3"
            color="primary"
            tabindex="0"
            (click)="onRegister()"
            [isBusy]="isBusy"
            [disabled]="!formGroup.valid"
          >
            Submit
          </button>
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
</section>
