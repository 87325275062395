import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, Optional, Self, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';
import { KeyValuePair } from '../../../models/key-value-pair';
import { LoggerService } from '../../../services/logger/logger.service';
import { BaseWrapperComponent } from '../base-wrapper.component';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
})
export class RadioGroupComponent extends BaseWrapperComponent implements OnInit, OnChanges {
  optionsBacking: KeyValuePair[];

  @Input() hideAsterisk = false;

  @Input() wrap = false;

  @Input() column = false;

  @Input() useUndo = true;

  @Input() inline = false;

  @Input() noLabelAsterisk = false;

  @Input() defaultValue: string | boolean = '';

  @Input()
  set options(value: KeyValuePair[]) {
    this.optionsBacking = value;
  }

  constructor(
    logger: LoggerService,
    @Self()
    @Optional()
    ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(logger, ngControl, changeDetectorRef);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.disabledState) {
      this.useUndo = false;
    }
    if (this.control.value === null || this.control.value === undefined) this.control.setValue(this.defaultValue);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (this.disabledState) {
      this.useUndo = false;
    }
  }

  onClear() {
    this.control.setValue(null);
    this.control.markAsDirty();
    this.control.markAsTouched();
    this.control.updateValueAndValidity();
  }

  selectionChanged(event) {
    this.writeValue(event.value);
  }
}
