<ng-container [formGroup]="goalFormGroup">
  <div class="row">
    <div class="col-md-12 col-lg-6 col-xl-4">
      <app-date-picker label="Date" controlName="date" [min]="minDate" [max]="today"></app-date-picker>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-8">
      <div class="display-flex align-items-baseline">
        <p class="my-0 mr-2">Target Value: {{ goal.primaryMeasurement.targetValue }}</p>
        <p class="my-0 mr-2">Baseline: {{ goal.primaryMeasurement?.baseline }}</p>
      </div>
    </div>
    <div class="col-md-12">
      <app-textarea
        label="Monitoring Notes"
        formControlName="note"
        [maxLength]="10000"
        [idPrefix]="goal.primaryMeasurement.iepGoalQuantifiableMeasurementId"
      ></app-textarea>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-lg-6 col-xl-4">
      <app-autocomplete
        [showAsterisk]="goalFormGroup.get('phaseLineNote').hasError('required')"
        label="Phase Line"
        formControlName="phaseLineNote"
        [options]="phaseLineOptions"
      ></app-autocomplete>
    </div>
    <div class="col-md-12">
      <div *ngIf="isOtherPhaseLineSelected">
        <app-textarea label="Other" formControlName="phaseLineNoteOther" [maxLength]="10000"></app-textarea>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-lg-6 col-xl-4">
      <app-autocomplete
        [showAsterisk]="goalFormGroup.get('commentNote').hasError('required')"
        label="Comment"
        formControlName="commentNote"
        [options]="commentOptions"
      ></app-autocomplete>
    </div>
    <div class="col-md-12">
      <div *ngIf="isOtherCommentSelected">
        <app-textarea label="Other" formControlName="commentNoteOther" [maxLength]="10000"></app-textarea>
      </div>
    </div>
  </div>
  <div class="display-flex justify-content-end mb-2">
    <button mat-raised-button aria-label="Complete" color="primary" (click)="onSubmit()" [disabled]="goalFormGroup.invalid" tabindex="0">
      Complete
    </button>
  </div>
</ng-container>
<br />
<ng-container [formGroup]="objectiveFormGroup">
  <div class="row">
    <div class="col-md-12 col-lg-6 col-xl-4">
      <app-date-picker label="Date" controlName="date" [min]="minDate" [max]="today"></app-date-picker>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-8">
      <div class="display-flex align-items-baseline">
        <p class="my-0 mr-2">Target Value: {{ goal.primaryMeasurement.targetValue }}</p>
        <p class="my-0 mr-2">Baseline: {{ goal.primaryMeasurement?.baseline }}</p>
      </div>
    </div>
  </div>
  <ng-container formArrayName="objectives">
    <ng-container *ngFor="let control of objectiveFormGroupFormArray.controls; let i = index" [formGroupName]="i">
      <div class="row">
        <div class="col">
          <h4>
            {{ control.get('name').value }}
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <app-number-field
            label="Value"
            formControlName="value"
            [min]="0"
            [idPrefix]="goal.primaryMeasurement.iepGoalQuantifiableMeasurementId"
          ></app-number-field>
        </div>
        <div class="col-10">
          <app-textarea
            label="Monitoring Notes"
            formControlName="note"
            [maxLength]="10000"
            [idPrefix]="goal.primaryMeasurement.iepGoalQuantifiableMeasurementId"
          ></app-textarea>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div class="display-flex justify-content-end mb-2">
    <button
      mat-raised-button
      aria-label="Complete"
      color="primary"
      (click)="onObjectivesSubmit()"
      [disabled]="objectiveFormGroup.invalid"
      tabindex="0"
    >
      Complete
    </button>
  </div>
</ng-container>
<ng-container>
  <mat-accordion class="accordion accordion--short" multi *ngIf="goal.objectives[0]?.primaryMeasurement?.scaleRatings?.length > 0">
    <mat-expansion-panel class="mat-elevation-z0 mt-2">
      <mat-expansion-panel-header>
        <mat-panel-title> <h3 class="my-0 text-normal">Scale Rating</h3></mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row">
        <div class="col">
          <table mat-table class="w-100" [dataSource]="goal.objectives[0]?.primaryMeasurement?.scaleRatings">
            <ng-container matColumnDef="rating">
              <th mat-header-cell *matHeaderCellDef>Rating</th>
              <td mat-cell *matCellDef="let element">
                {{ element.rating }}
              </td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Description</th>
              <td mat-cell *matCellDef="let element">
                {{ element.description }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
