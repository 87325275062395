<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Application Error</h3>
<mat-dialog-content>
  <ng-container *ngIf="!config?.devMode; else devModeTemplate">
    <div class="detail-card">
      We've encountered an unexpected error and cannot complete this operation. Please try again. If the problem persists, please contact
      achievetestingsupport@anlar.com.<br />
      You can also click "Generate" to produce a log file to attach to an email.
    </div>
  </ng-container>

  <ng-template #devModeTemplate>
    <div class="detail-card">
      <header>Please Provide the following details to the Development Team:</header>
      <code>
        <pre><p>{{config?.details | json}}</p></pre>
      </code>
    </div>
    <app-page-alert
      *ngIf="config?.details?.connectionError"
      [alert]="{
        status: 'info',
        message: 'This seems to be a Server/Connection error. Please, try again after a few minutes.'
      }"
      aria-live="polite"
    >
    </app-page-alert>
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button [mat-dialog-close]="true" (click)="close()" cdkFocusInitial color="primary" mat-raised-button aria-label="Ok" tabindex="0">
    OK
  </button>
  <button
    *ngIf="config?.devMode"
    color="primary"
    mat-raised-button
    aria-label="Copy details to Clipboard"
    [cdkCopyToClipboard]="config?.details | json"
    tabindex="0"
  >
    Copy details to Clipboard
  </button>
  <button (click)="generateHar()" color="primary" mat-raised-button aria-label="Generate Log" tabindex="0">Generate Log</button>
  <a id="blobLink" style="display: none" tabindex="0" role="link"></a>
</mat-dialog-actions>
