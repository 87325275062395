import { animate, query, style, transition, trigger, group } from '@angular/animations';

export function routerAnimation() {
  return trigger('routerAnimation', [
    transition('-1 => *', [
      query(
        ':enter',
        [
          style({
            position: 'absolute',
            maxWidth: '1270px',
            width: '100%',
            // top: '-16px',
            transform: 'translateX(-100px)',
          }),
          animate(
            '300ms ease',
            style({
              opacity: 1,
              transform: 'translateX(0%)',
            })
          ),
        ],
        { optional: true }
      ),
    ]),

    // Previous, slide left to right to show left page
    transition(':decrement', [
      // set new page X location to be -100%
      query(
        ':enter',
        style({
          position: 'absolute',
          maxWidth: '1270px',
          width: '100%',
          // top: '-16px',
          transform: 'translateX(-100px)',
        })
      ),

      group([
        // slide existing page from 0% to 100% to the right
        query(
          ':leave',
          animate(
            '0s ease',
            style({
              position: 'absolute',
              opacity: 0,
            })
          ),
          { optional: true }
        ),
        // slide new page from -100% to 0% to the right
        query(
          ':enter',
          animate(
            '300ms ease',
            style({
              opacity: 1,
              transform: 'translateX(0%)',
            })
          ),
          { optional: true }
        ),
      ]),
    ]),

    // Next, slide right to left to show right page
    transition(':increment', [
      // set new page X location to be 100%
      query(
        ':enter',
        style({
          position: 'absolute',
          maxWidth: '1270px',
          width: '100%',
          // top: '-16px',
          transform: 'translateX(100px)',
        })
      ),

      group([
        // slide existing page from 0% to -100% to the left
        query(
          ':leave',
          animate(
            '0ms ease',
            style({
              position: 'absolute',
              opacity: 0,
            })
          ),
          { optional: true }
        ),
        // slide new page from 100% to 0% to the left
        query(
          ':enter',
          animate(
            '300ms ease',
            style({
              opacity: 1,
              transform: 'translateX(0%)',
            })
          ),
          { optional: true }
        ),
      ]),
    ]),
  ]);
}

// import { trigger, transition, style, query, animateChild, group, animate } from '@angular/animations';

// export const slideInAnimation =
//   trigger('routeAnimations', [
//     transition('LearnerDashboard => ContactConsent', [
//       style({ position: 'relative' }),
//       query(':enter, :leave', [
//         style({
//           position: 'absolute',
//           left: 0,
//           top: '-16px',
//           width: '100%'
//         })
//       ]),
//       query(':enter', [
//         style({ left: '-100px'})
//       ]),
//       query(':leave', animateChild()),
//       group([
//         query(':leave', [
//           animate('0ms ease-out', style({ opacity: 0 }))
//         ]),
//         query(':enter', [
//           animate('300ms ease-out', style({ left: '0%'}))
//         ])
//       ]),
//       query(':enter', animateChild()),
//     ]),
//     transition('ContactConsent => LearnerDashboard', [
//       style({ position: 'relative' }),
//       query(':enter, :leave', [
//         style({
//           position: 'absolute',
//           right: 0,
//           top: '-16px',
//           width: '100%'
//         })
//       ]),
//       query(':enter', [
//         style({ right: '-100px'})
//       ]),
//       query(':leave', animateChild()),
//       group([
//         query(':leave', [
//           animate('0ms ease-out', style({ opacity: 0 }))
//         ]),
//         query(':enter', [
//           animate('300ms ease-out', style({ right: '0%'}))
//         ])
//       ]),
//       query(':enter', animateChild()),
//     ]),
//   ]);
