<form class="child-verification-form" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div>
    <h1 class="mt-0">Create a password</h1>
    <app-text-field label="Username" formControlName="username"></app-text-field>

    <app-text-field label=" Password" formControlName="password" [inputType]="'password'"></app-text-field>

    <app-text-field label="Confirm Password" formControlName="confirmedPassword" [inputType]="'password'"></app-text-field>

    <mat-error *ngIf="formGroup.touched && formGroup.hasError('passwordMismatch')">Passwords must match</mat-error>

    <p>
      <b>Password requirements</b><br />
      <small>
        A minimum of 8 characters <br />
        Must have at least one uppercase letter <br />
        Must have at least one lowercase letter <br />
        Must have a number <br />
        Must have any one of these special characters (#?!@$%^&*-)
      </small>
    </p>

    <button
      mat-flat-button
      aria-label="Complete"
      #submitButton
      class="buttons w-100 mt-3"
      type="submit"
      color="primary"
      tabindex="0"
      [disabled]="!formGroup.valid"
    >
      Complete
    </button>
  </div>
  <mat-error *ngIf="registerError">{{ registerError }}</mat-error>
</form>
