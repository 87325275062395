import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, NgZone } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { JL } from 'jsnlog';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth/auth.service';
import { GlobalErrorModalComponent } from './shared/components/global-error-modal/global-error-modal.component';
import { NotificationService } from './shared/services/notification.service';
import { AuthUser } from './auth/auth-user';
import { LoggerService } from './shared/services/logger/logger.service';
import { JsnLoggerFactory } from './shared/services/logger/jsn-logger.factory';
import { CorrelationService } from './shared/services/logger/correlation.service';
import { JsnLoggerService } from './shared/services/logger/jsn-logger.service';

@Injectable({
  providedIn: 'root',
})
export class CustomErrorHandler implements ErrorHandler {
  private isProduction = environment.production;
  private isOpen = false;
  private offlineModalOpen = false;
  private logger: JsnLoggerService;

  constructor(
    private readonly loggerService: LoggerService,
    private dialog: MatDialog,
    private locationStrategy: LocationStrategy,
    private authService: AuthService,
    private notificationService: NotificationService,
    private ngZone: NgZone,
    private correlationService: CorrelationService,
    private loggerFactory: JsnLoggerFactory
  ) {
    this.logger = this.loggerFactory.create(this);
  }

  handleError(error) {
    this.ngZone.run(() => {
      const user = this.authService?.user?.fullName;
      const originalError = error.originalError || error;
      if (error instanceof HttpErrorResponse || (error.rejection && error.rejection instanceof HttpErrorResponse)) {
        // Server or connection error happened
        if (!navigator.onLine) {
          if (!this.offlineModalOpen) {
            this.offlineModalOpen = true;
            this.notificationService.alert('No internet connection.', null, () => {
              this.offlineModalOpen = false;
            });
          }
        } else {
          const httpError = error instanceof HttpErrorResponse ? error : error.rejection;
          if (httpError.status === 0 || (httpError.status >= 500 && httpError.status <= 599)) {
            const connectionError = httpError.status === 0 || httpError.status === 503;
            this.displayModal(this.addContextInfo(httpError, user, connectionError));
          } else if (httpError.status === 403) {
            this.notificationService.alert("You're not authorized to perform this action", 'Unauthorized');
          }
        }
      } else {
        // Handle Client Error (Angular Error, ReferenceError...)
        this.displayModal(this.addContextInfo(error, user));
      }
      this.loggerService.error(originalError);

      this.logger.error(this.createJlErrorObject(error, this.authService?.user));
    });
  }
  createJlErrorObject(error: any, authUser: AuthUser) {
    const obj = {};
    const user = authUser?.fullName;
    authUser?.claims?.forEach((e: { type: string; value: string | number }) => {
      obj[e.type.split(/[\/]+/).pop()] = e.value;
    });
    if (error.rejection) {
      for (const [key, value] of Object.entries(error.rejection)) {
        obj[key] = value;
      }
    }

    return Object.assign(this.addContextInfo(error, user), obj);
  }
  addContextInfo(error: any, user: string, connectionError = false) {
    const name = error.name || null;
    const time = new Date().getTime();
    const url = this.locationStrategy instanceof PathLocationStrategy ? this.locationStrategy.path() : '';
    const status = error.status || null;
    const message = error.message || error.toString();
    const err = error.error || null;
    const stackTrace = error.stack || null;

    return {
      name,
      user,
      time,
      url,
      status,
      message,
      error: err,
      stackTrace,
      connectionError,
    };
  }

  displayModal(details: any) {
    if (this.isOpen) {
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '748px';
    dialogConfig.data = {
      devMode: !this.isProduction,
      details,
    };
    this.isOpen = true;
    const dialogRef = this.dialog.open(GlobalErrorModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => (this.isOpen = false));
  }
}
