<button
  mat-flat-button
  aria-label="Add Icon"
  color="primary"
  *ngIf="hasOpenAmendment"
  class="mr-2"
  (click)="$event.stopPropagation(); addNew()"
>
  Add New
</button>

<button mat-raised-button color="accent" aria-labelledby="Open tags in new window" (click)="openTags()">View IEP Goal Tags</button>

<ng-container *ngIf="dataSource.data.length === 0">No Goals added yet</ng-container>

<div *ngIf="dataSource.data.length > 0" class="table-overflow app-table">
  <table mat-table [dataSource]="dataSource" matSort class="w-100">
    <ng-container matColumnDef="actions">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-actions mat-column-actions--more">Actions</th>
      <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
        <div class="display-flex align-items-center justify-content-start">
          <button aria-label="View Icon" mat-icon-button color="primary" (click)="onViewGoal(element)" tabindex="0">
            <mat-icon>remove_red_eye</mat-icon>
          </button>
          <ng-container *ngIf="!isEditing">
            <button mat-icon-button aria-label="Quick Access Menu" color="primary" [matMenuTriggerFor]="actionMenu">
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #actionMenu="matMenu">
              <button
                mat-menu-item
                aria-labelledby="Add Progress Monitoring"
                *ngIf="!element.amendmentEndDate"
                (click)="openComingSoon()"
                tabindex="0"
              >
                Add Progress Monitoring
              </button>
              <button mat-menu-item aria-label="View Graphs" *ngIf="element.displayGraph" (click)="onOpenGraph(element)" tabindex="0">
                View Graphs
              </button>
              <button mat-menu-item aria-label="Amend" *ngIf="canAmend(element)" (click)="onEdit(element)" tabindex="0">Amend</button>
              <button *ngIf="canUndo(element)" mat-menu-item aria-label="Undo" (click)="onRemove(element, true)" tabindex="0">Undo</button>
              <button *ngIf="canRemove(element)" mat-menu-item aria-label="Delete Icon" (click)="onRemove(element)" tabindex="0">
                Remove
              </button>
              <button
                mat-menu-item
                aria-label="View Audit Log"
                *ngIf="canViewAuditLog(element)"
                (click)="viewAuditLog(element)"
                tabindex="0"
              >
                View Audit Log
              </button>
              <button *ngIf="canEnd(element)" (click)="onEnd(element)" mat-menu-item aria-label="End">End</button>
              <button
                mat-menu-item
                aria-label="Change Providers"
                *ngIf="!anyEndDatesEqualOrPast(element)"
                (click)="onChangeProviders(element)"
                tabindex="0"
              >
                Change Providers
              </button>
            </mat-menu>

            <ng-container *ngIf="hasOpenAmendment">
              <div class="fake-icon-button" *ngIf="!element.isComplete">
                <mat-icon class="icon-incomplete" aria-label="Warning">warning</mat-icon>
              </div>
            </ng-container>

            <ng-container *ngIf="anyEndDatesInPast(element)">
              <div class="fake-icon-button">
                <mat-icon aria-labelledby="Check">check</mat-icon>
              </div>
            </ng-container>

            <div *ngIf="element.amendmentEndDate && !element.isActive" class="fake-icon-button">
              <i class="fas fa-calendar-times icon-incomplete"></i>
            </div>
          </ng-container>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="goalDomain">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Goal Area / Domain</th>

      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.amendmentEndDate"
          [priorVersionId]="element.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="getGoalDomainText(element)"
          [currentVersionText]="getGoalDomainText(element?.priorVersion)"
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        >
        </app-compare-amendment-output>
      </td>
    </ng-container>

    <ng-container matColumnDef="goalSentence">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Goal Sentence</th>

      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.amendmentEndDate"
          [priorVersionId]="element.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="element.conditionIndividualWillPerform"
          [currentVersionText]="element.priorVersion?.conditionIndividualWillPerform"
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </td>
    </ng-container>

    <ng-container matColumnDef="monitorFreq">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Monitoring Frequency</th>

      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.amendmentEndDate"
          [priorVersionId]="element.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="getFrequencyAmountText(element)"
          [currentVersionText]="getFrequencyAmountText(element?.priorVersion)"
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [dataSource]="dataSource"></mat-paginator>
</div>
<p *ngIf="!dataSource">No data yet...</p>
<mat-card class="mt-2" [style.display]="showGoalsForm ? 'block' : 'none'">
  <app-iep-goals
    #goalsComponent
    [amendingGoal]="amendingGoal"
    [amendmentId]="amendment?.id"
    [amendments]="amendments"
    [behaviorAddressed]="behaviorAddressed"
    [isPK]="isPK"
  ></app-iep-goals>
</mat-card>
