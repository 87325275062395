import { ReferralReasonInformation, ReferralSourceInformation } from '../../child-find/early-access-referral/referral';
import { IepLearnerInfo, IepStatus } from '../../iep/models/iep';
import { Aea } from './aea';
import { Building } from './building';
import { CaseCreate, IntakeType } from './case';
import { District } from './district';
import { LookupBase } from './lookup-base';
import { Surrogate } from './surrogate';

export interface LearnerCaseSummary {
  id: string;
  intakeType: IntakeType;
  owner: string;
}

export interface LearnerSummary {
  showTransitionPlanning: boolean;
  showTransitionPlanningStepper: boolean;
  hasIep: boolean;
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  fullName: string;
  dateOfBirth: Date;
  age: number;
  ageInMonths: string;
  stateAssignedId: string;
  gender: string;
  county: string;
  familyMembers: FamilyMember[];
  surrogates: Surrogate[];
  cannotEditFamilyInfo: boolean;
  building: Building;
  gradeDescription: string;
  attendingAeaId: string;
  attendingAea: string;
  attendingDistrictId: string;
  attendingDistrict: string;
  residentDistrictId: string;
  residentAeaId: string;
  residentAea: string;
  residentDistrict: string;
  attendingBuilding: string;
  domicileDistrictId: string;
  domicileDistrict: string;
  domicileBuildingId: string;
  domicileBuilding: string;
  hostDistrictId: string;
  hostDistrict: string;

  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  activeCases: Array<LearnerCaseSummary>;
  hasWorkableCase: boolean;
  serviceCoordinator: string;
  serviceCoordinatorPhone: string;
  serviceCoordinatorEmail: string;

  initialDueDate: Date;
  referralDate?: Date;
  fiieFormCompletition?: Date;
  partCEligibilityDecision?: boolean;
  partBEligibilityDecision?: boolean;
  eligibilityDeterminationDueDate?: Date;
  eligibilityMeetingDate?: Date;
  eligibilityFacilitatorName: string;
  eligibilitySubmittedOnIntake?: boolean;
  movingFromOutOfState?: boolean;
  eligibleInLegacySystem?: boolean;
  hasMoveInDetails?: boolean;
  eligibleInLegacySystemPartC?: boolean;

  progress: string;
  disabilitySuspectFormSubmitted?: boolean;
  showEvaluation: boolean;
  showDocuments: boolean;
  isSecondaryTransition?: boolean;
  hasIfsp: boolean;
  finalizedIfsp: boolean;

  email: string;
  kindergartenStartDate?: Date;
  languageSpokenInHome: string;

  iepInformation: IepLearnerInfo;
  reevaluationDueOn?: Date;
  isMigrated: boolean;
  isExceptionDestroyData: boolean;

  isPK: boolean;
  finalEcoRatingPkStatus: FinalEcoRatingPkStatus;
  isEligibleForFamilyPortal: boolean;
  sifIdUpdatedOn?: Date;
  hasPortalAccess: boolean;

  studentPersonal: any;

  currentEnrollment: any;
}

export interface Learner {
  id: string;
  stateAssignedId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  fullName: string;
  dateOfBirth: string;
  isLessThanThree: boolean;
  isLessThanFive: boolean;
  age: number;
  isPK: boolean;
  intakeType: IntakeType;

  gender: LookupBase;
  languageSpokenInHome: LookupBase;
  interpreterNeeded: boolean;

  streetAddress: string;
  city: string;
  county: string;
  state: string;
  zipCode: string;

  familyMembers: FamilyMember[];

  grade: string;
  gradeDescription: string;
  residentAea: Aea;
  attendingAea: Aea;
  residentDistrict: District;
  attendingDistrict: District;
  attendingAeaId: string;
  attendingDistrictId: string;
  residentAeaId: string;
  residentDistrictId: string;

  initialDueDate?: Date;
  referralDate?: Date;
  serviceCoordinator: string;
  fiieFormCompletition?: Date;
  eligibilityDeterminationDueDate?: Date;
  eligibilityMeetingDate?: Date;
  eligibilityFacilitatorName?: string;
  isSecondaryTransition?: boolean;
  eligibilitySubmittedOnIntake: boolean;
  isExceptionDestroyData: boolean;
}

export interface LearnerCreate {
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: Date;
  genderId: string;

  streetAddress: string;
  city: string;
  county: string;
  state: string;
  zipCode: string;

  movingFromOutOfState?: boolean;
  eligibleInLegacySystem?: boolean;
  eligibleInLegacySystemPartC?: boolean;

  languageSpokenInHomeId: string;
  interpreterNeeded: boolean;

  residentAeaId: number;
  residentDistrictId: number;
  attendingDistrictId: number;
  attendingBuildingId: string;

  referralId: string;
  performedById: string;

  familyMembers: FamilyMemberCreate[];
  case: CaseCreate;
  newFromSif: boolean;
}

export interface LearnerUpdate {
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: Date;
  genderId: string;

  streetAddress: string;
  city: string;
  county: string;
  state: string;
  zipCode: string;

  languageSpokenInHomeId: string;
  interpreterNeeded: boolean;

  residentAeaId: number;
  residentDistrictId: number;
  attendingDistrictId: number;
}

export interface FamilyMember {
  id: string;
  familyUserId?: string;
  fullName: string;
  email: string;
  type: FamilyMemberType;
  livesWithChild?: boolean;
  homePhone: string;
  workPhone: string;
  cellPhone: string;
  familyRelationship?: string;
  familyRelationshipId?: string;
  bestWayToReachHomePhone?: boolean;
  bestWayToReachCellPhone?: boolean;
  bestWayToReachWorkPhone?: boolean;
  bestWayToReachEmail?: boolean;
  bestWayToReachText?: boolean;
  bestTimeToContact?: string;
  isPersonCompletingReferral?: boolean;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  isUserAccountActive: boolean;
  isFamilyMemberReadOnly: boolean;
}

export interface FamilyMemberCreate {
  fullName: string;
  type: FamilyMemberType;
  email: string;
  livesWithChild?: boolean;
  homePhone: string;
  workPhone: string;
  cellPhone: string;
  bestWayToReachHomePhone?: boolean;
  bestWayToReachCellPhone?: boolean;
  bestWayToReachWorkPhone?: boolean;
  bestWayToReachEmail?: boolean;
  bestTimeToContact?: string;
  isPersonCompletingReferral?: boolean;
  familyRelationshipId?: string;
}

export interface FamilyMemberUpdate {
  id: string;
  fullName: string;
  type: FamilyMemberType;
  email: string;
  livesWithChild?: boolean;
  homePhone: string;
  workPhone: string;
  cellPhone: string;
  bestWayToReachHomePhone?: boolean;
  bestWayToReachCellPhone?: boolean;
  bestWayToReachWorkPhone?: boolean;
  bestWayToReachEmail?: boolean;
  bestTimeToContact?: string;
  isPersonCompletingReferral?: boolean;
  familyRelationshipId?: string;
}

export interface LearnerSearchResult {
  stateAssignedId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: Date;
  activeCase: {
    intakeType: IntakeType;
    grade: string;
    evaluation: {
      evaluationId: string;
      isFinalized: boolean;
    };
    eligibilityDecision: boolean;
    exitFinalizedOn: Date;
    facilitatorServiceCoordinator: string;
    reevaluationDueOn: Date;
    iepAnnualReviewDate: Date;
    latestIep: {
      iepId: string;
      iepStatus: IepStatus;
      latestAmmendment: {
        finalizeDate: Date;
        iepAmmendmentId: string;
      };
    };
    ifspAnnualReviewStartDate: Date;
  };
  attendingAea: string;
  attendingDistrict: string;
  zoneId: string;
  isSif: boolean;

  //These will be calculated at client-side
  grade: string;
  status: string;
  owner: string;
  annualReviewDate: Date;
  reevalDate: Date;
  sifRecord: any;
}

export interface LearnerReadonly {
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth?: Date;
  gender: string;
  languageSpokenInHome: string;
  interpreterNeeded: boolean;
  streetAddress: string;
  city: string;
  state: string;
  county: string;
  zipCode: string;

  attendsChildCare?: boolean;

  childCareProviderName: string;
  childCareProviderStreetAddress: string;
  childCareProviderCity: string;
  childCareProviderState: string;
  childCareProviderZipCode: string;
  othersInHome: string;

  familyInfo: FamilyMember[];
  referralSourceInfo: ReferralSourceInformation;
  referralReasonInfo: ReferralReasonInformation;
  residentDistrict: string;
  attendingDistrict: string;

  referralSourceHowHearAboutUs: string;
  referralSourceEarlyAccessOtherText: string;
  exchangeInformation?: boolean;
  legalGuardian?: boolean;
}

export enum FamilyMemberType {
  Parent1 = 'Parent1',
  Parent2 = 'Parent2',
  LivesWith1 = 'LivesWith1',
  LivesWith2 = 'LivesWith2',
  Relative = 'Relative',
  Friend = 'Friend',
  Guardian = 'Guardian',
  FosterParent = 'FosterParent',
  Surrogate = 'Surrogate',
  Learner = 'Learner',
}

export enum Grade {
  PreK = 'PK',
  Kindergarten = 'Kindergarten',
  First = '1',
  Second = '2',
  Third = '3',
  Fourth = '4',
  Fifth = '5',
  Sixth = '6',
  Seventh = '7',
  Eighth = '8',
  Ninth = '9',
  Tenth = '10',
  Eleventh = '11',
  Twelfth = '12',
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
}

export enum FinalEcoRatingPkStatus {
  Draft = 'Draft',
  Completed = 'Completed',
}
