<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Begin Amendment</h3>
<ng-container [formGroup]="formGroup">
  <div mat-dialog-content class="py-3">
    <div class="mb-2">
      <app-page-alert [alert]="alert" role="status" aria-live="polite"></app-page-alert>
    </div>
    <mat-card class="my-0">
      <h3 class="mt-0 mb-2 text-danger">IEP duration to date is {{ iepEndDate | dateFormat }}</h3>
      <div class="row">
        <div class="col-md-12">
          <fieldset>
            <app-radio-group label="Is a meeting required?" formControlName="meetingRequired" [options]="yesNoOptions"></app-radio-group>
          </fieldset>
        </div>
        <ng-container *ngIf="meetingRequiredHasValue && formGroup.get('meetingRequired').value === false">
          <div class="col-md-12">
            <app-select label="Parent contacted by" formControlName="personContactedParentId" [options]="personContactedParentOptions">
            </app-select>
          </div>
          <div class="col-md-12">
            <app-select label="Method of Contact" formControlName="methodOfContact" [options]="methodOfContactOptions"> </app-select>
          </div>
          <div class="col-md-12">
            <app-date-picker
              label="Date of Agreement to Amend Without a Meeting"
              controlName="dateOfAmendWithoutMeeting"
              [max]="today"
            ></app-date-picker>
          </div>
        </ng-container>
        <div class="col-md-12 mt-2">
          <app-checkbox-single
            formControlName="includesTrialPlacement"
            aria-labelledby="Include in Trial Placement"
            label="Include in Trial Placement"
          >
          </app-checkbox-single>
        </div>
        <ng-container *ngIf="doesIncludeTrialPlacement">
          <div class="col-md-12">
            <app-date-picker [min]="tenDaysPrior" label="Trial Placement End Date" controlName="trialPlacementDate"></app-date-picker>
          </div>
        </ng-container>
      </div>
    </mat-card>
  </div>

  <div mat-dialog-actions class="display-flex justify-content-end align-items-center">
    <button mat-raised-button aria-label="Cancel Button" (click)="onCancel()" cdkFocusInitial type="button" class="ml-2" tabindex="0">
      Cancel
    </button>
    <button
      mat-raised-button
      aria-label="Begin Amendment"
      color="primary"
      class="ml-2"
      [disabled]="disableSubmitBtn"
      [disableAfterBusy]="true"
      [isBusy]="submittingForm"
      (click)="onSubmit()"
      tabindex="0"
    >
      Begin Amendment
    </button>
  </div>
</ng-container>
