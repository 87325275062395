import { Component, Input, OnChanges } from '@angular/core';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { IntakeType } from '../../../models/case';
import { LearnerSummary } from '../../../models/learner';
import { LearnerService } from '../../../services/learner/learner.service';

@Component({
  selector: 'app-learner-info-header',
  templateUrl: './learner-info-header.component.html',
  styleUrls: ['./learner-info-header.component.scss'],
})
export class LearnerInfoHeaderComponent implements OnChanges {
  @Input() public learnerId: string;
  @Input() public learnerSummary: LearnerSummary;
  @Input() public fewFields = false;
  @Input() public condensed = false;
  @Input() public isPrint = false;

  shortDateFormat = shortDateFormat;

  get isPartC(): boolean {
    return this.learnerSummary?.activeCases?.some((x) => x.intakeType === IntakeType.PartC || x.intakeType === IntakeType.PartCLate);
  }

  get serviceCoordinator(): string {
    return (
      this.learnerSummary.activeCases.find((x) => x.intakeType === IntakeType.PartC || x.intakeType === IntakeType.PartCLate)?.owner ??
      'not assigned'
    );
  }

  constructor(private readonly learnerService: LearnerService) {}

  ngOnChanges() {
    if (this.learnerId && this.learnerId !== this.learnerSummary?.id) {
      this.learnerService.getLearnerSummary(this.learnerId).subscribe((res) => (this.learnerSummary = res));
    }
  }
}
